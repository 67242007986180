import { Global_var } from '../global/global_var';
import RestDataSource from '../services/restdatasource';

export const LoginService = {
    SendUser,
    LogoutUser,
    SessionUserDetails,
    TokenUser,
    ForgotPassword,
    ChangePassword,
    updateForgotPassword,
    setSubscriber,
    updateFlag
};

function SendUser(user, isMobile, fn, fnError) {
    let url = '';
    let userMob = user;
    if (!isMobile) {
        url = Global_var.BASEURL + Global_var.URL_LOGIN;
    } else {
        url = Global_var.BASEURL + Global_var.URL_LOGIN_MOBILE;
        userMob = {
            "mobileNumber": user.userLoginName,
            "otp": user.userPassword
        }
    }

    return new RestDataSource(url, fnError).Store(userMob, (res) => fn(res.data));
}

function LogoutUser(user, isMobile, fn, fnError) {
    let url = Global_var.BASEURL + Global_var.URL_LOGOUT;
    let userMob = user;
    if (isMobile) {
        userMob = {
            "mobileNumber": user.userName
        }
    }
    return new RestDataSource(url, fnError).Store(userMob, (res) => {
        localStorage.setItem('jti-token', res.headers.jtitoken);
 if( localStorage.getItem('nwesLetterEmailId'))
{
  localStorage.setItem('nwesLetterEmailId','SUBSCRIBED')
}
        fn(res.data)
    });
}

function TokenUser(fn, fnError) {
    let url = Global_var.BASEURL + Global_var.URL_JWT_TRUST;
    return new RestDataSource(url, fnError).GetData(res => fn(res.data));
}

function ForgotPassword(user, fn, fnError) {
    let url = Global_var.BASEURL + Global_var.URL_FORGOTPASSWORD;
    return new RestDataSource(url, fnError).Store(user, (res) => {
        localStorage.setItem('jti-token', res.headers.jtitoken);
        fn(res.data)
    });
}

function updateForgotPassword(user, fn, fnError) {
    let url = Global_var.BASEURL + Global_var.URL_UPDATEFORGOTPASSWORD;
    return new RestDataSource(url, fnError).Store(user, (res) => {
        localStorage.setItem('jti-token', res.headers.jtitoken);
        fn(res.data);
    });
}

function ChangePassword(user, fn, fnError) {
    let url = Global_var.BASEURL + Global_var.URL_CHANGEPASSWORD;
    return new RestDataSource(url, fnError).Store(user, (res) => {
        localStorage.setItem('jti-token', res.headers.jtitoken);
        fn(res.data)
    });
}

function SessionUserDetails(sessionEmailId, fn, fnError) {
    let url = Global_var.BASEURL + Global_var.URL_Session_DETAILS;
    return new RestDataSource(url, fnError).GetOneByParam(sessionEmailId, (res) => {
        localStorage.setItem('jti-token', res.headers.jtitoken);
        fn(res.data)
    });
}

function updateFlag(emailID, fn, fnError) {
    var url = Global_var.BASEURL + Global_var.URL_UPDATE_FLAG;
    return new RestDataSource(url, fnError).GetOneByParam(emailID, res => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }
  
        fn(res.data);
      }
    });
  }

function setSubscriber()
{
if( localStorage.getItem('nwesLetterEmailId'))
{
  localStorage.setItem('nwesLetterEmailId','SUBSCRIBED')
}
}