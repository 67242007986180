import {
  fetchUserActivityPending,
  fetchUserActivitySuccess,
  fetchUserActivityError
} from "../action/fetchUserActivity";
import {
  maintenanceFetchPending,
  maintenanceFetchSuccess,
  maintenanceFetchError,
} from "../action/maintenanceAction";
import RestDataSource from "./restdatasource";
import { Global_var } from "../global/global_var";

export function UserManagementservice(parameters) {
  let url = Global_var.BASEURL + Global_var.URL_USER_MANAGEMENT;

  return dispatch => {
    dispatch(fetchUserActivityPending());
    return new RestDataSource(url, err =>
      dispatch(fetchUserActivityError(err))
    ).GetOneByParam(parameters, res => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }
        dispatch(fetchUserActivitySuccess(res.data.responseListObject));
      }
    });
  };
}

export function fetchDeploymentFlagAPI(fn, fnError) {
  const url = `${Global_var.BASEURL}${Global_var.URL_DEPLOYMENT}`;
  // let url =
  //   "https://finxmicro.njtechdesk.com/thor-gateway-finx" +
  //   Global_var.URL_DEPLOYMENT;
  // const url ="http://192.168.71.118:8061/finx-fc-admin/api/v1/deploymentFlag";
  return (dispatch) => {
    dispatch(maintenanceFetchPending(true));
    return new RestDataSource(url, (err) => {
      if (fnError) {
        fnError(err);
      }
      maintenanceFetchError(err);
    }).GetData((res) => {
      if (["success", "SUCCESS"].includes(res.data.status)) {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          const { deploymentFlag, toDate, notificationFlag, startDate } =
            res.data.responseObject;
          setTimeout(() => {
            const validFlags = [0, 1, "0", "1"];
            const validDates = ["", null, undefined];
            if (
              validFlags.includes(deploymentFlag) &&
              validFlags.includes(notificationFlag) &&
              !validDates.includes(toDate) &&
              !validDates.includes(startDate)
            ) {
              dispatch(
                maintenanceFetchSuccess({
                  isMaintenance: deploymentFlag,
                  timeRemaining: toDate,
                  isNotified: notificationFlag,
                  timeNotiRemaining: startDate,
                  isClicked: false,
                })
              );
            }
          }, 500);
          fn(res.data);
        }
      } else {
        maintenanceFetchError(res.data.reasonText);
      }
    });
  };
}
