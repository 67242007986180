import RestDataSource from "./restdatasource";
import { Global_var } from "../global/global_var";
import { LoginService } from "./login.service";
import { fetchAdminUserPending, fetchAdminUserSuccess, fetchAdminUserError } from "../action/adminUserAction";
import { fetchDistributorListPending, fetchDistributorListSuccess, fetchDistributorListError } from "../action/distributorListAction";
import { fetchStatusCountPending, fetchStatusCountListSuccess, fetchStatusCountError } from "../action/funnelStatusCountAction";

export function addAdminUser(data, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      let url = Global_var.BASEURL + Global_var.URL_ADMIN_CREATE_USER;
      return new RestDataSource(url, fnError).Store(data, res => {
        if (res !== null) {
          if (res.headers["jtitoken"] !== null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}

export function fetchDistributorList(param, fn) {
  //var url = "http://192.168.12.113:9006"
  let url = Global_var.BASEURL + Global_var.URL_GET_ALL_DISTRIBUTOR_LIST;
  //Global_var.BASEURL
  //let url=Global_var.URL_GETDISTRIBUTOR
  return dispatch => {
    // dispatch(fetchDistributorListPending());
    return new RestDataSource(url, err =>
      dispatch(fetchDistributorListError(err.message))
    ).GetOneByParam(param, res => {
      if (res !== null) {
        if (res.headers["jtitoken"] !== null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);

          console.log("res.data.responseListObject",res.data.responseListObject)
          dispatch(fetchDistributorListSuccess(res.data.responseListObject));
          fn(res.data);
        }
      }
    });
  };
}

export function fetchDistributor(param) {
  //var url = "http://192.168.12.113:9006"
  let url = Global_var.BASEURL + Global_var.URL_GETDISTRIBUTOR;
  //Global_var.BASEURL
  //let url=Global_var.URL_GETDISTRIBUTOR
  return dispatch => {
    // dispatch(fetchDistributorListPending());
    return new RestDataSource(url, err =>
      dispatch(fetchDistributorListError(err.message))
    ).GetOneByParam(param, res => {
      if (res !== null) {
        if (res.headers["jtitoken"] !== null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);

          console.log("res.data.responseListObject",res.data.responseListObject)
          dispatch(fetchDistributorListSuccess(res.data.responseListObject));
        }
      }
    });
  };
}

export function fetchAdminUserByPartyAndRole(partyID, roleId, callback) {
  let url = Global_var.BASEURL + Global_var.URL_FETCH_ADMIN_USER_BY_ROLE_PARTY_ID + "?partyID=" + partyID + "&roleID=" + roleId;
  return dispatch => {
    dispatch(fetchAdminUserPending());
    return new RestDataSource(url, err =>
      dispatch(fetchAdminUserError(err.message))
    ).GetData(res => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }
        dispatch(fetchAdminUserSuccess(res.data.responseObject));
        callback(res.data.responseObject);
      }
    });
  };
}


export function editAdminUser(data, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      let url = Global_var.BASEURL + Global_var.URL_ADMIN_EDIT_USER;
      return new RestDataSource(url, fnError).Update(data, res => {
        fn(res.data);
        if (res !== null) {
          if (res.headers["jtitoken"] !== null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
        }
      });
    }
  });
}

export function inActiveAdminUser(data, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      let url = Global_var.BASEURL + Global_var.URL_INACTIVE_ADMIN_USER;
      return new RestDataSource(url, fnError).Update(data, res => {
        //console.log("RESPONSE", res.data);
        fn(res.data);
        if (res !== null) {
          if (res.headers["jtitoken"] !== null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
        }
      });
    }
  });
}
export function unblockUser(data, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      let url = Global_var.BASEURL + Global_var.URL_UNBLOCK_USER;
      return new RestDataSource(url, fnError).Update(data, res => {
        //console.log("RESPONSE", res.data);
        fn(res.data);
        if (res !== null) {
          if (res.headers["jtitoken"] !== null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
        }
      });
    }
  });
}


export function getFunnelCount() {

  //  var url="http://192.168.12.100:9006/fc-learner/api/v1/stage/stage_count/_get"
  let url = Global_var.BASEURL + Global_var.URL_GETFUNEL_STATUS_COUNT
  return dispatch => {
    dispatch(fetchStatusCountPending());
    return new RestDataSource(url, err =>
      dispatch(fetchStatusCountError(err.message))
    ).GetData(res => {
      if (res !== null) {
        if (res.headers["jtitoken"] !== null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);

          console.log(res)
          dispatch(fetchStatusCountListSuccess(res.data.responseListObject));
        }
      }
    });
  };
}

export function updateUserMandate(data, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      let url = Global_var.BASEURL + Global_var.URL_UPDATE_USER_MANDATE;
      return new RestDataSource(url, fnError).Update(data, res => {
        fn(res.data);
        if (res !== null) {
          if (res.headers["jtitoken"] !== null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
        }
      });
    }
  });
}
export function pdfExcelData(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_PDF_EXCEL_DATA;
  return new RestDataSource(url, fnError).Store(data, res => {
      if (res != null) {
          if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
      }
  });
}
export function getExperienceList(fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_EXPERIENCE;

  return new RestDataSource(url, fnError).GetData(res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
export function getFieldActiveFlagList(fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_FIELD_ACTIVE_FLAG;

  return new RestDataSource(url, fnError).GetData(res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
