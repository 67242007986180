import React from "react";
import "../../components/popup/ForcedPopup.css";

const FirstMessage = () => {
  return (
    <div className="notify">
      <br />
      <span
        style={{
          float: "right",
          fontSize: "30px",
          marginTop: "-27px",
          marginRight: "6px",
        }}
      >
        &times;
      </span>
      <b className="notify">Success!</b>
      <p className="notify">
        1. Thank you, we have recorded your details. NISM will be sending
        you a verification link from noreply22-certification@nism.ac.in on your
        personal or official email ID. Please click on the link to validate your
        profile.
        <br />
        2. Online self-paced study material has been assigned to you. There are
        400 questions to prepare from. CIEL will shortly contact you to block
        your NISM exam date. Prepare well!
      </p>
    </div>
  );
};
const SecondMessage = () => {
  return (
    <div className="notify">
      <br />
      <span
        style={{
          float: "right",
          fontSize: "30px",
          marginTop: "-27px",
          marginRight: "6px",
        }}
      >
        &times;
      </span>
      <b className="notify">Success!</b>
      <p className="notify">
        1. Thank you, we have recorded your details. NISM will be sending
        you a verification link from noreply22-certification@nism.ac.in on your
        personal or official email ID. Please click on the link to validate your
        profile.
        <br /> 2. CIEL will contact you shortly to block your NISM exam date.
      </p>
    </div>
  );
};

const ThirdMessage = () => {
  return (
    <div className="notify">
      <br />
      <span
        style={{
          float: "right",
          fontSize: "30px",
          marginTop: "-27px",
          marginRight: "6px",
        }}
      >
        &times;
      </span>
      <b className="notify">Success!</b>
      <p className="notify">
        1. Thank you, we have recorded your details. NISM will be sending
        you a verification link from noreply22-certification@nism.ac.in on your
        personal or official email ID. Please click on the link to validate your
        profile.
        <br /> 2. Please make the payment towards the exam fees to complete the
        registration.
      </p>
    </div>
  );
};
const DeploymentMessage = (props) => {
  return (
    <div
      className="notify"
      style={{
        backgroundColor: "rgb(199 235 0)",
        padding: "3px",
        margin: "2px",
      }}
    >
      <br />
      <span
        style={{
          float: "right",
          fontSize: "30px",
          marginTop: "-27px",
          marginRight: "6px",
        }}
      >
        &times;
      </span>
      <p
        className="notify"
        style={{
          backgroundColor: "rgb(199 235 0)",
          padding: "3px",
          margin: "2px",
        }}
      >
        <div
          className="popup-content"
          style={{
            backgroundColor: "rgb(199 235 0)",
            padding: "3px",
            margin: "2px",
          }}
        >
          <div class="deployment-wrapper">
            <div class="deployment-icons">
              <div class="deployment-icon-wrapper">
                <svg
                  class="deployment-gear deployment-gear1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M19.14 12.936c.02-.15.02-.3.02-.45s-.02-.3-.02-.45l2.06-1.6c.19-.14.22-.4.06-.58l-2-3.46c-.15-.24-.39-.34-.62-.24l-2.4.98a6.927 6.927 0 0 0-1.17-.7l-.36-2.64c-.04-.26-.24-.45-.5-.45h-4c-.26 0-.46.2-.5.45l-.36 2.64c-.43.27-.85.55-1.25.85l-2.4-.98c-.24-.1-.48-.01-.62.24l-2 3.46c-.16.18-.13.44.06.58l2.06 1.6c-.02.15-.02.3-.02.45s.02.3.02.45l-2.06 1.6c-.19.14-.22.4-.06.58l2 3.46c.15.24.39.34.62.24l2.4-.98c.4.3.82.58 1.25.85l.36 2.64c.04.26.24.45.5.45h4c.26 0 .46-.2.5-.45l.36-2.64c.43-.27.85-.55 1.25-.85l2.4.98c.24.1.48.01.62-.24l2-3.46c.16-.18.13-.44-.06-.58l-2.06-1.6zM12 15.36l-1.41-1.41 3.75-3.75 1.41 1.41-3.75 3.75z" />
                </svg>
                <svg
                  class="deployment-gear deployment-gear2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M19.14 12.936c.02-.15.02-.3.02-.45s-.02-.3-.02-.45l2.06-1.6c.19-.14.22-.4.06-.58l-2-3.46c-.15-.24-.39-.34-.62-.24l-2.4.98a6.927 6.927 0 0 0-1.17-.7l-.36-2.64c-.04-.26-.24-.45-.5-.45h-4c-.26 0-.46.2-.5.45l-.36 2.64c-.43.27-.85.55-1.25.85l-2.4-.98c-.24-.1-.48-.01-.62.24l-2 3.46c-.16.18-.13.44.06.58l2.06 1.6c-.02.15-.02.3-.02.45s.02.3.02.45l-2.06 1.6c-.19.14-.22.4-.06.58l2 3.46c.15.24.39.34.62.24l2.4-.98c.4.3.82.58 1.25.85l.36 2.64c.04.26.24.45.5.45h4c.26 0 .46-.2.5-.45l.36-2.64c.43-.27.85-.55 1.25-.85l2.4.98c.24.1.48.01.62-.24l2-3.46c.16-.18.13-.44-.06-.58l-2.06-1.6zM12 15.36l-1.41-1.41 3.75-3.75 1.41 1.41-3.75 3.75z" />
                </svg>
              </div>
            </div>
            <div
              className="deployment-text"
              style={{
                padding: "0px",
                borderRadius: "5px",
                backgroundColor: "rgb(199 235 0)",
                fontSize: "14px",
              }}
            >
              <span>
                <strong>
                  <h4 style={{ textAlign: "center" }}>⚠️ Important Notice:</h4>
                </strong>
                <br />
                1. Our system will undergo scheduled maintenance starting on{" "}
                <span style={{ color: "green" }}>
                  {" "}
                  {/* {props.timeNotiRemaining} */}
                  {props.updatedTimeNotiRemaining}
                </span>
                . The maintenance is expected to be completed within{" "}
                <span style={{ color: "green" }}>30 to 45 minutes.</span>
                <br />
                2. During this period, access to the system may be temporarily
                unavailable or limited. Please ensure you save your work before
                the maintenance begins. We apologize for any inconvenience and
                appreciate your understanding as we work to enhance our
                services.
              </span>
            </div>
          </div>
        </div>
      </p>
    </div>
  );
};

export { FirstMessage, SecondMessage, ThirdMessage, DeploymentMessage };
