import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { CredentialService } from "../../services/credentials.services";
import { RegionManagementService } from "../../services/regionManagement.service";
import {
  info,
  infoNotification,
  success,
  successNotification,
  errorNotification,
  error,
  warningNotification,
  warning
} from "../notification/notifications";
import $ from "jquery";
import {
  initateGDPI,
  updateLearnerAssessment
} from "../../services/fetchLearners";
import moment from "moment";
import { LoginService } from "../../services/login.service";
import Modal from "react-responsive-modal";
import linq from "linq";
import ReactPlayer from "react-player";
import { LearnerAssessmentService } from "../../services/learnerAssessment.service";
import { OrderService } from "../../services/order.service";
import { Global_var } from "../../global/global_var";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
import { CardMedia } from "@material-ui/core";
import CourseImage from "../../assets/images/course_logo.png";
import { RegisterUserService } from "../../services/registration.service";
import { ProductService } from "../../services/product.service";
import { updateStatus } from "../../services/preAssessment.Service";

/* https://www.npmjs.com/package/react-multi-carousel */

export default class MyProductExtract extends React.Component {
  constructor(props) {
    super(props);

    //console.log("my-product", props);
    this.state = {
      f_auth: {
        uname: "",
        passwd: "",
        productID: ""
      },
      isFeat: false,
      isGDPI: false,
      isScoreupdated: false,
      showGDPiModal: false,
      isPaymentCompleted: false,
      isPaymentPending: false,
      showPaymentPending: false,
      isAHCredentialpresent: false,
      isProductDisplay: false,
      statusName: "",
      ProductName: "",
      profilePercentage: 0,
      videoUrl: "",
      personnelProfileData: this.props.personnelProfileData,
      productdetail: this.props.myproduct,
      checkout: [],
      ReportDownloadLink: "",
      loading: true,
      trainingGroup: "UAT-RCP",
      //trainingGroup: "PROD-RCP",
      hasTraining: 1
    };
  }

  componentDidMount() {
    
    //console.log("this.state.personnelProfileData::::: ", this.state.personnelProfileData)
    let ispaymade = this.props.myproduct.paymentID !== "0" ? true : false;
    let profilePercent =
      localStorage.getItem("profilecomlitionvalue") === undefined
        ? parseInt(0)
        : parseInt(localStorage.getItem("profilecomlitionvalue"));
    let isDisplay =
      this.props.myproduct.productEntity.isDisplayinCourseCatalog === 0
        ? false
        : true;
    let productVideourl = this.props.myproduct.productEntity.productVideourl
      ? this.props.myproduct.productEntity.productVideourl
      : "https://www.youtube.com/embed/tgbNymZ7vqY";
    this.setState({
      isProductDisplay: isDisplay,
      videoUrl: productVideourl,
      isPaymentCompleted: ispaymade,
      profilePercentage: profilePercent,
      loading: true
    });
    let prod = this.props.myproduct.productEntity.productName;
    let isFeat = prod === "FEAT" ? true : false;
    let isScoreupdated =
      this.props.myproduct.isScoreUpdated === undefined
        ? 0
        : this.props.myproduct.isScoreUpdated;

    console.log("this.state.personnelProfileData", this.state.personnelProfileData)
    //let productPrice = 0;


    let productPrice = this.props.myproduct.productEntity.productPrice
    let statusName = ""
    if (this.props.myproduct.productEntity.productName === "EUIN Mapping" || this.props.myproduct.productEntity.productName === "EUIN Renewal" || this.props.myproduct.productEntity.productName === "EUIN Application" || this.props.myproduct.productEntity.productName === "CPE" || this.props.myproduct.productEntity.productName === "EUIN" || this.props.myproduct.productEntity.productName === "NISM VA Certification- Reattempt" || this.props.myproduct.productEntity.productName === "NISM VA Preparatory Course" || this.props.myproduct.productEntity.productName === "Product 1" || this.props.myproduct.productEntity.productName === "Product 2") {
      //statusName = "FRESH EUIN Application"
      statusName = this.props.myproduct.attemptNumberGrade
    }
    else {
      statusName = ispaymade === false ? parseInt(productPrice) > 0 ? "Make Payment" : this.props.myproduct.attemptNumberGrade == "Reattempt" ? "Reattempt" : "Initiate Course" : "Initiate Course";
    }

    // if (this.props.myproduct.attemptNumberGrade === "Initiate" && this.props.myproduct.productEntity.productName === "NISM VA Preparatory Course") {
    //   statusName = "NISM VA Online Course"
    // }

    let isGDPI = false;
    let learnerParty = "learnerPartyID=" + localStorage.getItem("userid-Token");
    let selectedProd =
      "&productID=" + this.props.myproduct.productEntity.productID;
    let queryprameters = learnerParty + selectedProd;
    this.setState({
      isScoreupdated: isScoreupdated,
      isFeat: isFeat,
      statusName: statusName,
      isGDPI: isGDPI,
      showGDPiModal: isGDPI,
      ProductName: prod,
      isPaymentCompleted: ispaymade,
      profilePercentage: profilePercent,
      isProductDisplay: isDisplay
    });

    LearnerAssessmentService.fetchLearnerPrequisiteByProductID(
      queryprameters,
      res1 => {
        if (res1.status === "success") {

          let validationpass = true;
          let iscoreupdateofprevious = [];
          let prerequisiteList = res1.responseListObject;
          this.setState({ Prequisite: prerequisiteList, loading: true });
          console.log("this.state.Prequisite:::::::::", this.state.Prequisite)
          //if (ispaymade) {
          if (this.state.productdetail.isActive === 0) {
            this.setState({ isPaymentPending: true });
          }
          (this.state.Prequisite || "").map(element => {
            if (element.prodcutID !== null) {
              if (
                element.prodcutID !==
                this.props.myproduct.productEntity.productID
              ) {
                iscoreupdateofprevious.push({
                  productName: element.productEntity.productName,
                  isScoreUpdated: element.isScoreUpdated
                });
                if (
                  (element.isScoreUpdated === 0 ||
                    element.isScoreUpdated === null) &&
                  !this.state.isGDPI
                ) {
                  if (element.productEntity.productName === "GDPI") {
                    iscoreupdateofprevious.forEach(ele => {
                      if (
                        ele.productName !== element.productEntity.productName
                      ) {
                        if (
                          ele.productName === "Feat" &&
                          ele.isScoreUpdated >= 1
                        ) {
                          this.props.showmymappedproducts.map(ele1 => {
                            if (ele1.productEntity.productName === "GDPI") {
                              statusName = ele1.attemptNumberGrade;
                              this.setState({
                                isScoreupdated: 0,
                                statusName: statusName,
                                isGDPI: !isGDPI,
                                showGDPiModal: !isGDPI
                              });
                            } else {
                              isGDPI = prod === "CFX" ? true : false;
                              // statusName = 'Initiate GDPI';
                              if (ele.isScoreUpdated >= 1) {
                                statusName = "Initiate GDPI";
                              } else {
                                statusName = "Attempt FEAT First";
                              }
                            }
                          });
                        } else if (
                          ele.productName === "Feat" &&
                          ele.isScoreUpdated === null
                        ) {
                          isGDPI = false;
                          //statusName = 'Initiate GDPI';
                          if (ele.isScoreUpdated >= 1) {
                            statusName = "Initiate GDPI";
                          } else {
                            statusName = "Attempt FEAT First";
                          }
                        } else {
                          isGDPI = false;
                          statusName = "Attempt FEAT First";
                        }
                      }
                    });
                    this.setState({
                      isScoreupdated: 0,
                      statusName: statusName,
                      isGDPI: isGDPI,
                      showGDPiModal: isGDPI
                    });
                    this.props.showmymappedproducts.map(ele => {
                      if (
                        ele.productEntity.productID ===
                        element.productEntity.productID
                      ) {
                        this.setState({
                          isScoreupdated: 0,
                          statusName: ele.attemptNumberGrade,
                          isGDPI: !isGDPI,
                          showGDPiModal: !isGDPI
                        });
                      }
                    });
                  }
                  this.setState({ loading: false })
                }
              } else if (
                element.prodcutID ===
                this.props.myproduct.productEntity.productID &&
                !this.state.isGDPI
              ) {
                if (isScoreupdated === 1) {
                  if (
                    this.props.myproduct.attemptNumberReportURL === "" ||
                    this.props.myproduct.attemptNumberReportURL === undefined
                  ) {
                    statusName = "Report in Progress";
                    this.setState({ statusName: statusName });
                  } else {
                    statusName = "Download Report";
                    this.setState({
                      statusName: statusName,
                      ReportDownloadLink: this.props.myproduct
                        .attemptNumberReportURL
                    });
                  }
                } else if (isScoreupdated === 0) {
                  LoginService.TokenUser(res => {
                    if (res.status === "success") {
                      localStorage.setItem("jwt-token", res.responseObject);
                      CredentialService.AHLearnerCredential(
                        localStorage.getItem("userid-Token"),
                        this.props.myproduct.productEntity.productID,
                        res1 => {
                          //alert("AH Access::::::::::::::::::::::: :" + JSON.stringify(res1))
                          if (res1.reasonText === null) {
                            let learnercred = res1.responseObject;
                            if (res1.status === "success") {
                              //alert("hello")
                              localStorage.setItem('f_authPassword', learnercred.learnerProviderPassword)
                              //alert("22"+this.props.myproduct.productEntity.productName)
                              this.setState({
                                f_auth: {
                                  uname: learnercred.learnerProviderUsername,
                                  passwd: learnercred.learnerProviderPassword,
                                  productID: learnercred.productID
                                },
                                isFeat: isFeat,
                                ProductName: prod,
                                isAHCredentialpresent: true,
                                isPaymentCompleted: ispaymade,
                                profilePercentage: profilePercent
                              });
                            } else if (res1.status === "fail") {
                              this.setState({ isAHCredentialpresent: false });
                            }
                          } else if (res1.reasonText === "No record found") {
                            if (this.props.myproduct.productEntity.productName === 'FEAT') {
                              this.setState({
                                f_auth: {
                                  productID: this.props.myproduct.productEntity.productID
                                },
                                isFeat: isFeat,
                                ProductName: prod,
                                isAHCredentialpresent: false,
                                isPaymentCompleted: ispaymade,
                                profilePercentage: profilePercent
                              });
                            } else {
                              this.setState({ isAHCredentialpresent: false });
                            }


                          } else {
                            //alert("ELSE 2 : "+this.props.myproduct.productEntity.productName)
                            //let prod = this.props.myproduct.productEntity.productName;
                            this.setState({
                              isFeat: isFeat,
                              ProductName: prod,
                              isAHCredentialpresent: true,
                              isPaymentCompleted: ispaymade,
                              profilePercentage: profilePercent
                            });
                          }
                        },
                        err => {
                          console.log(err);
                        }
                      );
                    }
                  });
                } else if (isScoreupdated === 2) {
                  statusName = "Download Report";
                  this.setState({
                    statusName: statusName,
                    ReportDownloadLink: this.props.myproduct
                      .attemptNumberReportURL
                  });

                  //let prod = this.props.myproduct.productEntity.productName;
                  //alert("4"+this.props.myproduct.productEntity.productName)
                  // this.setState({ ProductName: prod, isFeat: isFeat, isPaymentCompleted: ispaymade, profilePercentage: profilePercent, isProductDisplay: isDisplay },
                  //   () => console.log("Learner access cred", this.state)
                  // )
                } else if (isScoreupdated === 3) {
                  statusName = "Completed";
                  this.setState({
                    statusName: statusName,
                    ReportDownloadLink: this.props.myproduct
                      .attemptNumberReportURL
                  });

                  //let prod = this.props.myproduct.productEntity.productName;
                  //alert("4"+this.props.myproduct.productEntity.productName)
                  // this.setState({ ProductName: prod, isFeat: isFeat, isPaymentCompleted: ispaymade, profilePercentage: profilePercent, isProductDisplay: isDisplay },
                  //   () => console.log("Learner access cred", this.state)
                  // )
                }
                this.setState({ loading: false });
              }
            }

          });

          //}
        }
        else {
          warning(
            "Failed to recieve update, Trying to fetch data again !",
            warningNotification
          );
          setTimeout(() => {
            window.location.href = '/learnerDashboard'
          }, 3000);
        }
        this.setState({ loading: false });
      }
    );

    //if (this.props.myproduct.productEntity.productName == 'Feat') {
    //alert(this.props.myproduct.productEntity.productName)

    //}
    // else {

    //   let prod = this.props.myproduct.productEntity.productName;
    //   //alert("5"+this.props.myproduct.productEntity.productName)
    //   this.setState({ ProductName: prod, isFeat: false, isPaymentCompleted: ispaymade, profilePercentage: profilePercent },
    //     () => console.log("Learner access cred", this.state)
    //   )
    // }

    if (this.props.personnelProfileData.isMandateUser === 1) {
      RegionManagementService.getUserMandateById(
        "mandateId=" + this.props.personnelProfileData.mandateId,
        (mandateres) => {
          if (mandateres.status === "success") {
            let training = mandateres.responseObject.hasTraining;
            this.setState({
              hasTraining: training,
            });
            if (training === 0) {
              document.getElementById("initButton").disabled = true;
            }

            let group = mandateres.responseObject.trainingGroup
            this.setState({
              trainingGroup: group,
            });
          }
        }
      );
    }

    //var productPrice = this.props.myproduct.productEntity.productPrice > 0 ? this.props.myproduct.productEntity.productPrice : 0
    RegionManagementService.getUserDetails("pan=" + this.props.personnelProfileData.panNumber + "&mobileNo=" + this.props.personnelProfileData.mobileNo + "&emailId=" + this.props.personnelProfileData.emailID, (res) => {
      if (res.reasonText !== "No record found") {
        let mandateId = ""
        for (let i = 0; i < res.responseListObject.length; i++) {
          mandateId = res.responseListObject[i].mandateId;
        }
        if(mandateId !==0){
        RegionManagementService.getMandateComponents("mandateId=" + mandateId,
          (res2) => {
            for (let i = 0; i < res2.responseListObject.length; i++) {
              if (res2.responseListObject[i].productid === this.props.myproduct.productEntity.productID) {
                productPrice = res2.responseListObject[i].productPrice
                break
              }
            }

          })
      } else {
        productPrice = this.props.myproduct.productEntity.productPrice
      }
    }
    })
  }

  handleClick() {
    this.inputElement.click();
  }

  productSubscribe = () => {
     var productPrice = 0;
    debugger
    //var productPrice = this.props.myproduct.productEntity.productPrice > 0 ? this.props.myproduct.productEntity.productPrice : 0
    RegionManagementService.getUserDetails("pan=" + this.props.personnelProfileData.panNumber + "&mobileNo=" + this.props.personnelProfileData.mobileNo + "&emailId=" + this.props.personnelProfileData.emailID, (res) => {
      if (res.reasonText !== "No record found") {
        let mandateId = ""
        for (let i = 0; i < res.responseListObject.length; i++) {
          mandateId = res.responseListObject[i].mandateId;
        }
        if(mandateId !==0 ){
        RegionManagementService.getMandateComponents("mandateId=" + mandateId,
          (res2) => {
            for (let i = 0; i < res2.responseListObject.length; i++) {
              if (res2.responseListObject[i].productid === this.props.myproduct.productEntity.productID) {
                productPrice = res2.responseListObject[i].productPrice
                break
              }
            }

          })
      } else {
        productPrice = this.props.myproduct.productEntity.productPrice
      }
    }
    })



    let currentState = localStorage.getItem("currentState")

    if (this.state.profilePercentage > "60" && this.state.personnelProfileData.applicationStatus === "SUBMITTED") {
      let learnerParty =
        "learnerPartyID=" + localStorage.getItem("userid-Token");
      let selectedProd =
        "&productID=" + this.props.myproduct.productEntity.productID;
      let queryprameters = learnerParty + selectedProd;
      LearnerAssessmentService.fetchLearnerPrequisiteByProductID(
        queryprameters,
        res1 => {
          if (res1.status = "success") {
            let validationpass = true;
            let prerequisiteList = res1.responseListObject;
            //console.log("prerequisiteList", prerequisiteList);
            this.setState({ Prequisite: prerequisiteList });
            //console.log("prerequisiteListState", this.state.Prequisite);
            this.state.Prequisite.map(element => {
              if (
                element.prodcutID !==
                this.props.myproduct.productEntity.productID
              ) {

                if (element.status !== "Record found" && validationpass) {
                  validationpass = false;
                  warning(
                    "Please complete " +
                    element.productEntity.productName +
                    " First.",
                    warningNotification
                  );
                } else if (
                  element.status === "Record found" &&
                  (element.isScoreUpdated === null ||
                    element.isScoreUpdated === 0) &&
                  validationpass
                ) {
                  validationpass = false;
                  warning(
                    "Please complete " +
                    element.productEntity.productName +
                    " First.",
                    warningNotification
                  );
                }
              } else if (
                element.prodcutID ===
                this.props.myproduct.productEntity.productID
              ) {
                if (this.props.myproduct.paymentID !== "0") {
                  if (validationpass) {
                    if (element.status === "Record found") {
                      validationpass = false;
                      warning(
                        " You have already applied for " +
                        element.productEntity.productName +
                        ".",
                        warningNotification
                      );
                    } else if (element.status !== "Record found") {
                      validationpass = true;
                      //warning("Please complete " + element.productEntity.productName + " First.", warningNotification);
                    }
                  }
                }
              }
            });
            if (validationpass) {


              let checkoutd = "";

              var personaldetails = {
                partyId: this.state.personnelProfileData.learnerPartyID,
                mobileNo: this.state.personnelProfileData.mobileNo,
                firstName: this.state.personnelProfileData.firstName,
                lastName: this.state.personnelProfileData.lastName,
                emailID: this.state.personnelProfileData.emailID,
                entryMode: 1,
                dateOfBirth: this.state.personnelProfileData.dob
              };

              let data = []
              // console.log(this.state.productdetail)
              this.props.myproduct1.map(e => {
                if (e.productEntity.productName == "NISM VA Certification- Reattempt") {
                  let obj = {
                    productId: e.productEntity.productID,
                    productName: e.productEntity.productName,
                    productPrice: e.productEntity
                      .productPrice,
                    partnerID: e.productEntity.partnerID
                  }
                  data.push(obj)
                }
              })
              var productdetails = {
                productId: this.state.productdetail.productEntity.productID,
                productName: this.state.productdetail.productEntity.productName,
                // productPrice: this.state.productdetail.productEntity.productPrice,
                productPrice: productPrice,
                partnerID: this.state.productdetail.productEntity.partnerID
              };
              data.push(productdetails)

              this.state.checkout.push({ personaldetails, data });   //productdetails

              let orderItemListArray = [];
              let orderItemList = {
                itemID: 1,
                productID: this.state.productdetail.productEntity.productID,
                couponApplied: "ZERO",
                //prodctPrice: this.state.productdetail.productEntity.productPrice
                prodctPrice: productPrice,
              };
              orderItemListArray.push(orderItemList);
              checkoutd = {
                learnerPartyID: localStorage.getItem("userid-Token"),
                orderPlacedOn: moment(new Date()).format("DD/MM/YYYY HH:mm:ss"), //'15/11/2018 15:18:33',
                // totalItemPrice: this.state.productdetail.productEntity.productPrice,
                // totalOrderPrice: this.state.productdetail.productEntity.productPrice,
                totalItemPrice: productPrice,
                totalOrderPrice: productPrice,
                paymentStatus: "Initiated",
                orderItemList: orderItemListArray,
                "currency": "INR"  // Field added for RazorPAy
              };
              //alert("Rohan")

              OrderService.RazorPayInsertOrder(
                checkoutd,
                res => {
                  //this.props.history.push("/razorpay");
                  if (res.status === "success") {
                    localStorage.setItem("reload", "true");
                    this.setState({ loading: false });
                    this.props.history.push("/razorpay", {
                      props: this.state.checkout,
                      orderDetails: res.responseObject
                    });
                  } else if (res.status === "fail") {
                    this.setState({ loading: false });
                    if (res.reasonCode === "SM_11") {
                      window.location.href = "/";
                    } else {
                      error(res.reasonText, errorNotification);
                    }
                  }
                },
                // OrderService.InsertOrder(
                //   checkoutd,
                //   res => {
                //     if (res.status === "success") {
                //       localStorage.setItem("reload", "true");
                //       this.setState({ loading: false });
                //       this.props.history.push("/checkout", {
                //         props: this.state.checkout,
                //         orderDetails: res.responseObject
                //       });
                //     } else if (res.status === "fail") {
                //       this.setState({ loading: false });
                //       if (res.reasonCode === "SM_11") {
                //         window.location.href = "/";
                //       } else {
                //         error(res.reasonText, errorNotification);
                //       }
                //     }
                //   },
                error => {
                  console.log("Order Service ", error);
                }
              );
            }
          } else if (res1.status === "fail") {
            this.setState({ loading: false });
            if (res1.reasonCode === "SM_11") {
              window.location.href = "/";
            } else {
              error(res1.reasonText, errorNotification);
            }
          }
        },
        err => {
          error(err, errorNotification);
        }
      );
    } else {
      warning(
        "Please submit your profile first. Your profile score should be greater than 60% to proceed.",
        warningNotification
      );
    }
  };
  //}

  takeGDPI = () => {

    if (this.state.profilePercentage > 60) {
      if (this.state.isGDPI) {
        var todaydate = new Date();
        let GDPIProdID = 0;
        linq.from(this.props.productDetailsAll).forEach(function (obj) {
          if (obj.productName === "GDPI") {
            GDPIProdID = parseInt(obj.productID);
          }
        });

        LoginService.TokenUser(res => {
          if (res.status === "success") {
            localStorage.setItem("jwt-token", res.responseObject);
            let data = {
              productEntity: {
                productID: GDPIProdID
              },
              learnerPartyID: localStorage.getItem("userid-Token"),
              isActive: 1,
              createdDate: moment(todaydate).format("DD/MM/YYYY HH:mm:ss"),
              paymentID: "0"
            };
            initateGDPI(data, res => {
              if (res.status === "success") {
                success(
                  "Your request for GDPI has been posted for Approval. You would be contacted by Admin shortly",
                  successNotification
                );
                this.setState({ showGDPiModal: false });
                this.props.takeGdpi();
              } else if (res.status === "fail") {
                error(
                  res.reasonText === null
                    ? "Data Updation Failed"
                    : res.reasonText,
                  errorNotification
                );
              }
            });
          } else if (res.status === "fail") {
            this.setState({ loading: false });
            if (res.reasonCode === "SM_11") {
              window.location.href = "/";
            } else {
              error(res.reasonText, errorNotification);
            }
          }
        });
      } else {
        warning(
          "Please submit your profile first. Your profile score should be greater than 60% to proceed.",
          warningNotification
        );
      }
    }
  };

  onCloseModal = () => {
    this.setState({ showGDPiModal: false, showPaymentPending: false });
  };

  takeFEAT = () => {
   // alert("oooo"+this.state.f_auth.productID)
    //if (this.state.profilePercentage > 60) {
    if (!this.state.isPaymentPending) {
      var assheuburl = "";
      if (this.state.f_auth.productID !== "") {
        if (
          this.state.f_auth.productID === 20849 ||
          this.state.f_auth.productID === 1 ||
          this.state.f_auth.productID === 203401 ||
          this.state.f_auth.productID === 300004 ||
          this.state.f_auth.productID === 300005
        ) {

          if (this.state.isAHCredentialpresent) {
            if (this.state.f_auth.productID === 20849) {
              assheuburl =
                Global_var.URL_AH_CREDENTIAL_LOGIN +
                this.state.f_auth.uname +
                "&passwd=" +
                this.state.f_auth.passwd;
              window.open(assheuburl, "_blank");
            } else if (this.state.f_auth.productID === 1 || this.state.f_auth.productID === 203401 || this.state.f_auth.productID === 300004 || this.state.f_auth.productID === 300005) {
              assheuburl =
                Global_var.URL_GCUBE_CREDENTIAL_LOGIN1 +
                this.state.f_auth.passwd;
              console.log("assheuburl", assheuburl);
              window.open(assheuburl, "_blank");
            } else {
              info("Credentials of Assesshub are missing", infoNotification);
            }
          } else {
            warning(
              "AccessHub Credentials are not available. Please contact expertmfd@ciel.co.in or 9922953305. for login support",
              warningNotification
            );
          }
        } else if (this.state.f_auth.productID === '203402') {
          warning(
            "This will be available only for Reattempt and Online Exam",
            warningNotification
          );
        }
        else {
          //warning("Feat & GDPI are prerequisite for proceeding CFX. Please subscribe first to FEAT to proceed further and complete the GDPI process.", warningNotification);
          warning(
            "The course assignment is in progress. You will be able to access the same in 24 hours.",
            warningNotification
          );
        }
      } else {
        warning(
          "Product Details not defined, Please contact support",
          warningNotification
        );
      }
    } else {
      this.setState({ showGDPiModal: false, showPaymentPending: true });
    }
    // } else {
    //   warning(
    //     "Please update your profile first. Your profile score should be greater then 60% to proceed.",
    //     warningNotification
    //   );
    // }
  };

  takePREASSESSMENT = () => {

    if (this.props.myproduct.attemptNumberGrade === "Completed") {

      window.$('#TestReaatempt').modal('show')

    } else {

      if (this.state.profilePercentage > 60 && this.state.personnelProfileData.applicationStatus === "SUBMITTED") {
        if (!this.state.isPaymentPending) {
          var assheuburl = "";
          if (this.state.f_auth.productID !== "") {
            if (
              this.state.f_auth.productID !== 20849) {
              this.props.history.push("/preAssessment", {
                productId: this.state.f_auth.productID
              });
            } else {
              //warning("Feat & GDPI are prerequisite for proceeding CFX. Please subscribe first to FEAT to proceed further and complete the GDPI process.", warningNotification);
              warning(
                "The course assignment is in progress. You will be able to access the same in 24 hours.",
                warningNotification
              );
            }
          } else {
            warning(
              "Product Details not defined, Please contact support",
              warningNotification
            );
          }
        } else {
          this.setState({ showGDPiModal: false, showPaymentPending: true });
        }
      } else {
        warning(
          "Please Complete your profile first. Your profile score should be greater than 60% & the compulsory fields & documents should be added inorder to proceed. Only once you are able to submit your form, you can attempt FEAT.",
          // "Please update your profile first. Your profile score should be greater then 60% to proceed.",
          warningNotification
        );
        // warning(
        //   "Please update your profile first. Your profile score should be greater then 60% to proceed and status should be SUBMITTED.",
        //   warningNotification
        // );
      }
    }
  };
  InititateCourseTrainning = () => {
    // alert()
    if (this.state.productdetail.productEntity.productID === 203401 || this.state.productdetail.productEntity.productID === 203403) {
      window.$('#GcubeInfo').modal('show')

    }
    else {
      this.InititateCourse()
    }
  }

  InititateCourse = () => {
    if (this.state.profilePercentage > "60" && this.state.personnelProfileData.applicationStatus === "SUBMITTED") {
    if (this.props.myproduct.productEntity.productID === 300005) {
      document.getElementById("initButton").disabled = true;
      
      let reasonReponse = ""
      CredentialService.AHLearnerCredential(
        localStorage.getItem("userid-Token"),
        this.props.myproduct.productEntity.productID,
        res1 => {
          if (res1.status === "success") {
            reasonReponse = res1.reasonText
            if (reasonReponse === "No record found") {
              var data1 = localStorage.getItem("PersonnelData");
              let data = {
                learnerID: this.props.personnelData.learnerID,
                buID: this.state.productdetail.buID,
                hasTraining: this.state.hasTraining,
                learnerPartyID: this.state.productdetail.learnerPartyID,
                productID: this.state.productdetail.productEntity.productID,
                paymentID: this.state.productdetail.paymentID,
                firstName: JSON.parse(data1).responseObject.firstName,
                middleName: null,
                lastName: JSON.parse(data1).responseObject.lastName,
                gender: JSON.parse(data1).responseObject.gender,
                panNumber: null,
                aadharNumber: null,
                emailID: JSON.parse(data1).responseObject.emailID,
                mobileNo: JSON.parse(data1).responseObject.mobileNo,
                contactNo: null,
                entryMode: null,
                dob: JSON.parse(data1).responseObject.dob,
                secondaryEmailID: null,
                trainingGroup: this.state.trainingGroup
              }
              CredentialService.assignCource(data, (courseResponse) => {
                if (courseResponse.status === "success") {
                  window.location.reload();
                 // this.takeFEAT();
                }
              });
            }
            else {
              if (this.state.statusName == "Reattempt") {
                window.$('#termsandcondition').modal('show')
              }

              else {
                if (this.state.productdetail.productEntity.productID === 203401) {
                  window.$('#GcubeInfo').modal('show')
                  // alert('aaaa')
                }
                else if (this.state.productdetail.productEntity.productID === 203403) {
                  window.$('#GcubeInfo1').modal('show')
                  // alert('aaaa')
                }

                else {

                  if (!this.state.isPaymentPending) {
                    //alert("there")
                    if (this.state.isAHCredentialpresent) {
                      //alert("here")
                      this.takeFEAT();
                    } else {
                      if (this.state.productdetail.productEntity.productID === 203402) {
                        // alert("kkhh")
                        warning('This certification can be attempted from the NISM Portal or if you would like to reattempt the certification you can make a payment from here. Option to reattempt will be available after a failed attempt.', warningNotification);
                      } else if (this.state.productdetail.productEntity.productID === 300001) {
                        warning(
                          " Your EUIN details have been sent to CAMS, we will shortly get in touch with you informing you of your EUIN number and validity date.",
                          warningNotification
                        );
                      }
                      else if (this.state.productdetail.productEntity.productID === 300002) {
                        //Renewal message
                        warning(
                          "Your EUIN Renewal Details have been sent to CAMS, we will shortly get in touch with you informing you of your EUIN Renewal number and validity date.",
                          warningNotification
                        );
                      }
                      else {
                        warning(
                          "The course assignment is in progress. You will be able to access the same in 24 hours.",
                          warningNotification
                        );
                      }
                    }
                  } else {
                    this.setState({ showGDPiModal: false, showPaymentPending: true });
                  }
                }
              }
            }
          }
        },
        err => {
          console.log(err);
        }
      );

    } else {
      if (this.state.statusName == "Reattempt") {
        window.$('#termsandcondition').modal('show')
      }

      else {
        if (this.state.productdetail.productEntity.productID === 203401) {
          window.$('#GcubeInfo').modal('show')
          // alert('aaaa')
        }
        else if (this.state.productdetail.productEntity.productID === 203403) {
          window.$('#GcubeInfo1').modal('show')
          // alert('aaaa')
        }

        else {

          if (!this.state.isPaymentPending) {
            //alert("there")
            if (this.state.isAHCredentialpresent) {
              //alert("here")
              this.takeFEAT();
            } else {
              if (this.state.productdetail.productEntity.productID === 203402) {
                // alert("kkhh")
                warning('This certification can be attempted from the NISM Portal or if you would like to reattempt the certification you can make a payment from here. Option to reattempt will be available after a failed attempt.', warningNotification);
              } else if (this.state.productdetail.productEntity.productID === 300001) {
                warning(
                  " Your EUIN details have been sent to CAMS, we will shortly get in touch with you informing you of your EUIN number and validity date.",
                  warningNotification
                );
              }
              else if (this.state.productdetail.productEntity.productID === 300002) {
                //Renewal message
                warning(
                  "Your EUIN Renewal Details have been sent to CAMS, we will shortly get in touch with you informing you of your EUIN Renewal number and validity date.",
                  warningNotification
                );
              }
              else {
                warning(
                  "The course assignment is in progress. You will be able to access the same in 24 hours.",
                  warningNotification
                );
              }
            }
          } else {
            this.setState({ showGDPiModal: false, showPaymentPending: true });
          }
        }
      }
    }
  } else {
    warning(
      "Please submit your profile first. Your profile score should be greater than 60% to proceed.",
      warningNotification
    );
  }
  };

  Featsubscription = () => {
    warning(
      "Feat & GDPI are prerequisite for proceeding CFX. Please subscribe first to FEAT to proceed further and complete the GDPI process",
      warningNotification
    );
  };

  euinMapping = () => {
    debugger
    if (
      (this.state.personnelProfileData.euinNumber !== "" && this.state.personnelProfileData.euinNumber !== undefined) &&
      // (this.state.personnelProfileData.euinValidUpto !== "" ||
      // this.state.personnelProfileData.euinValidUpto !== undefined) &&
      this.state.profilePercentage >= 60 &&
      this.state.personnelProfileData.applicationStatus === "SUBMITTED"
    ) {
      error(
        "EUIN Fresh is not applicable as EUIN Number is provided",
        errorNotification
      );
    }  else  if (this.state.profilePercentage > "60" && this.state.personnelProfileData.applicationStatus === "SUBMITTED") {
      window.$('#euinMappingModal').modal('show')
    } 
    else {
      warning(
        "Please submit your profile first. Your profile score should be greater than 60% to proceed.",
        warningNotification
      );
      window.$('#euinMappingModal').modal('hide')
    }
  };

  //original
  euinRenewal = () => {
    if ((this.state.personnelProfileData.nismRegistrationNo === "" && this.state.personnelProfileData.nismRegistrationNo === undefined) &&
      this.state.personnelProfileData.certificateValidUpto === "" ||
      this.state.personnelProfileData.certificateValidUpto === undefined) {
      warning(
        "Please submit your NISM Certificate Details first in update profile.",
        warningNotification
      );
    }
    else if ((this.state.personnelProfileData.euinNumber !== "" && this.state.personnelProfileData.euinNumber !== undefined) &&
      // (this.state.personnelProfileData.euinValidUpto !== "" ||
      //   this.state.personnelProfileData.euinValidUpto !== undefined) &&
      this.state.profilePercentage >= 60 &&
      this.state.personnelProfileData.applicationStatus === "SUBMITTED") {

     // var euinDt = this.state.personnelProfileData.euinValidUpto.split(" ");
     // let euinDate = euinDt[0].split("/");
     // let euinDate2 = euinDate[2] + "-" + euinDate[1] + "-" + euinDate[0];

      // let mydate = new Date(euinDate2);
      // var today = new Date();
      // var diff = mydate.getTime() - today.getTime();
      // var daydiff = diff / (1000 * 60 * 60 * 24);

      // if (daydiff > 365) {
      //   error("Your EUIN is still active and not eligible for renewal.", errorNotification);
      // } else {
        let productName = "productName=EUIN Renewal";
        ProductService.ProductByName(productName, (res) => {
          if (res.status === "success") {
            var data1 = localStorage.getItem("PersonnelData");
            let subscribedData = {
              learnerPartyID:
                JSON.parse(data1).responseObject.learnerPartyID,
              attemptNumberGrade: "EUIN Renewal Applied",
              isActive: "1",
              productEntity: {
                productID: res.responseObject.productID,
              },
            };
            LearnerAssessmentService.subscribeProduct(
              subscribedData,
              (response) => {
                if (response.status === "success") {
                  let acceptData = [];
                  let obj = {
                    action: "EUIN RENEWAL APPLY",
                    workflowId:
                      JSON.parse(data1).responseObject.learnerID,
                    workflowName: "EUIN",
                    comment: "",
                  };
                  acceptData.push(obj);
                  if (acceptData.length > 0) {
                    let workflowtemp = {
                      ExcecutionTracker: acceptData,
                    };
                    updateStatus(workflowtemp, (res1) => { });
                  }
                }
              }
            );
          }
        });

        let data = {
          learnerID: this.props.personnelData.learnerID,
          learnerPartyID: this.props.personnelData.learnerPartyID,
          euinNumber: this.state.personnelProfileData.euinNumber,
        //  euinValidUpto: this.state.personnelProfileData.euinValidUpto,
        };
        console.log("data:: " + JSON.stringify(data));

        var data1 = localStorage.getItem("PersonnelData");
        RegionManagementService.getEncryptedString(
          "partyID=" +
          JSON.parse(data1).responseObject.learnerPartyID,
          (encryptedString) => {
            if (encryptedString.status === "success") {
              var kyd_data = {
                Client_ID:
                  encryptedString.responseObject.amfi_request
                    .client_id,
                Client_Secret:
                  encryptedString.responseObject.amfi_request
                    .client_secret,
                Session_id:
                  encryptedString.responseObject.amfi_request
                    .session_id,
                return_url:
                  encryptedString.responseObject.amfi_request
                    .return_url,
                request_data: {
                  key: encryptedString.responseObject.amfi_request
                    .request_data.key,
                  data: encryptedString.responseObject
                    .amfi_request.request_data.data,
                  hash: encryptedString.responseObject
                    .amfi_request.request_data.hash,
                },
              };

              this.setState({
                kyd_data: JSON.stringify(kyd_data),
                url: encryptedString.responseObject.amfi_request
                  .return_url,
                session_id:
                  encryptedString.responseObject.amfi_request
                    .session_id,
              });

              setTimeout(() => {
                if (
                  this.state.kyd_data != "" &&
                  this.state.url != "" &&
                  this.state.session_id != ""
                ) {

                  this.handleClick();
                } else {
                  error(
                    "We cannot process this request.",
                    errorNotification
                  );
                }
              }, 3000);

              let emaildata = [
                {
                  userName:
                    JSON.parse(data1).responseObject.firstName,
                  emailId:
                    JSON.parse(data1).responseObject.emailID,
                  templateId: "70240",
                  partyId:
                    JSON.parse(data1).responseObject
                      .learnerPartyID,
                  status: "Applied for EUIN",
                },
              ];
              RegisterUserService.userMail(
                emaildata,
                (emailResponse) => { }
              );

              let smsdata = [
                {
                  userName:
                    JSON.parse(data1).responseObject.firstName,
                  emailId: "",
                  templateId: "70280",
                  partyId:
                    JSON.parse(data1).responseObject
                      .learnerPartyID,
                  mobileNumber:
                    JSON.parse(data1).responseObject.mobileNo,
                  status: "Applied for EUIN",
                },
              ];
              RegisterUserService.userSMS(
                smsdata,
                (smsResponse) => { }
              );
            } else {
              this.setState({
                loading1: "none",
              });
              error("No data found.", errorNotification);
            }
          }
        );
      //}
    }
    else if (this.state.profilePercentage > "60" && this.state.personnelProfileData.applicationStatus === "SUBMITTED") {
      window.$('#euinRenewalModal').modal('show')
    } else {
      warning(
        "Please submit your profile first. Your profile score should be greater than 60% to proceed.",
        warningNotification
      );
      window.$('#euinRenewalModal').modal('hide')
    }
  };

  //temporary
  // euinRenewal = () => {
  //   warning("Coming Soon", warningNotification);
  // };

  euinNumberGenerated = () => {
    window.$('#euinNumberGeneratedModal').modal('show')
  };

  euinNumberRejected = () => {
    window.$('#euinNumberRejectedModal').modal('show')
  };

  euinNumberFailed = () => {
    window.$('#euinNumberFailedModal').modal('show')
  };

  euinNumberRenewalGenerated = () => {
    window.$('#euinNumberRenewalGeneratedModal').modal('show')
  };

  euinNumberRenewalRejected = () => {
    window.$('#euinNumberRenewalRejectedModal').modal('show')
  };

  euinNumberRenewalFailed = () => {
    window.$('#euinNumberRenewalFailedModal').modal('show')
  };


  activateprod = () => {
    $(".prgbarbtn").on("click", function () {
      $(".prgbarbtn")
        .parent()
        .parent()
        .removeClass("active");
      $(this)
        .parent()
        .parent()
        .addClass("active");
    });
  };

  showMyprod = () => {

    localStorage.setItem('showproductid', "progress-status")
    document.getElementById('progress-status').style.display = "block";
    if (document.getElementById('progress-status1') !== null) {
      document.getElementById('progress-status1').style.display = "none";
    }
    $(".course-footer").on("click", function () {
      $(".course-footer").removeClass("active");
      $(this).addClass("active");
    });
    if (this.state.profilePercentage > 60) {
      //console.log("Mypod", this.props.myproduct);
      localStorage.setItem(
        "mylearnigshowpath",
        this.props.myproduct.productEntity.productName
      );
      this.props.showMyproductprogresspath(this.props.myproduct);
    } else {
      warning(
        "Please submit your profile first. Your profile score should be greater than 60% to proceed.",
        warningNotification
      );
    }
  };

  render() {
    
    return (
      // <Card>
      <React.Fragment>

        {/* uat url */}
        {/* <form
          action="https://eiscuat1.camsonline.com/Amfi/AMFI_Integration.aspx"
          method="POST"
        > */}

        {/* prod URL */}
        <form
          action="https://web.camsonline.com/Amfi/AMFI_Integration.aspx"
          method="POST"
        >
        
          <input type="hidden" name="kyd_data" value={this.state.kyd_data} />
          <input
            type="hidden"
            name="session_id"
            value={this.state.session_id}
          />
          <input type="hidden" name="url" value={this.state.url} />
          <button type="submit" ref={(input) => (this.inputElement = input)}  style={{ visibility : "hidden" }}>
            Submit
          </button>
        </form>

        <PopUpPortal
          HidePopup={this.state.loading}
          IsVisible={this.state.loading}
        />
        {this.state.showGDPiModal ? (
          <Modal
            open={this.state.showGDPiModal}
            onClose={this.onCloseModal}
            center
          >
            <div
              className=""
              id="roadmap"
              tabindex="-1" role="dialog" aria-labelledby="modalLabel" data-keyboard="true" aria-hidden="true"
            >
              <div className="modal-dialog modal-md" role="document">
                <div class="modal-content">
                  <div className="modal-header">
                    <div class="scr-board">
                      <h2>ExpertMFD</h2>
                      <div class="bottom-line"></div>
                    </div>
                    {/* <h4 className="modal-title">DAP Login</h4> */}
                  </div>
                  <div className="modal-body">
                    <div className="">
                      <div className="">
                        <div className="">
                          <div class="reset">
                            {/* <h3 className="message-text">Thanks for attempting FEAT. Your Feat score is available click on ProgressBar to view the score. Do you want to proceed for registering for CFx program</h3> */}
                            <h3 className="message-text">
                              Your Feat score is available, click on ProgressBar
                              to view the score. Do you want to proceed for
                              registering for CFx program
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      className="btn-5 mt-40 mr-20"
                      name="btnYes"
                      onClick={this.takeGDPI}
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      className="btn-5 mt-40"
                      name="btnNo"
                      onClick={this.onCloseModal}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        ) : this.state.showPaymentPending ? (
          <Modal
            open={this.state.showPaymentPending}
            onClose={this.onCloseModal}
            center
          >
            <div
              className=""
              id="roadmap"
              tabindex="-1" role="dialog" aria-labelledby="modalLabel" data-keyboard="true" aria-hidden="true"
            >
              <div className="modal-dialog modal-md" role="document">
                <div class="modal-content">
                  <div className="modal-header">
                    <div class="scr-board">
                      <h2>ExpertMFD </h2>
                      <div class="bottom-line"></div>
                    </div>
                    {/* <h4 className="modal-title">DAP Login</h4> */}
                  </div>
                  <div className="modal-body">
                    <div className="">
                      <div className="">
                        <div className="">
                          <div class="reset">
                            {/* <h3 className="message-text">Thanks for attempting FEAT. Your Feat score is available click on ProgressBar to view the score. Do you want to proceed for registering for CFx program</h3> */}
                            <h3 className="message-text">
                              Your Payment is pending Please contact
                              Administrator
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      className="btn-5 mt-40 mr-20"
                      name="btnYes"
                      onClick={this.onCloseModal}
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      className="btn-5 mt-40"
                      name="btnNo"
                      onClick={this.onCloseModal}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        ) : null}
        {this.state.isProductDisplay ? (
          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
            <div class="wrapper">
              <div className="course">
                <div class="product-header">
                  <div className="video">
                    <img src={CourseImage} width="100%" height="110px" />
                    {/* <CardMedia
                      component="img"
                      alt="Contemplative Reptile"
                      height="110"
                      image="/static/media/course_logo.710e9d53.png"
                      title="Contemplative Reptile"
                    /> */}
                    {/* <ReactPlayer
                      width="100%"
                      height="110px"
                      url={this.state.videoUrl}
                      playing={false}
                      controls
                    /> */}
                  </div>
                  <div className="course-type">
                    <h5 className="course-name">
                      E-Learning <span className="pull-right">English</span>
                    </h5>
                  </div>
                </div>
                <div class="content">
                  <div className="course-number">
                    <h5 className="course-no">
                    
                      {/* {this.state.ProductName} */}
                      {(this.state.ProductName === "NISM VA Preparatory Course" && this.props.myproduct.attemptNumberGrade === "Make Payment")? (
                        "NISM VA Exam Fees"
                      ) : this.state.ProductName}
                    </h5>

                    {!this.state.isPaymentCompleted &&
                      !this.state.isGDPI &&
                      this.state.statusName === "Make Payment" ? (
                        <CardHeader
                          //title={this.props.myproduct.productEntity.productName}
                          action={
                            <button
                              className="btn-7"
                              onClick={this.productSubscribe}
                            >
                              Make Payment
                          </button>
                          }
                        />
                      ) : this.state.isGDPI ? (
                        <CardHeader
                          //title={this.props.myproduct.productEntity.productName}
                          action={
                            this.state.isGDPI ? (
                              <button className="btn-7" onClick={this.takeGDPI}>
                                Initiate GDPI
                              </button>
                            ) : null
                          }
                        />
                      ) : this.state.isFeat && this.state.isScoreupdated === 0 && this.state.ProductName === 'Feat' ? (
                        <CardHeader
                          //title={this.props.myproduct.productEntity.productName}
                          action={
                            this.state.isFeat &&
                              this.state.ProductName === "Feat" ? (
                                <button className="btn-7" onClick={this.takeFEAT}>
                                  Initiate Course
                                </button>
                              ) : null
                          }
                        />
                      ) : this.state.isFeat && this.state.isScoreupdated === 0 && this.state.ProductName === 'FEAT' ? (

                        <CardHeader
                          //title={this.props.myproduct.productEntity.productName}
                          action={
                            this.state.isFeat &&
                              this.state.ProductName === "FEAT" ? (
                                <button className="btn-7" onClick={this.takePREASSESSMENT}>
                                  {this.props.myproduct.attemptNumberGrade === "Completed" ? "Completed" : "Initiate Course"}
                                </button>
                              ) : null
                          }
                        />
                      ) : this.state.statusName === "Download Report" ? (
                        <CardHeader
                          //title={this.props.myproduct.productEntity.productName}
                          action={
                            this.state.statusName === "Download Report" ? (
                              <button className="btn-7">
                                {" "}
                                <a
                                  href={this.state.ReportDownloadLink}
                                  className="download"
                                  target="_blank"
                                >
                                  {this.state.statusName}{" "}
                                </a>
                              </button>
                            ) : null
                          }
                        />
                      ) : this.state.statusName === "Attempt FEAT First" ? (
                        <CardHeader
                          //title={this.props.myproduct.productEntity.productName}
                          action={
                            this.state.statusName === "Attempt FEAT First" ? (
                              <button className="btn-7">
                                {this.state.statusName}
                              </button>
                            ) : null
                          }
                        />
                      ) : this.state.statusName === "Report in Progress" ? (
                        <CardHeader
                          //title={this.props.myproduct.productEntity.productName}
                          action={
                            this.state.statusName === "Report in Progress" ? (
                              <button className="btn-7" onClick={e => {
                                //alert()
                                if (this.props.myproduct.productEntity.productName == "NISM VA Certification- Reattempt") {
                                  //  window.$('#termsandcondition').modal('show')
                                } else {
                                  window.$('#TestReaatempt').modal('show')
                                }
                                console.log(this.props.myproduct.productEntity.productName)

                              }}>
                                Completed
                                {/* {this.state.statusName} */}
                              </button>
                            ) : null
                          }
                        />
                      ) : this.state.statusName === "Initiate GDPI" ? (
                        <CardHeader
                          //title={this.props.myproduct.productEntity.productName}
                          action={
                            this.state.statusName === "Initiate GDPI" ? (
                              <button
                                className="btn-7"
                                onClick={this.Featsubscription}
                              >
                                {this.state.statusName}{" "}
                              </button>
                            ) : null
                          }
                        />
                      )
                                    : this.state.statusName === "FRESH EUIN Application" ? (
                                      <CardHeader
                                        //title={this.props.myproduct.productEntity.productName}
                                        action={
                                          this.state.statusName === "FRESH EUIN Application" ? (
                                            <button
                                              className="btn-7"
                                              onClick={this.euinMapping}
                                            >
                                              {this.state.statusName}{" "}
                                            </button>
                                          ) : null
                                        }
                                      />
                                    )

                                      : this.state.statusName === "Initiate EUIN Renewal" ? (
                                        <CardHeader
                                          //title={this.props.myproduct.productEntity.productName}
                                          action={
                                            this.state.statusName === "Initiate EUIN Renewal" ? (
                                              <button
                                                className="btn-7"
                                                onClick={this.euinRenewal}
                                              >
                                                {this.state.statusName}{" "}
                                              </button>
                                            ) : null
                                          }
                                        />
                                      )

                                        : this.state.statusName === "EUIN Number Generated" ? (
                                          <CardHeader
                                            //title={this.props.myproduct.productEntity.productName}
                                            action={
                                              this.state.statusName === "EUIN Number Generated" ? (
                                                <button
                                                  className="btn-7"
                                                  onClick={this.euinNumberGenerated}
                                                >
                                                  {this.state.statusName}{" "}
                                                </button>
                                              ) : null
                                            }
                                          />
                                        )

                                          : this.state.statusName === "EUIN Number Rejected" ? (
                                            <CardHeader
                                              //title={this.props.myproduct.productEntity.productName}
                                              action={
                                                this.state.statusName === "EUIN Number Rejected" ? (
                                                  <button
                                                    className="btn-7"
                                                    onClick={this.euinNumberRejected}
                                                  >
                                                    {this.state.statusName}{" "}
                                                  </button>
                                                ) : null
                                              }
                                            />
                                          )

                                            : this.state.statusName === "EUIN Number Failed" ? (
                                              <CardHeader
                                                //title={this.props.myproduct.productEntity.productName}
                                                action={
                                                  this.state.statusName === "EUIN Number Failed" ? (
                                                    <button
                                                      className="btn-7"
                                                      onClick={this.euinNumberFailed}
                                                    >
                                                      {this.state.statusName}{" "}
                                                    </button>
                                                  ) : null
                                                }
                                              />
                                            )

                                              : this.state.statusName === "EUIN Number Renewed" ? (
                                                <CardHeader
                                                  //title={this.props.myproduct.productEntity.productName}
                                                  action={
                                                    this.state.statusName === "EUIN Number Renewed" ? (
                                                      <button
                                                        className="btn-7"
                                                        onClick={this.euinNumberRenewalGenerated}
                                                      >
                                                        {this.state.statusName}{" "}
                                                      </button>
                                                    ) : null
                                                  }
                                                />
                                              )

                                                : this.state.statusName === "EUIN Renewal Rejected" ? (
                                                  <CardHeader
                                                    //title={this.props.myproduct.productEntity.productName}
                                                    action={
                                                      this.state.statusName === "EUIN Renewal Rejected" ? (
                                                        <button
                                                          className="btn-7"
                                                          onClick={this.euinNumberRenewalRejected}
                                                        >
                                                          {this.state.statusName}{" "}
                                                        </button>
                                                      ) : null
                                                    }
                                                  />
                                                )

                                                  : this.state.statusName === "EUIN Renewal Failed" ? (
                                                    <CardHeader
                                                      //title={this.props.myproduct.productEntity.productName}
                                                      action={
                                                        this.state.statusName === "EUIN Renewal Failed" ? (
                                                          <button
                                                            className="btn-7"
                                                            onClick={this.euinNumberRenewalFailed}
                                                          >
                                                            {this.state.statusName}{" "}
                                                          </button>
                                                        ) : null
                                                      }
                                                    />
                                                  )

                                                    : this.state.statusName === "Test" ? (
                                                      <CardHeader
                                                        //title={this.props.myproduct.productEntity.productName}
                                                        action={
                                                          this.state.statusName === "Test" ? (
                                                            null
                                                          ) : null
                                                        }
                                                      />
                                                    )
                                                      : (
                                                        <CardHeader
                                                          //title={this.props.myproduct.productEntity.productName}
                                                          action={
                                                            this.state.statusName !== "Report in Progress" ? (
                                                              <button
                                                                id="initButton"
                                                                className="btn-77"
                                                                onClick={this.InititateCourse}
                                                              >
                                                                {this.state.statusName}{" "}
                                                              </button>
                                                            ) : null
                                                          } //onClick={this.InititateCourse}
                                                        />
                                                      )}

                    {/* {this.state.isPaymentCompleted ?
                      this.state.isGDPI && this.state.ProductName === 'Feat' ?
                        <CardHeader
                          //title={this.props.myproduct.productEntity.productName}
                          action={
                            this.state.isGDPI && this.state.ProductName === 'Feat' ? <button className="btn-7" onClick={this.takeGDPI}>Initiate GDPI</button> : null}
                        />
                        :
                        this.state.isFeat && this.state.ProductName === 'Feat' ?
                          <CardHeader
                            //title={this.props.myproduct.productEntity.productName}
                            action={this.state.isFeat && this.state.ProductName === 'Feat' ? <button className="btn-7" onClick={this.takeFEAT}>Initiate Course</button> : null}
                          /> : null
                      :
                      <CardHeader
                        //title={this.props.myproduct.productEntity.productName}
                        action={<button className="btn-7" onClick={this.productSubscribe}>Make Payment</button>}
                      />
                    } */}
                  </div>
                  {/* <CardMedia
                className={classes.media}
                image="/static/images/cards/paella.jpg"
                title="Paella dish"
            /> */}
                  <div className="course-description">
                    <CardContent>
                      <p>
                        {this.props.myproduct.productEntity.productDescription}
                        {/* {this.props.myproduct.productEntity.productDescription.substring(
                          0,
                          100
                        ) + " ...."} */}
                        {/* Set aside off of the heat to let rest for 10 minutes, and then serve. */}
                      </p>
                    </CardContent>
                  </div>

                  {/* <div className="course-rating">
              <span>
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star" aria-hidden="true"></i>
              </span>
            </div> */}
                </div>
              </div>

              <div
                onClick={this.showMyprod}
                className="course-footer prgbarbtn"
              >
                <div class="footer">
                  <span>
                    <i className="fa fa-user1" aria-hidden="true">
                      {" "}
                    </i>
                  </span>
                  <button
                    type="button"
                    class="btn-11 prgbarbtn"
                    onClick={this.activateprod}
                  >
                    <a href="#"></a>My Progress Bar
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}

      </React.Fragment>

      // </Card>

    );

  }
}
