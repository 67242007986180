import React from 'react';
import sectionImage from '../../../assets/images/AboutDap.png';
import ProcessFlow from '../../../assets/images/ProcessFlowDAP.png';
import BenefitsPoints from '../../../assets/images/benefitsPoints.png';
import BenefitsIcon1 from '../../../assets/images/benefitIcon1.png';
import BenefitsIcon2 from '../../../assets/images/benefitIcon2.png';
import BenefitsIcon3 from '../../../assets/images/benefitIcon3.png';
function aboutus() {
    return (
        <React.Fragment>
            <section className="about section-padding" id="about">
                <div className="container">
                    <div className="row">
                        <div className="section-title-black">
                            <h2>ABOUT</h2></div>
                        <div className="col-md-8 col-sm-8 col-xs-12">
                            <div className="about-paragraph">
                                <p>
                                    The Regulatory Certification Program (RCP) is an endeavour of CIEL to make certification for employees of corporates a seamless process. The portal aims to bring together and create a unique 360 degree platform to provide end to end services of onboarding, training and EUIN. Candidates will get all the support and handholding in creating their profiles on NISM, get access to the online training modules to prepare and also faster generation of EUIN in order to start procuring third party business.
                                    </p>
                                <p>
                                    RCP caters to both: CIEL's corporate clients who have mandated us to help their employees in their certification journey as well as individuals who wish CIEL to undertake the NISM exam together with training.
                                    </p>
                                <p>
                                    Currently the portal caters to NISM VA Mutual fund Distributors Examination, we will soon have all other mandatory NISM exams as part of the portal. Also soon we will also introduce Insurance IC38 exam preparation and onboarding.
                                        </p>
                                {/* <p><a href="https://ciel.co.in/" target="_blank">CIEL </a> is a leading skilling and capacity building organization with more than 300,000 learners across its certifications, executive development programs, investor awareness and capacity building initiatives.</p> */}
                                <p>CIEL is a leading skilling and capacity building organization with more than 300,000 learners across its certifications, executive development programs, investor awareness and capacity building initiatives.</p>
                                {/* <p>
                                    <ul>
                                        <li>
                                            <span>Initiative by CIEL to support our Corporate clients.</span>
                                        </li>
                                        <li>
                                            <span>End to end journey: support, training, examination, EUIN</span>
                                        </li>
                                        <li>
                                            <span>Covering a spectrum of NISM certifications</span>
                                        </li>
                                    </ul>
                                </p> */}
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-12">
                            <div data-aos="flip-right"> <img src={sectionImage} className="img-responsive" /></div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="benefits section-padding" id="benefits">
                <div className="container">
                    <div className="row">
                        <div className="section-title-black">
                            <h2>BENEFITS</h2></div>
                        <div className="col-md-3 col-sm-3 col-xs-12">
                            <div className="benefits-box">
                                <div className="benefits-icon">
                                    <img src={BenefitsIcon1} className="img-responsive" />
                                </div>
                                <h2 className="benefits-title">
                                    Support and Handholding
                            </h2>
                            </div>
                            <div className="benefits-box">
                                <div className="benefits-icon">
                                    <img src={BenefitsIcon2} className="img-responsive" />
                                </div>
                                <h2 className="benefits-title">
                                    Kickstart on garnering business
                            </h2>
                            </div>
                            <div className="benefits-box">
                                <div className="benefits-icon">
                                    <img src={BenefitsIcon3} className="img-responsive" />
                                </div>
                                <h2 className="benefits-title">
                                    One stop shop for your regulatory requirements
                            </h2>
                            </div>
                        </div>
                        <div className="col-md-9 col-sm-9 col-xs-12">
                            <div data-aos="flip-right"> <img src={BenefitsPoints} className="img-responsive" /></div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="processFlow section-padding" id="processflow">
                <div className="container">
                    <div className="row">
                        <div className="section-title-black">
                            <h2>RCP PROCESS & STEPS INVOLVED</h2>
                        </div>

                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div data-aos="flip-right">
                                <img src={ProcessFlow} className="img-responsive" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="testimonials section-padding" id="testimonials">
                <div className="container">
                    <div className="row">
                        <div className="section-title-black">
                            <h2>TESTIMONIALS</h2>
                        </div>

                        <div className="col-md-12 col-sm-12 col-xs-12">

                        </div>
                    </div>
                </div>
            </section> */}

            {/* <section className="video section-padding" id="videos">
                <div className="container">
                    <div className="row">
                        <div className="section-title-black">
                            <h2>VIDEOS</h2>

                        </div>

                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="row"> */}
            {/* <div className="col-md-4 col-sm-6 col-xs-12">
                                    <video src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/3/h264.mov" controls></video>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <video src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/3/h264.mov" controls></video>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <video src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/3/h264.mov" controls></video>
                                </div> */}
            {/* <h4>Coming Soon</h4>
                            </div>

                        </div>
                    </div>
                </div>
            </section> */}

            {/* <section className="referral section-padding" id="referralprogram"> */}
            <section className="section-padding" id="referralprogram">
                <div className="container">
                    <div className="row">
                        {/* <div className="section-title-white"> */}
                        <div className="section-title-black">
                            <h2>Continuous Professional Education (CPE)</h2>
                        </div>

                        {/* <div className="col-md-7 col-sm-7 col-xs-12"> */}
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="about-paragraph">
                                <p>CIEL is a NISM  accredited CPE Provider for conducting CPE trainings for revalidation of certification under different programs offered by NISM. NISM certificates are valid for a period of 3 years and you can renew the same (anytime within one year of its expiry) by attending a one day physical classroom session.</p>
                                <p>CIEL is the longest serving NISM-empanelled training service provider for NISM CPEs, offering these programs since 2010. CIEL conducts CPEs programmes in 300+ locations base on  calendar and has trained over 1 lac candidates till January 2022.</p>
                                <p>CIEL offers CPE trainings for renewal of certification for the following NISM certifications: </p>
                                <p>
                                    <ul>
                                        <li>
                                            <a href="https://www.nism.ac.in/currency-derivatives-cpe/" target="_blank" style={{textAlign: 'left'}}>NISM Series-I : Currency Derivatives </a>
                                        </li>

                                        <li>
                                            <a href="https://www.nism.ac.in/rta-corporate-cpe/" target="_blank" style={{textAlign: 'left'}}>NISM Series-II-A : RTA- Corporate </a>
                                        </li>

                                        <li>
                                            <a href="https://www.nism.ac.in/rta-mutual-fund-cpe/" target="_blank" style={{textAlign: 'left'}}>NISM Series-II-B : RTA - Mutual Funds </a>
                                        </li>

                                        <li>
                                            <a href="https://www.nism.ac.in/interest-rate-derivatives-cpe/" target="_blank" style={{textAlign: 'left'}}>NISM Series-IV : Interest Rate Derivatives </a>
                                        </li>

                                        <li>
                                            <a href="https://www.nism.ac.in/mutual-fund-distributors-cpe/" target="_blank" style={{textAlign: 'left'}}> NISM Series-V-A : Mutual Fund Distributors  </a>
                                        </li>

                                        <li>
                                            <a href="https://www.nism.ac.in/mutual-fund-foundation-cpe/" target="_blank" style={{textAlign: 'left'}}>NISM Series-V-B : Mutual Fund Foundation  </a>
                                        </li>

                                        <li>
                                            <a href="https://www.nism.ac.in/depository-operations-cpe/" target="_blank" style={{textAlign: 'left'}}>NISM Series-VI : Depository Operations  </a>
                                        </li>

                                        <li>
                                            <a href="https://www.nism.ac.in/securities-operations-and-risk-management-cpe/" target="_blank" style={{textAlign: 'left'}}>NISM Series-VII : Securities Operations and Risk Management </a>
                                        </li>

                                        <li>
                                            <a href="https://www.nism.ac.in/equity-derivatives-cpe/" target="_blank" style={{textAlign: 'left'}}> NISM Series-VIII : Equity Derivatives   </a>
                                        </li>

                                        <li>
                                            <a href="https://www.nism.ac.in/merchant-banking-cpe/" target="_blank" style={{textAlign: 'left'}}>NISM Series-IX : Merchant Banking   </a>
                                        </li>

                                    </ul>
                                </p>
                                <p>To view  CIEL's CPE training calendar <a href="https://ciel.co.in/training_calendar_CPE.php" target="_blank">Click Here. </a></p>
                                <p>The calendar  is updated with available training dates for various locations. To enrol for a CPE program you should have an active and approved profile on NISM.</p>
                                {/* <div className="referral-button">
                                <button type="button" class="share-btn"> <i class="fa fa-share-alt-square" aria-hidden="true"></i> Link to Share</button>
                            </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>



        </React.Fragment>
    );
}

export default aboutus;
