import { store } from 'react-notifications-component';

export const successNotification =
{
    title: "Success!",
    message: "",
    type: "success",
    insert: "top",
    container: "top-center",
    //"top-center",
    animationIn: ["animated", "flipInY"],
    animationOut: ["animated", "bounceOut"],
    dismiss: {
        duration: 7000,
        onScreen: false,
        showIcon: true,
    }
}

export const successNotification1 =
{
    title: "Success!",
    message: "",
    type: "success",
    insert: "top",
    container: "top-center",
    animationIn: ["animated", "flipInY"],
    animationOut: ["animated", "bounceOut"],
    dismiss: {
        duration: 7000,
        onScreen: false,
        showIcon: true,
    }
}

export const warningNotification =
{
    title: "Warning!",
    message: "",
    type: "warning",
    insert: "bottom",
    container: "top-center",
    //"top-center",
    animationIn: ["animated", "flipInY"],
    animationOut: ["animated", "bounceOut"],
    dismiss: {
        duration: 7000,
        onScreen: false,
        showIcon: true,
    }
}


export const infoNotification =
{
    title: "Info!",
    message: "",
    type: "info",
    insert: "bottom",
    container: "top-center",
    //"top-center",
    animationIn: ["animated", "flipInY"],
    animationOut: ["animated", "bounceOut"],
    dismiss: {
        duration: 7000,
        onScreen: false,
        showIcon: true,
    }
}

export const infoNotification2 = {
    title: "Deployment Info!",
    message: "",  
    type: "info", 
    insert: "top",
    container: "top-center",
    animationIn: ["animated", "flipInY"], 
    animationOut: ["animated", "bounceOut"],
    dismiss: {
        duration: 10000, 
        onScreen: true,  
        showIcon: true,
    },
    backgroundColor: "lightblue",  
    icon: "deployment-icon",  
};

export const errorNotification =
{
    title: "Error!",
    message: "",
    type: "danger",
    insert: "bottom",
    container: "top-center",
    //"top-center",
    animationIn: ["animated", "flipInY"],
    animationOut: ["animated", "bounceOut"],
    dismiss: {
        duration: 7000,
        onScreen: false,
        showIcon: true,
    }
}

export const sessionNotification =
{
    title: "Session Error!",
    message: "",
    type: "danger",
    insert: "bottom",
    container: "top-center",
    //"top-center",
    animationIn: ["animated", "flipInY"],
    animationOut: ["animated", "bounceOut"],
    dismiss: {
        duration: 7000,
        onScreen: false,
        showIcon: true,
    }
}

export const success = (message, notification) => {
    store.addNotification({
        ...notification,
        message: message
    });
}

export const success1 = (content, notification) => {
    store.addNotification({
        ...notification,
        content: content
    });
}

export const warning = (message, notification) => {
    store.addNotification({
        ...notification,
        message: message
    });
}

export const info = (message, notification) => {
    store.addNotification({
        ...notification,
        message: message
    });
}

export const info2 = (content, notification) => {
    store.addNotification({
        ...notification,
        content: content
    });
}

export const error = (message, notification) => {
    store.addNotification({
        ...notification,
        message: message
    });
}

export const sessionerror = (message, notification) => {
    store.addNotification({
        ...notification,
        message: message
    });
}
