// Library Imports
import React from 'react';
import $ from 'jquery';
import * as moment from "moment";
import * as Yup from "yup";
import ReactTable from "react-table";
import loadable from "@loadable/component";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from "formik";
// Redux Imports
import { getNotificationEvents, getNotificationEventsError, getNotificationEventsPending } from '../../reducer/notificationReducer';
// Service Imports
import { fetchNotificationEvents } from '../../services/fetchLearnerDashboard.service';
import { FetchNotificationService } from "../../services/fetchnotification";
// Other Imports
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
import { ExcelService } from "../Excel/excel";
import { success, successNotification, error, errorNotification, warning, warningNotification } from "../notification/notifications";
// Component Imports
import LoadingFallBack from "../../components/loadingFallBack"
const Finxfooter = loadable(() => import('../../containers/finxfooter/finxfooter'), {
  fallback: <LoadingFallBack />
});
const DashboardHeader = loadable(() => import('../dashboardheader/dashboardheader'), {
  fallback: <LoadingFallBack />
});
// unused imports
// import ReactHTMLParser from "react-html-parser";

//set data to container/ componant
const mapStateToProps = state => ({
  notificationEvents: getNotificationEvents(state.notificationEventsReducer),
  pendingNotificationEvents: getNotificationEventsPending(
    state.notificationEventsReducer
  ),
  errorNotificationEvents: getNotificationEventsError(
    state.notificationEventsReducer
  ),

});

//set state  data to API/ Service
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchNotificationEvents: fetchNotificationEvents

    },
    dispatch
  );

class MyMessagesInbox extends React.Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   MessageDetails: [],
    // };
    this.state = {
      documentList:[],
      data: [],
      dataSent: [],
      dataDraft: [],
      MessageDetails: [],
      dataAllMail: [],
      candidateMessagedata: [],
      alertcount: "",
      notificationcount: "",
      alertNotificationEvents: JSON.parse(localStorage.getItem('AdminAlertNotification')),
      //notificationEvents: JSON.parse(localStorage.getItem('AdminNotification')),
      isdisplay: "none",
      inboxCount: "",
      fromDate: "",
      toDate: "",
      currentTab: localStorage.getItem("roleId") == "6"?0:1,
      showTable: localStorage.getItem("roleId") == "6"?[true, false, false, false, false]:[false,true,false, false, false],
      loading: false,
    };
  }
  handleDocumentDownload=async (data)=>{
  let result = [];
   for (let i = 1; i <= 5; i++) {
    let idkey=`documentId${i}`;
    let nameKey=`documentName${i}`;
    let id = data[idkey]||data[idkey];
    let name =data[nameKey]||data[nameKey];  
    if (![null,undefined,""].includes(id)&&![null,undefined,""].includes(name)) {
      let obj={}
        obj[0]=name;
        obj[1]=id;
        result.push(obj)
    }
}
await this.setState({
  documentList:result
});    
  }
  handleFromToDate = async () => {
    let endDate = new Date();
    let startDate = new Date();
    let startDate1 = new Date();
    startDate.setDate(endDate.getDate() - 31);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    let dateFormatter = new Intl.DateTimeFormat("en-IN", options);
    let fromDate = dateFormatter.format(startDate);
    let toDate = dateFormatter.format(endDate);
    let formattedFromDate1 = `${fromDate?.split("/")[2]}-${fromDate?.split("/")[1]
      }-${fromDate?.split("/")[0]}`;
    let formattedToDate1 = `${toDate?.split("/")[2]}-${toDate?.split("/")[1]
      }-${toDate?.split("/")[0]}`;

   await this.setState({
      fromDate: formattedFromDate1,
      toDate: formattedToDate1,
    });
  }
  handleDocument = () => {
    $(document).ready(function () {
      $('.tabs a').click(function () {
        $('.panel').hide();
        $('#inboxTab li a').removeClass('active');
        $(this).addClass('active');
        var panel = $(this).attr('href');
        $(panel).fadeIn(1000);
        let currentTable = `.${panel?.slice(1)}-table`
        $(currentTable).fadeIn(1000);
        return false;  // prevents link action
      });  // end click 

      $('.tabs li:first a').click();
    }); // end ready
  }
  handleToggle = (anchorId) => {
    const anchor = document.getElementById(anchorId);
    const candidateAnchor = document.getElementById("candidate-anchor");
    const inboxAnchor = document.getElementById("inbox-anchor");
    const sentAnchor = document.getElementById("sent-anchor");
    const draftAnchor = document.getElementById("draft-anchor");
    const allMailAnchor = document.getElementById("allMail-anchor");
    let anchorsArray = [candidateAnchor, inboxAnchor, sentAnchor, allMailAnchor, draftAnchor]
    if (anchor) {
      anchorsArray.filter((item) => item.id !== anchorId).map((item1) => {
        item1.classList.remove("active");
        return null;
      })
      if (anchor.classList.contains("active")) {
      } else {
        anchor.classList.add("active");
      }
    }
  }
  formatDate=(inputDate)=> {
    const parts = inputDate?.split("-");
    const formattedDate = `${parts[2]}/${parts[1]}/${parts[0]}`;
    return formattedDate;
}
convertHTMLToPlainText(html) {
  const element = document.createElement('div');
  element.innerHTML = html;
  const textContent = element.textContent || element.innerText || '';
  return textContent.trim();
}
  componentDidMount() {
    // this.handleFromToDate();
    if(this.props.history.location?.state?.currentTab){
      let currentTab=this.props.history.location?.state?.currentTab;
   let idArray=["candidate-anchor","inbox-anchor","sent-anchor","draft-anchor","allMail-anchor"];
      // this.setState({currentTab})
      this.handleTabClick("",idArray[currentTab],currentTab)
    }
    let partyID = localStorage.getItem("ciel-userid-Token");
    // FetchNotificationService.fetchNotification(params0, (res1) => {
    //   this.setState({
    //     data: res1.responseListObject,
    //     notificationEvents: res1.responseListObject
    //   })
    // })
    
    if (localStorage.getItem("roleId") == "6") {
      document.getElementById('draft1').style.display = "block";
      document.getElementById('allMail1').style.display = "block";
      document.getElementById('candidate1').style.display = "block";
    }
    else {
      document.getElementById('draft1').style.display = "none";
      document.getElementById('allMail1').style.display = "none";
      document.getElementById('candidate1').style.display = "none";
      
    }
    this.handleFromToDate().then(()=>{
    // if (localStorage.getItem("roleId") == "6") {
    //   document.getElementById('allMail1').style.display = "block";
    // }
    // else {
    //   document.getElementById('allMail1').style.display = "none";
    // }
   let params0=`fromDate=${this.formatDate(this.state.fromDate)}&toDate=${this.formatDate(this.state.toDate)}&notifyManagerFlag=Y`
    this.setState({loading:true})
    FetchNotificationService.fetchNotificationCandidate(params0, (res5) => {
      if(["success",null].includes(res5.status)){
      this.setState({
        candidateMessagedata: res5.responseListObject,
        loading:false
      })
      console.log("data candidate:: ", this.state.candidateMessagedata)
    }
    else{
      this.setState({ loading: false }) 
    }
    })
    let params1=`partyId=${partyID}&fromDate=${this.formatDate(this.state.fromDate)}&toDate=${this.formatDate(this.state.toDate)}&notifyManagerFlag=Y`  
    // FetchNotificationService.fetchNotification(params1, (res1) => {
      FetchNotificationService.fetchNotificationEventsForInboxandAllMail(params1,(res1)=>{
      if(["success",null].includes(res1.status)){
      this.setState({
        data: res1.responseListObject,
        notificationEvents: res1.responseListObject,
        loading:false
      })
      console.log("data inbox:: ", this.state.data)
    }
    else{
      this.setState({ loading: false }) 
    }
    })
    let params2=`senderPartyId=${partyID}&fromDate=${this.formatDate(this.state.fromDate)}&toDate=${this.formatDate(this.state.toDate)}&notifyManagerFlag=Y`
      FetchNotificationService.fetchNotificationSent(params2, (res) => {
        if(["success",null].includes(res.status)){
      this.setState({
        dataSent: res.responseListObject,
        loading: false
      })
    }
    else{
      this.setState({ loading: false }) 
    }
    })
    let params3=`senderPartyId=${partyID}&deliveryChannel=email&fromDate=${this.formatDate(this.state.fromDate)}&toDate=${this.formatDate(this.state.toDate)}`
      FetchNotificationService.fetchNotificationDarft(params3, (res2) => {
        if(["success",null].includes(res2.status)){
      this.setState({
        dataDraft: res2.responseListObject,
        loading: false
      })
    }
       else{
          this.setState({ loading: false }) 
        }
    })
    let params4=`fromDate=${this.formatDate(this.state.fromDate)}&toDate=${this.formatDate(this.state.toDate)}&notifyManagerFlag=Y`
      // FetchNotificationService.fetchNotificationEvents(params4, (res4) => {
        FetchNotificationService.fetchNotificationEventsForInboxandAllMail(params4,(res4)=>{
        if(["success",null].includes(res4.status)){
      console.log("res4.responseListObject:: ", res4.responseListObject)
      this.setState({
        dataAllMail: res4.responseListObject,
        loading: false
      })
    }
    else{
      this.setState({ loading: false }) 
    }
    })
  });
    // FetchNotificationService.fetchNotificationEvents("partyId=" , (res4) => {
    //   let data = [];
    //   for (let i = 0; i < res4.responseListObject.length; i++) {
    //     let data1 = {}
    //     data1['notifyHistoryId'] = res4.responseListObject[i].notifyHistoryId
    //     data1['fromEmailId'] = res4.responseListObject[i].fromEmailId
    //     data1['fromEmailName'] = res4.responseListObject[i].fromEmailName
    //     data1['toEmailIdOrMobileNo'] = res4.responseListObject[i].toEmailIdOrMobileNo
    //     data1['emailSubjectText'] = res4.responseListObject[i].emailSubjectText
    //     data1['deliveryStatus'] = res4.responseListObject[i].deliveryStatus
    //     if (res4.responseListObject[i].notifyEvent.lastModifiedDateTime !== "") {
    //         data1['lastAttemptDatetime'] = res4.responseListObject[i].notifyEvent.lastModifiedDateTime
    //     }
    //     data.push(data1)

    // }
    //   this.setState({
    //     dataAllMail: data
    //   })
    //   console.log("res4.responseListObject:: ", this.state.dataAllMail)
    // })


    // $(document).ready(function () {
    //   $('.tabs a').click(function () {

    //     $('.panel').hide();
    //     $('#inboxTab li a').removeClass('active');
    //     $(this).addClass('active');

    //     var panel = $(this).attr('href');
    //     $(panel).fadeIn(1000);
    //     console.log("vkd doc", panel)
    //     let currentTable = `.${panel?.slice(1)}-table`
    //     console.log("vkd doc1", currentTable, panel?.slice(1))
    //     $(currentTable).fadeIn(1000);
    //     return false;  // prevents link action

    //   });  // end click 

    //   $('.tabs li:first a').click();

    // }); // end ready
  }
  convert(url) {
    // var text = document.getElementById("url").value;
    var exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    var text1 = url.replace(exp, "<a href='$1'>$1</a>");
    var exp2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    alert(text1.replace(exp2, '$1<a target="_blank" href="http://$2">$2</a>'))
    return text1.replace(exp2, '$1<a target="_blank" href="http://$2">$2</a>');
    // document.getElementById("converted_url").innerHTML = text1.replace(exp2, '$1<a target="_blank" href="http://$2">$2</a>');
  }


  // notification = (key) => {
  //   if (this.state.notificationEvents.length > 0) {
  //     this.props.history.push("/notification", {
  //       alertid: key,
  //       notification: this.state.notificationEvents
        
  //     })
  //     window.location.reload()
  //   }
  // }

  notification =async (key, object) => {
    await this.handleDocumentDownload(object);
    if(object.read_unread === false){
     // 
      //alert("kkk")
      let notifyHistory = {
        "notifyHistoryId": key,
      }
        FetchNotificationService.updateReadUnread(notifyHistory, (res2) => {
          //alert("ppp")
          if(res2.status === "success"){
          //  alert("lll")
          let senderPartyId = localStorage.getItem("ciel-userid-Token");
            FetchNotificationService.getCount("senderPartyId=" + senderPartyId, (res1) => {
              this.setState({
                inboxCount: res1.responseObject.unreadCount
              })
            })
          }
        })     
    } 
    console.log("object ::", object)
  
    // if (this.state.notificationEvents?.length > 0) {
      if(this.state.data?.length>0){
      let arrayObj =[]
      arrayObj.push(object)
      arrayObj.forEach(obj => {
        obj.lastAttemptDatetime = obj.lastAttemptDateTime;
        obj.emailSMSBodyText=obj.emailSmsBodyText;
        obj.toEmailIdOrMobileNo=obj.toEmailIdOrMobileNo
        obj.fromEmailId =obj.fromEmailId
    });
      this.props.history.push("/notification", {
        alertid: key,
        notification: arrayObj,
        value:"Inbox",
        inboxCount:this.state.inboxCount,
        documentList:this.state.documentList,
        currentTab:this.state.currentTab
        
      })
       setTimeout(function () {
      window.location.reload()
       }, 1000)
    }
 
  }

  draftView =async (key) => {
    // await this.handleDocumentDownload(object);
    if (this.state.dataDraft?.length > 0) {
      this.props.history.push("/my-inbox-create", {
        alertid: key,
        notification: this.state.dataDraft
      })
      // $(".modal-backdrop").hide();
      window.location.reload()
    }
  }


  // notificationSent = (key) => {
  //   if (this.state.dataSent.length > 0) {
  //     this.props.history.push("/notification", {
  //       alertid: key,
  //       notification: this.state.dataSent
  //     })
  //     window.location.reload()
  //   }
  // }

  notificationSent = async (key, object) => {
    console.log("object ::", object)
    await this.handleDocumentDownload(object);
    // if (this.state.notificationEvents?.length > 0) {
      if(this.state.dataSent?.length>0){
      let arrayObj =[]
      arrayObj.push(object)
      arrayObj.forEach(obj => {
        obj.lastAttemptDatetime = obj.lastAttemptDateTime;
        obj.emailSMSBodyText=obj.emailSmsBodyText;
        obj.toEmailIdOrMobileNo=obj.toEmailIdOrMobileNo
        obj.fromEmailId =obj.fromEmailId
    });
      this.props.history.push("/notification", {
        alertid: key,
        notification: arrayObj,
        value:"Sent",
        documentList:this.state.documentList,
        currentTab:this.state.currentTab
      })
      window.location.reload()
    }
  }

  notificationAllMail =async (key, object) => {
    await this.handleDocumentDownload(object);
    console.log("object ::", object)
      if (this.state.dataAllMail?.length > 0) {
      let arrayObj =[]
      arrayObj.push(object)
      
      arrayObj.forEach(obj => {
        obj.lastAttemptDatetime = obj.lastAttemptDateTime;
        obj.emailSMSBodyText=obj.emailSmsBodyText;
        obj.toEmailIdOrMobileNo=obj.toEmailIdOrMobileNo
        obj.fromEmailId =obj.fromEmailId
    });
      this.props.history.push("/notification", {
        alertid: key,
        notification: arrayObj,
        value:"Sent",
        documentList:this.state.documentList,
        currentTab:this.state.currentTab
      })
      window.location.reload()
    }
  }
  notificationCandidate = async (key, object) => {
    await this.handleDocumentDownload(object);
    console.log("object ::", object)
    if (this.state.candidateMessagedata?.length > 0) {
      let arrayObj = []
      arrayObj.push(object)
      arrayObj.forEach(obj => {
        obj.lastAttemptDatetime = obj.deliveryDate;
        obj.emailSMSBodyText=obj.emailSmsBodyText;
        obj.toEmailIdOrMobileNo=obj.toEmailIdOrMobileNo
        obj.fromEmailId =obj.fromEmailId
    });
    
    await  this.props.history.push("/notification", {
        alertid: key,
        notification: arrayObj,
        value: "Candidate",
        documentList:this.state.documentList,
        currentTab:this.state.currentTab
      })
      window.location.reload()
    }
  }


  filterCaseInsensitive = ({ id, value }, row) =>
    row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true;
  eMailMessage = (values) => {
    let message = values.emailSMSBodyText.replaceAll('<br>', '')
    let message1 = message.replaceAll('<p>', '')
    let message2 = message1.replaceAll('</p>', '')

    // let message3 = this.convert(message2)


    // // <a href='
    // alert(message3)
    // message = values.emailSMSBodyText.replaceAll('</p>', '')
    values.emailSMSBodyText = message2;

    this.setState({ MessageDetails: values });
    console.log(this.state.MessageDetails);
  }
  exportToCandidateCSV = () => {
    let modifiedData = [];
    let data = this.state.candidateMessagedata
    if (data?.length > 0) {
      
      data.forEach((element, index) => {
     let plainText= this.convertHTMLToPlainText(element.emailSmsBodyText);
        let d = element
        var data1 = {
          "Sender Email Id": element.senderEmailId,
          "Sender Mobile No": element.mobileNo,
          "Subject": element.emailSubjectText,
          // "Body Text":element.emailSmsBodyText,
          "Body Text":plainText,
          "Mandate Name": element.mandateName,
          "Bank Name": element.companyName,
          "Delivery Date": (d.deliveryDate?.split(' ')[0]?.split('/')[0] + "-" + d.deliveryDate?.split(' ')[0]?.split('/')[1] + "-" + d.deliveryDate?.split(' ')[0]?.split('/')[2] + " " + d.deliveryDate?.split(' ')[1] || ''),
          "CC Email id": element.ccEmailId,
          // "Delivery Status": (element.deliveryStatus || '').toString(),
          "Delivery Status":['completed',null,undefined].includes(element.deliveryStatus?.toString())? 'Success' : 'Failure',
        };
        modifiedData.push(data1);
      });
      ExcelService.ExcelData(modifiedData, 'RCP-MessageList');
    }
    else {
      warning("No record found", warningNotification)
    }

  }
  exportToCSV = () => {

    let modifiedData = [];
    let data = this.state.data
    if (data?.length > 0) {


      data.forEach((element, index) => {
        let d = element
        var data1 = {

          "From ID": element.fromEmailId,
          "From Name": element.fromEmailName,
          "Subject": element.emailSubjectText,
          // "Delivery Status": (element.deliveryStatus || '').toString() === 'completed' ? 'Success' : 'Failure',
          "Delivery Status":['completed',null,undefined].includes(element.deliveryStatus?.toString())? 'Success' : 'Failure',
          //"Delivery Date": moment(element.lastAttemptDatetime.split(" ")[0], "DD/MM/YYYY").format("DD-MM-YYYY")
          "Delivery Date": (d.lastAttemptDateTime?.split(' ')[0]?.split('/')[0] + "-" + d.lastAttemptDateTime?.split(' ')[0]?.split('/')[1] + "-" + d.lastAttemptDateTime?.split(' ')[0]?.split('/')[2] + " " + d.lastAttemptDateTime?.split(' ')[1] || ''),


        };
        modifiedData.push(data1);
      });

      ExcelService.ExcelData(modifiedData, 'RCP-InboxList');
    }
    else {
      warning("No record found", warningNotification)
    }
  };
  exportToSentCSV = () => {

    let modifiedData = [];
    let data = this.state.dataSent
    if (data?.length > 0) {


      data.forEach((element, index) => {
        let d = element
        var data1 = {

          "From ID": element.fromEmailId,
          "From Name": element.fromEmailName,
          "To ID": element.fromEmailId,

          "Subject": element.emailSubjectText,
          // "Delivery Status": (element.deliveryStatus || '').toString() === 'completed' ? 'Success' : 'Failure',
          "Delivery Status":['completed',null,undefined].includes(element.deliveryStatus?.toString())? 'Success' : 'Failure',
          //"Delivery Date": moment(element.lastAttemptDatetime.split(" ")[0], "DD/MM/YYYY").format("DD-MM-YYYY")
          "Delivery Date": (d.lastAttemptDateTime?.split(' ')[0]?.split('/')[0] + "-" + d.lastAttemptDateTime?.split(' ')[0]?.split('/')[1] + "-" + d.lastAttemptDateTime?.split(' ')[0]?.split('/')[2] + " " + d.lastAttemptDateTime?.split(' ')[1] || ''),

        };
        modifiedData.push(data1);
      });

      ExcelService.ExcelData(modifiedData, 'RCP-SentList');
    }
    else {
      warning("No record found", warningNotification)
    }
  };

  exportToAllMailCSV = () => {

    let modifiedData = [];
    let data = this.state.dataAllMail
    if (data?.length > 0) {


      data.forEach((element, index) => {
        let d = element
        var data1 = {

          "From ID": element.fromEmailId,
          "From Name": element.fromEmailName,
          "To ID": element.toEmailIdOrMobileNo,

          "Subject": element.emailSubjectText,
          // "Delivery Status": (element.deliveryStatus || '').toString() === 'completed' ? 'Success' : 'Failure',
          "Delivery Status":['completed',null,undefined].includes(element.deliveryStatus?.toString())? 'Success' : 'Failure',
          //"Delivery Date": moment(element.lastAttemptDatetime.split(" ")[0], "DD/MM/YYYY").format("DD-MM-YYYY")
         // "Delivery Date": (d.lastAttemptDatetime.split(' ')[0].split('/')[0] + "-" + d.lastAttemptDatetime.split(' ')[0].split('/')[1] + "-" + d.lastAttemptDatetime.split(' ')[0].split('/')[2] + " " + d.lastAttemptDatetime.split(' ')[1] || ''),
          "Delivery Date": (d.lastAttemptDateTime ||'').toString() !== ''?(d.lastAttemptDateTime?.split(' ')[0]?.split('/')[0] + "-" + d.lastAttemptDateTime?.split(' ')[0]?.split('/')[1] + "-" + d.lastAttemptDateTime?.split(' ')[0]?.split('/')[2] + " " + d.lastAttemptDateTime?.split(' ')[1] || ''):'',
        };
        modifiedData.push(data1);
      });

      ExcelService.ExcelData(modifiedData, 'RCP-AllMailList');
    }
    else {
      warning("No record found", warningNotification)
    }
  };

  createMail = () => {
    console.log(this.props)
    //window.location.href="/my-inbox-create"
    let role = localStorage.getItem("roleId")
    if (role === "6") {
      this.props.history.push("/my-inbox-create", {
        alerts: this.props.alertNotificationEvents,
        notification: this.props.notificationEvents,
        userRoleAccess: this.props.userRoleAccess,

      }
      )
    }
    else {
      this.props.history.push("/my-inbox-compose", {
        alerts: this.props.alertNotificationEvents,
        notification: this.props.notificationEvents,
        userRoleAccess: this.props.userRoleAccess,

      }
      )
    }
  }

  switchTab = (evt, inp) => {

    //  var i, tabcontent, tablinks;
    // tabcontent = document.getElementsByClassName("tabcontent");
    // if (inp == "factual") {
    //   this.setState({ display: 'none' })
    //   this.setState({ signuptabName: "tab active" })
    //   this.setState({ logintabName: "tab" })
    //   this.setState({ draftMessage: "tab" })
    //   document.getElementById('behavioural').style.display = "none";
    //   document.getElementById('draft').style.display = "none";
    //   document.getElementById('factual').style.display = "block";
    //   evt.currentTarget.className += " active";
    // }
    // else {
    //   this.setState({ display: 'block' })
    //   this.setState({ signuptabName: "tab" })
    //   this.setState({ logintabName: "tab active" })
    //   document.getElementById('factual').style.display = "none";
    //   document.getElementById('draft').style.display = "none";
    //   document.getElementById('behavioural').style.display = "block";
    //   evt.currentTarget.className += " active";
    // }


  }
  DeleteDraft = (data1) => {
    let d1 = {
      "draftHistoryId": data1.draftHistoryId,
    }
    var txt = "Are you sure you want to delete?"
    var r = window.confirm(txt);
    if (r == true) {
      FetchNotificationService.deleteDarft(d1, (res2) => {
        success("Draft deleted sucessfully.", successNotification)
        window.location.reload();
      })
    }
  }
  showAll = (tab, tabIndex) => {
    console.log("dates before", this.state.fromDate, this.state.toDate)
    let formattedFromDate = `${this.state.fromDate?.split("-")[0]}-${this.state.fromDate?.split("-")[1]
      }-${this.state.fromDate?.split("-")[2]}`;
    let formattedToDate = `${this.state.toDate?.split("-")[0]}-${this.state.toDate?.split("-")[1]
      }-${this.state.toDate?.split("-")[2]}`;
    console.log("dates after", formattedFromDate, formattedToDate)
    let showTableUpdated = [...this.state.showTable];
    showTableUpdated[tabIndex] = false;
    this.setState({
      fromDate: formattedFromDate,
      toDate: formattedToDate,
      showTable: showTableUpdated
    });
    console.log("showAll finalState", this.state.showTable, showTableUpdated)
  }
handleDataFetch=async (tabIndex)=>{
  let partyID = localStorage.getItem("ciel-userid-Token");
  console.log("tabindex",tabIndex)
  if(tabIndex===0){
    let params0=`fromDate=${this.formatDate(this.state.fromDate)}&toDate=${this.formatDate(this.state.toDate)}&notifyManagerFlag=Y`
    this.setState({loading:true})
    FetchNotificationService.fetchNotificationCandidate(params0, (res5) => {
      if(["success",null].includes(res5.status)){
        this.setState({
          candidateMessagedata: res5.responseListObject,
          loading:false
        })
        console.log("data candidate::", this.state.candidateMessagedata)
      }
      else{
        this.setState({ loading: false }) 
      }
      })
   }
   if(tabIndex===1){
      let params1=`partyId=${partyID}&fromDate=${this.formatDate(this.state.fromDate)}&toDate=${this.formatDate(this.state.toDate)}&notifyManagerFlag=Y`  
      this.setState({loading:true})
      // FetchNotificationService.fetchNotification(params1, (res1) => {
        FetchNotificationService.fetchNotificationEventsForInboxandAllMail(params1,(res1)=>{
        if(["success",null].includes(res1.status)){
        this.setState({
          data: res1.responseListObject,
          loading:false
        })
        console.log("data inbox:: ", this.state.data)
      }
      else{
        this.setState({ loading: false }) 
      }
      })
    }
      if(tabIndex===2){
      let params2=`senderPartyId=${partyID}&fromDate=${this.formatDate(this.state.fromDate)}&toDate=${this.formatDate(this.state.toDate)}&notifyManagerFlag=Y`
      this.setState({loading:true})
      FetchNotificationService.fetchNotificationSent(params2, (res) => {
        if(["success",null].includes(res.status)){
      this.setState({
        dataSent: res.responseListObject,
        loading: false
      })
    }
    else{
      this.setState({ loading: false }) 
    }
    })
      }
      if(tabIndex===3){
      let params3=`senderPartyId=${partyID}&deliveryChannel=email&fromDate=${this.formatDate(this.state.fromDate)}&toDate=${this.formatDate(this.state.toDate)}`
      this.setState({loading:true})
    FetchNotificationService.fetchNotificationDarft(params3, (res2) => {
      if(["success",null].includes(res2.status)){
    this.setState({
      dataDraft: res2.responseListObject,
      loading: false
    })
  }
     else{
        this.setState({ loading: false }) 
      }
  })
}
if(tabIndex===4){
      let params4=`fromDate=${this.formatDate(this.state.fromDate)}&toDate=${this.formatDate(this.state.toDate)}&notifyManagerFlag=Y`
      this.setState({loading:true})
      // FetchNotificationService.fetchNotificationEvents(params4, (res4) => {
        FetchNotificationService.fetchNotificationEventsForInboxandAllMail(params4,(res4)=>{
        if(["success",null].includes(res4.status)){
      console.log("res4.responseListObject:: ", res4.responseListObject)
      this.setState({
        dataAllMail: res4.responseListObject,
        loading: false
      })
    }
    else{
      this.setState({ loading: false }) 
    }
    })
 
  }

}
  handleTabClick = async (e,id, tabIndex) => {
    if(e){
      e.preventDefault();
    }
    let showTableUpdated = this.state.showTable?.map((value, index) => index === tabIndex)
    this.setState({
      showTable: showTableUpdated,
      currentTab: tabIndex
    });
    await this.handleToggle(id);
    await this.handleFromToDate();
    await this.handleDataFetch(tabIndex);
  };
  render(props) {
    return (
      <React.Fragment>
        <DashboardHeader
          {...this.props} />
        <div class="container">
          <div className="preassessmentDetailBox mb-50">
            <div className="pre-detail-tab">
              <div className="row">
              <div className="col-md-8 col-sm-8 col-xs-12">
              <ul class="tabs tab-group" id="inboxTab">
                <li id="candidate1"><a className={ localStorage.getItem("roleId") == "6"?"active":""} id="candidate-anchor" onClick={(e) => { this.handleTabClick(e,e.target.id, 0) }} href="#candidate">CANDIDATE MESSAGE</a></li>
                <li><a id="inbox-anchor" className={ localStorage.getItem("roleId") == "6"?"":"active"} onClick={(e) => { this.handleTabClick(e,e.target.id, 1) }} href="#inbox">INBOX</a></li>
                <li><a id="sent-anchor" onClick={(e) => { this.handleTabClick(e,e.target.id, 2) }} href="#sent">SENT</a></li>
                <li id="draft1"><a id="draft-anchor" onClick={(e) => { this.handleTabClick(e,e.target.id, 3) }} href="#draft">DRAFT</a></li>
                <li id="allMail1"><a id="allMail-anchor" onClick={(e) => { this.handleTabClick(e,e.target.id, 4) }} href="#allMail">ALL MAIL</a></li>
                {/* <li><a href="">COMPOSE</a></li> */}
              </ul>
              </div>
              <div className="col-md-4 col-sm-4 col-xs-12">
                <div className="composeBtn1">
              <button className="btn-2" onClick={this.createMail}>Compose</button>
              </div>
              </div>
              </div>
            </div>
            {/* Candidate Message Starts */}
            {this.state.currentTab === 0 &&
              <Formik
                enableReinitialize={true}
                initialValues={{
                  fromDate: this.state.fromDate,
                  toDate: this.state.toDate,
                }}
                validationSchema={Yup.object().shape({
                  fromDate: Yup.string().required("You must specify From Date"),
                  toDate: Yup.string().required("You must specify To Date"),
                })}
                onSubmit={(values) => {
                  let showTableUpdated = this.state.showTable?.map((value, index) => index === 0)
                  this.setState({
                    showTable: showTableUpdated
                  });
                  let formattedFromDate = `${values["fromDate"]?.split("-")[2]}/${values["fromDate"]?.split("-")[1]
                    }/${values["fromDate"]?.split("-")[0]} 00:00:00`;
                  let formattedEndDate = `${values["toDate"]?.split("-")[2]}/${values["toDate"]?.split("-")[1]
                    }/${values["toDate"]?.split("-")[0]} 00:00:00`;

                  let fromDtConverted = moment(formattedFromDate, "DD.MM.YYYY");
                  let toDtConverted = moment(formattedEndDate, "DD.MM.YYYY");
                  if (fromDtConverted.diff(toDtConverted, "days") > 0) {
                    warning(
                      "From Date should not be greater than To Date",
                      warningNotification
                    );
                    return;
                  }
                  this.setState({ loading: true });
                  let partyID = localStorage.getItem("ciel-userid-Token");
                  // let params0=`partyId=${partyID}&fromDate=${this.formatDate(values.fromDate)}&toDate=${this.formatDate(values.toDate)}&notifyManagerFlag=Y`
                  let params0=`fromDate=${this.formatDate(values.fromDate)}&toDate=${this.formatDate(values.toDate)}&notifyManagerFlag=Y`
                  FetchNotificationService.fetchNotificationCandidate(params0, (res5) => {
                    if(["success",null].includes(res5.status)){
                    this.setState({
                      candidateMessagedata: res5.responseListObject,
                      loading:false
                    })
                    console.log("data candidate:: ", this.state.candidateMessagedata)
                  }
                  else{
                    this.setState({ loading: false }) 
                  }
                  })
                  setTimeout(() => { this.setState({ loading: false }) }, 2000)

                }}
                render={({ values, handleSubmit, handleChange, setFieldValue }) => (
                  <React.Fragment>
                    {this.state.loading && (
                      <PopUpPortal
                        HidePopup={this.state.loading}
                        IsVisible={this.state.loading}
                      />
                    )}
                    <section class="panel dashboard-tab user-tab pb-mb-5" id="candidate">
                      <div class="">
                        <div class="container-fluid">
                          <div class="row">
                            <div class="col-md-12 col-sm-12 col-xs-12">
                              <div class="table-header">
                                <h3>
                                  <div class="row">
                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                      <span class="left-icon">
                                        <i class="fa fa-list" aria-hidden="true"></i>
                                      </span>{" "}
                                      Candidate Message
                                    </div>
                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                      <span class="right-icon">
                                        <a title="Export">
                                          <i
                                            onClick={() => this.exportToCandidateCSV()}
                                            class="fa fa-file-excel-o"
                                            aria-hidden="true"
                                          ></i>
                                        </a>
                                      </span>

                                    </div>
                                  </div>
                                </h3>
                              </div>
                              <div class="bg-box">
                                <Form onSubmit={handleSubmit} className="">
                                  <div class="row">
                                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                      <div className="filter">
                                        <div className="form-group">
                                          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                            <h5 className="filter-heading">
                                              Search Criteria
                                            </h5>
                                          </div>
                                          <div className="row">
                                            <div className="col-md-4 col-sm-4 col-xs-12">
                                              <label>
                                                From Date
                                                <span className="validation"> *</span>
                                              </label>
                                              <Field
                                                type="date"
                                                name="fromDate"
                                                id="fromDate"
                                                className="form-control"
                                                onChange={handleChange}
                                              />
                                              <ErrorMessage
                                                name="fromDate"
                                                component="div"
                                                className="validation"
                                              />
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-xs-12">
                                              <label>
                                                To Date
                                                <span className="validation"> *</span>
                                              </label>
                                              <Field
                                                type="date"
                                                name="toDate"
                                                id="toDate"
                                                className="form-control"
                                                onChange={handleChange}
                                              />
                                              <ErrorMessage
                                                name="toDate"
                                                component="div"
                                                className="validation"
                                              />
                                            </div>
                                          </div>
                                          <div class="row">
                                            <div className="col-md-12 col-sm-12 mt-40">
                                              <button
                                                className="btn-5 mr-20 ml-20"
                                                // type="submit"
                                                type="button"
                                                id="candidateSearch"
                                                // onClick={()=>{handleSubmit(values,"candidate")}}
                                                onClick={() => {
                                                  setFieldValue("tab", "candidate")
                                                  handleSubmit();
                                                }}
                                              >
                                                Search
                                              </button>
                                              <button
                                                className="btn-5"
                                                type="reset"
                                                onClick={() => { this.showAll("candidate", 0) }}
                                                id="candidateReset"
                                              >
                                                Reset
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Form>
                                {/* </div> */}
                                {this.state.showTable?.[0] === true &&
                                  <div class="table-responsive candidate-table1">
                                    <ReactTable
                                      minRows={2}
                                      data={this.state.candidateMessagedata || []}
                                      filterable
                                      ref={(refReactTable) => {
                                        this.refReactTable = refReactTable;
                                      }}
                                      defaultFilterMethod={this.filterCaseInsensitive}
                                      columns={[
                                        {
                                          columns: [
                                            {
                                              Header: "Sender Email Id",
                                              id: "senderEmailId",
                                              accessor: (d) =>
                                                (d.senderEmailId || "").toString(),

                                              Cell: props => {
                                                return (
                                                  <div>
                                                    <strong className={" " + (props.original.read_unread ? '' : 'unread')}>
                                                      {props.original.senderEmailId}
                                                    </strong>
                                                  </div>
                                                );
                                              }

                                            },
                                            {
                                              Header: "Sender Mobile No",
                                              id: "mobileNo",
                                              accessor: (d) =>
                                                (d.mobileNo || "").toString(),
                                              Cell: props => {
                                                return (
                                                  <div>
                                                    <strong className={" " + (props.original.read_unread ? '' : 'unread')}>
                                                      {props.original.mobileNo}
                                                    </strong>
                                                  </div>
                                                );
                                              }
                                            },
                                            {
                                              Header: "Subject",
                                              id: "emailSubjectText",
                                              accessor: (d) =>
                                                (d.emailSubjectText || "").toString(),
                                              Cell: props => {
                                                return (
                                                  <div>
                                                    <a class="mar-left">
                                                      {
                                                        <div>
                                                          <a
                                                            title="View Email"
                                                            aria-hidden="true" data-toggle="modal"
                                                            data-keyboard="true"
                                                            data-target="#modal-Message-Inbox"
                                                            onClick={() =>
                                                              this.notificationCandidate(props.original.notifyHistoryId, props.original)
                                                            }


                                                          >{props.original.emailSubjectText}</a>
                                                        </div>
                                                      }
                                                    </a>
                                                  </div>
                                                );
                                              }
                                            },
                                            {
                                              Header: "Mandate Name",
                                              id: "mandateName",
                                              accessor: (d) =>
                                                (d.mandateName || "").toString(),
                                              Cell: props => {
                                                return (
                                                  <div>
                                                    <strong className={" " + (props.original.read_unread ? '' : 'unread')}>
                                                      {props.original.mandateName}
                                                    </strong>
                                                  </div>
                                                );
                                              }
                                            },
                                            {
                                              Header: "Bank Name",
                                              id: "companyName",
                                              accessor: (d) =>
                                                (d.companyName || "").toString(),
                                              Cell: props => {
                                                return (
                                                  <div>
                                                    <strong className={" " + (props.original.read_unread ? '' : 'unread')}>
                                                      {props.original.companyName}
                                                    </strong>
                                                  </div>
                                                );
                                              }
                                            },
                                            {
                                              Header: "Delivery Date",
                                              id: "deliveryDate",
                                              accessor: (d) =>
                                                (d.deliveryDate?.split(' ')[0]?.split('/')[0] + "-" + d.deliveryDate?.split(' ')[0]?.split('/')[1] + "-" + d.deliveryDate?.split(' ')[0]?.split('/')[2] + " " + d.deliveryDate?.split(' ')[1] || ''),
                                              Cell: props => {
                                                return (
                                                  <div>
                                                    <strong className={" " + (props.original.read_unread ? '' : 'unread')}>
                                                      {props.original.deliveryDate?.split(' ')[0]?.split('/')[0] + "-" + props.original.deliveryDate?.split(' ')[0]?.split('/')[1] + "-" + props.original.deliveryDate?.split(' ')[0]?.split('/')[2] + " " + props.original.deliveryDate?.split(' ')[1] || ''}
                                                    </strong>
                                                  </div>
                                                );
                                              }
                                            },
                                            {
                                              Header: "CC Email id",
                                              id: "ccEmailId",
                                              accessor: (d) =>
                                                (d.ccEmailId || "").toString(),
                                              Cell: props => {
                                                return (
                                                  <div>
                                                    <strong className={" " + (props.original.read_unread ? '' : 'unread')}>
                                                      {props.original.ccEmailId}
                                                    </strong>
                                                  </div>
                                                );
                                              }
                                            },
                                            {
                                              Header: "Delivery Status",
                                              id: "deliveryStatus",
                                              // accessor: (d) => (d.deliveryStatus || '').toString() === 'completed' ? 'Success' : 'Failure',
                                              accessor:(d)=>['completed',null,undefined].includes(d.deliveryStatus?.toString())? 'Success' : 'Failure',
                                              
                                              Cell: props => {
                                                return (
                                                  <div>
                                                    <strong className={" " + (props.original.read_unread ? '' : 'unread')}>
                                                      {/* {props.original.deliveryStatus.toString() === 'completed' ? 'Success' : 'Failure'} */}
                                                      {['completed',null,undefined].includes(props.original?.deliveryStatus?.toString())? 'Success' : 'Failure'}
                                                    </strong>
                                                  </div>
                                                );
                                              }
                                            },
                                          ],
                                        },
                                      ]}
                                      defaultPageSize={10}
                                      style={{
                                        width: "100%",
                                        maxHeight: "500px",
                                      }}
                                    />
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                          <div class="modal fade" id="modal-Message-Inbox" tabIndex="-1" role="dialog" aria-labelledby="modalLabel" data-keyboard="true" aria-hidden="true">
                            <div class="modal-dialog" style={{ width: "70%" }} >
                              <div class="modal-content">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                                <Formik
                                  enableReinitialize={true}
                                  render={({
                                    errors, touched
                                  }) => (
                                    <React.Fragment>
                                      <Form className="form-group">
                                        <section >
                                          <div >
                                            <div class="row">
                                              <div class="col-md-2 col-lg-2 col-sm-2 col-xs-2">
                                              </div>
                                              <div class="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                                                <div class="product-add">
                                                  <div class="form-group">
                                                    <div class="row">
                                                      <div class="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                                                        <label>
                                                          FROM
                                                        </label>
                                                        <Field
                                                          type="text"
                                                          name="FromEMailId"
                                                          id="FromEMailId"
                                                          placeholder="FromEMailId"
                                                          value={this.state.MessageDetails.fromEmailName} //emailSubjectText  //emailSMSBodyText }
                                                          className={
                                                            "form-control"
                                                          }

                                                        >
                                                        </Field>

                                                      </div>
                                                      <div class="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                                                        <label>
                                                          Subject
                                                        </label>
                                                        <Field
                                                          type="text"
                                                          name="subject"
                                                          id="subject"
                                                          autoComplete="subject"
                                                          value={this.state.MessageDetails.emailSubjectText}  //emailSMSBodyText }
                                                          className={
                                                            "form-control " +
                                                            (errors.subject &&
                                                              touched.text
                                                              ? " is-invalid"
                                                              : "")
                                                          }
                                                          placeholder="Subject"
                                                        // className="form-control"
                                                        />
                                                        <ErrorMessage
                                                          name="subject"
                                                          component="div"
                                                          className="validation"
                                                        />
                                                      </div>
                                                      <div class="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                                                        <label>
                                                          Message
                                                        </label>
                                                        <Field as="textarea" rows="8" cols="50" name="message"
                                                          id="message"
                                                          autoComplete="message"
                                                          value={this.state.MessageDetails.emailSMSBodyText}
                                                          className={
                                                            "form-control"
                                                          }
                                                        />
                                                        <ErrorMessage
                                                          name="message"
                                                          className="validation"
                                                          component="div"
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="col-md-2 col-lg-2 col-sm-2 col-xs-2">
                                              </div>
                                            </div>
                                          </div>
                                        </section>
                                      </Form>
                                    </React.Fragment>
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </section>

                  </React.Fragment>
                )}
              />
            }
            {/* Candidate Message Ends */}
            {this.state.currentTab === 1 &&
              <Formik
                enableReinitialize={true}
                initialValues={{
                  tab: "",
                  fromDate: this.state.fromDate,
                  toDate: this.state.toDate,
                }}
                validationSchema={Yup.object().shape({
                  fromDate: Yup.string().required("You must specify From Date"),
                  toDate: Yup.string().required("You must specify To Date"),
                })}
                onSubmit={(values) => {
                  let showTableUpdated = this.state.showTable?.map((value, index) => index === 1)
                  this.setState({
                    showTable: showTableUpdated
                  });
                  let formattedFromDate = `${values["fromDate"]?.split("-")[2]}/${values["fromDate"]?.split("-")[1]
                    }/${values["fromDate"]?.split("-")[0]} 00:00:00`;
                  let formattedEndDate = `${values["toDate"]?.split("-")[2]}/${values["toDate"]?.split("-")[1]
                    }/${values["toDate"]?.split("-")[0]} 00:00:00`;

                  let fromDtConverted = moment(formattedFromDate, "DD.MM.YYYY");
                  let toDtConverted = moment(formattedEndDate, "DD.MM.YYYY");
                  if (fromDtConverted.diff(toDtConverted, "days") > 0) {
                    warning(
                      "From Date should not be greater than To Date",
                      warningNotification
                    );
                    return;
                  }
                  this.setState({ loading: true });
                  let partyID = localStorage.getItem("ciel-userid-Token");
                  let params1=`partyId=${partyID}&fromDate=${this.formatDate(values.fromDate)}&toDate=${this.formatDate(values.toDate)}&notifyManagerFlag=Y`
                    // FetchNotificationService.fetchNotification(params1, (res1) => {
                      FetchNotificationService.fetchNotificationEventsForInboxandAllMail(params1,(res1)=>{
                      if(["success",null].includes(res1.status)){
                    this.setState({
                      data: res1.responseListObject,
                      loading:false
                    })
                    console.log("data inbox:: ", this.state.data)
                  }
                  else{
                    this.setState({ loading: false }) 
                  }
                  })              

                  setTimeout(() => { this.setState({ loading: false }) }, 2000)

                }}
                render={({ values, handleSubmit, handleChange, setFieldValue }) => (
                  <React.Fragment>
                    {this.state.loading && (
                      <PopUpPortal
                        HidePopup={this.state.loading}
                        IsVisible={this.state.loading}
                      />
                    )}


                    {!this.props.pendingNotificationEvents ?

                      <section class="panel dashboard-tab user-tab pb-mb-5" id="inbox">
                        <div class="">
                          <div class="container-fluid">
                            <div class="row">
                              <div class="col-md-12 col-sm-12 col-xs-12">
                                <div class="table-header">
                                  <h3>
                                    <div class="row">
                                      <div class="col-md-6 col-sm-6 col-xs-6">
                                        <span class="left-icon">
                                          <i class="fa fa-list" aria-hidden="true"></i>
                                        </span>{" "}
                                        Inbox
                                      </div>
                                      <div class="col-md-6 col-sm-6 col-xs-6">
                                        <span class="right-icon">
                                          <a title="Export">
                                            <i
                                              onClick={() => this.exportToCSV()}
                                              class="fa fa-file-excel-o"
                                              aria-hidden="true"
                                            ></i>
                                          </a>
                                        </span>

                                      </div>
                                    </div>
                                  </h3>
                                </div>
                                <div class="bg-box">
                                  <Form onSubmit={handleSubmit} className="">
                                    <div class="row">
                                      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                        <div className="filter">
                                          <div className="form-group">
                                            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                              <h5 className="filter-heading">
                                                Search Criteria
                                              </h5>
                                            </div>
                                            <div className="row">
                                              <div className="col-md-4 col-sm-4 col-xs-12">
                                                <label>
                                                  From Date
                                                  <span className="validation"> *</span>
                                                </label>
                                                <Field
                                                  type="date"
                                                  name="fromDate"
                                                  id="fromDate"
                                                  className="form-control"
                                                  onChange={handleChange}
                                                />
                                                <ErrorMessage
                                                  name="fromDate"
                                                  component="div"
                                                  className="validation"
                                                />
                                              </div>
                                              <div className="col-md-4 col-sm-4 col-xs-12">
                                                <label>
                                                  To Date
                                                  <span className="validation"> *</span>
                                                </label>
                                                <Field
                                                  type="date"
                                                  name="toDate"
                                                  id="toDate"
                                                  className="form-control"
                                                  onChange={handleChange}
                                                />
                                                <ErrorMessage
                                                  name="toDate"
                                                  component="div"
                                                  className="validation"
                                                />
                                              </div>
                                            </div>
                                            <div class="row">
                                              <div className="col-md-12 col-sm-12 mt-40">
                                                <button
                                                  className="btn-5 mr-20 ml-20"
                                                  // type="submit"
                                                  type="button"
                                                  // onClick={()=>{handleSubmit(values,"inbox")}}
                                                  onClick={() => {
                                                    setFieldValue("tab", "inbox")
                                                    handleSubmit();
                                                  }}
                                                  id="inboxSearch"
                                                >
                                                  Search
                                                </button>
                                                <button
                                                  className="btn-5"
                                                  type="reset"
                                                  onClick={() => { this.showAll("inbox", 1) }}
                                                  id="inboxReset"
                                                >
                                                  Reset
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Form>
                                  {this.state.showTable?.[1] === true &&
                                    <div class="table-responsive inbox-table1">
                                      <ReactTable

                                        minRows={2}
                                        data={this.state.data || []}
                                        filterable
                                        ref={(refReactTable) => {
                                          this.refReactTable = refReactTable;
                                        }}
                                        defaultFilterMethod={this.filterCaseInsensitive}
                                        columns={[
                                          {
                                            columns: [
                                              {
                                                Header: "From ID",
                                                id: "fromEmailId",
                                                //className:"unread",
                                                //className={" " +(props.original.read_unread ? 'unread': '' )},
                                                accessor: (d) =>
                                                  (d.fromEmailId || "").toString(),

                                                Cell: props => {
                                                  return (
                                                    <div>
                                                      <strong className={" " + (props.original.read_unread ? '' : 'unread')}>
                                                        {props.original.fromEmailId}
                                                      </strong>
                                                    </div>
                                                  );
                                                }

                                              },
                                              {
                                                Header: "From Name",
                                                id: "fromEmailName",
                                                // className:"unread",
                                                accessor: (d) =>
                                                  (d.fromEmailName || "").toString(),
                                                Cell: props => {
                                                  return (
                                                    <div>
                                                      <strong className={" " + (props.original.read_unread ? '' : 'unread')}>
                                                        {props.original.fromEmailName}
                                                      </strong>
                                                    </div>
                                                  );
                                                }
                                              },
                                              {
                                                Header: "Subject",
                                                id: "emailSubjectText",
                                                // className:"unread",
                                                accessor: (d) =>
                                                  (d.emailSubjectText || "").toString(),
                                                Cell: props => {
                                                  return (
                                                    <div>
                                                      <a class="mar-left">
                                                        {

                                                          <div>
                                                            <a
                                                              title="View Email"

                                                              aria-hidden="true" data-toggle="modal"
                                                              data-keyboard="true"
                                                              data-target="#modal-Message-Inbox"
                                                              onClick={() =>
                                                                // this.eMailMessage(props.original)
                                                                this.notification(props.original.notifyHistoryId, props.original)
                                                                // this.notification(props.original.notifyHistoryId)
                                                              }


                                                            >{props.original.emailSubjectText}</a>
                                                          </div>
                                                          //: ''
                                                        }
                                                      </a>
                                                    </div>
                                                  );
                                                }
                                                // },
                                                // ((d.productEntity || "").productName || '').toString()
                                              },
                                              {
                                                Header: "Delivery Status",
                                                id: "deliveryStatus",
                                                // className:"unread",
                                                // accessor: (d) => (d.deliveryStatus || '').toString() === 'completed' ? 'Success' : 'Failure',
                                                accessor: (d) =>['completed',null,undefined].includes(d.deliveryStatus?.toString())? 'Success' : 'Failure',
                                                
                                                Cell: props => {
                                                  return (
                                                    <div>
                                                      <strong className={" " + (props.original.read_unread ? '' : 'unread')}>
                                                        {/* {props.original.deliveryStatus.toString() === 'completed' ? 'Success' : 'Failure'} */}
                                                        {['completed',null,undefined].includes(props.original?.deliveryStatus?.toString()) ? 'Success' : 'Failure'}
                                                      </strong>
                                                    </div>
                                                  );
                                                }
                                              },
                                              {
                                                Header: "Delivery Date",
                                                id: "lastAttemptDateTime",
                                                // className:"unread",
                                                accessor: (d) =>
                                                  (d.lastAttemptDateTime?.split(' ')[0]?.split('/')[0] + "-" + d.lastAttemptDateTime?.split(' ')[0]?.split('/')[1] + "-" + d.lastAttemptDateTime?.split(' ')[0]?.split('/')[2] + " " + d.lastAttemptDateTime?.split(' ')[1] || ''),
                                                Cell: props => {
                                                  return (
                                                    <div>
                                                      <strong className={" " + (props.original.read_unread ? '' : 'unread')}>
                                                        {props.original.lastAttemptDateTime?.split(' ')[0]?.split('/')[0] + "-" + props.original.lastAttemptDateTime?.split(' ')[0]?.split('/')[1] + "-" + props.original.lastAttemptDateTime?.split(' ')[0]?.split('/')[2] + " " + props.original.lastAttemptDateTime?.split(' ')[1] || ''}
                                                      </strong>
                                                    </div>
                                                  );
                                                }
                                              },
                                              // {
                                              //   Header: "Action",
                                              //   id: "action",
                                              //   filterable: false,
                                              //   sortable: false,
                                              //   Cell: props => {
                                              //     return (
                                              //       <div>
                                              //         <a class="mar-left">
                                              //           {
                                              //             //props.original.isActive == 1 ?
                                              //             <i
                                              //               title="View Email"
                                              //               className="fa fa-paper-plane"
                                              //               aria-hidden="true" data-toggle="modal"
                                              //                  data-keyboard="true"
                                              //               data-target="#modal-Message-Inbox"
                                              //               onClick={() =>
                                              //                 this.eMailMessage(props.original)
                                              //               }
                                              //             ></i>
                                              //             //: ''
                                              //           }
                                              //         </a>
                                              //       </div>
                                              //     );
                                              //   }
                                              // },

                                            ],
                                          },
                                        ]}
                                        defaultPageSize={10}
                                        style={{
                                          width: "100%",
                                          maxHeight: "500px",
                                        }}
                                      />
                                    </div>
                                  }
                                </div>
                              </div>
                            </div>
                            {/* </div>
            </div> */}
                            <div class="modal fade" id="modal-Message-Inbox" tabIndex="-1" role="dialog" aria-labelledby="modalLabel" data-keyboard="true" aria-hidden="true">
                              <div class="modal-dialog" style={{ width: "70%" }} >
                                <div class="modal-content">
                                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                  <Formik
                                    enableReinitialize={true}
                                    render={({
                                      errors, touched
                                    }) => (
                                      <React.Fragment>
                                        <Form className="form-group">
                                          <section >
                                            <div >
                                              <div class="row">
                                                <div class="col-md-2 col-lg-2 col-sm-2 col-xs-2">
                                                </div>
                                                <div class="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                                                  <div class="product-add">
                                                    <div class="form-group">
                                                      <div class="row">
                                                        <div class="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                                                          <label>
                                                            FROM
                                                          </label>
                                                          <Field
                                                            type="text"
                                                            name="FromEMailId"
                                                            id="FromEMailId"
                                                            placeholder="FromEMailId"
                                                            value={this.state.MessageDetails.fromEmailName} //emailSubjectText  //emailSMSBodyText }
                                                            className={
                                                              "form-control"
                                                            }

                                                          >
                                                          </Field>

                                                        </div>
                                                        <div class="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                                                          <label>
                                                            Subject
                                                          </label>
                                                          <Field
                                                            type="text"
                                                            name="subject"
                                                            id="subject"
                                                            autoComplete="subject"
                                                            value={this.state.MessageDetails.emailSubjectText}  //emailSMSBodyText }
                                                            className={
                                                              "form-control " +
                                                              (errors.subject &&
                                                                touched.text
                                                                ? " is-invalid"
                                                                : "")
                                                            }
                                                            placeholder="Subject"
                                                          // className="form-control"
                                                          />
                                                          <ErrorMessage
                                                            name="subject"
                                                            component="div"
                                                            className="validation"
                                                          />
                                                        </div>
                                                        <div class="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                                                          <label>
                                                            Message
                                                          </label>
                                                          <Field as="textarea" rows="8" cols="50" name="message"
                                                            id="message"
                                                            autoComplete="message"
                                                            value={this.state.MessageDetails.emailSMSBodyText}
                                                            className={
                                                              "form-control"
                                                            }
                                                          />
                                                          <ErrorMessage
                                                            name="message"
                                                            className="validation"
                                                            component="div"
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="col-md-2 col-lg-2 col-sm-2 col-xs-2">
                                                </div>
                                              </div>
                                            </div>
                                          </section>
                                        </Form>
                                      </React.Fragment>
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </section>
                      : ""}
                  </React.Fragment>
                )}
              />
            }
            {this.state.currentTab === 2 &&
              <Formik
                enableReinitialize={true}
                initialValues={{
                  fromDate: this.state.fromDate,
                  toDate: this.state.toDate,
                }}
                validationSchema={Yup.object().shape({
                  fromDate: Yup.string().required("You must specify From Date"),
                  toDate: Yup.string().required("You must specify To Date"),
                })}
                onSubmit={(values) => {
                  let showTableUpdated = this.state.showTable?.map((value, index) => index === 2)
                  this.setState({
                    showTable: showTableUpdated
                  });
                  let formattedFromDate = `${values["fromDate"]?.split("-")[2]}/${values["fromDate"]?.split("-")[1]
                    }/${values["fromDate"]?.split("-")[0]} 00:00:00`;
                  let formattedEndDate = `${values["toDate"]?.split("-")[2]}/${values["toDate"]?.split("-")[1]
                    }/${values["toDate"]?.split("-")[0]} 00:00:00`;

                  let fromDtConverted = moment(formattedFromDate, "DD.MM.YYYY");
                  let toDtConverted = moment(formattedEndDate, "DD.MM.YYYY");
                  if (fromDtConverted.diff(toDtConverted, "days") > 0) {
                    warning(
                      "From Date should not be greater than To Date",
                      warningNotification
                    );
                    return;
                  }

                  
                  this.setState({ loading: true });
                  let partyID = localStorage.getItem("ciel-userid-Token");
                  let params2=`senderPartyId=${partyID}&fromDate=${this.formatDate(values.fromDate)}&toDate=${this.formatDate(values.toDate)}&notifyManagerFlag=Y`
                    FetchNotificationService.fetchNotificationSent(params2, (res) => {
                      if(["success",null].includes(res.status)){
                    this.setState({
                      dataSent: res.responseListObject,
                      loading: false
                    })
                  }
                  else{
                    this.setState({ loading: false }) 
                  }
                  })
                  setTimeout(() => { this.setState({ loading: false }) }, 2000)

                }}
                render={({ values, handleSubmit, handleChange, setFieldValue }) => (
                  <React.Fragment>
                    {this.state.loading && (
                      <PopUpPortal
                        HidePopup={this.state.loading}
                        IsVisible={this.state.loading}
                      />
                    )}
                    <section class="panel dashboard-tab user-tab pb-mb-5" id="sent">
                      <div class="">
                        <div class="container-fluid">
                          <div class="row">
                            <div class="col-md-12 col-sm-12 col-xs-12">
                              <div class="table-header">
                                <h3>
                                  <div class="row">
                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                      <span class="left-icon">
                                        <i class="fa fa-list" aria-hidden="true"></i>
                                      </span>{" "}
                                      Sent
                                    </div>
                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                      <span class="right-icon">
                                        <a title="Export">
                                          <i
                                            onClick={() => this.exportToSentCSV()}
                                            class="fa fa-file-excel-o"
                                            aria-hidden="true"
                                          ></i>
                                        </a>
                                      </span>

                                    </div>
                                  </div>
                                </h3>
                              </div>
                              <div class="bg-box">
                                <Form onSubmit={handleSubmit} className="">
                                  <div class="row">
                                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                      <div className="filter">
                                        <div className="form-group">
                                          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                            <h5 className="filter-heading">
                                              Search Criteria
                                            </h5>
                                          </div>
                                          <div className="row">
                                            <div className="col-md-4 col-sm-4 col-xs-12">
                                              <label>
                                                From Date
                                                <span className="validation"> *</span>
                                              </label>
                                              <Field
                                                type="date"
                                                name="fromDate"
                                                id="fromDate"
                                                className="form-control"
                                                onChange={handleChange}
                                              />
                                              <ErrorMessage
                                                name="fromDate"
                                                component="div"
                                                className="validation"
                                              />
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-xs-12">
                                              <label>
                                                To Date
                                                <span className="validation"> *</span>
                                              </label>
                                              <Field
                                                type="date"
                                                name="toDate"
                                                id="toDate"
                                                className="form-control"
                                                onChange={handleChange}
                                              />
                                              <ErrorMessage
                                                name="toDate"
                                                component="div"
                                                className="validation"
                                              />
                                            </div>
                                          </div>
                                          <div class="row">
                                            <div className="col-md-12 col-sm-12 mt-40">
                                              <button
                                                className="btn-5 mr-20 ml-20"
                                                // type="submit"
                                                type="button"
                                                // onClick={()=>{handleSubmit(values,"sent")}}
                                                onClick={() => {
                                                  setFieldValue("tab", "sent")
                                                  handleSubmit();
                                                }}
                                                id="sentSearch"
                                              >
                                                Search
                                              </button>
                                              <button
                                                className="btn-5"
                                                type="reset"
                                                onClick={() => { this.showAll("sent", 2) }}
                                                id="sentReset"
                                              >
                                                Reset
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Form>
                                {this.state.showTable?.[2] === true &&
                                  <div class="table-responsive sent-table1">
                                    <ReactTable

                                      minRows={2}
                                      data={this.state.dataSent || []}
                                      filterable
                                      ref={(refReactTable) => {
                                        this.refReactTable = refReactTable;
                                      }}
                                      defaultFilterMethod={this.filterCaseInsensitive}
                                      columns={[
                                        {
                                          columns: [
                                            {
                                              Header: "From ID",
                                              id: "fromEmailId",
                                              accessor: (d) =>
                                                (d.fromEmailId || "").toString(),
                                            },
                                            {
                                              Header: "From Name",
                                              id: "fromEmailName",
                                              accessor: (d) =>
                                                (d.fromEmailName || "").toString(),
                                            },
                                            {
                                              Header: "To",
                                              id: "toEmailId",
                                              accessor: (d) =>
                                                (d.toEmailIdOrMobileNo || "").toString(),
                                            },
                                            {
                                              Header: "Subject",
                                              id: "emailSubjectText",
                                              accessor: (d) =>
                                                (d.emailSubjectText || "").toString(),
                                              Cell: props => {
                                                return (
                                                  <div>
                                                    <a class="mar-left">
                                                      {

                                                        <div>
                                                          <a
                                                            title="View Email"
                                                            //      className="fa fa-paper-plane"
                                                            aria-hidden="true" data-toggle="modal"
                                                            data-keyboard="true"
                                                            data-target="#modal-Message-Inbox"
                                                            onClick={() =>
                                                              // this.eMailMessage(props.original)
                                                              // this.notificationSent(props.original.notifyHistoryId)
                                                              this.notificationSent(props.original.notifyHistoryId, props.original)
                                                            }


                                                          >{props.original.emailSubjectText}</a>
                                                        </div>
                                                        //: ''
                                                      }
                                                    </a>
                                                  </div>
                                                );
                                              }
                                            },
                                            {
                                              Header: "Delivery Status",
                                              id: "deliveryStatus",
                                              // accessor: (d) => (d.deliveryStatus || '').toString() === 'completed' ? 'Success' : 'Failure'
                                              accessor: (d) =>['completed',null,undefined].includes(d.deliveryStatus?.toString())? 'Success' : 'Failure',
                                            },
                                            {
                                              Header: "Delivery Date",
                                              id: "lastAttemptDateTime",
                                              accessor: (d) =>
                                                (d.lastAttemptDateTime?.split(' ')[0]?.split('/')[0] + "-" + d.lastAttemptDateTime?.split(' ')[0]?.split('/')[1] + "-" + d.lastAttemptDateTime?.split(' ')[0]?.split('/')[2] + " " + d.lastAttemptDateTime?.split(' ')[1] || ''),
                                            },
                                            // {
                                            //   Header: "Delivery Date",
                                            //   id: "lastAttemptDatetime",
                                            //   accessor: (d) =>
                                            //     (moment(d.lastAttemptDatetime.split(" ")[0], "DD/MM/YYYY").format("DD-MM-YYYY") || ""),
                                            // },
                                          ],
                                        },
                                      ]}
                                      defaultPageSize={10}
                                      style={{
                                        width: "100%",
                                        maxHeight: "500px",
                                      }}
                                    />
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                          {/* </div>
            </div> */}
                          <div class="modal fade" id="modal-Message-Inbox" tabIndex="-1" role="dialog" aria-labelledby="modalLabel" data-keyboard="true" aria-hidden="true">
                            <div class="modal-dialog" style={{ width: "70%" }} >
                              <div class="modal-content">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                                <Formik
                                  enableReinitialize={true}
                                  render={({
                                    errors, touched
                                  }) => (
                                    <React.Fragment>
                                      <Form className="form-group">
                                        <section >
                                          <div >
                                            <div class="row">
                                              <div class="col-md-2 col-lg-2 col-sm-2 col-xs-2">
                                              </div>
                                              <div class="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                                                <div class="product-add">
                                                  <div class="form-group">
                                                    <div class="row">
                                                      <div class="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                                                        <label>
                                                          FROM
                                                        </label>
                                                        <Field
                                                          type="text"
                                                          name="FromEMailId"
                                                          id="FromEMailId"
                                                          placeholder="FromEMailId"
                                                          value={this.state.MessageDetails.fromEmailName} //emailSubjectText  //emailSMSBodyText }
                                                          className={
                                                            "form-control"
                                                          }

                                                        >
                                                        </Field>

                                                      </div>
                                                      <div class="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                                                        <label>
                                                          Subject
                                                        </label>
                                                        <Field
                                                          type="text"
                                                          name="subject"
                                                          id="subject"
                                                          autoComplete="subject"
                                                          value={this.state.MessageDetails.emailSubjectText}  //emailSMSBodyText }
                                                          className={
                                                            "form-control " +
                                                            (errors.subject &&
                                                              touched.text
                                                              ? " is-invalid"
                                                              : "")
                                                          }
                                                          placeholder="Subject"
                                                        // className="form-control"
                                                        />
                                                        <ErrorMessage
                                                          name="subject"
                                                          component="div"
                                                          className="validation"
                                                        />
                                                      </div>
                                                      <div class="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                                                        <label>
                                                          Message
                                                        </label>
                                                        <Field as="textarea" rows="8" cols="50" name="message"
                                                          id="message"
                                                          autoComplete="message"
                                                          value={this.state.MessageDetails.emailSMSBodyText}
                                                          className={
                                                            "form-control"
                                                          }
                                                        />
                                                        <ErrorMessage
                                                          name="message"
                                                          className="validation"
                                                          component="div"
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="col-md-2 col-lg-2 col-sm-2 col-xs-2">
                                              </div>
                                            </div>
                                          </div>
                                        </section>
                                      </Form>
                                    </React.Fragment>
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </section>
                  </React.Fragment>
                )}
              />
            }
            {this.state.currentTab === 3 &&
              <Formik
                enableReinitialize={true}
                initialValues={{
                  fromDate: this.state.fromDate,
                  toDate: this.state.toDate,
                }}
                validationSchema={Yup.object().shape({
                  fromDate: Yup.string().required("You must specify From Date"),
                  toDate: Yup.string().required("You must specify To Date"),
                })}
                onSubmit={(values) => {
                  let showTableUpdated = this.state.showTable?.map((value, index) => index === 3)
                  this.setState({
                    showTable: showTableUpdated
                  });
                  let formattedFromDate = `${values["fromDate"]?.split("-")[2]}/${values["fromDate"]?.split("-")[1]
                    }/${values["fromDate"]?.split("-")[0]} 00:00:00`;
                  let formattedEndDate = `${values["toDate"]?.split("-")[2]}/${values["toDate"]?.split("-")[1]
                    }/${values["toDate"]?.split("-")[0]} 00:00:00`;

                  let fromDtConverted = moment(formattedFromDate, "DD.MM.YYYY");
                  let toDtConverted = moment(formattedEndDate, "DD.MM.YYYY");
                  if (fromDtConverted.diff(toDtConverted, "days") > 0) {
                    warning(
                      "From Date should not be greater than To Date",
                      warningNotification
                    );
                    return;
                  }
                  this.setState({ loading: true });
                  let partyID = localStorage.getItem("ciel-userid-Token");
                  let params3=`senderPartyId=${partyID}&deliveryChannel=email&fromDate=${this.formatDate(values.fromDate)}&toDate=${this.formatDate(values.toDate)}`
                    FetchNotificationService.fetchNotificationDarft(params3, (res2) => {
                      if(["success",null].includes(res2.status)){
                    this.setState({
                      dataDraft: res2.responseListObject,
                      loading: false
                    })
                  }
                     else{
                        this.setState({ loading: false }) 
                      }
                  })
                 setTimeout(() => { this.setState({ loading: false }) }, 2000)

                }}
                render={({ values, handleSubmit, handleChange, setFieldValue }) => (
                  <React.Fragment>
                    {this.state.loading && (
                      <PopUpPortal
                        HidePopup={this.state.loading}
                        IsVisible={this.state.loading}
                      />
                    )}
                    <section class="panel dashboard-tab user-tab pb-mb-5" id="draft">
                      <div class="">
                        <div class="container-fluid">
                          <div class="row">
                            <div class="col-md-12 col-sm-12 col-xs-12">
                              <div class="table-header">
                                <h3>
                                  <div class="row">
                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                      <span class="left-icon">
                                        <i class="fa fa-list" aria-hidden="true"></i>
                                      </span>{" "}
                                      Draft
                                    </div>
                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                      {/* <span class="right-icon">
                                <a title="Export">
                                  <i
                                    onClick={() => this.exportToCSV()}
                                    class="fa fa-file-excel-o"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </span> */}

                                    </div>
                                  </div>
                                </h3>
                              </div>
                              <div class="bg-box">
                                <Form onSubmit={handleSubmit} className="">
                                  <div class="row">
                                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                      <div className="filter">
                                        <div className="form-group">
                                          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                            <h5 className="filter-heading">
                                              Search Criteria
                                            </h5>
                                          </div>
                                          <div className="row">
                                            <div className="col-md-4 col-sm-4 col-xs-12">
                                              <label>
                                                From Date
                                                <span className="validation"> *</span>
                                              </label>
                                              <Field
                                                type="date"
                                                name="fromDate"
                                                id="fromDate"
                                                className="form-control"
                                                onChange={handleChange}
                                              />
                                              <ErrorMessage
                                                name="fromDate"
                                                component="div"
                                                className="validation"
                                              />
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-xs-12">
                                              <label>
                                                To Date
                                                <span className="validation"> *</span>
                                              </label>
                                              <Field
                                                type="date"
                                                name="toDate"
                                                id="toDate"
                                                className="form-control"
                                                onChange={handleChange}
                                              />
                                              <ErrorMessage
                                                name="toDate"
                                                component="div"
                                                className="validation"
                                              />
                                            </div>
                                          </div>
                                          <div class="row">
                                            <div className="col-md-12 col-sm-12 mt-40">
                                              <button
                                                className="btn-5 mr-20 ml-20"
                                                // type="submit"
                                                type="button"
                                                // onClick={()=>{handleSubmit(values,"draft")}}
                                                onClick={() => {
                                                  setFieldValue("tab", "draft")
                                                  handleSubmit();
                                                }}
                                                id="draftSearch"
                                              >
                                                Search
                                              </button>
                                              <button
                                                className="btn-5"
                                                type="reset"
                                                onClick={() => { this.showAll("draft", 3) }}
                                                id="draftReset"
                                              >
                                                Reset
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Form>
                                {this.state.showTable?.[3] === true &&
                                  <div class="table-responsive draft-table1">
                                    <ReactTable

                                      minRows={2}
                                      data={this.state.dataDraft || []}
                                      filterable
                                      ref={(refReactTable) => {
                                        this.refReactTable = refReactTable;
                                      }}
                                      defaultFilterMethod={this.filterCaseInsensitive}
                                      columns={[
                                        {
                                          columns: [

                                            {
                                              Header: "Subject",
                                              id: "emailSubject",
                                              accessor: (d) =>
                                                (d.emailSubject || "").toString(),
                                              Cell: props => {
                                                return (
                                                  <div>
                                                    <a class="mar-left">
                                                      {

                                                        <div>
                                                          <a
                                                            title="View Email"
                                                            //      className="fa fa-paper-plane"
                                                            aria-hidden="true" data-toggle="modal"
                                                            data-keyboard="true"
                                                            data-target="#modal-Message-Inbox"
                                                            onClick={() =>
                                                              this.draftView(props.original.draftHistoryId)
                                                            }


                                                          >{props.original.emailSubject}</a>
                                                        </div>
                                                        //: ''
                                                      }
                                                    </a>
                                                  </div>
                                                );
                                              }
                                            },

                                            // {
                                            //   Header: "Draft Date",
                                            //   id: "lastAttemptDatetime",
                                            //   accessor: (d) => (d.createdDateTime?.split(' ')[0]?.split('/')[0] + "-" + d.createdDateTime?.split(' ')[0]?.split('/')[1] + "-" + d.createdDateTime?.split(' ')[0]?.split('/')[2] + " " + d.createdDateTime?.split(' ')[1] || ''),
                                            //   //     d.createdDateTime
                                            //   //  (moment(d.lastAttemptDatetime.split(" ")[0], "DD/MM/YYYY").format("DD-MM-YYYY") || ""),
                                            // },
                                            {
                                              Header: "Draft Date",
                                              id: "createdDate",
                                              accessor: (d) => (d.createdDate?.split(' ')[0]?.split('/')[0] + "-" + d.createdDate?.split(' ')[0]?.split('/')[1] + "-" + d.createdDate?.split(' ')[0]?.split('/')[2] + " " + d.createdDate?.split(' ')[1] || ''),
                                              //     d.createdDateTime
                                              //  (moment(d.lastAttemptDatetime.split(" ")[0], "DD/MM/YYYY").format("DD-MM-YYYY") || ""),
                                            },
                                            {
                                              Header: "Action",
                                              id: "fromEmailId",
                                              filterable: false,
                                              sortable: false,
                                              Cell: props => {
                                                return (
                                                  <div>


                                                    <a class="mar-left">

                                                      <i
                                                        title="Delete"
                                                        // className="fa fa-view fa-2x"
                                                        className="fa fa-trash"
                                                        aria-hidden="true"


                                                        onClick={e => this.DeleteDraft(props.original)}
                                                      >
                                                      </i>

                                                    </a>

                                                  </div>
                                                );
                                              }
                                            }

                                          ],
                                        },
                                      ]}
                                      defaultPageSize={10}
                                      style={{
                                        width: "100%",
                                        maxHeight: "500px",
                                      }}
                                    />
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                          {/* </div>
            </div> */}
                          <div class="modal fade" id="modal-Message-Inbox" tabIndex="-1" role="dialog" aria-labelledby="modalLabel" data-keyboard="true" aria-hidden="true">
                            <div class="modal-dialog" style={{ width: "70%" }} >
                              <div class="modal-content">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                                <Formik
                                  enableReinitialize={true}
                                  render={({
                                    errors, touched
                                  }) => (
                                    <React.Fragment>
                                      <Form className="form-group">
                                        <section >
                                          <div >
                                            <div class="row">
                                              <div class="col-md-2 col-lg-2 col-sm-2 col-xs-2">
                                              </div>
                                              <div class="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                                                <div class="product-add">
                                                  <div class="form-group">
                                                    <div class="row">
                                                      <div class="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                                                        <label>
                                                          FROM
                                                        </label>
                                                        <Field
                                                          type="text"
                                                          name="FromEMailId"
                                                          id="FromEMailId"
                                                          placeholder="FromEMailId"
                                                          value={this.state.MessageDetails.fromEmailName} //emailSubjectText  //emailSMSBodyText }
                                                          className={
                                                            "form-control"
                                                          }

                                                        >
                                                        </Field>

                                                      </div>
                                                      <div class="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                                                        <label>
                                                          Subject
                                                        </label>
                                                        <Field
                                                          type="text"
                                                          name="subject"
                                                          id="subject"
                                                          autoComplete="subject"
                                                          value={this.state.MessageDetails.emailSubjectText}  //emailSMSBodyText }
                                                          className={
                                                            "form-control " +
                                                            (errors.subject &&
                                                              touched.text
                                                              ? " is-invalid"
                                                              : "")
                                                          }
                                                          placeholder="Subject"
                                                        // className="form-control"
                                                        />
                                                        <ErrorMessage
                                                          name="subject"
                                                          component="div"
                                                          className="validation"
                                                        />
                                                      </div>
                                                      <div class="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                                                        <label>
                                                          Message
                                                        </label>
                                                        <Field as="textarea" rows="8" cols="50" name="message"
                                                          id="message"
                                                          autoComplete="message"
                                                          value={this.state.MessageDetails.emailSMSBodyText}
                                                          className={
                                                            "form-control"
                                                          }
                                                        />
                                                        <ErrorMessage
                                                          name="message"
                                                          className="validation"
                                                          component="div"
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="col-md-2 col-lg-2 col-sm-2 col-xs-2">
                                              </div>
                                            </div>
                                          </div>
                                        </section>
                                      </Form>
                                    </React.Fragment>
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </section>
                  </React.Fragment>
                )}
              />
            }
            {this.state.currentTab === 4 &&
              <Formik
                enableReinitialize={true}
                initialValues={{
                  fromDate: this.state.fromDate,
                  toDate: this.state.toDate,
                }}
                validationSchema={Yup.object().shape({
                  fromDate: Yup.string().required("You must specify From Date"),
                  toDate: Yup.string().required("You must specify To Date"),
                })}
                onSubmit={(values) => {
                  let showTableUpdated = this.state.showTable?.map((value, index) => index === 4)
                  this.setState({
                    showTable: showTableUpdated
                  });
                  let formattedFromDate = `${values["fromDate"]?.split("-")[2]}/${values["fromDate"]?.split("-")[1]
                    }/${values["fromDate"]?.split("-")[0]} 00:00:00`;
                  let formattedEndDate = `${values["toDate"]?.split("-")[2]}/${values["toDate"]?.split("-")[1]
                    }/${values["toDate"]?.split("-")[0]} 00:00:00`;

                  let fromDtConverted = moment(formattedFromDate, "DD.MM.YYYY");
                  let toDtConverted = moment(formattedEndDate, "DD.MM.YYYY");
                  if (fromDtConverted.diff(toDtConverted, "days") > 0) {
                    warning(
                      "From Date should not be greater than To Date",
                      warningNotification
                    );
                    return;
                  }else if (toDtConverted.diff(fromDtConverted, "days") > 31) {
                    warning(
                      "Maximum allowed range should be less than or equal to 31 days",
                      warningNotification
                    );
                    return;
                  }
                  this.setState({ loading: true });
                  let partyID = localStorage.getItem("ciel-userid-Token");
                  let params4=`fromDate=${this.formatDate(values.fromDate)}&toDate=${this.formatDate(values.toDate)}&notifyManagerFlag=Y`
                    // FetchNotificationService.fetchNotificationEvents(params4, (res4) => {
                      FetchNotificationService.fetchNotificationEventsForInboxandAllMail(params4,(res4)=>{
                      if(["success",null].includes(res4.status)){
                    console.log("res4.responseListObject:: ", res4.responseListObject)
                    this.setState({
                      dataAllMail: res4.responseListObject,
                      loading: false
                    })
                  }
                  else{
                    this.setState({ loading: false }) 
                  }
                  })
                  setTimeout(()=>{ this.setState({ loading: false })},2000)
                }}
                render={({ values, handleSubmit, handleChange, setFieldValue }) => (
                  <React.Fragment>
                    {this.state.loading && (
                      <PopUpPortal
                        HidePopup={this.state.loading}
                        IsVisible={this.state.loading}
                      />
                    )}
                    <section class="panel dashboard-tab user-tab pb-mb-5" id="allMail">
                      <div class="">
                        <div class="container-fluid">
                          <div class="row">
                            <div class="col-md-12 col-sm-12 col-xs-12">
                              <div class="table-header">
                                <h3>
                                  <div class="row">
                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                      <span class="left-icon">
                                        <i class="fa fa-list" aria-hidden="true"></i>
                                      </span>{" "}
                                      All Mail
                                    </div>
                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                      <span class="right-icon">
                                        <a title="Export">
                                          <i
                                            onClick={() => this.exportToAllMailCSV()}
                                            class="fa fa-file-excel-o"
                                            aria-hidden="true"
                                          ></i>
                                        </a>
                                      </span>

                                    </div>
                                  </div>
                                </h3>
                              </div>
                              <div class="bg-box">
                                <Form onSubmit={handleSubmit} className="">
                                  <div class="row">
                                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                      <div className="filter">
                                        <div className="form-group">
                                          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                            <h5 className="filter-heading">
                                              Search Criteria
                                            </h5>
                                          </div>
                                          <div className="row">
                                            <div className="col-md-4 col-sm-4 col-xs-12">
                                              <label>
                                                From Date
                                                <span className="validation"> *</span>
                                              </label>
                                              <Field
                                                type="date"
                                                name="fromDate"
                                                id="fromDate"
                                                className="form-control"
                                                onChange={handleChange}
                                              />
                                              <ErrorMessage
                                                name="fromDate"
                                                component="div"
                                                className="validation"
                                              />
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-xs-12">
                                              <label>
                                                To Date
                                                <span className="validation"> *</span>
                                              </label>
                                              <Field
                                                type="date"
                                                name="toDate"
                                                id="toDate"
                                                className="form-control"
                                                onChange={handleChange}
                                              />
                                              <ErrorMessage
                                                name="toDate"
                                                component="div"
                                                className="validation"
                                              />
                                            </div>
                                          </div>
                                          <div class="row">
                                            <div className="col-md-12 col-sm-12 mt-40">
                                              <button
                                                className="btn-5 mr-20 ml-20"
                                                // type="submit"
                                                type="button"
                                                // onClick={()=>{handleSubmit(values,"allMail")}}
                                                onClick={() => {
                                                  setFieldValue("tab", "allMail")
                                                  handleSubmit();
                                                }}
                                                id="allMailSearch"
                                              >
                                                Search
                                              </button>
                                              <button
                                                className="btn-5"
                                                type="reset"
                                                onClick={() => { this.showAll("allMail", 4) }}
                                                id="allMailReset"
                                              >
                                                Reset
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Form>
                                {this.state.showTable?.[4] === true &&
                                  <div class="table-responsive allMail-table1">
                                    <ReactTable

                                      minRows={2}
                                      data={this.state.dataAllMail || []}
                                      filterable
                                      ref={(refReactTable) => {
                                        this.refReactTable = refReactTable;
                                      }}
                                      defaultFilterMethod={this.filterCaseInsensitive}
                                      columns={[
                                        {
                                          columns: [
                                            {
                                              Header: "From ID",
                                              id: "fromEmailId",
                                              accessor: (d) =>
                                                (d.fromEmailId || "").toString(),
                                            },
                                            {
                                              Header: "From Name",
                                              id: "fromEmailName",
                                              accessor: (d) =>
                                                (d.fromEmailName || "").toString(),
                                            },
                                            {
                                              Header: "To",
                                              id: "toEmailId",
                                              accessor: (d) =>
                                                (d.toEmailIdOrMobileNo || "").toString(),
                                            },
                                            {
                                              Header: "Subject",
                                              id: "emailSubjectText",
                                              accessor: (d) =>
                                                (d.emailSubjectText || "").toString(),
                                              Cell: props => {
                                                return (
                                                  <div>
                                                    <a class="mar-left">
                                                      {

                                                        <div>
                                                          <a
                                                            title="View Email"
                                                            //      className="fa fa-paper-plane"
                                                            aria-hidden="true" data-toggle="modal"
                                                            data-keyboard="true"
                                                            data-target="#modal-Message-Inbox"
                                                            onClick={() =>
                                                              this.notificationAllMail(props.original.notifyHistoryId, props.original)
                                                            }


                                                          >{props.original.emailSubjectText}</a>
                                                        </div>
                                                        //: ''
                                                      }
                                                    </a>
                                                  </div>
                                                );
                                              }
                                            },
                                            {
                                              Header: "Delivery Status",
                                              id: "deliveryStatus",
                                              // accessor: (d) => (d.deliveryStatus || '').toString() === 'completed' ? 'Success' : 'Failure'
                                              accessor: (d) =>['completed',null,undefined].includes(d.deliveryStatus?.toString())? 'Success' : 'Failure',
                                            },
                                            {
                                              Header: "Delivery Date",
                                              id: "lastAttemptDateTime",
                                              accessor: (d) =>
                                                (d.lastAttemptDateTime || '').toString() !== '' ? (d.lastAttemptDateTime?.split(' ')[0]?.split('/')[0] + "-" + d.lastAttemptDateTime?.split(' ')[0]?.split('/')[1] + "-" + d.lastAttemptDateTime?.split(' ')[0]?.split('/')[2] + " " + d.lastAttemptDateTime?.split(' ')[1] || '') : ''

                                              // (d.lastAttemptDatetime.split(' ')[0].split('/')[0] + "-" + d.lastAttemptDatetime.split(' ')[0].split('/')[1] + "-" + d.lastAttemptDatetime.split(' ')[0].split('/')[2] + " " + d.lastAttemptDatetime.split(' ')[1] || ''),
                                              // (d.notifyEvent.lastModifiedDateTime.split(' ')[0].split('/')[0] + "-" + d.notifyEvent.lastModifiedDateTime.split(' ')[0].split('/')[1] + "-" + d.notifyEvent.lastModifiedDateTime.split(' ')[0].split('/')[2] + " " + d.notifyEvent.lastModifiedDateTime.split(' ')[1] || ''),
                                            },

                                          ],
                                        },
                                      ]}
                                      defaultPageSize={10}
                                      style={{
                                        width: "100%",
                                        maxHeight: "500px",
                                      }}
                                    />
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                          {/* </div>
            </div> */}
                          <div class="modal fade" id="modal-Message-Inbox" tabIndex="-1" role="dialog" aria-labelledby="modalLabel" data-keyboard="true" aria-hidden="true">
                            <div class="modal-dialog" style={{ width: "70%" }} >
                              <div class="modal-content">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                                <Formik
                                  enableReinitialize={true}
                                  render={({
                                    errors, touched
                                  }) => (
                                    <React.Fragment>
                                      <Form className="form-group">
                                        <section >
                                          <div >
                                            <div class="row">
                                              <div class="col-md-2 col-lg-2 col-sm-2 col-xs-2">
                                              </div>
                                              <div class="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                                                <div class="product-add">
                                                  <div class="form-group">
                                                    <div class="row">
                                                      <div class="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                                                        <label>
                                                          FROM
                                                        </label>
                                                        <Field
                                                          type="text"
                                                          name="FromEMailId"
                                                          id="FromEMailId"
                                                          placeholder="FromEMailId"
                                                          value={this.state.MessageDetails.fromEmailName} //emailSubjectText  //emailSMSBodyText }
                                                          className={
                                                            "form-control"
                                                          }

                                                        >
                                                        </Field>

                                                      </div>
                                                      <div class="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                                                        <label>
                                                          Subject
                                                        </label>
                                                        <Field
                                                          type="text"
                                                          name="subject"
                                                          id="subject"
                                                          autoComplete="subject"
                                                          value={this.state.MessageDetails.emailSubjectText}  //emailSMSBodyText }
                                                          className={
                                                            "form-control " +
                                                            (errors.subject &&
                                                              touched.text
                                                              ? " is-invalid"
                                                              : "")
                                                          }
                                                          placeholder="Subject"
                                                        // className="form-control"
                                                        />
                                                        <ErrorMessage
                                                          name="subject"
                                                          component="div"
                                                          className="validation"
                                                        />
                                                      </div>
                                                      <div class="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                                                        <label>
                                                          Message
                                                        </label>
                                                        <Field as="textarea" rows="8" cols="50" name="message"
                                                          id="message"
                                                          autoComplete="message"
                                                          value={this.state.MessageDetails.emailSMSBodyText}
                                                          className={
                                                            "form-control"
                                                          }
                                                        />
                                                        <ErrorMessage
                                                          name="message"
                                                          className="validation"
                                                          component="div"
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="col-md-2 col-lg-2 col-sm-2 col-xs-2">
                                              </div>
                                            </div>
                                          </div>
                                        </section>
                                      </Form>
                                    </React.Fragment>
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </section>
                  </React.Fragment>
                )}
              />
            }

          </div>

          {/* <div className="composeBtn">
            <button className="btn-2" onClick={this.createMail}>Compose</button>
          </div> */}
        </div>
        <Finxfooter />
      </React.Fragment >)
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(MyMessagesInbox);

