import React, { Component } from "react";
import AdminHeader from "../../components/dashboardheader/adminheader";
import Finxfooter from "../finxfooter/finxfooter";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchAdminUserByPartyAndRole } from "../../services/admin.service";
import { RegisterUserService } from "../../services/registration.service";
import { LoginService } from "../../services/login.service";
import Admin from "../../components/admin-dashboard/admin";

import { getAdminUser, getAdminUserPending, getAdminUserError } from "../../reducer/adminUserReducer";
import {
  getLearnersProgressOverview,
  getLearnersProgressOverviewPending
} from "../../reducer/learnerProgressOverviewReducer";
import { GeneralEnquiryService } from "../../services/generalenquiryService";
import { getLearnerEnquiry } from "../../reducer/learnerEnquiryReducer";
import {
  getProgressOverview, fetchCalenderEvents, fetchAlertNotificationEvents, fetchNotificationEvents
} from "../../services/fetchLearnerDashboard.service";
import { getCalenderEvents, getCalenderEventsPending } from "../../reducer/calenderEventsReducer";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
import { getAlertNotificationEvents, getAlertNotificationEventsPending, getAlertNotificationEventsError } from "../../reducer/alertsNotificationReducer";
import { getNotificationEvents, getNotificationEventsPending, getNotificationEventsError } from "../../reducer/notificationReducer";
import { fetchAdminUser } from "../../services/admin.service";

const mapStateToProps = state => ({
  adminUser: getAdminUser(state.adminUserReducer),
  adminUserPending: getAdminUserPending(state.adminUserReducer),
  adminUserError: getAdminUserError(state.adminUserReducer),

  learnerProgressOverviewData: getLearnersProgressOverview(
    state.learnersProgressOverviewReducer
  ),
  learnerProgressOverviewDataPending: getLearnersProgressOverviewPending(
    state.learnersProgressOverviewReducer
  ),
  learnerEnquiry: getLearnerEnquiry(state.learnerEnquiryReducer),
  calenderEvents: getCalenderEvents(state.calenderEventsReducer),
  calenderEventsPending: getCalenderEventsPending(state.calenderEventsReducer),


  alertNotificationEvents: getAlertNotificationEvents(
    state.alertNotificationEventsReducer
  ),
  pendingAlertNotificationEvents: getAlertNotificationEventsPending(
    state.alertNotificationEventsReducer
  ),
  errorAlertNotificationEvents: getAlertNotificationEventsError(
    state.alertNotificationEventsReducer
  ),

  notificationEvents: getNotificationEvents(state.notificationEventsReducer),
  pendingNotificationEvents: getNotificationEventsPending(
    state.notificationEventsReducer
  ),
  errorNotificationEvents: getNotificationEventsError(
    state.notificationEventsReducer
  ),

});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      //fetchProducts: fetchProductsAction,
      fetchAdminUser: fetchAdminUser,
      fetchAdminUserByPartyAndRole: fetchAdminUserByPartyAndRole,
      fetchUserAddressData: RegisterUserService.getUserAddress,
      fetchLearnerProfileProgressOverview: getProgressOverview,
      fetchLearnerEnquiry: GeneralEnquiryService.getEnquiry,
      fetchCalenderEvents: fetchCalenderEvents,
      fetchAlertNotificationEvents: fetchAlertNotificationEvents,
      fetchNotificationEvents: fetchNotificationEvents,
    },
    dispatch
  );

const connectFunction = connect(mapStateToProps, mapDispatchToProps);
const admindashboardlanding = connectFunction(
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true
      };
    }

    componentDidMount() {

      let partyID = localStorage.getItem("userid-Token");
      let roleId = localStorage.getItem("roleId");

      LoginService.TokenUser(res => {
        localStorage.setItem("jwt-token", res.responseObject);
        if (localStorage.getItem('reload') === 'true') {
          localStorage.setItem('reload', 'false');
          window.location.reload();
        }
      });

      const {
        fetchAdminUser,
        adminUserPending,
        fetchAdminUserByPartyAndRole,
        fetchLearnerProfileProgressOverview,
        fetchLearnerEnquiry,
        fetchCalenderEvents,
        fetchAlertNotificationEvents,
        fetchNotificationEvents
      } = this.props;

      fetchAdminUserByPartyAndRole(partyID, roleId, err => {
        //console.log(err);
      });
      fetchLearnerProfileProgressOverview("learnerPartyId=0");
      fetchLearnerEnquiry("enquiryType=1");
      fetchCalenderEvents();
      fetchAlertNotificationEvents();
      fetchNotificationEvents("partyId=" + partyID);
    }
    render() {
      const {
        adminUser,
        adminUserPending,
        fetchUserAddressData,
        learnerProgressOverviewData,
        learnerEnquiry,
        calenderEvents,
        learnerProgressOverviewDataPending,
        calenderEventsPending,
        notificationEvents,
        pendingNotificationEvents,
        errorNotificationEvents,
        alertNotificationEvents,
        pendingAlertNotificationEvents,
        errorAlertNotigicationEvents,
      } = this.props;



      if (!adminUserPending && !learnerProgressOverviewDataPending && !calenderEventsPending && !pendingNotificationEvents && !pendingAlertNotificationEvents) {
        //console.log("adminUser")
        //console.log(this.props.adminUser)
        //localStorage.setItem('adminUserprops', this.props.adminUser)
        localStorage.setItem('AdminAlertNotification', JSON.stringify(alertNotificationEvents))
        //localStorage.setItem('AdminNotification', JSON.stringify(notificationEvents))

        return (
          <React.Fragment>
            <AdminHeader
              ////alertNotificationEvents={alertNotificationEvents}
              ////notificationEvents={notificationEvents}
              {...this.props}
            />
            <Admin
              adminUser={adminUser}
              fetchAdminUserByPartyAndRole={fetchAdminUserByPartyAndRole}
              getAdminAddress={fetchUserAddressData}
              learnerProgressOverviewData={learnerProgressOverviewData}
              learnerEnquiry={learnerEnquiry}
              calenderEvents={calenderEvents}
              learnerProgressOverviewDataPending={learnerProgressOverviewDataPending}
              calenderEventsPending={calenderEventsPending}
              //alertNotificationEvents={alertNotificationEvents}
              //notificationEvents={notificationEvents}
              {...this.props}
            />
            <Finxfooter />
          </React.Fragment>
        );
      } else {

        return (
          <React.Fragment>
            <PopUpPortal
              HidePopup={this.state.loading}
              IsVisible={this.state.loading}
            />
            <AdminHeader
              adminUser={adminUser}
              {...this.props}
            />
            <Admin
              adminUser={adminUser}
              fetchAdminUserByPartyAndRole={fetchAdminUserByPartyAndRole}
              getAdminAddress={fetchUserAddressData}
              learnerProgressOverviewData={learnerProgressOverviewData}
              learnerEnquiry={learnerEnquiry}
              calenderEvents={calenderEvents}
              calenderEventsPending={calenderEventsPending}
              //alertNotificationEvents={alertNotificationEvents}
              //notificationEvents={notificationEvents}
              {...this.props}
            />
            <Finxfooter />
          </React.Fragment>
        );
      }
      // if (learnerProgressOverviewDataPending && calenderEventsPending && pendingNotificationEvents && pendingAlertNotificationEvents) {
      //  
      //   return (
      //     <React.Fragment>
      //       <PopUpPortal
      //         HidePopup={this.state.loading}
      //         IsVisible={this.state.loading}
      //       />
      //       <AdminHeader 
      //            {...this.props}
      //       />
      //       <Admin
      //         adminUser={adminUser}
      //         fetchAdminUserByPartyAndRole={fetchAdminUserByPartyAndRole}
      //         getAdminAddress={fetchUserAddressData}
      //         learnerProgressOverviewData={learnerProgressOverviewData}
      //         learnerEnquiry={learnerEnquiry}
      //         calenderEvents={calenderEvents}
      //         calenderEventsPending={calenderEventsPending}
      //         {...this.props}
      //       />
      //       <Finxfooter />
      //     </React.Fragment>
      //   );
      // } else {
      //   return (
      //     <React.Fragment>
      //       <AdminHeader 
      //        //alertNotificationEvents={alertNotificationEvents}
      //        //notificationEvents={notificationEvents}
      //        {...this.props}
      //       />
      //       <Admin
      //         adminUser={adminUser}
      //         fetchAdminUserByPartyAndRole={fetchAdminUserByPartyAndRole}
      //         getAdminAddress={fetchUserAddressData}
      //         learnerProgressOverviewData={learnerProgressOverviewData}
      //         learnerEnquiry={learnerEnquiry}
      //         calenderEvents={calenderEvents}
      //         learnerProgressOverviewDataPending={learnerProgressOverviewDataPending}
      //         calenderEventsPending={calenderEventsPending}
      //         {...this.props}
      //       />
      //       <Finxfooter />
      //     </React.Fragment>
      //   );
      // }
    }
  }
);
export default admindashboardlanding;
