import React, { Component } from "react";
import { LoginService } from "../../services/login.service";
import { success, successNotification, error, errorNotification, warning, warningNotification } from "../notification/notifications";

import { fetchQuestionOption, updateTermsAndCondition, updateScore, updateStatus, reattemptSendMail } from "../../services/preAssessment.Service"
import Countdown from 'react-countdown';
import Circle from 'react-circle';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import * as moment from "moment";

import { ReattemptTestService } from "../../services/reattemptTest.service"
class FeatTest extends Component {
    constructor(props) {

        super(props);
        if (localStorage.getItem('login') !== null) {
            this.state = {
                data: [],
                data1: []
            };


        }
        else {
            window.location.href = '/';
        }

    }


    onScrollStep = () => {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
    }

    scrollToTop = () => {
        let intervalId = setInterval(this.onScrollStep, this.props.delayInMs);
        this.setState({ intervalId: intervalId });
    }
    componentDidMount() {
    ReattemptTestService.getAuditData("learnerPartyID="+this.props.personnelProfileData.learnerPartyID,(res) => {
        if (res.status === "success") {
        this.setState({
            data: res.responseListObject.reverse()
        })
    }
        console.log("Audit Data:::::::::::::::::::::::::::::::::::::::::::::::"+JSON.stringify(this.state.data))
      })

    


        // let tempdata = {
        //     "workflowId": this.props.personnelProfileData.learnerID,
        //     "history": true
        // }
        // ReattemptTestService.getStatuscompleted(tempdata, res => {
        //     let tem = res.data.responseListObject
        //     this.setState({
        //         data: tem

        //     })

        //     console.log(this.state.data)
        // })
    }



    render() {
        return (
            // <div id="auditBox" >
            //     <div className="container-fluid" id="preassessment" style={{ paddingTop: "0px !important" }}>
            //         {this.state.data.length > 0 && this.state.data.map((e, i) => {

            //             return (
            //                 <div class="boxbg gridChange assess-body">
                               
            //                     <div class="auditStage">{e.state.applicationStatus}</div>
            //                     <div id="assessmentOption">
            //                         <div className="row">
            //                             <div className="col-md-3 col-sm-6 col-xs-12">
            //                                 <h4 className="alpabate">Status</h4>
            //                                 <p>{e.state.applicationStatus}</p>
            //                             </div>
            //                             <div className="col-md-3 col-sm-6 col-xs-12">
            //                                 <h4 className="alpabate">Action Owner</h4>
            //                                 <p>{e.createdBy === 102 ? "CIEL OPS" : e.createdBy === 103 ? "AMC" : "DISTRIBUTOR"}</p>
            //                             </div>
            //                             <div className="col-md-3 col-sm-6 col-xs-12">
            //                                 <h4 className="alpabate">Start Date</h4>

                                           
            //                                 <p>{e.createdDate && moment(e.createdDate.split(" ")[0], "DD/MM/YYYY").format("DD-MM-YYYY")}</p>
            //                             </div>
            //                             <div className="col-md-3 col-sm-6 col-xs-12">
            //                                 <h4 className="alpabate">End Date</h4>
            //                                 <p>{e.lastModifiedDate && moment(e.lastModifiedDate.split(" ")[0], "DD/MM/YYYY").format("DD-MM-YYYY")}</p>
            //                             </div>
                                        
            //                         </div>
            //                     </div>
            //                 </div>
            //             )
            //         })}
            //     </div>
            // </div>


            
            <div id="auditBox" >
                <div className="container-fluid" id="preassessment" style={{ paddingTop: "0px !important" }}>
                    {this.state.data.length > 0 && this.state.data.map((e, i) => {
                        return (
                            <div class="boxbg gridChange assess-body">
                             
                                <div class="auditStage">{e.action}</div>
                                <div id="assessmentOption">
                                    <div className="row">
                                        <div className="col-md-3 col-sm-6 col-xs-12">
                                            <h4 className="alpabate">Status</h4>
                                            <p>{e.action}</p>
                                            {/* {e.action === "ADMIT CARD AVAILABLE" ? localStorage.setItem( "cardstartDate", e.startDate): ""} */}
                                        </div>
                                        <div className="col-md-3 col-sm-6 col-xs-12">
                                            <h4 className="alpabate">Action Owner</h4>
                                            {/* {(e.action === "NISM CERTIFICATION COMPLETED" && localStorage.getItem('cardstartDate')) === "null" ?<p>USER</p>:<p>SYSTEM</p>} */}
                                             <p>{e.createdBy === 102 ? "CIEL OPS" : e.createdBy === 101 ? "SYSTEM" : e.startDate !== null ? "USER" :""}</p>
                                        </div>
                                        <div className="col-md-3 col-sm-6 col-xs-12">
                                            <h4 className="alpabate">Start Date</h4>
                                            <p>{e.startDate && moment(e.startDate.split("T")[0], "YYYY-MM-DD").format("DD-MM-YYYY")}</p>
                                        </div>
                                        <div className="col-md-3 col-sm-6 col-xs-12">
                                            <h4 className="alpabate">End Date</h4>
                                            <p>{e.startDate && moment(e.startDate.split("T")[0], "YYYY-MM-DD").format("DD-MM-YYYY")}</p>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>

        );
    }
}

export default FeatTest;
