export const MAINTENANCE_FETCH_PENDING = "MAINTENANCE_FETCH_PENDING";
export const MAINTENANCE_FETCH_SUCCESS = "MAINTENANCE_FETCH_SUCCESS";
export const MAINTENANCE_FETCH_ERROR = "MAINTENANCE_FETCH_ERROR";

export function maintenanceFetchPending() {
  return {
    type: MAINTENANCE_FETCH_PENDING,
  };
}

export function maintenanceFetchSuccess(data) {
  return {
    type: MAINTENANCE_FETCH_SUCCESS,
    payload: data,
  };
}

export function maintenanceFetchError(error) {
  return {
    type: MAINTENANCE_FETCH_ERROR,
    error: error,
  };
}
