import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { LoginService } from "../../services/login.service";
import { error, errorNotification } from "../../components/notification/notifications";
import { getUserRoleAcess, getUserRoleAcessError, getUserRoleAcessPending } from "../../reducer/roleAccessReducer";

import { fetchUserRoleAccess } from '../../services/userAccess.service';
import Unauthorized from "../../components/unauthorized/Unauthorized";
import LearnerDashboardLandingAuthenticated from "./learnerdashboardlandingAuthenticate";


const mapStateToProps = state => ({
  userRoleAccess: getUserRoleAcess(state.roleAccessReducer),
  pendingUserRoleAccess: getUserRoleAcessPending(state.roleAccessReducer),
  errorUserRoleAccess: getUserRoleAcessError(state.roleAccessReducer),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchUserRoleAccess: fetchUserRoleAccess
    },
    dispatch
  );

const connectFunction = connect(mapStateToProps, mapDispatchToProps);

const learnerdashboardlanding = connectFunction(

  class extends Component {
    constructor(props) {
      super(props);
      console.log("in studnbt dash", props);
      this.state = {
        isVisible: false,
        isAuthorised: false,
      };
    }

    componentDidMount() {
      let RoleId = localStorage.getItem('roleId')

      LoginService.TokenUser(res => {
        localStorage.setItem("jti-token", res.responseObject);
        localStorage.setItem("jwt-token", res.responseObject);
        if (localStorage.getItem('reload') === 'true') {
          localStorage.setItem('reload', 'false');
          window.location.reload();
        }

      });

      const {
        fetchUserRoleAccess
      } = this.props;

      const roleDetails = {
        roleId: {
          roleId: RoleId
        }
      }
      fetchUserRoleAccess(roleDetails);
    }

    render() {

      const {
        userRoleAccess,
        pendingUserRoleAccess

      } = this.props;

      let __FOUNDENCROACH;
      console.log('userRoleAccess',userRoleAccess);
      console.log('pendingUserRoleAccess',pendingUserRoleAccess);

      if (!pendingUserRoleAccess) {
        if (userRoleAccess && userRoleAccess.length > 0) {
          __FOUNDENCROACH = userRoleAccess.find(function (encroachRecord, index) {
            if (encroachRecord.description == 'Learner Dashboard' || 'CIEL Dashboard')
              return true;
          });
        }
        if (__FOUNDENCROACH !== undefined) {
          return (<LearnerDashboardLandingAuthenticated {...this.props} />)
        } else {
          return (
            setTimeout(()=> <Unauthorized />  ,1500)
          )
        }
      } else {
        return null;
      }
    }
  }
);

export default learnerdashboardlanding;
