import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { Component } from "react";
import "react-table/react-table.css";
import "react-toggle/style.css";
import Finxfooter from "../../containers/finxfooter/finxfooter";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
import { RegisterUserService } from "../../services/registration.service";
import DashboardHeader from "../dashboardheader/dashboardheader";
import { error, errorNotification, success, successNotification } from "../notification/notifications";


class UpdatePersonalDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userData: null, 
            showUpdatePanForm: false,
            showMandatePanForm: false,
            showAll: false,
            showUpdateEmailForm: false,
            showUpdateMobileForm: false,
            showUnlocakForm: false,
            formKey: 0, // Initialize formKey
            loading: false,
          };
        
     
    }

    resetForm = () => {
      this.setState((prevState) => ({ formKey: prevState.formKey + 1 }));
    };

    toggleUpdatePanForm = () => {
        this.setState({
          showUpdatePanForm: !this.state.showUpdatePanForm,
          showUpdateEmailForm: false,
          showUpdateMobileForm: false,
          showUnlocakForm:false,
          showUpdateSecEmailForm:false,
          showMandatePanForm: false,
        });
      };
      toggleUpdateMandatePanForm = () => {
        this.setState({
          showMandatePanForm: !this.state.showMandatePanForm,
          showAll: false,
          showUpdatePanForm:false,
          showUpdateEmailForm: false,
          showUpdateMobileForm: false,
          showUnlocakForm:false,
          showUpdateSecEmailForm:false,
        });
      };

      toggleUpdateEmailForm = () => {
        this.setState({
          showUpdateEmailForm: !this.state.showUpdateEmailForm,
          showUpdatePanForm: false,
          showUpdateMobileForm: false,
          showUnlocakForm:false,
          showUpdateSecEmailForm:false,
          showMandatePanForm: false,
        });
      };

      toggleUpdateSecEmailForm = () => {
        this.setState({
          showUpdateSecEmailForm: !this.state.showUpdateSecEmailForm,
          showUpdatePanForm: false,
          showUpdateMobileForm: false,
          showUnlocakForm:false,
          showUpdateEmailForm: false,
          showMandatePanForm: false,
        });
      };
    
      toggleUpdateMobileForm = () => {
        this.setState({
          showUpdateMobileForm: !this.state.showUpdateMobileForm,
          showUpdatePanForm: false,
          showUpdateEmailForm: false,
          showUnlocakForm:false,
          showUpdateSecEmailForm:false,
          showMandatePanForm: false,
        });
      };

      toggleUpdateLockForm = () => {
        this.setState({
          showUnlocakForm: !this.state.showUnlocakForm,
          showUpdatePanForm: false,
          showUpdateEmailForm: false,
          showUpdateMobileForm: false,
          showUpdateSecEmailForm:false,
          showMandatePanForm: false,
        });
    };

    validate = (values) => {
        const errors = {};
        if(values.panNumber!= "")
        {
            if(!values.panNumber.toUpperCase().match(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/)) {
                errors.panNumber = "Provide valid PAN number.";
              }
        }
        if(values.mobileNumber!= "")
        {
            if(!values.mobileNumber.match(/^[0-9]{10}$/)) {
                errors.mobileNumber = "Provide valid mobile number.";
            }
        }
        if(values.emailID!= "")
        {
            if (!values.emailID.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)) {
                errors.emailID = "Provide valid Email ID.";
              }
        }
        if(values.emailIDSec!= "")
        {
            if (!values.emailIDSec.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)) {
                errors.emailIDSec = "Provide valid Secondary Email ID.";
              }
        }
        return errors;
      };

      validatePan = (values) => {
        const errors = {};
        if (values.newPanNumber === "") {
          errors.newPanNumber = "New PAN number is required.";
        } else if (!values.newPanNumber.toUpperCase().match(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/)) {
          errors.newPanNumber = "Provide valid PAN number.";
        } else if (values.oldPanNumber === values.newPanNumber.toUpperCase()) {
            errors.newPanNumber = "New PAN number must be different from old PAN number.";
        }
        return errors;
      };

      validateMobile = (values) => {
        const errors = {};
        if (values.newMobileNumber === "") {
          errors.newMobileNumber = "New mobile number is required.";
        } else if (!values.newMobileNumber.match(/^[0-9]{10}$/)) {
          errors.newMobileNumber = "Provide valid mobile number.";
        } else if (values.oldMobileNumber === values.newMobileNumber) {
          errors.newMobileNumber = "New mobile number must be different from old mobile number.";
        }
        return errors;
      };

      validateEmail = (values) => {
        const errors = {};
        if (values.newEmail === "") {
            errors.newEmail = "New Email ID is required.";
          } else if (!values.newEmail.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)) {
            errors.newEmail = "Provide valid Email ID.";
          } else if (values.oldEmail === values.newEmail) {
            errors.newEmail = "New Email ID  must be different from Old Email ID.";
          }
        return errors;
      };

      validateSecEmail = (values) => {
        const errors = {};
        if (values.newSecEmail === "") {
            errors.newSecEmail = "New Secondary Email ID is required.";
          } else if (!values.newSecEmail.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)) {
            errors.newSecEmail = "Provide valid Secondary Email ID.";
          } else if (values.oldSecEmail === values.newSecEmail) {
            errors.newSecEmail = "New Secondary Email ID  must be different from Old Secondary Email ID.";
          }
        return errors;
      };
    
    handleSubmit = (values) => {
     
        if(values.panNumber === "" && values.mobileNumber==="" && values.emailID==="" && values.emailIDSec==="")
        { error("Please enter value for PAN Number or Mobile Number or Email ID or Secondary Email ID.", {
                ...errorNotification,
                container: "top-center"
              });
              this.setState({
                showUpdatePanForm: false,
                showUpdateEmailForm: false,
                showUpdateMobileForm: false,
                showUnlocakForm:false,
                showUpdateSecEmailForm:false,
                showMandatePanForm:false,
                userData: null,
            });
        }else{
            let urls="";
            if(values.panNumber!="" && values.emailID!="" && values.mobileNumber!="" && values.emailIDSec)
            {
            urls = "panNumber="+values.panNumber.toUpperCase()+"&emailID="+values.emailID+"&mobileNumber="+values.mobileNumber+"&emailIDSec="+values.emailIDSec;
            }
            else if(values.panNumber!="" && values.emailID!="" && values.emailIDSec!="")
            {
            urls = "panNumber="+values.panNumber.toUpperCase()+"&emailID="+values.emailID+"&emailIDSec="+values.emailIDSec;
            }
            else if(values.emailID!="" && values.mobileNumber!="" && values.emailIDSec!="")
            {
            urls = "emailID="+values.emailID+"&mobileNumber="+values.mobileNumber+"&emailIDSec="+values.emailIDSec;
            }
            else if(values.panNumber!="" && values.mobileNumber!="" && values.emailIDSec!="")
            {
                urls = "panNumber="+values.panNumber.toUpperCase()+"&mobileNumber="+values.mobileNumber+"&emailIDSec="+values.emailIDSec;
            }
            else if(values.panNumber!="" && values.mobileNumber!="" && values.emailID!="")
            {
                urls = "panNumber="+values.panNumber.toUpperCase()+"&mobileNumber="+values.mobileNumber+"&emailID="+values.emailID;
            }
            else if(values.panNumber!="" && values.emailID!="")
            {
            urls = "panNumber="+values.panNumber.toUpperCase()+"&emailID="+values.emailID;
            }
            else if(values.panNumber!="" && values.mobileNumber!="")
            {
                urls = "panNumber="+values.panNumber.toUpperCase()+"&mobileNumber="+values.mobileNumber;
            }
            else if(values.panNumber!="" && values.emailIDSec!="")
            {
                urls = "panNumber="+values.panNumber.toUpperCase()+"&emailIDSec="+values.emailIDSec;
            }
            else if(values.mobileNumber!="" && values.emailIDSec!="")
            {
                urls = "mobileNumber="+values.mobileNumber+"&emailIDSec="+values.emailIDSec;
            }
            else if(values.mobileNumber!="" && values.emailID!="")
            {
                urls = "mobileNumber="+values.mobileNumber+"&emailID="+values.emailID;
            }
            else if(values.emailIDSec!="" && values.emailID!="")
            {
                urls = "emailIDSec="+values.emailIDSec+"&emailID="+values.emailID;
            }
            else if(values.panNumber!="")
            {
                urls = "panNumber="+values.panNumber.toUpperCase();
            }
            else if(values.mobileNumber!="")
            {
                urls = "mobileNumber="+values.mobileNumber;
            }
            else if(values.emailID)
            {
                urls = "emailID="+values.emailID;
            }
            else if(values.emailIDSec)
            {
                urls = "emailIDSec="+values.emailIDSec;
            }

            // RegisterUserService.getUserPersonnelDetailsForUpdate("learnerPartyID=" + 1086855,
            //     (ures) => {
            //     // Store the fetched data in the state
            //      this.setState({ userData: ures.responseObject });
            //     }
            // );
            this.setState({ loading: true }); // Set loading to true
            RegisterUserService.getUserPersonnelDetailsForUpdate(urls,
                (ures) => {
                 
                  if(ures.reasonText==="No record found")
                  {
                    this.setState({
                      showUpdatePanForm: false,
                      showUpdateEmailForm: false,
                      showUpdateMobileForm: false,
                      showUnlocakForm:false,
                      showUpdateSecEmailForm:false,
                      showMandatePanForm:false,
                      userData: null,
                  });
                  }
                  else
                  { this.setState({ userData: ures.responseObject });}
                  this.setState({ loading: false });
                }
            );
    };}
    handleMandatePanSubmit = (values) => {
      let params = `panNo=${values.oldPanNumber}`
      this.setState({ loading: true });
      RegisterUserService.changeMandateType(params,
        (res) => {
          if(res.status==="success")
          {
            success("Mandate Changed Successfully ...!!!",successNotification)
            this.setState({
              showUpdatePanForm: false,
              showUpdateEmailForm: false,
              showUpdateMobileForm: false,
              showUnlocakForm:false,
              showUpdateSecEmailForm:false,
              showMandatePanForm:false,
              userData: null,
              loading: false,
          });
          this.resetForm();
          }
          else{
            error(res.reasonText,errorNotification)
            this.setState({
              showUpdatePanForm: false,
              showUpdateEmailForm: false,
              showUpdateMobileForm: false,
              showUnlocakForm:false,
              showUpdateSecEmailForm:false,
              showMandatePanForm:false,
              userData: null,
              loading: false
          });
          }
        }
      );
    }
    handlePanSubmit = (values) =>
    {
      this.setState({ loading: true }); // Set loading to true
        let data={
            "oldPanNumber" : values.oldPanNumber,
            "newPanNumber" : values.newPanNumber.toUpperCase(),
        }

        RegisterUserService.updateUserPersonalData(data,cb => {
            if (cb.status === "success") {
              success(
                "PAN Number has been updated successfully",
                successNotification
              );
              this.setState({
                showUpdatePanForm: false,
                showUpdateEmailForm: false,
                showUpdateMobileForm: false,
                showUnlocakForm:false,
                showUpdateSecEmailForm:false,
                showMandatePanForm:false,
                userData: null,
            });
            this.resetForm();
          }else {
                error(cb.reasonText, {
                  ...errorNotification,
                  container: "top-center"
                });

              //   this.setState({
              //     showUpdatePanForm: false,
              //     showUpdateEmailForm: false,
              //     showUpdateMobileForm: false,
              //     showUnlocakForm:false,
              //     userData: null,
              // });
              // this.resetForm();
            }
            this.setState({ loading: false });
          });
    }

    handleEmailSubmit = (values) =>
    {
        this.setState({ loading: true }); // Set loading to true
        let data={
            "oldEmail" : values.oldEmail,
            "newEmail" : values.newEmail,
        }

        RegisterUserService.updateUserPersonalData(data,cb => {
            if (cb.status === "success") {
              success(
                "Email ID has been updated successfully",
                successNotification
              );
              this.setState({
                showUpdatePanForm: false,
                showUpdateEmailForm: false,
                showUpdateMobileForm: false,
                showUnlocakForm:false,
                showUpdateSecEmailForm:false,
                showMandatePanForm:false,
                userData: null,
            });
            this.resetForm();
            }else {
                error(cb.reasonText, {
                  ...errorNotification,
                  container: "top-center"
                });

              //   this.setState({
              //     showUpdatePanForm: false,
              //     showUpdateEmailForm: false,
              //     showUpdateMobileForm: false,
              //     showUnlocakForm:false,
              //     userData: null,
              // });
              // this.resetForm();
            }
            this.setState({ loading: false});
          });
    }

    handleSecEmailSubmit = (values) =>
    {
        this.setState({ loading: true }); // Set loading to true
        let data={
            "oldSecEmail" : values.oldSecEmail,
            "newSecEmail" : values.newSecEmail,
        }

        RegisterUserService.updateUserPersonalData(data,cb => {
            if (cb.status === "success") {
              success(
                "Secondary email id has been updated successfully",
                successNotification
              );
              this.setState({
                showUpdatePanForm: false,
                showUpdateEmailForm: false,
                showUpdateMobileForm: false,
                showUnlocakForm:false,
                showUpdateSecEmailForm:false,
                showMandatePanForm:false,
                userData: null,
            });
            this.resetForm();
            }else {
                error(cb.reasonText, {
                  ...errorNotification,
                  container: "top-center"
                });

              //   this.setState({
              //     showUpdatePanForm: false,
              //     showUpdateEmailForm: false,
              //     showUpdateMobileForm: false,
              //     showUnlocakForm:false,
              //     userData: null,
              // });
              // this.resetForm();
            }
            this.setState({ loading: false});
          });
    }

    handleMobileSubmit = (values) =>
    {
        this.setState({ loading: true }); // Set loading to true
        let data={
            "oldMobileNumber" : values.oldMobileNumber,
            "newMobileNumber" : values.newMobileNumber,
        }

        RegisterUserService.updateUserPersonalData(data,cb => {
            if (cb.status === "success") {
              success(
                "Mobile number has been updated successfully",
                successNotification
              );
            
              this.setState({
                showUpdatePanForm: false,
                showUpdateEmailForm: false,
                showUpdateMobileForm: false,
                showUnlocakForm:false,
                showUpdateSecEmailForm:false,
                showMandatePanForm:false,
                userData: null
            });
            this.resetForm();
          }else {
                error(cb.reasonText, {
                  ...errorNotification,
                  container: "top-center"
                });

              //   this.setState({
              //     showUpdatePanForm: false,
              //     showUpdateEmailForm: false,
              //     showUpdateMobileForm: false,
              //     showUnlocakForm:false,
              //     userData: null
              // });
              // this.resetForm();
            }
            this.setState({ loading: false });
          });
          
    }


    unlockProfile=()=>{
      this.setState({ loading: true }); // Set loading to true
      let data={
        "mobileNumber" : this.state.userData.mobileNo,
        }

      RegisterUserService.unlockUserProfile(data,cb => {
          if (cb.status === "success") {
            success(
              "Profile unlocked successfully",
              successNotification
            );
          
            this.setState({
              showUpdatePanForm: false,
              showUpdateEmailForm: false,
              showUpdateMobileForm: false,
              showUnlocakForm:false,
              showUpdateSecEmailForm:false,
              showMandatePanForm:false,
              userData: null
          });
          this.resetForm();
        }else {
              error(cb.reasonText, {
                ...errorNotification,
                container: "top-center"
              });

            //   this.setState({
            //     showUpdatePanForm: false,
            //     showUpdateEmailForm: false,
            //     showUpdateMobileForm: false,
            //     showUnlocakForm:false,
            //     userData: null
            // });
            // this.resetForm();
          }
          this.setState({ loading: false }); 
      });
      
    }
   
    render() {
        const { userData, showUpdatePanForm, showMandatePanForm, showAll, showUpdateEmailForm, showUpdateSecEmailForm,showUpdateMobileForm ,showUnlocakForm} = this.state;
        return (
            <React.Fragment>
                <DashboardHeader
                    {...this.props}
                />
                {this.state.loading && (
                    <PopUpPortal
                        HidePopup={this.state.loading}
                        IsVisible={this.state.loading}
                    />
                )}


        <div id="page-container">
           <div id="table-list-box">
                <div className="container-fluid">
                 
                <Formik
                    key={this.state.formKey}
                    initialValues={{ mobileNumber:'', panNumber: '' , emailID:'',emailIDSec:''}}
                    validate={this.validate}
                    onSubmit={this.handleSubmit}
                >
                <Form>
                    <div className="row">
                        <div className="col-lg-2 col-sm-2 col-xs-12 form-group">
                            <Field
                            type="text"
                            name="panNumber"
                            className="form-control"
                            placeholder="Enter Pan NUMBER"
                            style={{ textTransform: "uppercase" }}
                            />
                            <ErrorMessage name="panNumber" component="div" className="validation" />
                        </div>
                        <div className="col-lg-1 col-sm-1 col-xs-12" style={{ cursor: 'default' }}> 
                           <p style={{textAlign:'center',marginTop:7,textColor:'gray',cursor: 'default'}}>OR</p>
                        </div>
                        <div className="col-lg-2 col-sm-2 col-xs-12 form-group">
                            <Field
                            type="text"
                            name="mobileNumber"
                            className="form-control"
                            placeholder="ENTER MOBILE NUMBER"
                            inputMode="numeric"  // Add this line
                            pattern="[0-9]*" 
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Allow only numeric characters
                              if (e.target.value.length > 10) {
                                e.target.value = e.target.value.slice(0, 10); // Limit to 10 digits
                              }
                            }}
                            />
                            <ErrorMessage name="mobileNumber" component="div" className="validation" />
                        </div>
                        <div className="col-lg-1 col-sm-1 col-xs-12" style={{ cursor: 'default' }}>
                        <p style={{textAlign:'center',marginTop:7,textColor:'gray',cursor: 'default'}}>OR</p>
                        </div>
                        <div className="col-lg-2 col-sm-2 col-xs-12 form-group">
                            <Field
                            type="text"
                            name="emailID"
                            className="form-control"
                            placeholder="ENTER EMAIL ID"
                            />
                            <ErrorMessage name="emailID" component="div" className="validation" />
                        </div>
                        <div className="col-lg-1 col-sm-1 col-xs-12" style={{ cursor: 'default' }}>
                        <p style={{textAlign:'center',marginTop:7,textColor:'gray',cursor: 'default'}}>OR</p>
                        </div>
                        <div className="col-lg-2 col-sm-2 col-xs-12 form-group">
                            <Field
                            type="text"
                            name="emailIDSec"
                            className="form-control"
                            placeholder="ENTER SECONDARY EMAIL ID"
                            />
                            <ErrorMessage name="emailIDSec" component="div" className="validation" />
                        </div>
                        <div className="col-lg-1 col-sm-1 col-xs-12 form-group">
                                <button
                                type="submit"
                                className="btn btn-primary"
                                >
                                Search
                                </button>
                        </div>
                    </div>
                </Form>
                </Formik>
                <div className="table-responsive">
                    <table className="table table-borderless" style={{marginTop:10}}>
                        <thead  style={{ backgroundColor: "#085dab" , color :"white" , textAlign: "center"}}>
                        <tr>
                            <th scope="col">Employee No</th>
                            <th scope="col">First Name</th>
                            <th scope="col">Last Name</th>
                            <th scope="col">PAN No</th>
                            <th scope="col">Email Id</th>
                            <th scope="col">Secondary Email Id</th>
                            <th scope="col">Mobile Number</th>
                            <th scope="col">DOB</th>
                            <th scope="col">Profile Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        { userData === null ? (
                            <tr style={{textAlign: "center"}}><td colSpan="9">No records found</td></tr>
                            ) : (
                            <tr>
                                <td>{userData.empNo}</td>
                                <td>{userData.firstName}</td>
                                <td>{userData.lastName}</td>
                                <td>{userData.panNumber}</td>
                                <td>{userData.emailID}</td>
                                <td>{userData.secondaryEmailID}</td>
                                <td>{userData.mobileNo}</td>
                                <td>{userData.dob}</td>
                                <td>{userData.applicationStatus}</td>
                            </tr>
                        )}
                        </tbody>
                        </table>
                     </div>
                     <div className="center-buttons">
                      <button 
                            className={`btn btn-primary ${userData === null ? 'disabled-button' : ''}`} 
                            
                            onClick={this.toggleUpdatePanForm}  
                            disabled={userData === null}>Update PAN No</button>

                        <button 
                        className={`btn btn-primary ${userData === null ? 'disabled-button' : ''}`} 
                        
                        onClick={this.toggleUpdateEmailForm}  
                        disabled={userData === null}>Update Email Id</button>

                        <button 
                        className={`btn btn-primary ${userData === null ? 'disabled-button' : ''}`} 
                        onClick={this.toggleUpdateSecEmailForm}  
                        disabled={userData === null}>Update Secondary Email Id</button>

                        <button 
                        className={`btn btn-primary ${userData === null ? 'disabled-button' : ''}`} 
                        onClick={this.toggleUpdateMobileForm}  
                        disabled={userData === null}>Update Mobile No</button>
                        
                        <button 
                        className={`btn btn-primary ${userData === null ? 'disabled-button' : ''}`} 
                        onClick={this.toggleUpdateLockForm}  
                        disabled={userData === null}>Unlock Profile</button>

                        <button 
                        className={`btn btn-primary ${userData === null ? 'disabled-button' : ''}`} 
                        onClick={this.toggleUpdateMandatePanForm}  
                        disabled={userData === null}>Rollback Learner Option</button>

                      </div>
                </div>
                     

                      {showUpdatePanForm && (
                      <div id="update-list-box">
                        <Formik
                            initialValues={{ oldPanNumber:userData.panNumber, newPanNumber: '' }}
                            validate={this.validatePan}
                            onSubmit={this.handlePanSubmit}
                        >
                        <Form>
                        <div className="row">
                            <div className="col-lg-12 col-sm-12 col-xs-12 form-group">
                            <label>Old PAN Number</label>
                            <Field
                                value={userData.panNumber}
                                type="text"
                                name="oldPanNumber"
                                className="form-control"
                                style={{ textTransform: "uppercase" }}
                                disabled
                            />
                            <ErrorMessage name="oldPanNumber" component="div" className="validation" />
                            </div>
                            </div>
                            <div className="row">
                            <div className="col-lg-12 col-sm-12 col-xs-12 form-group">
                            <label>New PAN Number</label>
                            <Field
                                type="text"
                                name="newPanNumber"
                                className="form-control"
                                placeholder="Enter your new PAN number"
                                style={{ textTransform: "uppercase" }}
                            />
                            <ErrorMessage name="newPanNumber" component="div" className="validation" />
                            </div>
                            </div>
                            <button type="submit" className="btn-1 mt-40" value="Update">
                                Update
                            </button>
                        </Form>
                        </Formik>
                        </div>)}
                        {showMandatePanForm && (
                        <div id="update-list-box">
                          <Formik
                              initialValues={{ oldPanNumber:userData.panNumber }}
                              onSubmit={this.handleMandatePanSubmit}
                          >
                            <Form>
                            <div className="row">
                              <div className="col-lg-12 col-sm-12 col-xs-12 form-group">
                              <label>PAN Number</label>
                              <Field
                                value={userData.panNumber}
                                type="text"
                                name="oldPanNumber"
                                className="form-control"
                                style={{ textTransform: "uppercase" }}
                                disabled
                              />
                              <ErrorMessage name="oldPanNumber" component="div" className="validation" />
                              </div>
                              </div>
                              <button type="button" className="btn-1" value="Update"
                              onClick={()=>{
                                this.setState({
                                  showAll: !this.state.showAll
                                })
                              }}>
                                Update
                              </button>
                              {showAll && (
                              <>
                                <div className="row">
                                  <div className="col-md-12 col-sm-12 col-xs-12">
                                    <p style={{ textAlign: 'center',marginTop:"20px" }}>Are you sure you want to update the mandate type?</p>
                                    <div style={{ display: 'flex', gap: 10, justifyContent: 'center' }}>
                                      <button type="submit" className="btn btn-primary" value="Yes">Yes</button>
                                      <button type="button" className="btn btn-primary" value="No"
                                      onClick={this.toggleUpdateMandatePanForm}>No</button>
                                    </div>
                                  </div>
                                </div>
                              </>
                              )}
                            </Form>
                          </Formik>
                        </div>
                        )}
                        {showUpdateEmailForm && (
                                <div id="update-list-box">
                                   <Formik
                                        initialValues={{ oldEmail:userData.emailID, newEmail: '' }}
                                        validate={this.validateEmail}
                                        onSubmit={this.handleEmailSubmit}
                                    >
                                    <Form>
                                    <div className="row">
                                      <div className="col-lg-12 col-sm-12 col-xs-12 form-group">
                                        <label>Old Email ID</label>
                                        <Field
                                            value={userData.emailID}
                                            type="text"
                                            name="oldEmail"
                                            className="form-control"
                                            placeholder="Enter your old Email ID"
                                            disabled
                                        />
                                        <ErrorMessage name="oldEmail" component="div" className="validation" />
                                        </div>
                                      </div>
                                      <div className="row">
                                      <div className="col-lg-12 col-sm-12 col-xs-12 form-group">
                                        <label>New Email ID</label>
                                        <Field
                                            type="text"
                                            name="newEmail"
                                            className="form-control"
                                            placeholder="ENTER YOUR NEW EMAIL ID"
                                        />
                                        <ErrorMessage name="newEmail" component="div" className="validation" />
                                        </div>
                                      </div>
                                        <button type="submit" className="btn-1 mt-40" value="Update">
                                            Update
                                        </button>
                                    </Form>
                                    </Formik>
                                </div>
                        )}

                        {showUpdateSecEmailForm && (
                                <div id="update-list-box">
                                   <Formik
                                        initialValues={{ oldSecEmail:userData.secondaryEmailID, newSecEmail: '' }}
                                        validate={this.validateSecEmail}
                                        onSubmit={this.handleSecEmailSubmit}
                                    >
                                    <Form>
                                    <div className="row">
                                      <div className="col-lg-12 col-sm-12 col-xs-12 form-group">
                                        <label>Old Secondary Email ID</label>
                                        <Field
                                            value={userData.secondaryEmailID}
                                            type="text"
                                            name="oldSecEmail"
                                            className="form-control"
                                            placeholder="Enter your old Secondary Email ID"
                                            disabled
                                        />
                                        <ErrorMessage name="oldSecEmail" component="div" className="validation" />
                                        </div>
                                      </div>
                                      <div className="row">
                                      <div className="col-lg-12 col-sm-12 col-xs-12 form-group">
                                        <label>New Secondary Email ID</label>
                                        <Field
                                            type="text"
                                            name="newSecEmail"
                                            className="form-control"
                                            placeholder="ENTER YOUR NEW SECONDARY EMAIL ID"
                                        />
                                        <ErrorMessage name="newSecEmail" component="div" className="validation" />
                                        </div>
                                      </div>
                                        <button type="submit" className="btn-1 mt-40" value="Update">
                                            Update
                                        </button>
                                    </Form>
                                    </Formik>
                                </div>
                        )}

                        {showUpdateMobileForm && (
                            <div id="update-list-box">
                                <Formik
                                    initialValues={{ oldMobileNumber:userData.mobileNo, newMobileNumber: '' }}
                                    validate={this.validateMobile}
                                    onSubmit={this.handleMobileSubmit}
                                >
                                <Form>
                                <div className="row">
                                  <div className="col-lg-12 col-sm-12 col-xs-12 form-group">
                                    <label>Old Mobile Number</label>
                                    <Field
                                        value={userData.mobileNo}
                                        type="text"
                                        name="oldMobileNumber"
                                        className="form-control"
                                        placeholder="Enter your old mobile number"
                                        disabled
                                    />
                                    <ErrorMessage name="oldMobileNumber" component="div" className="validation" />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-12 col-sm-12 col-xs-12 form-group">
                                    <label>New Mobile Number</label>
                                    <Field
                                        type="text"
                                        name="newMobileNumber"
                                        className="form-control"
                                        placeholder="ENTER YOUR NEW MOBILE NUMBER"
                                        inputMode="numeric"  // Add this line
                                        pattern="[0-9]*" 
                                        onInput={(e) => {
                                          e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Allow only numeric characters
                                          if (e.target.value.length > 10) {
                                            e.target.value = e.target.value.slice(0, 10); // Limit to 10 digits
                                          }
                                        }}
                                    />
                                    <ErrorMessage name="newMobileNumber" component="div" className="validation" />
                                    </div>
                                  </div>
                                    <button type="submit" className="btn-1 mt-40" value="Update">
                                        Update
                                    </button>
                                </Form>
                                </Formik>
                            </div>
                        )}

                    {showUnlocakForm && (
                    <div id="update-list-box">
                        {/* Render your unlock form here */}
                        <p style={{ textAlign: 'center' }}>Total login attempts: {userData.loginAttempt}</p>
                        <p style={{ textAlign: 'center' }}>Are you sure you want to unlock this profile?</p>
                        <div style={{ display: 'flex', gap: 10, justifyContent: 'center' }}>
                            <button type="submit" className="btn btn-primary" value="Yes"
                            onClick={this.unlockProfile}>Yes</button>
                            <button type="button" className="btn btn-primary" value="No"
                             onClick={this.toggleUpdateLockForm}>No</button>
                        </div>
                    </div>
                )}
              
                        
            </div>    
            </div>
               
             <Finxfooter />
            </React.Fragment>
        
       
                                             
                                                                     
                 
        );
    }
}

export default UpdatePersonalDetails;
