import React, { Component } from "react";
import { LoginService } from "../../services/login.service";
import { success, successNotification, error, errorNotification, warning, warningNotification } from "../notification/notifications";

import { fetchQuestionOption, updateTermsAndCondition, updateScore, updateStatus, reattemptSendMail } from "../../services/preAssessment.Service"
import Countdown from 'react-countdown';
import Circle from 'react-circle';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./preAssessment.css"
import { RegisterUserService } from "../../services/registration.service";

class FeatTest extends Component {
  constructor(props) {

    super(props);
    if (localStorage.getItem('login') !== null) {
      this.state = {
        updateQuestion: {},
        showIntroPage: false,
        sectionChangeFlag: true,
        questionNo: 0,
        attemptQuestion: 0,
        currentSessionCount: 0,
        nextSectionIntro: false,
        continuePage: false,
        demo: null,
        questionOptionData: null,
        instruction: "Blank",
        termsAndCondistion: "Blank",
        timeUp: false,
        allAttempt: false,
        thankYouPage: false,
        testTime: 0,
        behaviourIntroPage: false,
        agree: false,
        pageValidationJSON: {},
        disabled: false,
        intervalId: 0,
        thePosition: false,
        step: 1,
        startTestTime: null,
        stepPage: 0,
        firstStart: true,
        start: 0,
        end: 0,
        page: 0,
        answerJSONList: {},
        activeTestid: "",
        saleForceTestId: "",
        submitting: false,
        time: 0,
        isOn: false,
        start: 0,
        timerFlag: null,
        finalTime: null,
        pageChecked: 0,
        display: "none",
        mailDataKYD:[],
        smsData:[],
      };

      this.handleClick = this.handleClick.bind(this);
      this.initialState = this.state;
    }
    else {
      window.location.href = '/';
    }

  }


  updateScoreCall = (data) => {
    // alert("Update API CALL :: "+ JSON.stringify(data))
    const sampleData = data

    var attemptedList = []
    var question = Object.keys(sampleData)
    for (var i = 0; i < Object.keys(sampleData).length; i++) {
      // Hard Code Section ID
      var sectiondummyID = Object.keys(this.state.answerJSONList).length > 12 ? 4 : 3
      var QuestionJSON = {
        "sectionId": parseInt(sectiondummyID),
        "questionId": parseInt(question[i]),
        "answerId": parseInt(sampleData[question[i]])
      }
      attemptedList.push(QuestionJSON)
    }

    // API CALL
    var data1 = localStorage.getItem("PersonnelData")
    console.log(JSON.parse(data1).responseObject.learnerID)
    let partyId = JSON.parse(localStorage.login).partyId;
    var dataPayload = {
      "testId": this.props.termCondition.responseObject.testId,
      "productId": this.props.location.state.productId, //190477, 
      // "module" : 190499,
      "learnerId": JSON.parse(data1).responseObject.learnerID, //localStorage
      "listAssessmentQuestionAnswer": attemptedList
    }

    updateScore(dataPayload, (res) => {
      //console.log("data", res)

      if (res.status === "success") {
        // alert("Accept terms")
      }
      else {
        // alert("Accept terms failed")
      }
    });
  }


  onScrollStep = () => {
    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
  }

  scrollToTop = () => {
    let intervalId = setInterval(this.onScrollStep, this.props.delayInMs);
    this.setState({ intervalId: intervalId });
  }
  componentDidMount() {
    var data = localStorage.getItem("PersonnelData")
    console.log(JSON.parse(data).responseObject.learnerID)
    LoginService.TokenUser(res => {
      localStorage.setItem("jwt-token", res.responseObject);
      // localStorage.setItem("jti-token", res.responseObject);
    })


    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        this.setState({ thePosition: true })
      } else {
        this.setState({ thePosition: false })
      }
    });
    window.scrollTo(0, 0);
  }

  // introPageHide = () => {
  //   this.setState({
  //       nextSectionIntro : false,
  //       continuePage : true
  //   })
  // }


  handleCancel = () => {
    this.props.history.goBack("-1");
    //window.$("#gdpi-score").modal('hide');
    // window.location.reload();
  };

  handleSelectOption(event) {

    const countSession = this.props.testIntroduction.responseListObject[0].noOfQuestions
    this.setState({
      attemptQuestion: Object.keys(this.state.answerJSONList).length + 1,
      currentSessionCount: countSession
    })
    if (Object.keys(this.state.answerJSONList).length + 1 === parseInt(countSession)) {
      this.setState({
        nextSectionIntro: true
      })
    }
    // Split , values and store in array
    var valuesQA = event.target.value.split(',');
    // set selected value
    this.setState({
      selectedValue: valuesQA[1]
    });

    // Validation JSON 
    var selectedOption = this.state.pageValidationJSON
    selectedOption[valuesQA[0]] = valuesQA[1]
    this.setState({
      pageValidationJSON: selectedOption
    })


    // Final Response JSON
    var qaJSON = this.state.answerJSONList
    qaJSON[valuesQA[0]] = valuesQA[1]
    this.setState({
      answerJSONList: qaJSON
    });

  }

  hideIntroPage = () => {
    this.setState({
      showIntroPage: false
    })
  }

  submitTest = (val) => {
    // const dataJSON = JSON.parse(val.target.value)
    this.updateScoreCall(val)

    const totalQCoint = Object.keys(this.state.questionOptionData).length
    const totalAnsCount = Object.keys(this.state.answerJSONList).length
    if (totalQCoint === totalAnsCount) {
      success('You have completed FEAT. Please click on "Subscribe" under NISM VA Preparatory Course to proceed.', successNotification)
      this.setState({
        thankYouPage: true,
        allAttempt: true
      })
    } else {
      warning("Please attempt all question...!", warningNotification)
    }

  }

  handleClick(val) {



    const pageData = val.target.value
    // Function call for validation check

    LoginService.TokenUser(res => {
      localStorage.setItem("jwt-token", res.responseObject);
      //  localStorage.setItem("jti-token", res.responseObject);
    })

    if (this.state.stepPage === 0) {
      // alert("Page :: "+ this.state.stepPage)
      // Tesrms and Condistion Update CAll 
      let partyId = JSON.parse(localStorage.login).partyId;
      var dataPayloadUpdateTerms = {
        "testId": this.props.termCondition.responseObject.testId,
        "termAccepted": true,
        "productId": this.props.location.state.productId, //190477,
        "userid": partyId
      }

      updateTermsAndCondition(dataPayloadUpdateTerms, (res) => {
        //console.log("data", res)

        if (res.status === "success") {
          // alert("Accept terms")
        }
        else {
          // alert("Accept terms failed")
        }
      });
    }
    const countSession = this.props.testIntroduction.responseListObject[0].noOfQuestions
    this.setState({
      attemptQuestion: Object.keys(this.state.answerJSONList).length + 1,
      currentSessionCount: countSession
    })
    const totalQCoint = Object.keys(this.state.questionOptionData || []).length
    const totalAnsCount = Object.keys(this.state.answerJSONList || []).length
    if (this.state.stepPage > 2 && totalQCoint === totalAnsCount) {

      this.submitTest(JSON.parse(pageData))
      var data1 = localStorage.getItem("PersonnelData")
     // alert("sneha")
      let data = {
        "ExcecutionTracker": [
          {
            "action": "FEAT COMPLETE",
            "workflowId": JSON.parse(data1).responseObject.learnerID,
            "workflowName": JSON.parse(data1).responseObject.lookingFor === "1001" ? "ARN" : "ARN",
            "comment": ""

          }
        ]
      }
      updateStatus(data)
      
      //alert("3")
      let mailDataKYD=[]
      let obj2 = {}
      obj2['emailId'] = JSON.parse(data1).responseObject.emailID
      obj2['userName'] = JSON.parse(data1).responseObject.firstName
      obj2['templateId'] = "1980880"
      obj2['status'] =  "FEAT COMPLETED"     
      obj2['partyId'] = JSON.parse(data1).responseObject.learnerPartyID
      obj2['mobileNumber'] = JSON.parse(data1).responseObject.mobileNo
      obj2["learnerId"]=JSON.parse(data1).responseObject.learnerID
      console.log("obj2 :::", obj2)
      mailDataKYD.push(obj2)
      this.setState({
        mailDataKYD:mailDataKYD
    });
      console.log("mailDataKYD :::",mailDataKYD)
      RegisterUserService.sendKYDMail(mailDataKYD, res2 => {
        //success("Feat completed successfully.", successNotification);
      //   if(res2.status === "success"){
      //     success("Feat mail sent. Plese check mail box", successNotification);
      // } else {
      //     error("Feat mail failed .", errorNotification);
      // }
    })

    let smsData=[]
      let sms = {}
      sms['userName'] = JSON.parse(data1).responseObject.firstName
      sms['templateId'] = "40030"
      sms['status'] =  "FEAT COMPLETED"     
      sms['partyId'] = JSON.parse(data1).responseObject.learnerPartyID
      sms['mobileNumber'] = JSON.parse(data1).responseObject.mobileNo
      
      console.log("sms :::", sms)
      smsData.push(sms)
      this.setState({
        smsData:smsData
    });
      console.log("smsData :::",smsData)
    RegisterUserService.sendSms(smsData, res3 => {
      //success("Feat completed successfully.", successNotification);
    //   if(res3.status === "success"){
    //     success("Feat sms sent", successNotification);
    // } else {
    //     error("Feat sms failed .", errorNotification);
    // }
  })

    }
    if (this.state.stepPage === 1) {
      var check = {}
      check["demo"] = "Demo"
      this.setState({
        demo: check
      })

      var dataPayload = {
        "testId": this.props.termCondition.responseObject.testId,
        "sectionId": this.props.termCondition.responseObject.sectionId
      }



      fetchQuestionOption(dataPayload, (res) => {
        //console.log("data", res)


        var questionData = res.responseListObject
        var section = this.props.termCondition.responseObject.sectionId
        var instructionData = this.props.testIntroduction.responseListObject
        questionData.sort(function (a, b) {
          return a.testQBankId - b.testQBankId;
        });
        this.setState({
          questionOptionData: questionData
        })

        if (res.status === "success") {

          success("Status changed successfully", successNotification);
        }
      });



    }

    if (this.state.stepPage < 2 && this.state.stepPage > 0) {
      this.setState({
        startTime: Date.now()
      })
    }

    var res;
    var validaionErrorIndex;
    const checkPg = this.state.pageChecked
    var pageCheckedNotChange = true

    if ((this.state.stepPage <= checkPg && this.state.stepPage > 0) || this.state.stepPage < 2) {
      res = true
      pageCheckedNotChange = false
    } else {
      const resValidation = this.pageValidation(JSON.parse(pageData))
      res = resValidation[0]
      validaionErrorIndex = resValidation[1]
    }


    if (res && this.state.agree) {

      // Update API CALL
      this.updateScoreCall(JSON.parse(pageData))

      // Clear JSON 
      if (pageCheckedNotChange) {
        if (this.state.step > 0) {
          this.setState({
            pageValidationJSON: {},
            pageChecked: this.state.stepPage,
            errorSpanFlag: false
          })
        }
      }


      if (this.state.questionOption != null) {
        const pg = this.state.questionOption.length % 5;
        var pages = pg != 0 ? parseInt(this.state.questionOption.length / 5) + 1 : this.state.questionOption.length / 5;
        this.setState({ page: pages + 2 })
      }

      this.setState(state => ({
        stepPage: this.state.stepPage + 1
      }));
      if (this.state.firstStart === true) {
        this.setState({
          behaviourIntroPage: true
        })
      }
      if (this.state.firstStart === true && this.state.behaviourIntroPage === true) {
        this.setState(state => ({
          firstStart: false
        }));
        this.setState(state => ({
          start: 0
        }));
      } else {

        if (this.state.end >= parseInt(this.state.currentSessionCount)) {
          if (this.state.sectionChangeFlag && parseInt(this.state.currentSessionCount) > 0) {
            this.setState(state => ({
              start: this.state.start + 5,
              sectionChangeFlag: false
            }));
          }
          else {


            this.setState(state => ({

              start: this.state.start + 5
            }));
          }
        } else {

          if (this.state.stepPage > 2) {
            this.setState(state => ({
              start: this.state.start + 5
            }));
          }
          else {
            this.setState(state => ({
              start: this.state.start + 6
            }));
          }
        }
      }

      if (this.state.stepPage >= 1) {
        if (this.state.end >= parseInt(this.state.currentSessionCount)) {
          this.setState(state => ({
            end: this.state.end + 5
          }));
        } else {
          if (this.state.stepPage >= 2) {
            //  if(this.state.stepPage==2)
            //  {
            //   this.setState({
            //     behaviourIntroPage: true
            //   })
            //  }
            //  else{
            this.setState(state => ({
              end: this.state.end + 5
            }));
            //}
          } else {
            this.setState(state => ({
              end: this.state.end + 6
            }));
          }
        }
      }
    } else {
      if (this.state.stepPage < 2) {
        warning("You need to agree terms and conditions before proceed..!", warningNotification);
      } else if (totalQCoint === totalAnsCount) {

      } else {
        warning("Please attempt all question...!", warningNotification);
      }

      // simple(
      //     "Attempt all questions..!!",
      //     simpleNotification
      //   );
      this.setState({
        errorIndex: validaionErrorIndex,
        errorSpanFlag: true
      })
    }

    // scroll to top of the screen
    if (this.state.thePosition) {
      this.scrollToTop()
    }

    let count = Object.keys(this.state.answerJSONList).length
    if (parseInt(this.state.currentSessionCount) > 0 && parseInt(this.state.currentSessionCount) === count) {
      // alert("Demo")
      this.setState({
        showIntroPage: true
      })
    }
  }

  pageValidation(pageData) {

    var doubleCheckLength;
    const validationJSOn = pageData
    const JSONkeys = Object.keys(validationJSOn)
    if (parseInt(this.state.currentSessionCount) >= Object.keys(this.state.answerJSONList).length) {
      doubleCheckLength = JSONkeys.length === 6 ? true : false
    }
    if (parseInt(this.state.currentSessionCount) < Object.keys(this.state.answerJSONList).length) {
      doubleCheckLength = JSONkeys.length === 5 ? true : false
    }
    if (this.state.stepPage > 2) {
      doubleCheckLength = JSONkeys.length === 5 ? true : false
    }
    // const doubleCheckLength = true
    if (doubleCheckLength) {
      this.setState({
        // pageValidationJSON: {}
      })
      return [true, null]

    }
    this.setState({
      // pageValidationJSON: {}
    })
    var indexForError = indexForError === 0 ? indexForError + 1 : indexForError + 2
    // If we find the index of particular field then we can show msg with particular field 
    return [false, 0]
  }

  preTestAgreePage = () => {
    this.setState({
      agree: !this.state.agree
    })
    var data1 = localStorage.getItem("PersonnelData")

    let data = {
      "ExcecutionTracker": [
        {
          "action": "FEAT COMPLETE",
          "workflowId": JSON.parse(data1).responseObject.learnerID,
          "workflowName": JSON.parse(data1).responseObject.lookingFor === "1001" ? "ARN" : "ARN",
          "comment": ""

        }
      ]
    }
    updateStatus(data)
    //alert("1")
  //   let mailDataKYD=[]
  //   let obj2 = {}
  //   obj2['emailId'] = JSON.parse(data1).responseObject.emailID
  //   obj2['userName'] = JSON.parse(data1).responseObject.firstName
  //   obj2['templateId'] = "1980880"
  //   obj2['status'] =  "FEAT COMPLETED"     
  //   obj2['partyId'] = JSON.parse(data1).responseObject.learnerPartyID
  //   obj2['mobileNumber'] = JSON.parse(data1).responseObject.mobileNo
  //   obj2["learnerId"]=JSON.parse(data1).responseObject.learnerID
  //   console.log("obj2 :::", obj2)
  //   mailDataKYD.push(obj2)
  //   this.setState({
  //     mailDataKYD:mailDataKYD
  // });
  //   console.log("mailDataKYD :::",mailDataKYD)
  //   RegisterUserService.sendKYDMail(mailDataKYD, res2 => {
  //     //success("Feat completed successfully.", successNotification);
  //   //   if(res2.status === "success"){
  //   //     success("Feat mail sent. Plese check mail box", successNotification);
  //   // } else {
  //   //     error("Feat mail failed .", errorNotification);
  //   // }
  // })

  }

  timeCheck = ({ hours, minutes, seconds, completed }) => {
    var sec = seconds < 10 ? "0" + seconds : seconds
    var min = minutes < 10 ? "0" + minutes : minutes
    var hr = hours < 10 ? "0" + hours : hours
    if (completed) {
      this.setState({
        timeUp: true
      })
      // this.props.history.push("/preAssessment")
      return <span>Time Up...!!!</span>
    } else {
      // Render a countdown
      return <span class="timer-preAssessment">{hr}:{min}:{sec}</span>;
    }
  };

  render() {



    const dataName = this.props.termCondition ? this.props.termCondition.reasonCode : undefined;
    if (dataName === undefined) {
    }
    else if (dataName === "NOT_FOUND") {
      // alert("Test Attempted...")
      // warning("Test attempted already..!", warningNotification);
      //   window.$('#TestReaatempt').modal('show')
      // this.setState({ display: "block" })
      //   this.setState({
      //     thankYouPage: true,
      // //s    allAttempt: true
      //   })
      var data1 = localStorage.getItem("PersonnelData")

      let data = {
        "ExcecutionTracker": [
          {
            "action": "FEAT COMPLETE",
            "workflowId": JSON.parse(data1).responseObject.learnerID,
            "workflowName": JSON.parse(data1).responseObject.lookingFor === "1001" ? "ARN" : "ARN",
            "comment": ""

          }
        ]
      }
      updateStatus(data)
//here
//alert("2")
let mailDataKYD=[]
      let obj2 = {}
      obj2['emailId'] = JSON.parse(data1).responseObject.emailID
      obj2['userName'] = JSON.parse(data1).responseObject.firstName
      obj2['templateId'] = "1980880"
      obj2['status'] =  "FEAT COMPLETED"     
      obj2['partyId'] = JSON.parse(data1).responseObject.learnerPartyID
      obj2['mobileNumber'] = JSON.parse(data1).responseObject.mobileNo
      obj2["learnerId"]=JSON.parse(data1).responseObject.learnerID
      console.log("obj2 :::", obj2)
      mailDataKYD.push(obj2)
      this.setState({
        mailDataKYD:mailDataKYD
    });
      console.log("mailDataKYD :::",mailDataKYD)
      RegisterUserService.sendKYDMail(mailDataKYD, res2 => {
        //success("Feat completed successfully.", successNotification);
      //   if(res2.status === "success"){
      //     success("Feat mail sent. Plese check mail box", successNotification);
      // } else {
      //     error("Feat mail failed .", errorNotification);
      // }
    })


    let smsData=[]
      let sms = {}
      sms['userName'] = JSON.parse(data1).responseObject.firstName
      sms['templateId'] = "40030"
      sms['status'] =  "FEAT COMPLETED"     
      sms['partyId'] = JSON.parse(data1).responseObject.learnerPartyID
      sms['mobileNumber'] = JSON.parse(data1).responseObject.mobileNo
      
      console.log("sms :::", sms)
      smsData.push(sms)
      this.setState({
        smsData:smsData
    });
      console.log("smsData :::",smsData)
    RegisterUserService.sendSms(smsData, res3 => {
     // success("Feat completed successfully.", successNotification);
    //   if(res3.status === "success"){
    //     success("Feat sms sent", successNotification);
    // } else {
    //     error("Feat sms failed .", errorNotification);
    // }
  })


      this.props.history.push("/learnerDashboard");
      //  this.props.history.push("/Thankyoupressesment");

      // const termsAttempt = true
    }

    var resJSON = this.state.questionOptionData;
    //Percentage 
    var approxTime = ""
    if (resJSON != null) {
      var pages = (parseInt(resJSON.length / 5) + 1);
      var percentComplete = parseInt((Object.keys(this.state.answerJSONList).length * 100) / resJSON.length)
    }

    // API values binding for Terms and condition
    var responseTermsandCondition = {
      "testId": 12,
      "timeRequired": "00",
      "testname": "TestName HD",
      "section": [3, 4],
      "termsAndCondtions": "TERM CONDITION HD",
      "testName": "TEST NAME"
    }
    var responseInstruction = [
      {
        "sectionId": 3,
        "testId": 12,
        "instructions": "",
        "sectionName": ""
      },
      {
        "sectionId": 4,
        "testId": 12,
        "instructions": "",
        "sectionName": ""
      }
    ]

    if (this.props.termCondition.responseObject) {
      var apiResponseTermCondition = this.props.termCondition.responseObject;
      var apiResponseIntroduction = this.props.testIntroduction.responseListObject;
      responseTermsandCondition = apiResponseTermCondition;
      responseInstruction = apiResponseIntroduction
    }

    let termsData = ""
    const text = "Tommy Vercetti \n Carl Johnson"
    if (this.props.termCondition.responseObject !== undefined) {
      const text1 = responseTermsandCondition.termsAndCondtions
      console.log(typeof text)
      console.log(typeof responseTermsandCondition.termsAndCondtions)
      termsData = text1.split('linebreak').map(i => {
        return <p>{i}</p>
      });

    }


    return (
      <div>
        <div className="container-fluid" id="preassessment">
          {/*  Page Test Title */}
          {/* <div class="assessment-title">{responseTermsandCondition.testName} Attempt Q :: {this.state.attemptQuestion}</div> */}
          {/* <div className="col-md-12 col-sm-12 col-xs-12">
            <div class="section-title-black">

              <h2>{responseTermsandCondition.testName}</h2>
            </div>
          </div> */}

          {/* Container Contain code for thank u page and assessment timer, tet, progressbar */}
          {
            //  ThankYou Page
            this.state.thankYouPage || this.state.timeUp ?
              <div class="">
                <div class="boxbg gridChange thank-you-page">
                  <div class="thank-you-title"> Thank You ! </div>
                  <div class="thank-you-content">
                    <div hidden={this.state.timeUp ? true : false}> Your FEAT is Completed. </div>
                    <div hidden={this.state.thankYouPage ? true : false}> Your Assessment Time is Over. </div>
                  </div>
                  <button class="btn-1 mt-30">
                    <a href="/learnerDashboard">Return to Home Page</a>
                  </button>
                </div>
              </div>
              :
              //  Common Container
              <div className="">
                <div className="col-md-3 col-lg-3 col-sm-4 col-xs-12 left-section">
                  {/* Timer Component */}
                  <div class="boxbg gridChange timer-details" id="pre-style">
                    <div class="timer-title title"> FEAT takes around {responseTermsandCondition.timeRequired} mins</div>
                    {/* <div class="timer-subtitle"> The overall test takes around {responseTermsandCondition.timeRequired} mins</div> */}

                    <div style={{ textAlign: "left" }} class="instruction-title">
                      <pre id="pre-style">
                        Factual Assessment          : {responseInstruction ? responseInstruction[0].noOfQuestions : 0} Questions

                      {this.state.stepPage >= 4 ?
                          <span className="pull-right">
                            {" "}
                            <i
                              className="fa fa-check-circle bg-green"
                              aria-hidden="true"
                            ></i>{" "}
                          </span>
                          :
                          // <span className="pull-right">
                          //   {" "}
                          //   <i
                          //     className="fa fa-times-circle red"
                          //     aria-hidden="true"
                          //   ></i>
                          // </span>
                          ''
                        }
                      </pre>
                    </div>

                    <div style={{ textAlign: "left", fontSize: "14" }} class="instruction-title">
                      <pre id="pre-style">Behavioural Assessment : {responseInstruction ? responseInstruction[1].noOfQuestions : 0} Questions

                      {this.state.stepPage >= 7 ?
                          <span className="pull-right">
                            {" "}
                            <i
                              className="fa fa-check-circle bg-green"
                              aria-hidden="true"
                            ></i>{" "}
                          </span>
                          :
                          // <span className="pull-right">
                          //   {" "}
                          //   <i
                          //     className="fa fa-times-circle red"
                          //     aria-hidden="true"
                          //   ></i>
                          // </span>
                          ''
                        }
                      </pre>
                    </div>
                    <div className="timer-btn">
                      {/* Timer after start */}
                      {/* {this.state.startTime} */}

                      {this.state.stepPage > 1 ?
                        <Countdown date={this.state.startTime + (60000 * (parseInt(responseTermsandCondition.timeRequired) - 0.009))}
                          className="timer-preAssessment"
                          renderer={this.timeCheck}
                        />
                        :
                        // Timer on first two page
                        <span className="timer-preAssessment"> 00:{responseTermsandCondition.timeRequired}:00 </span>
                      }
                    </div>
                  </div>

                  {/* Progress Bar */}
                  {this.state.stepPage >= 2 ?
                    <div class="boxbg gridChange assess-details">
                      <div class="assess-count title">{(this.state.questionOptionData || []).length} Questions</div>
                      <div class="assess-progress">Assessment Progress</div>
                      <div class="progress-img">
                        <Circle
                          size={110} // Number: Defines the size of the circle.
                          lineWidth={25}
                          progress={percentComplete}
                          textColor="black"
                          progressColor="cornflowerblue"

                          percentSpacing={10} // Number: Adjust spacing of "%" symbol and number.
                          roundedStroke={true} // Boolean: Rounded/Flat line ends
                          showPercentage={true} // Boolean: Show/hide percentage.
                          showPercentageSymbol={true} // Boolean: Show/hide only the "%" symbol.
                        />
                      </div>


                    </div> : null
                  }
                  {/* <div class="boxbg gridChange assess-details">
                  

                    <div class="progress-img">

                    </div>
                  </div> */}
                </div>

                <div className="col-md-9 col-lg-9 col-sm-8 col-xs-12 preright-section">
                  {/* Assessment Component */}
                  <div class={this.state.stepPage > 1 ? "assessment-test" : "boxbg gridChange assessment-test"}>
                    {/* Introduction Page */}
                    {
                      this.state.stepPage === 0 ? <div>
                        <div class="intro-title">Terms And Conditions</div>

                        <div class="instruction-title">



                          {termsData}
                          {/* {responseTermsandCondition.termsAndCondtions} */}

                        </div>
                      </div> :
                        // Introduction Page
                        this.state.stepPage === 1 ?
                          <div>
                            <div class="intro-title-heading"> {responseInstruction[0].sectionName} </div>
                            <div>
                              <div class="instruction-title">
                                {responseInstruction[0].instructions}
                              </div>
                            </div>
                          </div> :
                          null
                    }

                    {/* Test Question Option mapping */}
                    {
                      (this.state.questionOptionData || []).slice(this.state.start, this.state.end).map((question, index) => {
                        return (
                          <div hidden={this.state.showIntroPage ? true : false}>
                            <div class="boxbg gridChange assess-body">
                              <div class="question-style"> Question No:: {this.state.start + index + 1} </div>
                              <div class="question">{question.questionText}</div>
                              <form className="radioflex">
                                <fieldset onChange={this.handleSelectOption.bind(this)}>
                                  {((question || []).testQuestionBankOption || []).map((option, i) => (
                                    <div id="assessmentOption">

                                      <input className="radio-circle" type="radio" onChange={this.handleChange}
                                        checked={parseInt(this.state.answerJSONList[option.testQBankId]) === option.testQBankOptionId ? true : false}
                                        id={option.testQBankId} name={option.testQBankId} value={[option.testQBankId, option.testQBankOptionId]} />
                                      <span className="radio-text" for={option.testQBankId}>{option.questionOptionText}</span>
                                      <span className="alpabate" for={option.testQBankId}></span>

                                    </div>
                                  ))}
                                </fieldset>
                              </form>
                            </div>
                            {/* <div class="intro-row"></div>*/}
                          </div>
                        )
                      })

                    }
                    {/* Section Page */}

                    {this.state.stepPage > 2 ?
                      <div>
                        {/* hidden={this.state.showIntroPage ? false : true} */}
                        <div hidden={this.state.showIntroPage ? false : true} class="boxbg gridChange assessment-test">
                          <div class="intro-title-heading">  {responseInstruction[1].sectionName}</div>

                          <div>
                            <div class="instruction-title">
                              {responseInstruction[1].instructions}
                            </div>
                          </div>
                          <div className="align-right">
                            <button type="button" className="btn-preAssessment "
                              value={JSON.stringify(this.state.pageValidationJSON)}
                              onClick={this.hideIntroPage} data-dismiss="modal">
                              Next  <i className="fa fa-angle-right" aria-hidden="true"></i><i className="fa fa-angle-right" aria-hidden="true"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      : null}
                    {/* <div hidden={this.state.showIntroPage ? false : true} class="boxbg gridChange assessment-test">
          <div class="intro-title">  {responseInstruction[1].sectionName}</div>
          <div>
          <div class="instruction-title">
          {responseInstruction[1].instructions}
          </div>
          </div>

          <button type="button" className="btn-preAssessment " 
          value={JSON.stringify(this.state.pageValidationJSON)}
          onClick={this.hideIntroPage} data-dismiss="modal">
          Next <i className="fa fa-angle-right" aria-hidden="true"></i>
          </button>
        </div> */}

                    {/* Agree Button */}
                    <div className="bottom-border" hidden={this.state.agree && this.state.stepPage >= 1 ? true : false}>
                      <div for="agree" className="assess-subtext">
                        <input type="checkbox" id="agree" name="agree" value="agree" onClick={this.preTestAgreePage} />
                        <span> I Agree</span>
                      </div>
                    </div>
                    {/* <div className="bottom-border"></div> */}
                    <div className="align-right">
                      <div>

                        {
                          this.state.stepPage > 1 ?
                            <button type="button" className="btn-preAssessment"
                              hidden={this.state.showIntroPage ? true : false}
                              value={JSON.stringify(this.state.pageValidationJSON)}
                              onClick={this.handleClick} data-dismiss="modal">
                              {this.state.stepPage == 5 ? "Submit" : "Next "}  {this.state.stepPage == 5 ? '' :
                                <span>
                                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                                </span>

                              }
                            </button>

                            : this.state.stepPage == 1 ? <div>

                              <button type="button" className="btn-preAssessment"
                                value={JSON.stringify(this.state.pageValidationJSON)}
                                onClick={this.handleClick} data-dismiss="modal">
                                Next
                            <span>&nbsp;
                          <i className="fa fa-angle-right" aria-hidden="true"></i>
                                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                                </span>
                              </button>
                            </div> :
                              <div>
                                <button type="button" className="btn-preAssessment"
                                  value={JSON.stringify(this.state.pageValidationJSON)}
                                  onClick={this.handleCancel} data-dismiss="modal">
                                  {/* <i className="fa fa-angle-left" aria-hidden="true"></i> */}
                             Cancel
                              </button>&nbsp;
                              <button type="button" className="btn-preAssessment"
                                  value={JSON.stringify(this.state.pageValidationJSON)}
                                  onClick={this.handleClick} data-dismiss="modal">
                                  Submit
                              {/* <i className="fa fa-angle-right" aria-hidden="true"></i> */}
                                </button>
                              </div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          }
        </div>


        <Formik

          initialValues={{

            emailId: "",

          }}

          onSubmit={(fields) => {

            var data1 = localStorage.getItem("login")
            var data2 = localStorage.getItem("PersonnelData")


            let data = [{
              "userName": JSON.parse(data1).userName,
              "userId": JSON.parse(data1).userId,
              "emailId": JSON.parse(data1).emailId,
              "passward": "",
              "templateId": "1960110",
              "mobileNumber": JSON.parse(data2).responseObject.mobileNo
            }]
            reattemptSendMail(data, this.props.history, res => {
              console.log(res)
              // this.props.history.push("/learnerDashboard");
              // window.location.reload()
              // if (res.status === "success") {
              //   success(
              //     "Mail send successfully",
              //     successNotification
              //   );
              //   this.props.history.push("/learnerDashboard");
              //   window.location.reload()
              // }
              // else {
              //   warning(

              //     "",
              //     warningNotification
              //   );

              // }
            });


          }}

          render={({ errors, touched, handleSubmit, setFieldValue, values }) => (
            <React.Fragment>



              <div
                className="modal fade"
                id="TestReaatempt"
                style={{ display: this.state.display }}
                tabindex="-1" role="dialog" aria-labelledby="modalLabel" data-keyboard="true" aria-hidden="true"
              >

                <div className="modal-dialog modal-md" role="document">
                  <Form onSubmit={handleSubmit} className="form_name">
                    <div className="modal-content">
                      <button
                        type="reset"
                        class="close"
                        aria-label="Close"
                        onClick={
                          e => {
                            this.props.history.push("/learnerDashboard")
                            window.location.reload()
                          }
                        }
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>

                      <section className="registration">
                        <div className="modal-body">
                          <div classNames="container">
                            <div className="modal-head" style={{
                              padding: "9px 8px"
                            }}>
                              <div className="section-title-black" style={{ textAlign: "center" }} >
                                <h3 style={{ color: "black" }} >Reattempt Pre-Assessment</h3>
                              </div>
                            </div>
                            <div className="">
                              <p>Would you like to Reattempt the Pre-assessment? Please confirm and we will notify the ExpertMFD Support Team to enable the assessment.


</p>
                            </div>
                            <div className="mb-20 text-center">
                              <button type="submit" className="btn-5 mr-20">Submit</button>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </Form>
                </div>

              </div>
            </React.Fragment>
          )}
        />





      </div>
    );
  }
}

export default FeatTest;
