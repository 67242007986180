import React from "react";
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";


moment.locale("en-GB");
const localizer = momentLocalizer(moment);

const myEventsList = {} //empty object for now


class MyCalander extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            cal_events: [
                //State is updated via componentDidMount
            ],
        }
    }
    convertDate = (date) => {
        return moment.utc(date).toDate()
    }

    componentWillMount() {

        let self = this;
        let appointments = this.props.props;
        let events = [];

        if (appointments !== null) {
            for (let i = 0; i < appointments.length; i++) {
                //"09-11-2019 11:30:07",

                let evnetStartDate = appointments[i].eventStartTime;
                let appStartDate = evnetStartDate.split(' ');

                let evnetStartDate1 = appStartDate[0].split('/')
                let evnetStartDate2 = appStartDate[1] === undefined ? '8:25:30' : appStartDate[1].split(':')

                let evnetEndDate = appointments[i].eventEndTime;
                let appEndDate = evnetEndDate.split(' ');

                let evnetEndDate1 = appEndDate[0].split('/')
                let evnetEndDate2 = appEndDate[1] === undefined ? '12:25:30' : appEndDate[1].split(':')

                let Estring = appointments[i].eventDescription + " at location : " + appointments[i].eventLocation

                let Event = {
                    id: i,
                    //   title: "Coming Soon",
                    //appointments[i].eventTitle + " - " + appointments[i].eventDescription,
                    start: new Date(evnetStartDate1[2], evnetStartDate1[1] - 1, evnetStartDate1[0], evnetStartDate2[0], evnetStartDate2[1], evnetStartDate2[2]),
                    end: new Date(evnetEndDate1[2], evnetEndDate1[1] - 1, evnetEndDate1[0], evnetEndDate2[0], evnetEndDate2[1], evnetEndDate2[2]),
                    allDay: false,
                    desc: appointments[i].eventDescription
                };
                events.push(Event);
            }


            // this.state = {
            //     cal_events: events
            // };

        }
    }

    render() {
        const ColoredDateCellWrapper = ({ children }) =>
            React.cloneElement(React.Children.only(children), {
                style: {
                    backgroundColor: 'lightblue',
                },
            })

        const getCustomToolbar = (toolbar) => {

            this.toolbarDate = toolbar.date;
            const goToDayView = () => {
                //toolbar.onView("day");
            }
            const goToWeekView = () => {
                //toolbar.onView("week");
            }
            const goToMonthView = () => {
                // toolbar.onView("month");
            }
            const goToAgendaView = () => {
                // toolbar.onView("agenda");
            }
            const goToBack = () => {
                toolbar.date.setMonth(toolbar.date.getMonth() - 1);
                toolbar.onNavigate('prev');

            }
            const goToNext = () => {
                toolbar.date.setMonth(toolbar.date.getMonth() + 1);
                toolbar.onNavigate('next');
            }


            const label = () => {
                const date = moment(toolbar.date);
                return (
                    <span><b>{date.format('MMMM')}</b><span> {date.format('YYYY')}</span></span>
                );
            };


            return (
                <div className="toolbar-container">
                    <div className="navigation-buttons">
                        <button className="btn btn-back btn-event pull-left" onClick={goToBack}>
                            {"<<"}
                        </button>

                        <label className='label-date'><b>{label()}</b></label>
                        <button className="btn btn-next btn-event  pull-right" onClick={goToNext}>
                            {">>"}
                        </button>
                    </div>
                    <div className="filter-container text-center">
                        {/* <button className="btn-event" onClick={goToMonthView}>
                            <span className="label-filter-off">Month</span>
                        </button> */}
                        {/* <button className="btn-event" onClick={goToWeekView}>
                            <span className="label-filter-off">Week</span>
                        </button>
                        <button className="btn-event rbc-active" onClick={goToDayView}>
                            <span className="label-filter-off">Day</span>
                        </button>
                        <button className="btn-event " onClick={goToAgendaView}>
                            <span className="label-filter-off">Agenda</span>
                        </button> */}
                    </div>

                </div >
            )
        }

        return (
            <React.Fragment>
                <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                    <h3 className="learner-title">Events / Training Calendar</h3>
                    <div className="calendar-event">
                        <Calendar
                            localizer={localizer}
                            //   showMultiDayTimes
                            events={[]}
                            startAccessor="start"
                            endAccessor="end"
                            components={{
                                timeSlotWrapper: ColoredDateCellWrapper,
                                toolbar: getCustomToolbar,
                            }}
                            style={{ minHeight: 350 }}
                        />
                    </div>
                </div>

            </React.Fragment>
        )
    }

}

export default MyCalander;


// localizer={localizer}
                        // events={[
                        //     {
                        //         title: "My event",
                        //         allDay: true,
                        //         start: new Date(2018, 0, 1, 10, 0), // 10.00 AM
                        //         end: new Date(2018, 0, 1, 14, 0) // 2.00 PM
                        //     }
                        // ]}
                        // step={5}
                        // timeslots={3}
                        // view="month"
                        // views={["month"]}
                        // min={new Date(2008, 0, 1, 8, 0)} // 8.00 AM
                        // max={new Date(2008, 0, 1, 17, 0)} // Max will be 6.00 PM!
                        // date={new Date(2018, 0, 1)}
