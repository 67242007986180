import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
import saveAs from 'save-as'
import { ReattemptTestService } from "../../services/reattemptTest.service"
import JSZipUtils from 'jszip-utils'
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select, { createFilter } from 'react-select';
import { success, successNotification, error, errorNotification, warning, warningNotification } from "../notification/notifications";
import jsPDF from "jspdf";
import { ExcelService } from "../Excel/excel";
import Modal from "react-responsive-modal";
import "jspdf-autotable";
import * as moment from "moment";
import DatePicker from "react-datepicker";
import DashboardHeader from "../../components/dashboardheader/dashboardheader";
import { CSVLink, CSVDownload } from "react-csv";
import "react-datepicker/dist/react-datepicker.css";
import { LookUpService } from "../../services/lookupService";
import { RegisterUserService } from "../../services/registration.service";
import { Global_var } from "../../global/global_var";
import { RegionManagementService } from "../../services/regionManagement.service";
import { LoginService } from "../../services/login.service";
import { fetchDistributor } from "../../services/distributor.service";
var JSZip = require("jszip");

class MandateReport extends Component {
    constructor(props) {
        super(props);
        if (localStorage.getItem('login') !== null) {
            this.state = {
                loading: false,
                selected: {},
                selectAll: 0,
                data: [],
                AmcList: [],
                exportData: [],
                listOfYear1: [],
                listOfMonth: [],
                listOfDay: [],
                jobData: [],
                CompanyList:[],
                MandateList:[],
            }

        }
        else {
            window.location.href = '/';
        }
    }
    componentDidMount() {
        LookUpService.getMandateReport(
            "fromDate=&toDate=&firstName=&lastName=&emailId=&mobileNumber=&empNo=&orderId=&mandateId=0&panNumber=&amcId=&status=",
            res => {
                this.setState({
                    jobData: res.responseListObject,
                });
            }
        );
        RegionManagementService.getCompanyList(
            res => {
                (res.responseListObject).shift()
            this.setState({
              CompanyList: res.responseListObject,
              });
            }
          );
        var year = new Date().getFullYear();
        year = year
        var listOfYear = [];
        for (var i = 0; i < 100; i++) {
            var current = year - i;
            listOfYear.push(current);

        }
        var listOfMonth = [];

        var listOfMonth = [{ "key": 1, "value": "Jan" },
        { "key": 2, "value": "Feb" },
        { "key": 3, "value": "Mar" },
        { "key": 4, "value": "Apr" },
        { "key": 5, "value": "May" },
        { "key": 6, "value": "Jun" },
        { "key": 7, "value": "Jul" },
        { "key": 8, "value": "Aug" },
        { "key": 9, "value": "Sep" },
        { "key": 10, "value": "Oct" },
        { "key": 11, "value": "Nov" },
        { "key": 12, "value": "Dec" },
        ];
        var listOfDay = [];
        for (var i = 1; i <= 31; i++) {
            listOfDay.push(i);
        }
        this.setState({ listOfMonth: listOfMonth });
        this.setState({ listOfYear1: listOfYear });
        this.setState({ listOfDay: listOfDay });

    }

    filterCaseInsensitive = ({ id, value }, row) =>
        row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true;


    exportToCSV = () => {

        let modifiedData = [];
        let data = this.state.jobData
        if (data.length > 0) {

            data.forEach((element, index) => {
                let d = element
                var data1 = {
                    "Employee No.": element.empNo,                   
                    "First Name": element.firstName,
                    "Last Name": element.lastName,
                    "PAN No": element.panNumber ? element.panNumber : element.panNo,
                    "Email ID": element.emailID ? element.emailID : element.emailId,                 
                    "Mobile Number": element.mobileNo,
                   // "Date of Birth": ((d.dob || '').toString() !== '--' ? (d.dob.split(' ')[0].split('/')[1] + "-" + d.dob.split(' ')[0].split('/')[1] + "-" + d.dob.split(' ')[0].split('/')[2]) : ''),
                   // "Registration Date": moment(element.createdDate.split(" ")[0],"DD/MM/YYYY").format("DD/MM/YYYY") ,
                    "Registration Date":  (element.createdDate !== undefined && element.createdDate !== null )? (element.createdDate.split(' ')[0].split('-')[2] + "-" + element.createdDate.split(' ')[0].split('-')[1] + "-" + element.createdDate.split(' ')[0].split('-')[0])  : '',
                    // "Aadhaar Number": element.aadharNumber,
                    "Order id": element.orderId,
                    "Company name": element.learnerCompany,
                    "Mandate Status": element.isMandateUser ? (element.isMandateUser !== '' || 0 ? "Mandate Accepted" : 'Not Registered'):'Not Registered',
                };
                modifiedData.push(data1);
            });

            ExcelService.ExcelData(modifiedData, 'MandateDetail-Report');
        }
        else {
            warning("No record found", warningNotification)
        }
    };

    exportPDF = () => {

        if (this.state.jobData.length > 0) {
            const unit = "pt";
            const size = "A4"; // Use A1, A2, A3 or A4
            const orientation = "landscape"; // portrait or landscape

            const marginLeft = 40;
            const doc = new jsPDF(orientation, unit, size);

            doc.setFontSize(15);
            let modifiedData = [];
            const title = "Mandate List";
            let data = this.state.jobData.length > 0 ? this.state.jobData : this.state.jobData;

            const headers = [["Sr. No.", "First Name", "Last Name", "Email ID","Offical Email Id", "Mobile Number",
                 /*"Date of Birth" */"Registration Date", /*"Aadhaar No",*/ "PAN No", "Mandate Status"]];
            data.forEach((element, index) => {

                modifiedData.push([
                    index + 1,
                    element.firstName,
                    element.lastName,
                    element.emailId,
                    element.secondaryEmailID,
                    element.mobileNo,
                    // element.dob,
                    // (element.dob || '').toString() !== '' ? (element.dob.split(' ')[0].split('/')[0] + "-" + element.dob.split(' ')[0].split('/')[1] + "-" + element.dob.split(' ')[0].split('/')[2]) : '',
                    element.createdDate,
                    (element.createdDate !== undefined && element.createdDate !== null) ? (element.createdDate.split(' ')[0].split('-')[2] + "-" + element.createdDate.split(' ')[0].split('-')[1] + "-" + element.createdDate.split(' ')[0].split('-')[0])  : '',
                    //(element.createdDate || '').toString() !== '' ? (element.createdDate.split(' ')[0].split('/')[0] + "-" + element.createdDate.split(' ')[0].split('/')[1] + "-" + element.createdDate.split(' ')[0].split('/')[2]) : '',
                    element.aadharNumber,
                    element.panNumber,
                    element.isMandateUser?((element.isMandateUser || '' ).toString() !== '' || 0 ? "Mandate Accepted" : 'Not Registered'): 'Not Registered',
                    
                ])

            });

            let content = {
                startY: 50,
                head: headers,
                body: modifiedData
            };

            doc.text(title, marginLeft, 40);
            doc.autoTable(content);

            let date = new Date();
            let time = new Date().toLocaleString().split(",")[1]

            let time1 = time.split(" ")[2]
            time = time.split(":")[0] + "-" + time.split(":")[1] + "-" + time1
            time = time.split(" ")

            let date1 = moment(date).format("DD-MM-YYYY")
            let title1 = "Mandate-Detail-" + date1 + "-" + time[1] + ".pdf"

            doc.save(title1)
        }
        else {
            warning("No record found", warningNotification)
        }
    }

    isValidDate = (fromDate, toDate) => {

        if (fromDate !== "") {
            let fromDate1 = fromDate.split('-')
            if (fromDate1[1] == 2 && fromDate1[2] > 29) {
                warning("Please select valid Date", warningNotification)
                return false
            } else if (fromDate1[1] == 4 || fromDate1[1] == 6 || fromDate1[1] == 9 || fromDate1[1] == 11) {
                if (fromDate1[2] > 30) {
                    warning("Please select valid Date", warningNotification)
                    return false
                } else {
                    return true
                }
            }
            else {
                return true
            }
        }

        if (toDate !== "") {
            let toDate1 = fromDate.split('-')
            if (toDate1[1] == 2 && toDate1[2] > 29) {
                warning("Please select valid Date", warningNotification)
                return false

            } else if (toDate1[1] == 4 || toDate1[1] == 6 || toDate1[1] == 9 || toDate1[1] == 11) {
                if (toDate1[2] > 30) {
                    warning("Please select valid Date", warningNotification)
                    return false
                } else {
                    return true
                }
            } else {
                return true
            }
        }
    }

    calculateDate = (fromYear, fromMonth, fromDay, toYear, toMonth, toDay) => {

        fromYear = this.state.fromYear
        fromMonth = this.state.fromMonth
        fromDay = this.state.fromDay
        toYear = this.state.toYear
        toMonth = this.state.toMonth
        toDay = this.state.toDay

        let fromDate1 = ""
        if ((fromYear !== undefined && fromYear !== "" && fromYear !== "0") && (fromMonth !== undefined && fromMonth !== "" && fromMonth !== "0") && (fromDay !== undefined && fromDay !== "" && fromDay !== "0")) {
            fromDate1 = fromYear + "-" + fromMonth + "-" + fromDay
            let flag = this.isValidDate(fromDate1, "")
            this.setState({
                fromDate: fromDate1
            })
        } else {
            fromDate1 = ""
        }

        let toDate1 = ""
        if ((toYear !== undefined && toYear !== "" && toYear !== "0") && (toMonth !== undefined && toMonth !== "" && toMonth !== "0") && (toDay !== undefined && toDay !== "" && toDay !== "0")) {
            toDate1 = toYear + "-" + toMonth + "-" + toDay
            let flag = this.isValidDate("", toDate1)
            this.setState({
                toDate: toDate1
            })
        } else {
            toDate1 = ""
        }



        if (fromDate1 != "" && toDate1 != "") {
            if (fromDate1 > toDate1) {
                return false
            } else {
                return true
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <DashboardHeader

                    {...this.props}

                />
                <PopUpPortal
                    HidePopup={this.state.loading}
                    IsVisible={this.state.loading}
                />

                <section class="dashboard-tab user-tab pt-50 mb-50">
                    <div class="padding-rl-50">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-xs-12">

                                    <Formik
                                        enableReinitialize={false}
                                        initialValues={{
                                            status: '',
                                            firstName: '',
                                            lastName: '',
                                            mobileNumber: '',
                                            empNo: '',
                                            orderId: '',
                                            fromDate: "",
                                            toDate: "",
                                            fromDate1: "",
                                            toDate1: "",
                                            emailId: '',
                                            panNumber: '',
                                            parameter: '',
                                            listOfMonth: this.state.listOfMonth,
                                            listOfDay: this.state.listOfDay,
                                            listOfMonth1: this.state.listOfMonth,
                                            listOfDay1: this.state.listOfDay,
                                            day: '',
                                            month: '',
                                            year: '',
                                            today: '',
                                            tomonth: '',
                                            toyear: '',
                                            companyList: "",
                                            mandateStatus:''
                                        }}
                                        validationSchema={Yup.object().shape({
                                            //static validations
                                            firstName: Yup.string()
                                                .matches(/^[a-zA-Z ]{1,180}$/, "you must specify text"),
                                            emailId: Yup.string()
                                                .email('Please provide valid E-Mail'),
                                            lastName: Yup.string()
                                                .matches(/^[a-zA-Z ]{1,180}$/, "you must specify text"),
                                            panNumber: Yup.string()
                                                .matches(
                                                    /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
                                                    "Please enter the valid PAN number"
                                                ),
                                            mobileNumber: Yup.string()
                                                .matches(/^[6-9]\d{9}$/, "Please enter the valid mobile number"),
                                            empNo: Yup.string()
                                                .matches(/^[a-zA-Z0-9-]{1,10}$/, "Please enter the valid employee no")
                                                // empNo: Yup.string()
                                                // .matches(/^[0-9]\d{1,5}$/, "Please enter the valid order id")
                                        }
                                        )}

                                        onSubmit={(fields, { resetForm }) => {

                                            let fromDate1 = ""
                                            if ((fields['year'] !== undefined && fields['year'] !== "") || (fields['month'] !== undefined && fields['month'] !== "") || (fields['day'] !== undefined && fields['day'] !== "")) {
                                                fromDate1 = fields['year'] + "-" + fields['month'] + "-" + fields['day']

                                            } else {
                                                fromDate1 = ""
                                            }
                                            let toDate1 = ""
                                            if ((fields['toyear'] !== undefined && fields['toyear'] !== "") || (fields['tomonth'] !== undefined && fields['tomonth'] !== "") || (fields['today'] !== undefined && fields['today'] !== "")) {
                                                toDate1 = fields['toyear'] + "-" + fields['tomonth'] + "-" + fields['today']

                                            } else {
                                                toDate1 = ""
                                            }
                                            if (fromDate1 !== "" && toDate1 !== "") {
                                                if (new Date(fromDate1) > new Date(toDate1)) {
                                                    warning("To Date should be greater than From Date", warningNotification)
                                                    // fromDate1 = ""
                                                    // toDate1 = ""
                                                    this.setState({
                                                        fromYear: "",
                                                        fromMonth: "",
                                                        fromDay: "",
                                                        toYear: "",
                                                        toMonth: "",
                                                        toDay: "",
                                                        fromDate: "",
                                                        toDate: ""
                                                    })

                                                }
                                            }
                                            if (fields.status == "SEARCH") {
                                                debugger
                                                document.getElementById("SEARCH").disabled = true;
                                                let param = ''
                                                let isblank = false;
                                                if ((fields.firstName !== undefined || fields.firstName !== '')) {
                                                    param = param + "firstName=" + fields.firstName + '&'
                                                }
                                                if ((fields.lastName !== undefined || fields.lastName !== '')) {
                                                    param = param + "lastName=" + fields.lastName + '&'
                                                }
                                                if (fromDate1 !== undefined && fromDate1 !== "") {
                                                    param = param + "fromDate=" + fromDate1 + '&'
                                                }
                                                else {
                                                    param = param + "fromDate=" + '&'
                                                }
                                                if (toDate1 !== undefined && toDate1 !== "") {
                                                    param = param + "toDate=" + toDate1 + '&'
                                                }
                                                else {
                                                    param = param + "toDate=" + '&'
                                                }
                                                if ((fields.emailId !== undefined || fields.emailId !== '')) {
                                                    param = param + "emailId=" + fields.emailId + '&'
                                                }
                                                if ((fields.panNumber !== undefined || fields.panNumber !== '')) {
                                                    param = param + "panNumber=" + fields.panNumber + '&'
                                                }

                                                if ((fields.mobileNumber !== undefined || fields.mobileNumber !== '')) {
                                                    param = param + "mobileNumber=" + fields.mobileNumber + '&'
                                                }
                                                if ((fields.mandateStatus !== undefined || fields.mandateStatus !== '')) {
                                                    param = param + "status=" + fields.mandateStatus + '&'
                                                }
                                                if ((fields.empNo !== undefined || fields.empNo !== '')) {
                                                    param = param + "empNo=" + fields.empNo + '&'
                                                }
                                                if ((fields.orderId !== undefined || fields.orderId !== '')) {
                                                    param = param + "orderId=" + fields.orderId + '&'
                                                }
                                                if ((fields.mandateList !== '' && fields.mandateList !== undefined)) {
                                                    param = param + "mandateId=" + fields.mandateList 
                                                }else {
                                                    param = param + "mandateId=0"
                                                }
                                                 
                                                if (fields.firstName !== '' || fields.lastName !== '' || fields.panNumber !== '' || fields.emailId !== '' || toDate1 !== "" || fromDate1 !== "" || fields.mobileNumber !== '' || fields.empNo !== '' || fields.orderId !== ''|| fields.mandateStatus !== '' || (fields.companyList !== '' && fields.mandateList !== undefined)) {
                                                    LookUpService.getMandateReport(param, res => {
                                                        if (res.status === "success") {
                                                            this.setState({
                                                                jobData: res.responseListObject
                                                            })
                                                        }else{
                                                            this.setState({
                                                                jobData: []
                                                            })
                                                        }
                                                    });
                                                //     if(fields.mandateStatus === "Mandate Accepted"){
                                                //     LookUpService.getMandateReport(param, res => {
                                                //         if (res.status === "success") {
                                                //             this.setState({
                                                //                 jobData: res.responseListObject
                                                //             })
                                                //         }else{
                                                //             this.setState({
                                                //                 jobData: []
                                                //             })
                                                //         }
                                                //     });
                                                // } else if(fields.mandateStatus === "Not Registered"){
                                                //     LookUpService.notRegisteredUsers(param, res => {
                                                //         if (res.status === "success") {
                                                //             this.setState({
                                                //                 jobData: res.responseListObject
                                                //             })
                                                //         }else{
                                                //             this.setState({
                                                //                 jobData: []
                                                //             })
                                                //         }
                                                //     });
                                               
                                                // } else {
                                                //     LookUpService.getMandateReport(param, res => {
                                                //         if (res.status === "success") {
                                                //             this.setState({
                                                //                 jobData: res.responseListObject
                                                //             })
                                                //         }else{
                                                //             this.setState({
                                                //                 jobData: []
                                                //             })
                                                //         }
                                                //     });
                                                // }
                                                } 
                                                else { 
                                                   
                                                    if(fields.firstName === '' && fields.lastName === '' && fields.panNumber === '' && fields.emailId === '' && toDate1 === "" 
                                                    && fromDate1 === "" && fields.mobileNumber === '' && fields.empNo === '' && fields.orderId === '' && fields.companyList === '' && fields.mandateStatus === ''){
                                                        document.getElementById("SEARCH").disabled = false;
                                                        warning("Atleast one parameter is required to fetch the data", warningNotification)
                                                    }else{
                                                        document.getElementById("SEARCH").disabled = false;
                                                        warning("As you selected client , you also need to select the mandate to generate the report",warningNotification)
                                                    }
                                              }
                                                
                                            }
                                            else {
                                                fields.mandateList=undefined;
                                                LookUpService.getMandateReport(
                                                    "fromDate=&toDate=&firstName=&lastName=&emailId=&mobileNumber=&empNo=&orderId=&mandateId=0&panNumber=&amcId=&status=", res => {
                                                        if (res.status === "success") {
                                                            this.setState({
                                                                jobData: res.responseListObject
                                                            })
                                                        }else{
                                                            this.setState({
                                                                jobData: []
                                                            })
                                                        }
                                                    });
                                            }
                                        }}

                                        render={({
                                            errors, touched, handleSubmit, values, setFieldValue
                                        }) => (
                                                <React.Fragment>
                                                    <Form onSubmit={handleSubmit} className="form-group">
                                                        <section class="updateGDPI">
                                                            <div class="modal-body">
                                                                <div class="row">
                                                                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                                                        <div class="product-add">
                                                                            <div class="form-group">
                                                                                <div class="row">
                                                                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                                                                        <label>
                                                                                            First Name
                                        </label>
                                                                                        <Field
                                                                                            type="text"
                                                                                            name="firstName"
                                                                                            id="firstName"
                                                                                            autoComplete="firstName"
                                                                                            className={
                                                                                                "form-control" +
                                                                                                (errors.firstName &&
                                                                                                    touched.text
                                                                                                    ? " is-invalid"
                                                                                                    : "")
                                                                                            }
                                                                                            placeholder="First Name"
                                                                                            className="form-control"
                                                                                        />
                                                                                        <ErrorMessage
                                                                                            name="firstName"
                                                                                            component="div"
                                                                                            className="validation"
                                                                                        />
                                                                                    </div>
                                                                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                                                                        <label>
                                                                                            Last name
                                                                                        </label>
                                                                                        <Field
                                                                                            type="lastName"
                                                                                            name="lastName"
                                                                                            id="lastName"
                                                                                            autoComplete="lastName"
                                                                                            className={
                                                                                                "form-control" +
                                                                                                (errors.lastName &&
                                                                                                    touched.lastName
                                                                                                    ? " is-invalid"
                                                                                                    : "")
                                                                                            }
                                                                                            placeholder="Last Name"
                                                                                        />
                                                                                        <ErrorMessage
                                                                                            name="lastName"
                                                                                            component="div"
                                                                                            className="validation"
                                                                                        />
                                                                                    </div>
                                                                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                                                                        <label>
                                                                                            Email Id
                                                                                        </label>
                                                                                        <Field
                                                                                            type="emailId"
                                                                                            name="emailId"
                                                                                            id="emailId"
                                                                                            autoComplete="emailId"
                                                                                            className={
                                                                                                "form-control" +
                                                                                                (errors.emailId &&
                                                                                                    touched.emailId
                                                                                                    ? " is-invalid"
                                                                                                    : "")
                                                                                            }
                                                                                            placeholder="Email ID"
                                                                                        />
                                                                                        <ErrorMessage
                                                                                            name="emailId"
                                                                                            component="div"
                                                                                            className="validation"
                                                                                        />
                                                                                    </div>
                                                                                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12"></div>
                                                                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                                                                        <label>
                                                                                            REGISTERED From Date
                                                                                        </label>
                                                                                        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                                                                            <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 pl pr">
                                                                                                <Field
                                                                                                    as="select"
                                                                                                    name="day"
                                                                                                    id="day"
                                                                                                    onChange={
                                                                                                        e => {


                                                                                                            var listOfMonth = []
                                                                                                            setFieldValue(values.day = e.target.value)
                                                                                                            if (e.target.value == 31) {
                                                                                                                listOfMonth = [{ "key": 1, "value": "Jan" },

                                                                                                                { "key": 3, "value": "Mar" },

                                                                                                                { "key": 5, "value": "May" },

                                                                                                                { "key": 7, "value": "Jul" },
                                                                                                                { "key": 8, "value": "Aug" },

                                                                                                                { "key": 10, "value": "Oct" },

                                                                                                                { "key": 12, "value": "Dec" },
                                                                                                                ];


                                                                                                            } else if (e.target.value == 30) {
                                                                                                                listOfMonth = [

                                                                                                                    { "key": 1, "value": "Jan" },

                                                                                                                    { "key": 3, "value": "Mar" },
                                                                                                                    { "key": 4, "value": "Apr" },
                                                                                                                    { "key": 5, "value": "May" },
                                                                                                                    { "key": 6, "value": "Jun" },
                                                                                                                    { "key": 7, "value": "Jul" },
                                                                                                                    { "key": 8, "value": "Aug" },
                                                                                                                    { "key": 9, "value": "Sep" },
                                                                                                                    { "key": 10, "value": "Oct" },
                                                                                                                    { "key": 11, "value": "Nov" },
                                                                                                                    { "key": 12, "value": "Dec" },

                                                                                                                ];


                                                                                                            }
                                                                                                            else if (e.target.value == 28 || e.target.value == 29) {
                                                                                                                listOfMonth = [{ "key": 1, "value": "Jan" },
                                                                                                                { "key": 2, "value": "Feb" },
                                                                                                                { "key": 3, "value": "Mar" },
                                                                                                                { "key": 4, "value": "Apr" },
                                                                                                                { "key": 5, "value": "May" },
                                                                                                                { "key": 6, "value": "Jun" },
                                                                                                                { "key": 7, "value": "Jul" },
                                                                                                                { "key": 8, "value": "Aug" },
                                                                                                                { "key": 9, "value": "Sep" },
                                                                                                                { "key": 10, "value": "Oct" },
                                                                                                                { "key": 11, "value": "Nov" },
                                                                                                                { "key": 12, "value": "Dec" },
                                                                                                                ];
                                                                                                            } else {
                                                                                                                listOfMonth = [{ "key": 1, "value": "Jan" },
                                                                                                                { "key": 2, "value": "Feb" },
                                                                                                                { "key": 3, "value": "Mar" },
                                                                                                                { "key": 4, "value": "Apr" },
                                                                                                                { "key": 5, "value": "May" },
                                                                                                                { "key": 6, "value": "Jun" },
                                                                                                                { "key": 7, "value": "Jul" },
                                                                                                                { "key": 8, "value": "Aug" },
                                                                                                                { "key": 9, "value": "Sep" },
                                                                                                                { "key": 10, "value": "Oct" },
                                                                                                                { "key": 11, "value": "Nov" },
                                                                                                                { "key": 12, "value": "Dec" },
                                                                                                                ];

                                                                                                            }
                                                                                                            setFieldValue(values.listOfMonth = listOfMonth)

                                                                                                        }}

                                                                                                    className={
                                                                                                        "form-control" +
                                                                                                        (errors.day && touched.day
                                                                                                            ? "is-invalid"
                                                                                                            : "")
                                                                                                    }
                                                                                                    className="form-control"
                                                                                                >
                                                                                                    <option value="">DD</option>
                                                                                                    {(
                                                                                                        this.state.listOfDay ||
                                                                                                        []
                                                                                                    ).map(d => (
                                                                                                        <option
                                                                                                            value={d}
                                                                                                        >
                                                                                                            {d}
                                                                                                        </option>
                                                                                                    ))}
                                                                                                </Field>
                                                                                            </div>
                                                                                            <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 pl pr">
                                                                                                <Field
                                                                                                    as="select"
                                                                                                    name="month"
                                                                                                    id="month"
                                                                                                    onChange={
                                                                                                        e => {


                                                                                                            setFieldValue(values.month = e.target.value)
                                                                                                            if (e.target.value == 2) {
                                                                                                                var listOfDay = [];
                                                                                                                for (var i = 1; i <= 29; i++) {
                                                                                                                    listOfDay.push(i);
                                                                                                                }
                                                                                                            } else if (e.target.value == 1 || e.target.value == 3 || e.target.value == 5 || e.target.value == 7 || e.target.value == 8 || e.target.value == 10 || e.target.value == 12) {
                                                                                                                var listOfDay = [];
                                                                                                                for (var i = 1; i <= 31; i++) {
                                                                                                                    //    var current = year - i;
                                                                                                                    listOfDay.push(i);
                                                                                                                    //  this.setState({ listOfDay: listOfDay });
                                                                                                                }
                                                                                                            }
                                                                                                            else {
                                                                                                                var listOfDay = [];
                                                                                                                for (var i = 1; i <= 30; i++) {
                                                                                                                    //    var current = year - i;
                                                                                                                    listOfDay.push(i);
                                                                                                                    //  this.setState({ listOfDay: listOfDay });
                                                                                                                }
                                                                                                            }

                                                                                                            setFieldValue(values.listOfDay = listOfDay)
                                                                                                        }
                                                                                                    }
                                                                                                    className={
                                                                                                        "form-control" +
                                                                                                        (errors.month && touched.month
                                                                                                            ? "is-invalid"
                                                                                                            : "")
                                                                                                    }
                                                                                                    className="form-control"
                                                                                                >
                                                                                                    <option value="">MM</option>
                                                                                                    {(
                                                                                                        this.state.listOfMonth ||
                                                                                                        []
                                                                                                    ).map(m => (
                                                                                                        <option
                                                                                                            value={m.key}
                                                                                                        >
                                                                                                            {m.value}
                                                                                                        </option>
                                                                                                    ))}
                                                                                                </Field>
                                                                                            </div>
                                                                                            <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5 pl pr">
                                                                                                <Field
                                                                                                    as="select"
                                                                                                    name="year"
                                                                                                    id="year"
                                                                                                    onChange={
                                                                                                        e => {


                                                                                                            setFieldValue(values.year = e.target.value)
                                                                                                        }}
                                                                                                    className={
                                                                                                        "form-control" +
                                                                                                        (errors.year && touched.year
                                                                                                            ? "is-invalid"
                                                                                                            : "")
                                                                                                    }
                                                                                                    className="form-control"
                                                                                                >
                                                                                                    <option value="">YYYY</option>
                                                                                                    {(
                                                                                                        this.state.listOfYear1 ||
                                                                                                        []
                                                                                                    ).map(year => (
                                                                                                        <option
                                                                                                            value={year}
                                                                                                        >
                                                                                                            {year}
                                                                                                        </option>
                                                                                                    ))}
                                                                                                </Field>
                                                                                            </div>
                                                                                        </div>



                                                                                        <p className="validation">  {values.errmsg}</p>
                                                                                        {errors.fromDate && <div className="validation">{errors.fromDate}</div>}
                                                                                    </div>
                                                                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                                                                        <label>
                                                                                            REGISTERED To Date
                                                                                        </label>

                                                                                        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                                                                            <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 pl pr">
                                                                                                <Field
                                                                                                    as="select"
                                                                                                    name="today"
                                                                                                    id="today"
                                                                                                    // onChange={handleChange}
                                                                                                    //  disabled={this.state.isDisabled}
                                                                                                    //  disabled
                                                                                                    onChange={
                                                                                                        e => {


                                                                                                            var listOfMonth = []
                                                                                                            setFieldValue(values.today = e.target.value)
                                                                                                            if (e.target.value == 31) {
                                                                                                                listOfMonth = [{ "key": 1, "value": "Jan" },

                                                                                                                { "key": 3, "value": "Mar" },

                                                                                                                { "key": 5, "value": "May" },

                                                                                                                { "key": 7, "value": "Jul" },
                                                                                                                { "key": 8, "value": "Aug" },

                                                                                                                { "key": 10, "value": "Oct" },

                                                                                                                { "key": 12, "value": "Dec" },
                                                                                                                ];


                                                                                                            } else if (e.target.value == 30) {
                                                                                                                listOfMonth = [

                                                                                                                    { "key": 1, "value": "Jan" },

                                                                                                                    { "key": 3, "value": "Mar" },
                                                                                                                    { "key": 4, "value": "Apr" },
                                                                                                                    { "key": 5, "value": "May" },
                                                                                                                    { "key": 6, "value": "Jun" },
                                                                                                                    { "key": 7, "value": "Jul" },
                                                                                                                    { "key": 8, "value": "Aug" },
                                                                                                                    { "key": 9, "value": "Sep" },
                                                                                                                    { "key": 10, "value": "Oct" },
                                                                                                                    { "key": 11, "value": "Nov" },
                                                                                                                    { "key": 12, "value": "Dec" },

                                                                                                                ];


                                                                                                            }
                                                                                                            else if (e.target.value == 28 || e.target.value == 29) {
                                                                                                                listOfMonth = [{ "key": 1, "value": "Jan" },
                                                                                                                { "key": 2, "value": "Feb" },
                                                                                                                { "key": 3, "value": "Mar" },
                                                                                                                { "key": 4, "value": "Apr" },
                                                                                                                { "key": 5, "value": "May" },
                                                                                                                { "key": 6, "value": "Jun" },
                                                                                                                { "key": 7, "value": "Jul" },
                                                                                                                { "key": 8, "value": "Aug" },
                                                                                                                { "key": 9, "value": "Sep" },
                                                                                                                { "key": 10, "value": "Oct" },
                                                                                                                { "key": 11, "value": "Nov" },
                                                                                                                { "key": 12, "value": "Dec" },
                                                                                                                ];
                                                                                                            } else {
                                                                                                                listOfMonth = [{ "key": 1, "value": "Jan" },
                                                                                                                { "key": 2, "value": "Feb" },
                                                                                                                { "key": 3, "value": "Mar" },
                                                                                                                { "key": 4, "value": "Apr" },
                                                                                                                { "key": 5, "value": "May" },
                                                                                                                { "key": 6, "value": "Jun" },
                                                                                                                { "key": 7, "value": "Jul" },
                                                                                                                { "key": 8, "value": "Aug" },
                                                                                                                { "key": 9, "value": "Sep" },
                                                                                                                { "key": 10, "value": "Oct" },
                                                                                                                { "key": 11, "value": "Nov" },
                                                                                                                { "key": 12, "value": "Dec" },
                                                                                                                ];

                                                                                                            }

                                                                                                            setFieldValue(values.listOfMonth1 = listOfMonth)
                                                                                                            // this.setState({
                                                                                                            //   listOfMonth: listOfMonth
                                                                                                            // })
                                                                                                        }}

                                                                                                    className={
                                                                                                        "form-control" +
                                                                                                        (errors.today && touched.today
                                                                                                            ? "is-invalid"
                                                                                                            : "")
                                                                                                    }
                                                                                                    className="form-control"
                                                                                                >
                                                                                                    <option value="">DD</option>
                                                                                                    {(
                                                                                                        this.state.listOfDay ||
                                                                                                        []
                                                                                                    ).map(d => (
                                                                                                        <option
                                                                                                            value={d}
                                                                                                        >
                                                                                                            {d}
                                                                                                        </option>
                                                                                                    ))}
                                                                                                </Field>
                                                                                            </div>
                                                                                            <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 pl pr">
                                                                                                <Field
                                                                                                    as="select"
                                                                                                    name="tomonth"
                                                                                                    id="tomonth"
                                                                                                    //   onChange={handleChange}
                                                                                                    //     disabled={this.state.isDisabled}
                                                                                                    //  disabled
                                                                                                    onChange={
                                                                                                        e => {


                                                                                                            setFieldValue(values.tomonth = e.target.value)
                                                                                                            if (e.target.value == 2) {
                                                                                                                var listOfDay = [];
                                                                                                                for (var i = 1; i <= 29; i++) {
                                                                                                                    //    var current = year - i;
                                                                                                                    listOfDay.push(i);
                                                                                                                    // this.setState({ listOfDay: listOfDay });
                                                                                                                }
                                                                                                            } else if (e.target.value == 1 || e.target.value == 3 || e.target.value == 5 || e.target.value == 7 || e.target.value == 8 || e.target.value == 10 || e.target.value == 12) {
                                                                                                                var listOfDay = [];
                                                                                                                for (var i = 1; i <= 31; i++) {
                                                                                                                    //    var current = year - i;
                                                                                                                    listOfDay.push(i);
                                                                                                                    //  this.setState({ listOfDay: listOfDay });
                                                                                                                }
                                                                                                            }
                                                                                                            else {
                                                                                                                var listOfDay = [];
                                                                                                                for (var i = 1; i <= 30; i++) {
                                                                                                                    //    var current = year - i;
                                                                                                                    listOfDay.push(i);
                                                                                                                    //  this.setState({ listOfDay: listOfDay });
                                                                                                                }
                                                                                                            }
                                                                                                            setFieldValue(values.listOfDay1 = listOfDay)
                                                                                                        }
                                                                                                    }
                                                                                                    className={
                                                                                                        "form-control" +
                                                                                                        (errors.tomonth && touched.tomonth
                                                                                                            ? "is-invalid"
                                                                                                            : "")
                                                                                                    }
                                                                                                    className="form-control"
                                                                                                >
                                                                                                    <option value="">MM</option>
                                                                                                    {(
                                                                                                        this.state.listOfMonth ||
                                                                                                        []
                                                                                                    ).map(m => (
                                                                                                        <option
                                                                                                            value={m.key}
                                                                                                        >
                                                                                                            {m.value}
                                                                                                        </option>
                                                                                                    ))}
                                                                                                </Field>
                                                                                            </div>
                                                                                            <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5 pl pr">
                                                                                                <Field
                                                                                                    as="select"
                                                                                                    name="toyear"
                                                                                                    id="toyear"
                                                                                                    onChange={
                                                                                                        e => {


                                                                                                            setFieldValue(values.toyear = e.target.value)
                                                                                                        }}
                                                                                                    className={
                                                                                                        "form-control" +
                                                                                                        (errors.toyear && touched.toyear
                                                                                                            ? "is-invalid"
                                                                                                            : "")
                                                                                                    }
                                                                                                    className="form-control"
                                                                                                >
                                                                                                    <option value="">YYYY</option>
                                                                                                    {(
                                                                                                        this.state.listOfYear1 ||
                                                                                                        []
                                                                                                    ).map(year => (
                                                                                                        <option
                                                                                                            value={year}
                                                                                                        >
                                                                                                            {year}
                                                                                                        </option>
                                                                                                    ))}
                                                                                                </Field>
                                                                                            </div>
                                                                                        </div>

                                                                                        <p className="validation">  {values.errmsg1}</p>

                                                                                        {errors.toDate && <div className="validation">{errors.toDate}</div>}

                                                                                    </div>

                                                                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                                                                        <label>
                                                                                            PAN NO
                                                                                         </label>
                                                                                        <Field
                                                                                            type="text"
                                                                                            name="panNumber"
                                                                                            id="panNumber"
                                                                                            autoComplete="panNumber"
                                                                                            className={
                                                                                                "form-control" +
                                                                                                (errors.panNumber &&
                                                                                                    touched.panNumber
                                                                                                    ? " is-invalid"
                                                                                                    : "")
                                                                                            }
                                                                                            placeholder="PAN No"
                                                                                            style={{ textTransform: "uppercase" }}
                                                                                        />
                                                                                        <ErrorMessage
                                                                                            name="panNumber"
                                                                                            component="div"
                                                                                            className="validation"
                                                                                        />
                                                                                    </div>
                                                                                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12"></div>

                                                                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                                                                        <label>
                                                                                            Mobile Number
                                                                                             </label>
                                                                                        <Field
                                                                                            type="text"
                                                                                            name="mobileNumber"
                                                                                            id="mobileNumber"
                                                                                            autoComplete="mobileNumber"
                                                                                            className={
                                                                                                "form-control" +
                                                                                                (errors.mobileNumber &&
                                                                                                    touched.text
                                                                                                    ? " is-invalid"
                                                                                                    : "")
                                                                                            }
                                                                                            placeholder="Mobile Number"
                                                                                            className="form-control"
                                                                                        />
                                                                                        <ErrorMessage
                                                                                            name="mobileNumber"
                                                                                            component="div"
                                                                                            className="validation"
                                                                                        />
                                                                                    </div>
                                                                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                                                                        <label>
                                                                                            EMPLOYEE NO
                                                                                             </label>
                                                                                        <Field
                                                                                            type="text"
                                                                                            name="empNo"
                                                                                            id="empNo"
                                                                                            autoComplete="empNo"
                                                                                            className={
                                                                                                "form-control" +
                                                                                                (errors.empNo &&
                                                                                                    touched.text
                                                                                                    ? " is-invalid"
                                                                                                    : "")
                                                                                            }
                                                                                            placeholder="Employee No"
                                                                                            className="form-control"
                                                                                        />
                                                                                        <ErrorMessage
                                                                                            name="empNo"
                                                                                            component="div"
                                                                                            className="validation"
                                                                                        />
                                                                                    </div>
                                                                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                                                                        <label>
                                                                                            ORDER ID
                                                                                             </label>
                                                                                        <Field
                                                                                            type="text"
                                                                                            name="orderId"
                                                                                            id="orderId"
                                                                                            autoComplete="orderId"
                                                                                            className={
                                                                                                "form-control" +
                                                                                                (errors.orderId &&
                                                                                                    touched.text
                                                                                                    ? " is-invalid"
                                                                                                    : "")
                                                                                            }
                                                                                            placeholder="Order Id"
                                                                                            className="form-control"
                                                                                        />
                                                                                        {/* <ErrorMessage
                                                                                            name="orderId"
                                                                                            component="div"
                                                                                            className="validation"
                                                                                        /> */}
                                                                                    </div>
                                                                                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12"></div>

                                                                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                                                                        <label>
                                                                                            Client
                                                                                        </label>
                                                                                        <Field
                                                                                            as="select"
                                                                                            name="companyList"
                                                                                            id="companyList"
                                                                                            autoComplete="companyList"
                                                                                            className={
                                                                                                "form-control" +
                                                                                                (errors.companyList &&
                                                                                                    touched.companyList
                                                                                                    ? " is-invalid"
                                                                                                    : "")
                                                                                            }
                                                                                            placeholder="companyList"
                                                                                            onChange={event => {
                                                                                                LoginService.TokenUser(res => {
                                                                                                    localStorage.setItem(
                                                                                                        "jwt-token",
                                                                                                        res.responseObject
                                                                                                    );
                                                                                                });
                                                                                                if (
                                                                                                    event.target.value !== undefined
                                                                                                ) {
                                                                                                    setFieldValue(
                                                                                                        (values.companyList = event.target.value)
                                                                                                    )
                                                                                                    RegionManagementService.getCompanyMandateList(
                                                                                                        "companyId=" + event.target.value,
                                                                                                        res => {
                                                                                                            if (res.responseListObject !== null) {
                                                                                                                this.setState({
                                                                                                                    MandateList: res.responseListObject,
                                                                                                                });
                                                                                                            } else {
                                                                                                                this.setState({
                                                                                                                    MandateList: [],
                                                                                                                });
                                                                                                            }
                                                                                                        }
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                        >

                                                                                            <option value="">--Select--</option>
                                                                                            {this.state.CompanyList.length > 0 && this.state.CompanyList.map(team =>

                                                                                                <option
                                                                                                    key={team.companyId}
                                                                                                    value={team.companyId}
                                                                                                >
                                                                                                    {team.companyName}
                                                                                                </option>
                                                                                            )}

                                                                                        </Field>

                                                                                    </div>
                                                                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                                                                        <label>
                                                                                            Mandate
                                                                                        </label>
                                                                                        <Field
                                                                                            as="select"
                                                                                            name="mandateList"
                                                                                            id="mandateList"
                                                                                            autoComplete="mandateList"
                                                                                            className={
                                                                                                "form-control" +
                                                                                                (errors.mandateList &&
                                                                                                    touched.mandateList
                                                                                                    ? " is-invalid"
                                                                                                    : "")
                                                                                            }
                                                                                            placeholder="mandateList"

                                                                                        >

                                                                                            <option value="">--Select--</option>
                                                                                            {this.state.MandateList.length > 0 && this.state.MandateList.map(team =>

                                                                                                <option
                                                                                                    key={team.mandateId}
                                                                                                    value={team.mandateId}
                                                                                                >
                                                                                                    {team.mandateName}
                                                                                                </option>
                                                                                            )}
                                                                                        </Field>

                                                                                    </div>
                                                                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                                                                        <label>
                                                                                            Mandate Status
                                                                                        </label>
                                                                                        <Field
                                                                                            as="select"
                                                                                            name="mandateStatus"
                                                                                            id="mandateStatus"
                                                                                            disabled={values.disabledForCielops}
                                                                                            className={
                                                                                                "form-control" +
                                                                                                (errors.mandateStatus && touched.mandateStatus
                                                                                                    ? " is-invalid"
                                                                                                    : "")
                                                                                            }
                                                                                        >
                                                                                            <option value="">--Select--</option>
                                                                                            <option value="Mandate Accepted">Mandate Accepted</option>
                                                                                            <option value="Not Registered">Not Registered</option>
                                                                                        </Field>
                                                                                    </div>

                                                                                    {/* :""} */}
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr mb-20">
                                                                                <button
                                                                                    type="submit"
                                                                                    class="btn-4 pull-right"
                                                                                    id="SEARCH"
                                                                                    onClick={async () => {
                                                                                        setFieldValue(
                                                                                            (values.status = 'SEARCH')
                                                                                        );
                                                                                    }}
                                                                                >Search</button>
                                                                                <button
                                                                                    type="submit"
                                                                                    id="close"
                                                                                    class="btn-4 pull-right"
                                                                                    onClick={async () => {
                                                                                        setFieldValue(
                                                                                            (values.status = 'RESET')
                                                                                        );
                                                                                        setFieldValue(
                                                                                            (values.mobileNumber = '')
                                                                                        );
                                                                                        setFieldValue(
                                                                                            (values.empNo = '')
                                                                                        );
                                                                                        setFieldValue(
                                                                                            (values.orderId = '')
                                                                                        );
                                                                                        setFieldValue(
                                                                                            (values.firstName = '')
                                                                                        );
                                                                                        setFieldValue(
                                                                                            (values.lastName = '')
                                                                                        );
                                                                                        setFieldValue(
                                                                                            (values.fromDate = '')
                                                                                        ); setFieldValue(
                                                                                            (values.toDate = '')
                                                                                        );
                                                                                        setFieldValue(
                                                                                            (values.emailId = '')
                                                                                        ); setFieldValue(
                                                                                            (values.panNumber = '')
                                                                                        );

                                                                                        setFieldValue(
                                                                                            (values.city = '')
                                                                                        );

                                                                                        setFieldValue(
                                                                                            (values.amcList = '')
                                                                                        );



                                                                                        setFieldValue(
                                                                                            (values.day = "")
                                                                                        );
                                                                                        setFieldValue(
                                                                                            (values.month = "")
                                                                                        );
                                                                                        setFieldValue(
                                                                                            (values.year = "")
                                                                                        );


                                                                                        setFieldValue(
                                                                                            (values.today = "")
                                                                                        );
                                                                                        setFieldValue(
                                                                                            (values.tomonth = "")
                                                                                        );
                                                                                        setFieldValue(
                                                                                            (values.toyear = "")
                                                                                        );
                                                                                        this.setState({
                                                                                            MandateList: [],
                                                                                        });
                                                                                        setFieldValue(
                                                                                            (values.companyList = '')
                                                                                        );
                                                                                        setFieldValue(
                                                                                            (values.mandateList = '')
                                                                                        );
                                                                                        setFieldValue(
                                                                                            (values.mandateStatus = '')
                                                                                        );
                                                                                        document.getElementById("SEARCH").disabled = false;
                                                                                    }}
                                                                                >
                                                                                    Reset
                                                                                 </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                    </Form>
                                                </React.Fragment>
                                            )}
                                    />

                                    <div class="table-header">


                                        <h3>
                                            <div class="row">
                                                <div class="col-md-6 col-sm-6 col-xs-12">
                                                    <span class="left-icon">
                                                        <i class="fa fa-list" aria-hidden="true"></i>
                                                    </span>{" "}
                                                    Mandate Details
                                                </div>
                                                <div class="col-md-6 col-sm-6 col-xs-12">
                                                    <span class="right-icon">

                                                        <a title="Export to Excel" >
                                                            <i
                                                                onClick={() => this.exportToCSV()}
                                                                class="fa fa-file-excel-o"
                                                                aria-hidden="true"

                                                            ></i>
                                                        </a>
                                                    </span>
                                                    {/* <span class="right-icon">
                                                        <a title="Export to PDF">
                                                            <i
                                                                onClick={() => this.exportPDF()}
                                                                class="fa fa-file-pdf-o"
                                                                aria-hidden="true"
                                                            ></i>
                                                        </a>
                                                    </span> */}
                                                </div>
                                            </div>
                                        </h3>
                                    </div>

                                    <div class="bg-box mb-30">
                                        <div class="table-responsive">

                                            <ReactTable
                                                minRows={2}
                                                data={this.state.jobData === null ? [] : this.state.jobData}


                                                className="-striped -highlight"
                                                filterable={false}
                                                onPageChange={this.stateonpagechange}
                                                defaultFilterMethod={this.filterCaseInsensitive}
                                                ref={(refReactTable) => { this.refReactTable = refReactTable; }}

                                                columns={[
                                                    {
                                                        columns: [

                                                            {
                                                                Header: "Sr No",
                                                                id: "row",
                                                                width: 50,
                                                                Cell: (row) => {
                                                                    return <div>{row.index + 1}</div>;
                                                                }
                                                            },
                                                            {
                                                                Header: "Employee No",
                                                                id: "empNo",
                                                                accessor: d => d.empNo,

                                                            },
                                                           
                                                            {
                                                                Header: "First Name",
                                                                id: "firstName",
                                                                accessor: d => d.firstName,

                                                            },
                                                            {
                                                                Header: "Last Name",
                                                                id: "lastName",
                                                                accessor: d => d.lastName,

                                                            },
                                                            {
                                                                Header: "PAN No",
                                                                id: "pan",
                                                                accessor: d => d.panNumber ? d.panNumber : d.panNo
                                                            },

                                                            {
                                                                Header: "Email Id",
                                                                id: "email",
                                                                accessor: d => d.emailID ? d.emailID : d.emailId,

                                                            },
                                                            
                                                            {
                                                                Header: "Mobile No.",
                                                                id: "MobNo",
                                                                accessor: d => d.mobileNo,

                                                            },


                                                            // {
                                                            //     Header: "Date of Birth",
                                                            //     id: "dob",
                                                            //     accessor: (d) =>
                                                            //         (d.dob || '').toString() !== '--' ? (d.dob.split(' ')[0].split('/')[0] + "-" + d.dob.split(' ')[0].split('/')[1] + "-" + d.dob.split(' ')[0].split('/')[2]) : '',
                                                            // },
                                                            {
                                                                Header: "Registration Date",
                                                                id: "createdDate",
                                                                accessor: (d) =>
                                                               // d.isMandateUser   y m d
                                                               (d.createdDate !== undefined && d.createdDate !== null) ? (d.createdDate.split(' ')[0].split('-')[2] + "-" + d.createdDate.split(' ')[0].split('-')[1] + "-" + d.createdDate.split(' ')[0].split('-')[0])  : '',
                                                                   // (d.createdDate || '').toString() !== '--' ? (d.createdDate.split(' ')[0].split('/')[0] + "-" + d.createdDate.split(' ')[0].split('/')[1] + "-" + d.createdDate.split(' ')[0].split('/')[2]) : '',
                                                            },
                                                            // {
                                                            //     Header: "Aadhaar Number",
                                                            //     id: "aadharNumber",
                                                            //     accessor: (d) =>
                                                            //         d.aadharNumber,
                                                            // },
                                                            {
                                                                Header: "Company name",
                                                                id: "learnerCompany",
                                                                accessor: d => d.learnerCompany,
                                                            },
                                                            {
                                                                Header: "Order Id",
                                                                id: "orderId",
                                                                accessor: d => d.orderId !== undefined ? d.orderId : "",
                                                            },                                                            
                                                            
                                                            {
                                                                Header: "Mandate Status",
                                                                id: "mandateId",
                                                                accessor: d =>d.isMandateUser ? (d.isMandateUser !== '' || 0 ? "Mandate Accepted" : 'Not Registered'):'Not Registered',
                                                            },
                                                        ]
                                                    }
                                                ]}
                                                defaultPageSize={10}
                                                style={{
                                                    width: "100%",
                                                    maxHeight: "500px"
                                                }}
                                            />


                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>


            </React.Fragment>
        );

    }
}

export default MandateReport;
