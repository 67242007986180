// Library Imports
import React, { Component } from "react";
import $ from "jquery";
import ReactHTMLParser from "react-html-parser";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as moment from "moment";
import { Formik, Form, Field,ErrorMessage } from "formik";
import * as Yup from "yup";
// Service Imports
import { LoginService } from "../../services/login.service";
import { FetchNotificationService } from "../../services/fetchnotification";
import { FeedbackService } from '../../services/feedback.service';
// Reducer Imports
import { getUserRoleAcess, getUserRoleAcessError, getUserRoleAcessPending } from "../../reducer/roleAccessReducer";
// Other Imports
import { warningNotification, warning, error, errorNotification, success, successNotification } from '../../components/notification/notifications';
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
// Asset Imports
const Finxlogo = require('../../assets/images/dapIcon.png');

//! Unused Imports
// import Finxlogo from "../../assets/images/dapIcon.png";
// import Finxlogo from "../../assets/images/course_logo.png";
// import { fetchUserRoleAccess } from '../../services/userAccess.service';
// import Cfxroadmap from "../../containers/cfxroadmap/cfxroadmap";
// import LandingPage from "../../containers/landingPage/landingPage";

const mapStateToProps = state => ({
  userRoleAccess: getUserRoleAcess(state.roleAccessReducer),
  pendingUserRoleAccess: getUserRoleAcessPending(state.roleAccessReducer),
  errorUserRoleAccess: getUserRoleAcessError(state.roleAccessReducer),

});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      //fetchUserRoleAccess: fetchUserRoleAccess
    }, dispatch)

const connectFunction = connect(mapStateToProps, mapDispatchToProps);

const DashboardHeader = connectFunction(

  class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        alertcount: "",
        notificationcount: "",
        alertNotificationEvents: JSON.parse(localStorage.getItem('AdminAlertNotification')),
        //notificationEvents: JSON.parse(localStorage.getItem('AdminNotification')),
        inboxCount:"",
        emailid: "",
        partyid : "",
        rank:0,
        feedbackMsg:"",
        loading: false,
        loading1:false,
        loading2:false,
        notificationEvents: [],    
        saveFeedbackBtnDisabled: false,
        typeOfConcernList:[],
        typeOfConcernList1:[  {
          concernID: 1,
          concernName: "Unable to generate OTP"
      },
      {
          concernID: 2,
          concernName: "Site is very slow"
      },
      {
          concernID: 3,
          concernName: "Not able to make the payment"
      },
      {
          concernID: 4,
          concernName: "Not able to upload the documents"
      },
      {
          concernID: 5,
          concernName: "Site is not intuitive"
      },
      {
          concernID: 6,
          concernName: "Others"
      }]
     
      };
      //localStorage.setItem('checkedtab',false);
    }
    getConcernTypeList=()=>{
      this.setState({ loading: true})
      FeedbackService.getConcernTypeList(
          (cb) => {        
              if (cb.status === "success") {
                this.setState({ loading: false });
                this.setState({typeOfConcernList:cb.responseListObject})
              
              } else {        
                error(cb.status,errorNotification);
                this.setState({loading: false}) 
              }
            },
            (err) => console.log("Error", err)
        );
  
  }
   
    componentDidMount() {
   this.getConcernTypeList();
      if(this.props.data !== undefined){
        this.setState({
            email: JSON.parse(localStorage.getItem('login'))["emailId"] === undefined ? "" : JSON.parse(localStorage.getItem('login'))["emailId"],
            partyid: JSON.parse(localStorage.getItem('login'))["partyId"] === undefined ? "":JSON.parse(localStorage.getItem('login'))["partyId"]
        })
    }

    
    var header2 = document.getElementById("myDIV");
    var btns2 = header2.getElementsByClassName("emoji");
    for (var i = 0; i < btns2.length; i++) {
        btns2[i].addEventListener("click", function () {
            var current = document.getElementsByClassName("active");
            if(current.length > 0)
            {
            current[0].className = current[0].className.replace(" active", "");
            }
            this.className += " active";
        });
    }


      let partyID = localStorage.getItem("ciel-userid-Token");
      this.setState({loading1:true})
      FetchNotificationService.fetchNotification("partyId=" + partyID, (res1) => {
        if(res1.responseListObject?.length>0){
          this.setState({
            notificationEvents: res1.responseListObject,
            loading1:false
          })
        }
        else{
          this.setState({
            notificationEvents:[],
            loading1:false
          })
        }
      
  })

      // let RoleId = localStorage.getItem('roleId')

      // const { fetchUserRoleAccess } = this.props
      // const roleDetails = {
      //   roleId: {
      //     roleId: RoleId
      //   }
      // }
      //fetchUserRoleAccess(roleDetails);
      this.extentendedFun();
      
      let senderPartyId = localStorage.getItem("ciel-userid-Token");
      this.setState({
        loading2:true
      })
    FetchNotificationService.getCount("senderPartyId=" + senderPartyId, (res1) => {
      if(res1.status==="success"){
        this.setState({
          inboxCount: res1.responseObject.unreadCount,
          loading2:false
        })
      }else{
        this.setState({
          loading2:false
        })
      }
    })


      $('button').bind('click', function (e) {
        // if ($(e.target).closest('#navbar').length == 0) {
          if ([0,"0"].includes($(e.target).closest('#navbar').length)) {
          // click happened outside of .navbar, so hide
          var opened = $('.navbar-collapse').hasClass('collapse in');
          if (opened === true) {
            $('.navbar-collapse').removeClass('in');
          }
        }
      });
      //   $('button').on('click', function () {
      //     $('.navbar-collapse').toggleClass('in')
      // });

      $('body').bind('click', function (e) {
        // if ($(e.target).closest('#navbar').length == 0) {
        if ([0,"0"].includes($(e.target).closest('#navbar').length)) {
          // click happened outside of .navbar, so hide
          var opened = $('.navbar-collapse').hasClass('collapse in');
          if (opened === true) {
            $('.navbar-collapse').removeClass('in');
          }
        }
      });
    }

    extentendedFun() {

      $(window).blur(function () {
        window.addEventListener('storage', e => {
          if (localStorage.getItem('userid-Token') === null && localStorage.getItem('login') === null) {
            if (localStorage.getItem("windowloggedout") === true) {
              window.location.href = '/';
            }
          }
          e.currentTarget.removeEventListener("storage", e)
        });
      });
      if (localStorage.getItem('userid-Token') !== null && localStorage.getItem('login') !== null) {
        // if (localStorage.getItem('roleId') == 5) {
          if([5,"5"].includes(localStorage.getItem('roleId'))){
          //window.location.href = '/learnerDashboard';
          if (localStorage.getItem('checkedtab') === false) {
            localStorage.setItem('checkedtab', true);
            window.location.replace('/learnerDashboard');
          }
        }
        else {
          if (localStorage.getItem('checkedtab') === false) {
            localStorage.setItem('checkedtab', true);
            window.location.replace('/admin-dashboard');
          }
        }
      }
      else {
        // if (localStorage.getItem('checkedtab') == 'false') {
          if (['false',"false",false].includes(localStorage.getItem('checkedtab'))) {
          localStorage.setItem('checkedtab', false);
          window.location.href = '/';
        }
      }

      $(".popup10").click(function () {
        $("#myPopup10").toggleClass("show");
        setTimeout(function () {
          $('#myPopup10').toggleClass('show');
        }, 2000)
      });


      $(".popup11").click(function () {
        $("#myPopup11").toggleClass("show");
        setTimeout(function () {
          $('#myPopup11').toggleClass('show');
        }, 2000)
      });

      $(document).ready(function () {
        $(".popup13").click(function () {
          $("#myPopup13").toggleClass("show");
          setTimeout(function () {
            $('#myPopup13').toggleClass('show');
          }, 2000)
        });

        $("#myBtn").click(function () {
          $("#mySidenav").toggleClass("opensidenav");
          $(".dropdown-container").hide();
        });

        $(".popup14").click(function () {
          $("#myPopup14").toggleClass("show");
          setTimeout(function () {
            $('#myPopup14').toggleClass('show');
          }, 2000)
        });

        $(".popup15").click(function () {
          $("#myPopup15").toggleClass("show");
          setTimeout(function () {
            $('#myPopup15').toggleClass('show');
          }, 2000)
        });

        $(".popup16").click(function () {
          $("#myPopup16").toggleClass("show");
          setTimeout(function () {
            $('#myPopup16').toggleClass('show');
          }, 2000)
        });

        $(".popup17").click(function () {
          $("#myPopup17").toggleClass("show");
          setTimeout(function () {
            $('#myPopup17').toggleClass('show');
          }, 2000)
        });

        $(".popup18").click(function () {
          $("#myPopup18").toggleClass("show");
          setTimeout(function () {
            $('#myPopup18').toggleClass('show');
          }, 2000)
        });

        $(".popup19").click(function () {
          $("#myPopup19").toggleClass("show");
          setTimeout(function () {
            $('#myPopup19').toggleClass('show');
          }, 2000)
        });

        $(".popup20").click(function () {
          $("#myPopup20").toggleClass("show");
          setTimeout(function () {
            $('#myPopup20').toggleClass('show');
          }, 2000)
        });

        $(".popup21").click(function () {
          $("#myPopup21").toggleClass("show");
          setTimeout(function () {
            $('#myPopup21').toggleClass('show');
          }, 2000)
        });
        $(".popup22").click(function () {
          $("#myPopup22").toggleClass("show");
          setTimeout(function () {
            $('#myPopup22').toggleClass('show');
          }, 2000)
        });
        $(".popup23").click(function () {
          $("#myPopup23").toggleClass("show");
          setTimeout(function () {
            $('#myPopup23').toggleClass('show');
          }, 2000)
        });

      });

      //  $('body').on('click',function(event){
      //     if(!$(event.target).is('#myBtn')){
      //       $("#mySidenav").removeClass("opensidenav");
      //     }
      //  });

      //  $('#mySidenav').click(function(event){
      //     event.stopPropagation();
      // });  

      $('#return-to-top').click(function () {
        $('body,html').animate({
          scrollTop: 0
        }, 500);
      });

      var dropdown = document.getElementsByClassName("dropdown-btn");
      for (var i = 0; i < dropdown.length; i++) {
        dropdown[i].addEventListener("click", function () {
          $(".dropdown-container").hide("active");

          this.classList.toggle("active");
          var dropdownContent = this.nextElementSibling;
          if (dropdownContent.style.display === "none") {
            dropdownContent.style.display = "block";
          } else {
            dropdownContent.style.display = "none";
          }

        });
      }

    }

    // comingsoon = () => {
    //   $(".popup23").click(function () {
    //     $("#myPopup23").toggleClass("show");
    //     setTimeout(function () {
    //       $('#myPopup23').toggleClass('show');
    //     }, 2000)
    //   });

    // };

    signout = event => {
      event.preventDefault();
      this.setState({ loading: true });
      if (localStorage.getItem('roleName') === "DISTRIBUTOR" && localStorage.getItem('login') !== null) {
        var data1 = localStorage.getItem("PersonnelData")
        var data = {
          userName: JSON.parse(data1).responseObject.emailID,
          // userName: JSON.parse(localStorage.getItem('login'))["userName"],
          userId: JSON.parse(localStorage.getItem('login'))["userId"]
        };

        LoginService.LogoutUser(data, false, res => {
          if (res.status === "success") {
            this.setState({ loading: false });
            localStorage.clear();
            localStorage.setItem("windowloggedout", true)
            var email = data.userName;
            var partyid = JSON.parse(data1).responseObject.learnerPartyID;
            localStorage.setItem("showFeedback", "true");
            this.props.history.push("/",{email,partyid});

          } else {
            this.setState({ loading: false });
            warning(res.reasonText, warningNotification);
          }
        });
      }
      else if (localStorage.getItem('roleName') === "CIEL OPS" && localStorage.getItem('login') !== null) {
        var data2 = {
          userName: JSON.parse(localStorage.getItem('login'))["userName"],
          userId: JSON.parse(localStorage.getItem('login'))["userId"]
        };

        LoginService.LogoutUser(data2, false, res => {
          if (res.status === "success") {
            this.setState({ loading: false });
            localStorage.clear();
            localStorage.setItem("windowloggedout", true)
            window.location.href = "/";
          }
          else {
            this.setState({ loading: false });
            warning(res.reasonText, warningNotification);
          }
        });
      }
      else {
        this.setState({ loading: false });
        window.location.href = '/';
      }
    };

    changepassword = () => {
      this.props.history.push("/changepassword", {
        alerts: this.state.alertNotificationEvents,
        notification: this.state.notificationEvents
      })
    };

    userprofile = () => {
      this.props.history.push("/userprofile", {
        alertNotificationEvents: this.state.alertNotificationEvents,
        notificationEvents: this.state.notificationEvents
      })
    }

    alerts = (key) => {
      if (this.state.alertNotificationEvents.length > 0) {
        this.props.history.push("/myalerts", {
          alertid: key,
          alerts: this.state.alertNotificationEvents,
        })
      }
    };

    alert = () => {
      if (this.state.alertNotificationEvents.length > 0) {
        this.props.history.push("/myalerts", {
          alertid: 0,
          alerts: this.state.alertNotificationEvents,
        })
      }
    }


    notifications = () => {


      var notealertdata = JSON.parse(localStorage.getItem("AdminNotification"));

      // alert(this.state.notificationEvents.length)
      if (this.state.notificationEvents?.length > 0 || notealertdata?.length > 0) {
        this.props.history.push("/my-inbox", {
          alertid: 0,
          notification: notealertdata
        })
      }
      else {
        warning("No record found", warningNotification)
      }
    }

    notification = (key, object) => {
      // console.log("object", object)
      // for(let i= 0; i< this.state.notificationEvents.length; i++){
      //   if(key=== this.state.notificationEvents[i].notifyHistoryId && this.state.notificationEvents[i].read_unread === false){
      //     let notifyHistory = {
      //       "notifyHistoryId": key,
      //     }
      //       FetchNotificationService.updateReadUnread(notifyHistory, (res2) => {
      //         if(res2.status === "success"){
      //         let senderPartyId = localStorage.getItem("ciel-userid-Token");
      //           FetchNotificationService.getCount("senderPartyId=" + senderPartyId, (res1) => {
      //             this.setState({
      //               inboxCount: res1.responseObject.unreadCount
      //             })
      //           })
      //         }
      //       })     
      //   }
      // }


      // if (this.state.notificationEvents.length > 0) {
      //   let arrayObj =[]
      //   arrayObj.push(object)
      //   this.props.history.push("/notification", {
      //   alertid: key,
      //   notification: arrayObj,
      //   value:"Inbox",
      //   inboxCount:this.state.inboxCount,
      //   })
      // }
      if(object.read_unread === false){
         //alert("kkk")
         let notifyHistory = {
           "notifyHistoryId": key,
         }
           FetchNotificationService.updateReadUnread(notifyHistory, (res2) => {
             //alert("ppp")
             if(res2.status === "success"){
             //  alert("lll")
             let senderPartyId = localStorage.getItem("ciel-userid-Token");
               FetchNotificationService.getCount("senderPartyId=" + senderPartyId, (res1) => {
                 this.setState({
                   inboxCount: res1.responseObject.unreadCount
                 })
               })
             }
           })     
       } 
       console.log("object ::", object)
     
       if (this.state.notificationEvents.length > 0) {
         let arrayObj =[]
         arrayObj.push(object)
         this.props.history.push("/notification", {
           alertid: key,
           notification: arrayObj,
           value:"Inbox",
           inboxCount:this.state.inboxCount,
           
         })
          setTimeout(function () {
         window.location.reload()
          }, 1000)
       }
    
    }

    // alerts = (key) => {
    //   this.props.history.push("/myalerts", {
    //     alertid: key,
    //     alerts: this.props.alertNotificationEvents,
    //     notification: this.props.notificationEvents,
    //   })
    // };
    // alert = () => {
    //   this.props.history.push("/myalerts", {
    //     alerts: this.props.alertNotificationEvents,
    //     notification: this.props.notificationEvents,
    //   });
    // };
    // notifications = () => {
    //   this.props.history.push("/notification"
    //     , {
    //       alerts: this.props.alertNotificationEvents,
    //       notification: this.props.notificationEvents
    //     });
    // };
    // notification = key => {
    //   this.props.history.push("/notification", {
    //     alertid: key,
    //     alerts: this.props.alertNotificationEvents,
    //     notification: this.props.notificationEvents
    //   });
    // }
    order = () => {
      this.props.history.push("/my-orders", {
        alerts: this.props.alertNotificationEvents,
        notification: this.props.notificationEvents
      }
      )
    }

    inbox = () => {
      this.props.history.push("/my-inbox", {
        alerts: this.props.alertNotificationEvents,
        notification: this.props.notificationEvents
      }
      )
    }
    reattempt = () => {

      this.props.history.push("/PreassessmentView"
      )
    }
    dateRequest = () => {
      // this.props.history.push("/dateRequest"
      // )
      window.location.href = "/dateRequest"
    }

    updateScore = () => {
      window.location.href = "/uploadScore"
    }
    
    uploadExamDate = () => {
      window.location.href = "/uploadExamDate"
    }
    
    uploadNism = () => {
      window.location.href = "/uploadNism"
    }

    updatePayment = () => {
      window.location.href = "/updateWfToPaymentComplete"
    }
    nigoMark = () => {
      window.location.href = "/nigoMark"
    }

    updatePersonalDetails = () =>{
      window.location.href = "/updatePersonalDetails"
    }

    createMail = () => {

      this.props.history.push("/my-inbox-create", {
        alerts: this.props.alertNotificationEvents,
        notification: this.props.notificationEvents,
        userRoleAccess: this.props.userRoleAccess,

      }
      )
    }
    schedular = () => {
      // this.props.history.push("/dateRequest"
      // )
      window.location.href = "/Schedular"
    }
    cielReport = () => {
      window.location.href = "/ciel-report"
    }

    empanellementReport = () => {
      window.location.href = "/empanellement-report"
    }

    mandateReport = () => {
      window.location.href = "/mandate-report"
    }
    feedBack = () => { this.props.history.push("/ops-feedback-report",{typeOfConcernList:this.state.typeOfConcernList}); }
    getInTouch = () => { this.props.history.push("/ops-getintouch-report"); }
    // showFeedbackModal = () =>{
    //   alert("Inside showFeedbackModal")
    //   localStorage.setItem("showFeedback", "true");
    //   // $.getScript(Cfxroadmap, function(){
    //   //   alert("Hello");
    //     window.$('#feedback-modal').modal('show');
    // // });
    // }

    handleClose = () =>{
      this.setState({rank : 0,feedbackMsg:""});
      const child = document.querySelectorAll('.emoji');
      child.forEach(element => {
        element.classList.remove('active');
      }); 
    }

    render() {
     
      
      // const {
      //   userRoleAccess,
      //   pendingUserRoleAccess,
      //   errorUserRoleAccess
      // } = this.props;

      // var roledata = this.props.userRoleAccess;
      // var alertdata = JSON.parse(localStorage.getItem("AdminAlertNotification"));
     // var notealertdata = JSON.parse(localStorage.getItem("AdminNotification"));
     var notealertdata = this.state.notificationEvents;
      //if (!pendingUserRoleAccess) {
      return (

        <div>
          <React.Fragment>
          {/* {(this.state.loading ||this.state.loading1||this.state.loading2)&& ( */}
            <PopUpPortal
              HidePopup={this.state.loading||this.state.loading1||this.state.loading2}
              IsVisible={this.state.loading||this.state.loading1||this.state.loading2}
            />
          {/* )} */}
            {/* <!------ header section ----------> */}
            <section className="header">
              <nav className="navbar navbar navbar-fixed-top">
                <span id="myBtn" className="sidenva-icon sidebartogglexyz">
                  &#9776;
            </span>

                <div className="container-fluid">
                  {/* <!-- Brand/logo --> */}
                  <div className="navbar-header">
                    <button
                      type="button"
                      className="navbar-toggle collapsed"
                      data-toggle="collapse"
                      data-target="#navbar"
                      aria-expanded="false"
                      aria-controls="navbar"
                    >
                      <span className="sr-only">Toggle navigation</span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                    </button>
                    {/* {localStorage.getItem('roleId') == 5 ? */}
                    {[5,"5"].includes(localStorage.getItem('roleId')) ?
                      <a className="navbar-brand" href="/learnerdashboard">
                        <img src={Finxlogo} alt="Finx Logo" />
                      </a> : <a className="navbar-brand" href="/dashboard">
                        <img src={Finxlogo} alt="Finx Logo" />
                      </a>}
                  </div>
                  <button id="md-toggle" className="navbar-toggler hidden-sm hidden-xs" type="button" data-toggle="collapse" data-target="#navbar"
                    aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="dark-blue-text"><i
                      className="fa fa-bars"></i></span>
                  </button>
                  <div id="navbar" className="navbar-collapse collapse">
                    <ul className="nav navbar-nav navbar-right">
                      {/* <li className="row">
                        <div className="right-search"> */}
                      {/* <!-- Search form --> */}
                      {/* <form >
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search.."
                            name="search"
					              		disabled
                          />
                          <button type="submit">
                            <i className="fa fa-search"></i>
                          </button>
                        </form> */}
                      {/* </div>
                      </li> */}
                      {/* <li className="nav-item dropdown notification">
                        <a
                          className="nav-link text-light myPopup11"
                          // href="#"
                          className="notification"
                          id="navbarDropdown"
                          role="button"
                          // data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          title="Alerts"
                        >
                          <i className="fa fa-bell-o" aria-hidden="true" ></i>
                          <span class="popuptext" id="myPopup11">Coming Soon...</span>
                        </a> */}
                      {/* <li className="nav-item dropdown notification">
                        <a
                          className="nav-link text-light popup11"
                          //href="#"
                          id="navbarDropdown"
                          role="button"
                          // data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          title="Alerts"
                        >
                          <i className="fa fa-bell-o" aria-hidden="true"></i>
                          <span class="popuptext" id="myPopup11">Coming Soon...</span>
                        </a> */}

                      {/* <a
                          className="nav-link text-light"
                          // href="#"
                          className="notification"
                          id="navbarDropdown"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          title="Alerts"
                        >
                          <i className="fa fa-bell-o" ></i>
                           </a> */}
                      {/* <span className="badge hidden-xs">
                          {" "}
                          {(alertdata || []).length}{" "}
                        </span> */}

                      {/* <ul className="dropdown-menu" id="notification-alert">
                          <li className="head text-light bg-dark">
                            <div className="row">
                              <div className="col-lg-12 col-sm-12 col-12">
                                <span>Alerts({(alertdata || []).length})</span>
                              </div>
                            </div>
                          </li>
                          <li className="notification-box">
                            <div className="notification-content">
                              <div className="row">
                                <div class="col-lg-12 col-sm-12 col-12">
                                  {alertdata
                                    ? alertdata.slice(0, 3).map((item, key) => (
                                      <div
                                        className="notification-content"
                                        id={item.alertID}
                                      >
                                        <a
                                          onClick={() =>
                                            this.alerts(item.alertID)
                                          }
                                        >
                                          {" "}
                                          <div className="notification-body">

                                            <strong class="text-info">
                                              {item.alertName}{" "}
                                            </strong>

                                          </div>
                                        </a>
                                      </div>
                                    ))
                                    : ""}
                                </div>
                              </div>
                            </div>
                          </li>

                          <li className="footer bg-dark text-center">
                            <a onClick={this.alert} className="text-light">
                              View All
                        </a>
                          </li>
                        </ul>
                      </li> */}

                      <li className="nav-item dropdown notification" id="user-option">
                        <a href
                          //onClick={this.signout}
                          className="nav-link text-light notification"
                          // className="notification"
                          id="navbarDropdown"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          title="My Profile"
                        >
                          <i className="fa fa-user" aria-hidden="true"></i> Profile
                          <span className="popup23 popuptext" id="">Coming Soon...</span>
                        </a>

                        <ul className="dropdown-menu" id="notification-profile" style={{ width: 'auto !important' }}>
                          <li className="notification-box">
                            <div className="row">
                              <div className="panel-body">
                                <div className="notification-content">
                                  <div className="col-lg-12 col-sm-12 col-12">
                                    {/* {localStorage.getItem('roleId') == 5 ? */}
                                    {[5,"5"].includes(localStorage.getItem('roleId')) ?
                                      <a href onClick={this.userprofile} className="text-light">
                                        My Profile</a> : ''}

                                    <a href onClick={this.changepassword} className="text-light ">
                                      Change Password</a>

                                    <a href onClick={this.signout} className="text-light">
                                      Sign Out</a>

                                    {/* <a onClick={e => {
                                      warning("Coming Soon", warningNotification
                                      )
                                    }} className="text-light popup23">
                                      Ask For Help
                                     </a> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item dropdown notification">
                      {/* <div>this.state.inboxCount</div> */}
                        <a href
                          className="nav-link text-light notification"
                          // href="#"
                          // className="notification"
                          id="navbarDropdown"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          title="Messages"
                        >
                          <i className="fa fa-envelope-o" aria-hidden="true"></i> Messages ({this.state.inboxCount})


                        </a>
                        <ul className="dropdown-menu" id="notification-alert">
                          <li className="head text-light bg-dark">
                            <div className="row">
                              <div className="col-lg-12 col-sm-12 col-12">
                                <span>
                                  {/* Messages ({(notealertdata || []).length})({this.state.inboxCount}) */}
                                  Messages ({this.state.inboxCount})
                              </span>
                                    <a href className="text-light pull-right">{" "}</a>
                              </div>
                            </div>
                          </li>

                          <li className="notification-box">
                            <div className="row">
                              <div className="panel-body">
                                <div className="col-lg-12 col-sm-12 col-12">
                                  {notealertdata
                                    ? notealertdata.slice(0, 3).map((item, key) => (
                                      <div
                                        className="notification-content"
                                        id={item.notifyHistoryId}
                                      >
                                        <a href
                                          onClick={() =>
                                            this.notification(
                                              item.notifyHistoryId,item
                                            )
                                          }
                                        >
                                          <div className="notification-body">
                                            {/* <strong class="text-info unread"> */}
                                            <strong className={"text-info " +(item.read_unread ? 'unread': '' )}>
                                            {/* <div className={"btn-group pull-right " + (this.props.showBulkActions ? 'show' : 'hidden')}> */}

                                              {item.fromEmailId}
                                            </strong>
                                            <div className="notification-body">
                                              {ReactHTMLParser(
                                                item.emailSubjectText
                                              )}
                                            </div>
                                            <small className="text-warning">
                                              {moment(item.lastAttemptDatetime.split(" ")[0], "DD/MM/YYYY").format("DD-MM-YYYY") + " " + item.lastAttemptDatetime.split(" ")[1]}
                                            </small>
                                          </div>
                                        </a>
                                      </div>
                                    ))
                                    : ""}
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="footer bg-dark text-center">
                            <a href
                              onClick={this.notifications}
                              className="text-light"
                            >
                              View All
                        </a>
                          </li>
                        </ul>
                      </li>

                      {/* <li className="nav-item dropdown notification">
                        <a
                          className="nav-link text-light popup22"
                          //href="#"
                          id="navbarDropdown"
                          role="button"
                          // data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          title="Chat"
                        >
                          <i className="fa fa-comments-o" aria-hidden="true"></i>
                          <span class="popuptext" id="myPopup22">Coming Soon...</span>
                        </a>
                      </li> */}


                      {/* <li className="nav-item dropdown notification">
                        <a
                          className="nav-link text-light popup10"
                          // href="#"
                          id="navbarDropdown"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          title="Setting"
                        >
                          <i className="fa fa-cogs" aria-hidden="true"></i>
                          <span class="popuptext" id="myPopup10">Coming Soon...</span>

                        </a>
                      </li> */}
                      <li className="nav-item dropdown notification">
                        <a href
                          onClick={this.signout}
                          className="nav-link text-light notification"
                          // className="notification"
                          id="navbarDropdown"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          title="Sign-Out"
                        >
                          <i className="fa fa-sign-out" aria-hidden="true"></i> Sign Out
                        </a>
                      </li>
                      {(localStorage.getItem('roleName') === "DISTRIBUTOR" && localStorage.getItem('login') !== null) &&
                      <li className="nav-item dropdown notification">
                        <a href
                          className="nav-link text-light notification"
                          // className="notification"
                          id="navbarDropdown"
                          role="button"
                          onClick={(e) => {
                            this.setState({ saveFeedbackBtnDisabled: false, rank : 0,feedbackMsg:"" });
                            const child = document.querySelectorAll('.emoji');
                            child.forEach(element => {
                              element.classList.remove('active');
                            });
                            window.$('#feedback-modal').modal('show')
                          }}
                          title="Feedback"
                        >
                          <i className="fa fa-regular fa-comments" aria-hidden="true"></i>
                        </a>
                      </li>}
                    </ul>
                  </div>
                  {/* <!--/.nav-collapse --> */}
                </div>
              </nav>
            </section>

            {/* <!--------------- Left Side navbar -------------> */}
            <div id="mySidenav" className="sidenav sidebartogglexyz">

              {/* <a onClick={this.learnerDashboard}> */}
              {/* {localStorage.getItem('roleId') == 5 ? */}
              {[5,"5"].includes(localStorage.getItem('roleId')) ?
                <a href="/learnerDashboard" className="sidebartogglexyz">
                  <i className="fa fa-pie-chart" aria-hidden="true"></i> Dashboard
          </a>
                :
                <a href="/dashboard" className="sidebartogglexyz">
                  <i className="fa fa-pie-chart" aria-hidden="true"></i> Dashboard
    </a>}
              {/* {localStorage.getItem("roleId") == 5 ?
                <div>
                  <button className="dropdown-btn sidebartogglexyz">
                    <i className="fa fa-book sidebartogglexyz" aria-hidden="true"></i> Reports
            <i className="fa fa-caret-down sidebartogglexyz"></i>
                  </button>
                  <div className="dropdown-container sidebartogglexyz">

                    <a onClick={this.order} className="sidebartogglexyz">
                      My Payment</a>

                  </div>

                </div> : ""} */}

              <div><a href="/my-inbox" className="sidebartogglexyz">
                <i className="fa fa-bell sidebartogglexyz" aria-hidden="true"></i> Notification Manager</a></div>


              {/* 
              <button className="dropdown-btn sidebartogglexyz">
                <i className="fa fa-leanpub sidebartogglexyz" aria-hidden="true"></i> Messages
            <i className="fa fa-caret-down sidebartogglexyz"></i>
              </button>  */}

              {/* <div className="dropdown-container sidebartogglexyz">
                {
                  
                  <div>
                   
                    <a onClick={this.inbox} className="sidebartogglexyz">
                      My Inbox</a>
                  
                  </div>
                  
                }
                <a onClick={this.createMail} className="sidebartogglexyz">
                  Compose</a>
                  </div> */}
              {/* <a className="popup13 sidebartogglexyz">My Inbox
              <span class="popuptext" id="myPopup13">Coming Soon...</span>
              </a> */}
              {/* <a className="popup14 sidebartogglexyz">Sent
                            <span class="popuptext" id="myPopup14">Coming Soon...</span>
                </a>
                <a className="popup15 sidebartogglexyz">Draft
                            <span class="popuptext" id="myPopup15">Coming Soon...</span>
                </a> */}


              {/* <a onclick={this.createMail} className="sidebartogglexyz">Create</a> */}
              {/* <span class="popuptext" id="myPopup16">Coming Soon...</span> */}

              {/* <button className="dropdown-btn sidebartogglexyz">
                <i className="fa fa-leanpub sidebartogglexyz" aria-hidden="true"></i> Referral Section
            <i className="fa fa-caret-down sidebartogglexyz"></i>
              </button>
              <div className="dropdown-container sidebartogglexyz">
                <a className="popup17 sidebartogglexyz">Generate New Link
                            <span class="popuptext" id="myPopup17">Coming Soon...</span>
                </a>
                <a className="popup18 sidebartogglexyz">Track Referral Bonus
                            <span class="popuptext" id="myPopup18">Coming Soon...</span>
                </a>
                <a className="popup19 sidebartogglexyz">Draft
                            <span class="popuptext" id="myPopup19">Coming Soon...</span>
                </a>
                <a className="popup20 sidebartogglexyz">Create
                            <span class="popuptext" id="myPopup20">Coming Soon...</span>
                </a>
              </div>
              <button className="dropdown-btn sidebartogglexyz">
                <i className="fa fa-leanpub sidebartogglexyz" aria-hidden="true"></i> Status
            <i className="fa fa-caret-down sidebartogglexyz"></i>
              </button>
              <div className="dropdown-container sidebartogglexyz">
                <a className="popup21 sidebartogglexyz">Status
                            <span class="popuptext" id="myPopup21">Coming Soon...</span>
                </a>
              </div> */}
              {/* {localStorage.getItem('roleId') == 5 || localStorage.getItem('roleId') == 7 ? '' :
                <div>
                  <button className="dropdown-btn sidebartogglexyz">
                    <i className="fa fa-file sidebartogglexyz" aria-hidden="true"></i> Feat Details
            <i className="fa fa-caret-down sidebartogglexyz"></i>
                  </button>


                  <div className="dropdown-container sidebartogglexyz">
                    <a onClick={this.reattempt} className="sidebartogglexyz">
                      Reattempt Test</a>

                  </div>
                </div>
              } */}

              {/* {localStorage.getItem('roleId') == 6 ? */}
              {[6,"6"].includes(localStorage.getItem('roleId')) ?
                <div>
                  <button className="dropdown-btn sidebartogglexyz" >
                    <i className="fa fa-leanpub sidebartogglexyz" aria-hidden="true" ></i> NISM Exam Date Request
                    <i className="fa fa-caret-down sidebartogglexyz"></i>
                  </button>


                  <div className="dropdown-container sidebartogglexyz">
                    <a href onClick={this.dateRequest} className="sidebartogglexyz">
                      NISM Exam Date Request List</a>

                  </div>
                </div>
                : ''}

              {/* {localStorage.getItem('roleId') == 6 ? */}
              {[6,"6"].includes(localStorage.getItem('roleId')) ?
                <div>
                  <button className="dropdown-btn sidebartogglexyz" >
                    <i className="fa fa-edit sidebartogglexyz" aria-hidden="true" ></i> Update NISM Scores
                    <i className="fa fa-caret-down sidebartogglexyz"></i>
                  </button>


                  <div className="dropdown-container sidebartogglexyz">
                    <a href onClick={this.updateScore} className="sidebartogglexyz">
                      Update NISM Scores</a>

                  </div>
                </div>
                : ''}
                
                {/* {localStorage.getItem('roleId') == 6 ? */}
                {[6,"6"].includes(localStorage.getItem('roleId')) ?
                <div>
                  <button className="dropdown-btn sidebartogglexyz" >
                    <i className="fa fa-edit sidebartogglexyz" aria-hidden="true" ></i> Update Bulk NISM Profile Create
                    <i className="fa fa-caret-down sidebartogglexyz"></i>
                  </button>


                  <div className="dropdown-container sidebartogglexyz">
                    <a href onClick={this.uploadNism} className="sidebartogglexyz">
                      Update Bulk NISM Profile Create</a>

                  </div>
                </div>
                : ''}

            {/* {localStorage.getItem('roleId') == 6 ? */}
            {[6,"6"].includes(localStorage.getItem('roleId')) ?
                <div>
                  <button className="dropdown-btn sidebartogglexyz" >
                    <i className="fa fa-edit sidebartogglexyz" aria-hidden="true" ></i> OPS Upload
                    <i className="fa fa-caret-down sidebartogglexyz"></i>
                  </button>


                  <div className="dropdown-container sidebartogglexyz">
                    <a href onClick={this.updatePayment} className="sidebartogglexyz">
                    Update Workflow to Payment Complete</a>
                    <a href onClick={this.nigoMark} className="sidebartogglexyz">
                    Upload NIGO Marking</a>
                  </div>
                </div>
                : ''}

                {/* {localStorage.getItem('roleId') == 6 ? */}
                {[6,"6"].includes(localStorage.getItem('roleId')) ?
                <div>
                  <button className="dropdown-btn sidebartogglexyz" >
                    <i className="fa fa-edit sidebartogglexyz" aria-hidden="true" ></i> Update Bulk NISM Exam Date
                    <i className="fa fa-caret-down sidebartogglexyz"></i>
                  </button>


                  <div className="dropdown-container sidebartogglexyz">
                    <a href onClick={this.uploadExamDate} className="sidebartogglexyz">
                    Update Bulk NISM Exam Date</a>

                  </div>
                </div>
                : ''}

                {/* {localStorage.getItem('roleId') == 6 ? */}
                {[6,"6"].includes(localStorage.getItem('roleId')) ?
                <div>
                  <a href onClick={this.updatePersonalDetails} className="sidebartogglexyz">
                    <i className="fa fa-edit sidebartogglexyz" aria-hidden="true"></i> Update Personal Details
                  </a>
                </div>
                // <div>
                //   <button className="dropdown-btn sidebartogglexyz" >
                //     <i className="fa fa-edit sidebartogglexyz" aria-hidden="true" ></i> Update Personal Details
                //     <i className="fa fa-caret-down sidebartogglexyz"></i>
                //   </button>


                //   <div className="dropdown-container sidebartogglexyz">
                //     <a onClick={this.updatePanNumber} className="sidebartogglexyz">
                //     Update PAN Number</a>
                //     <a onClick={this.updateEmail} className="sidebartogglexyz">
                //     Update Email</a>
                //     <a onClick={this.updateMobileNumber} className="sidebartogglexyz">
                //     Update Mobile Number</a>

                //   </div>
                  
                // </div>
                : ''}
              {/* {localStorage.getItem('roleId') == 6 ?
                <div>
                  <button className="dropdown-btn sidebartogglexyz" >
                    <i className="fa fa-calendar sidebartogglexyz" aria-hidden="true" ></i> Schdeular
                    <i className="fa fa-caret-down sidebartogglexyz"></i>
                  </button>


                  <div className="dropdown-container sidebartogglexyz">
                    <a onClick={this.schedular} className="sidebartogglexyz">
                      Schdeular</a>

                  </div>
                </div>
                : ''} */}
              {/* {localStorage.getItem('roleId') == 6 ?
                <div>
                  <button className="dropdown-btn sidebartogglexyz" >
                    <i className="fa fa-book sidebartogglexyz" aria-hidden="true" ></i> Reports
    <i className="fa fa-caret-down sidebartogglexyz"></i>
                  </button>


                  <div className="dropdown-container sidebartogglexyz">
                    <a onClick={this.cielReport} className="sidebartogglexyz">
                    Distributor Details</a>

                  </div>

                </div>
                : ''} */}

                {/* {localStorage.getItem('roleId') == 6 ?
                <div>
                  <button className="dropdown-btn sidebartogglexyz" >
                    <i className="fa fa-book sidebartogglexyz" aria-hidden="true" ></i> Empanelment
    <i className="fa fa-caret-down sidebartogglexyz"></i>
                  </button>


                  <div className="dropdown-container sidebartogglexyz">
              
                    <a onClick={this.empanellementReport} className="sidebartogglexyz">
                    Empanelment Details</a>

                  </div>

                </div>
                : ''} */}

                {/* {localStorage.getItem('roleId') == 6 ? */}
                {[6,"6"].includes(localStorage.getItem('roleId')) ?
                <div>
                  <button className="dropdown-btn sidebartogglexyz" >
                    <i className="fa fa-book sidebartogglexyz" aria-hidden="true" ></i> Reports
    <i className="fa fa-caret-down sidebartogglexyz"></i>
                  </button>


                  <div className="dropdown-container sidebartogglexyz">
              
                    <a href onClick={this.mandateReport} className="sidebartogglexyz">
                    Mandate Details</a>
                    <a href onClick={this.feedBack} className="sidebartogglexyz">Feedback Report</a>
                    <a href onClick={this.getInTouch} className="sidebartogglexyz">Get in Touch Report</a>
                  </div>

                </div>
                : ''}

            </div>
          </React.Fragment >

          <Formik
              enableReinitialize={true}
              initialValues={{
                emailid:JSON.parse(localStorage.getItem('login'))["emailId"],
                partyid:JSON.parse(localStorage.getItem('login'))["partyId"],
                feedbackMsg:this.state.feedbackMsg,
                rank:this.state.rank,
                typeOfConcernList:this.state.typeOfConcernList||this.state.typeOfConcernList1,
                typeOfConcern:""
            }}
            validationSchema={Yup.object().shape({
              //static validations
              rank:Yup.number()
                    .min(1, "You must specify your reaction")
                    .max(5, "rating range should be between 1-5"),
              feedbackMsg: Yup.string()
                  .matches(/^[a-zA-Z0-9\s,\-&./()]+$/, "Please enter valid Feedback Message. Allowed characters : alphnumeric,Space,comma,hyphen,ampersand,dot,forward Slash, Round Brackets")
         ,
         typeOfConcern:Yup.string().when("rank",{
          is:(rank)=>rank===1||rank===2?true:false,
          then:Yup.string().required("You Must Select Type of Concern"),
          otherwise:Yup.string()
      })
         
                }
          )}
              onSubmit={(values)=>{
               
                if(values.emailid === "")
                {
                    warning("EmailId can't be null",warningNotification);
                    return;
                }
                else if(values.partyid === "")
                {
                    warning("partyId can't be null",warningNotification);
                    return;
                }
                let data = {
                    partyid : values.partyid,
                    emailid: values.emailid,
                    rank: values.rank,
                    feedback_msg: values.feedbackMsg,
                    concernID:values.typeOfConcern
                }
                console.log("data",data);
                this.setState({loading: true, saveFeedbackBtnDisabled: true})
                FeedbackService.PostFeedback(
                    data,
                    (cb) => {
                     
                        if (cb.status === "success") {
                          
                          this.setState({ loading: false,saveFeedbackBtnDisabled: false, rank : 0,feedbackMsg:"" });
                          success("Thank you for your valuable Feedback!", successNotification);
                          window
                          .$("#feedback-modal")
                          .modal("hide");
                          // this.setState({rank : 0,feedbackMsg:""});
                          const child = document.querySelectorAll('.emoji');
                          child.forEach(element => {
                            element.classList.remove('active');
                          }); 
                        } else {        
                          error(cb.status,errorNotification);
                          window
                          .$("#feedback-modal")
                          .modal("hide");
                          this.setState({ loading: false,saveFeedbackBtnDisabled: false, rank : 0,feedbackMsg:"" });
                          const child = document.querySelectorAll('.emoji');
                          child.forEach(element => {
                            element.classList.remove('active');
                          });
                        }
                      },
                      (err) => console.log("Error", err)
                  );
                
            }}
              render={({
                errors,
                touched,
                handleSubmit,
                // setFieldValue,
                // handleReset,
                values,
                handleChange,
                // handleClose
              }) => (
                  <React.Fragment>

                    <div
                      className="modal fade"
                      id="feedback-modal"
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="myModalLabel"
                    >
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                              onClick={this.handleClose}
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
            
                            <strong><h4 className="modal-title text-center" style={{fontWeight:"bold",marginBottom:20}} id="myModalLabel">
                                How was your overall experience with RCP?
                            </h4>
                            </strong>
                          </div>
                          <div className="modal-body">
                            <Form className="form-group">
                              <div className="form-group">
                                <div className="row">
                                   <div id="myDIV" className="col-md-12 col-sm-12 col-xs-12 text-center">
                                    <span name="rank" role="img" aria-label="Emoji" className="emoji" onClick={()=>{this.setState({rank: 1})}}>&#128544;</span>
                                    <span name="rank" role="img" aria-label="Emoji" className="emoji" onClick={()=>{this.setState({rank: 2})}}>&#128530;</span>
                                    <span name="rank" role="img" aria-label="Emoji" className="emoji" onClick={()=>{this.setState({rank: 3})}}>&#128529;</span>
                                    <span name="rank" role="img" aria-label="Emoji" className="emoji" onClick={()=>{this.setState({rank: 4})}}>&#128512;</span>
                                    <span name="rank" role="img" aria-label="Emoji" className="emoji" onClick={()=>{this.setState({rank: 5})}}>&#129321;</span>
                                   </div>
                                   <ErrorMessage
                                      name="rank"
                                      className="validation"
                                      component="div"
                                      style={{marginLeft:"42px"}}
                                    />
                                </div>
                                {["1",1,"2",2].includes(this.state.rank)&&
                                <div className="row">

                                <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                                    <label>
                                        Type Of Concern
                                        <span className="validation">
                                            {" "}*{" "}
                                        </span>
                                    </label>
                                        <Field
                                          as="select"
                                          name="typeOfConcern"
                                          id="typeOfConcern"
                                          style={{ border: "1px solid #fbc583",width:"90%", margin:"0px 5px 5px 3rem"  }}
                                          // onChange={(e)=>setFieldValue("typeOfConcern",e.target.value)}
                                          onChange={handleChange}
                                          className={
                                            "form-control" +
                                            (errors.typeOfConcern &&
                                            touched.typeOfConcern
                                              ? " is-invalid"
                                              : "")
                                          }
                                         
                                        >
                                          <option value="">--Select--</option>
                                          {(
                                            values.typeOfConcernList || []
                                          ).map((item) => (
                                            <option
                                              key={item.concernID}
                                              value={item.concernID}
                                            >
                                              {item.concernName}
                                            </option>
                                          ))}
                                        </Field>
                                        <ErrorMessage
                                          name="typeOfConcern"
                                          component="div"
                                          className="validation"
                                        />
                                      </div>
                                </div>
              }
                                <div className="row">
                                   <div className="col-md-12 col-sm-12 col-xs-12 mt-10">
                                  
                                    <Field
                                      rows="4"
                                      component="textarea"
                                      maxLength={255}
                                      name="feedbackMsg"
                                      id="feedbackMsg"
                                      placeholder="What can we do to improve?"
                                      autoComplete="feedbackMsg"
                                      onChange={handleChange}
                                      className={
                                        "form-control"
                                      }
                                      style={{ border: "1px solid #fbc583",width:"90%", margin:"0px 5px 5px 3rem"  }}
                                    
                                    />
                                    <ErrorMessage
                                          name="feedbackMsg"
                                          className="validation"
                                          component="div"
                                          style={{marginLeft:"42px"}}
                                        />
                                    {/* <ErrorMessage
                                      name="rank"
                                      className="validation"
                                      component="div"
                                      style={{marginLeft:"42px"}}
                                    /> */}
                                   </div>     
                                </div>
                              </div>
                            </Form>
                          </div>
                          <div className="modal-footer text-center">
                           
                            {/* <span style={{float:"center"}}> */}
                           
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={this.state.saveFeedbackBtnDisabled}
                                onClick={handleSubmit}
                            >
                              Send your Feedback
                            </button>
                            {/* <button
                              type="reset"
                              className="btn discount-table-heading"
                              onClick={handleReset}
                            >
                              Reset
                            </button> */}
                           
                            {/* </span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
            />   
        </div>

        

         
      );

      
      // }
      // else {
      //   return null;
      // }
    }
  }
);

export default DashboardHeader;

// onClfromEmailNameick
