import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
    success, successNotification, error, errorNotification, warning,
    warningNotification,
} from "../../notification/notifications";
import { GeneralEnquiryService } from "../../../services/generalenquiryService";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import FAQPDF from "../../../assets/pdf/RCP_FAQs.pdf";
import $ from "jquery";
import { LoginService } from "../../../services/login.service";
import { LookUpService } from "../../../services/lookupService";
import { MobileValidationService } from "../../../services/mobile.validation.service";
import * as moment from "moment";
import TC from "../../../assets/pdf/T-C_RCP.pdf";
import OrientationPDF from "../../../assets/pdf/OrientationPDF.pdf";



class generalenquiry extends React.Component {
    constructor(props) {
        super(props);



        var currentDate = new Date();

        this.state = {
            isMaintainenceActivated: false,
            display: "none",
            mobileNumber: '',
            fullName: '',
            // emailId: '',
            message: '',
            countryCode: "91",
            //  mobileNumber: '',
            OTP: '',
            firstName: '',
            lastName: '',
            //instituteName: '',
            educationQualification: '',
            emailId: '',
            passedOutYear: '',
            listOfYear: [],
            pursuingYear: '',
            dateOfBirth: moment(currentDate).format("DD/MM/YYYY"),
            today: moment(currentDate).format("YYYY-MM-DD"),
            popupVisibility: false,
            productId: '',
            productdetails: [],
            checkout: [],
            showGenerateOtp: false,
            showResendOtp: false,
            MobileVerified: false,
            isMobileUnique: false,
            showOTPBox: false,
            showTimer: false,
            isOTPGenerated: false,
            loading: false,
            flag: "false",
            isPaymentPending: false,
            isRegistered: false,
            instituteName: [],
            instituteNameisReg: '',
            user_obj: [],
            OTPTokenCount: 0,
            time: {},
            seconds: 31,
            disableopt: false,
            stateList: [],
            cityList: [],
            lookingForList: [],
            ca_state: '',
            ca_city: '',
            pinNo: '',
            gender: '',
            lookingFor: '',
            showStateCity: false,
            display: 'none',
            signuptabName: "tab active",
            logintabName: "tab",
            currentProfession: [],
            btnDisabled: false,
            countotp: 0,
            isValidOtp: false,
            whoareu: ''
        };

        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
    }
    secondsToTime = (secs) => {

        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return obj;
    }
    handleValidateOtp = e => {
        //this.state.countotp = this.state.countotp + 1

        this.setState({
            countotp: this.state.countotp + 1
        })
        let mobile = this.state.mobileNumber;
        let countrycd = this.state.countryCode;
        let validateOtp = e.target.value;

        if (validateOtp !== '') {
            MobileValidationService.MobileOTPValidation(
                mobile,
                countrycd,
                validateOtp,
                res => {

                    //console.log(res.status);
                    if (res.status === "success") {
                        this.setState({ time: {}, seconds: 0, showTimer: false, })
                        // success("Mobile Number Verified successfully", successNotification);
                        this.setState({ isValidOtp: true })
                        this.setState({
                            showGenerateOtp: false,
                            showResendOtp: false,
                            MobileVerified: true,
                            showOTPBox: true
                        });

                        if (this.state.isRegistered) {

                            let Trd = moment(this.state.user_obj.dob, "DD/MM/YYYY");
                            success("You are already registered with ExpertMFD. To start BFSI journey, please login to ExpertMFD.", warningNotification);

                            $("#feat-Reg").modal('hide');


                        }
                        if (this.state.educationQualification === 'under Graduate') {
                            localStorage.setItem("flag", "true");
                        } else {
                            localStorage.setItem("flag", "false");
                        }


                    } else {

                        this.setState({ isValidOtp: false })
                        this.setState({ loading: false });

                        if (res.reasonCode === 'DM_02') {
                            let otpcounter = parseInt(this.state.OTPTokenCount) + 1;
                            this.setState({ OTPTokenCount: otpcounter });
                            // if (this.state.OTPTokenCount >= 4) {
                            //     //warning("Click on Resend OTP", warningNotification);
                            //     this.setState({ loading: false, isLogined: false, showTimer: false, showResendOtp: false, OTP: '' });
                            //     warning('You have made 3 unsuccessful attempts.', warningNotification);
                            //     setTimeout(() => {
                            //         window.location.href = '/';
                            //     }, 5000);
                            // } else {
                            // this.setState({ isValidOtp: false })
                            // alert(res.reasonText)
                            //document.getElementById("OTP").innerHTML = ""
                            warning(res.reasonText + " count " + this.state.OTPTokenCount, warningNotification);

                            //}
                        }
                        //             else if (res.reasonCode === 'SM_11') {
                        //                 //warning("Token is getting refreshed", warningNotification);
                        //                 window.location.href = '/';
                        //             } else if (res.reasonCode === 'SM-03') {
                        //                 warning("Invalid Login Credentials. Please try again", warningNotification);
                        //                 this.setState({ loading: false, isLogined: false, showTimer: false, showResendOtp: false });
                        //             } else if (res.reasonCode === 'SM-07') {
                        //                 this.setState({ loading: false, isLogined: true, showTimer: false, showResendOtp: false });
                        //             } else if (res.reasonCode === 'SM-12') {
                        //                 this.setState({ loading: false, isLogined: false, showTimer: false, showResendOtp: false });
                        //                 warning('You have made 3 unsuccessful attempts to Login to ExpertMFD. Please contact expertmfd@ciel.co.in or 9922953305 for login support', warningNotification);
                        //             } else if (res.reasonCode === 'SM-13') {
                        //                 this.setState({ loading: false, isLogined: false, showTimer: false, showResendOtp: false });
                        //                 warning('Your Account has been Suspended. Please contact expertmfd@ciel.co.in or 9922953305 for login support', warningNotification);
                        //             } 
                        //             else {
                        //  this.setState({ loading: false, isLogined: false, showTimer: false, showResendOtp: false });

                        //                 warning(res.reasonText, warningNotification);
                        //             }

                    }
                    return true;
                },
                error => {
                    //console.log(error);
                }
            );
        } else {
            //if (!this.state.MobileVerified) {
            //  alert(this.state.countotp)
            if (this.state.countotp == 0) {

                warning("Please Enter OTP", warningNotification);

                // }
            }
        }
    }

    handleClose = () => {
        if (this.state.display === "none") {
            this.setState({
                display: "block"
            })
        }
        else {

            this.setState({

                display: "none"
            })

            window.location.reload();
        }

    }
    startTimer() {

        // this.setState({ OTP: null })
        // $(".notification-container-top-center").hide();
        // if (this.state.disableopt === false) {
        this.handleGenerateOtp()
        // this.setState({ time: {}, seconds: 31, disableopt: true })
        this.setState({ time: {}, seconds: 46, disableopt: true })
        // }
        // else {
        //     this.setState({ time: {}, seconds: 31, disableopt: false })
        //     console.log("click multiple times");
        // }



        // if (this.timer == 0 && this.state.seconds == 31) {
        if (this.timer == 0 && this.state.seconds == 46) {
            this.timer = setInterval(this.countDown, 1000);
        }
        else {

            // if (this.state.seconds !== 0) {
            //   clearInterval(this.timer);
            // } else {
            clearInterval(this.timer);
            this.timer = setInterval(this.countDown, 1000)
            //}
        }
    }
    handleGenerateOtp = () => {

        let mobile = this.state.mobileNumber;

        if (mobile.toString().length === 10) {
            // let productId = this.state.productdetails.productID !== undefined ? this.state.productdetails.productID : 0
            mobile = "mobileNo=" + mobile + "&productID=0"
            // + this.state.productdetails.productID;
            MobileValidationService.MobileValidation(
                mobile,
                res => {

                    if (res.status === 'success') {
                        this.GenerateOTP();
                        if (res.reasonCode === 'FC-01') {
                        }

                    }

                },
                error => {
                    //console.log(error);
                }
            );
        }
    };

    countDown() {

        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1 <= 0 ? 0 : this.state.seconds - 1;
        this.setState({ time: this.secondsToTime(seconds), seconds: seconds, });

        // Check if we're at zero.
        if (seconds == 0) {

            clearInterval(this.timer);
            // this.setState({ disableopt : false })

        }
        if (seconds === 0) {

            this.setState({
                showResendOtp: true,

            });
        }
        else {
            this.setState({
                showResendOtp: false,


            });
        }
    }


    GenerateOTP = () => {
        LookUpService.getLookingFor(
            "codeTypeId=100",
            res => {

                this.setState({
                    lookingForList: res.responseListObject,
                    // countryListForPer: res.responseListObject
                });
            }
        );
        LookUpService.getLookingFor(
            "codeTypeId=107",
            res => {

                this.setState({
                    currentProfession: res.responseListObject,
                    // countryListForPer: res.responseListObject
                });
            }
        );




        this.setState({ isMobileUnique: true, isOTPGenerated: true });

        if (this.state.isMobileUnique) {

            MobileValidationService.MobileOTP(
                this.state.mobileNumber,
                this.state.countryCode,
                res => {

                    if (res.status === "success") {
                        success(
                            "You will receive otp in 45 secs.",
                            successNotification
                        );
                    }
                    return true;
                }
            );
            this.setState({
                showGenerateOtp: false,
                showResendOtp: false,
                showOTPBox: true,
                showTimer: true,
            });
        }
    }
    isNumber(e) {
        const re = /^[0-9\b]+$/;
        if (re.test(e)) {
            return true;
        }
    }
    componentDidMount() {
        var self = this
        $(document).mouseup(function (e) {
            var container = $("#getintouch");
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                self.setState({

                    display: "none"
                })
            }
        });
        $(document).on('keydown', function (e) {
            if (e.keyCode === 27) { // ESC
                // $( elem ).hide();
                self.setState({

                    display: "none"
                })
            }
        });

        $('.accordion__button').on('click', function () {
            $('.accordion__button').removeClass('active');
            $(this).addClass('active');
        });
    }
    render() {
        return (
            <div>

                {/* <a id="getintouchclick1" target="_blank" href={OrientationPDF}>
                    <div className="getInTouch-box1">
                        <span className="getInTouchText">Why EpertMFD</span>
                    </div>
                </a> */}
                <a id="getintouchclick" onClick={e => {
                    this.handleClose()
                }} >
                    <div className="getInTouch-box">
                        <span className="getInTouchText">Get In Touch</span>
                    </div>
                </a>

                <Formik
                    initialValues={{



                    }}


                    render={({ errors, touched, handleSubmit }) => (
                        <React.Fragment>
                            <section className="faq section-padding" id='faq'>
                                <div className="container">
                                    <div className="row">
                                        <div className="section-title-black">
                                            <h2>FAQs</h2>
                                        </div>

                                        <div className="col-md-12 col-sm-12 col-xs-12">
                                            <Accordion allowZeroExpanded>
                                                <AccordionItem>
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                            {/* How can I register for the &quot;ExpertMFD&quot;? */}
                                                            How do I register on RCP?
                                                 </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                        <p>
                                                            You need to register and login into RCP.com. Post which you need to complete your profile by filling
                                                            in all the mandatory fields and uploading all your documents.
                                                         </p>
                                                        <p>You then need to make the payment for the NISM exam. CIEL will use this information to make your
                                                        NISM profile and subsequently call you for your exam enrolment.
</p>
                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                                <AccordionItem>
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                            Will candidate get online course? if yes, from where he can access?
                                                            {/* What is the eligibility criteria to register for the &quot;ExpertMFD&quot; initiative? */}
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                        <p>CIEL provides an online course to the user. They will get access to the course through the RCP platform.
                                                        There would be a tab of online course which needs to be clicked. The online course consists of 12
modules, short notes and 4 practice tests</p>

                                                    </AccordionItemPanel>
                                                </AccordionItem>

                                                <AccordionItem>
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                            {/* What are the eligibility criteria for becoming a mutual fund distributor through the ExpertMFD
                                                            initiative? */}
                                                            Where do I send the EUIN Form and Documents?
                                                     </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                        <p>Candidate must courier his form to CIEL’s office at the address given below:</p>
                                                        <p>Centre for Investment Education and Learning</p>
                                                        <p>Unit No. 320, Plot No. 105, Champaklal Industrial Estate, Next to Sion Telephone Exchange (at Sion
Koliwada Road), Sion East, Mumbai 400022, Maharashtra, India. Phone 9819814410</p>
                                                    </AccordionItemPanel>
                                                </AccordionItem>

                                                <AccordionItem>
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                            {/* Why do I need to enter all my details for profile completion?                     */}
                                                            What is the TAT for processing EUIN?
                                                            </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                        <p>EUIN form is received verified for completeness. For fresh forms the TAT would be 3-5 days from the
date the payment is made by the corporate.</p>
                                                        <p>For physical forms CAMS has a TAT of 3-4 weeks form the date of submission at CAMS</p>
                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                            </Accordion>
                                            <div className="faq-btn">
                                                <a href={FAQPDF} target="_blank" >
                                                    <button type="button" class="blue-btn">
                                                        MORE</button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            {/* <FinxRegistration {...this.props} /> */}




                        </React.Fragment>

                    )}
                />




                <Formik
                    initialValues={{
                        mobileNumber: this.state.mobileNumber,
                        fullName: this.state.fullName,
                        emailId: this.state.emailID,
                        message: this.state.message,
                        OTP: "",
                        termsandconditions: false
                    }}
                    validationSchema={Yup.object().shape({
                        // mobileNumber: Yup.string()
                        //     .matches(/^[2-9]\d{9}$/, "Please enter the valid mobile number")
                        //     .required('You must specify a Mobile Number'),


                        mobileNumber: Yup.string()
                            .matches(/^[2-9]\d{9}$/, "Please enter the valid mobile number")
                            .required("You must specify a Mobile Number")
                            .when(value => {
                                if (value !== undefined) {
                                    if (value.length === 10 && this.isNumber(value)) {
                                        this.setState({
                                            showGenerateOtp: true,
                                            mobileNumber: value
                                        });
                                    }
                                    if (value.length < 10 || value.length > 10) {
                                        this.setState({ showGenerateOtp: false });
                                    }
                                } else {
                                    this.setState({ showGenerateOtp: false });
                                }
                            }),
                        fullName: Yup.string()
                            .min(2, 'Too Short!')
                            .max(50, 'Too Long!')
                            .required('You must specify a  Name'),

                        emailId: Yup.string()
                            .email('Invalid E-Mail')
                            .required('You must specify a E-Mail Id'),
                        message: Yup.string()
                            .required('You must specify a Message'),
                    })}

                    onSubmit={(fields, { resetForm }, setFieldValue) => {

                        if (fields.termsandconditions === false || fields.termsandconditions === undefined) {
                            warning("Please select terms and conditions", warningNotification);
                        }
                        else {
                            if (this.state.isValidOtp) {
                                //  var data2 = localStorage.getItem("PersonnelData")
                                let data = {
                                    mobileNumber: fields.mobileNumber,
                                    fullName: fields.fullName,
                                    emailID: fields.emailId,
                                    enquiryMessage: fields.message,
                                    enquiryType: 1,
                                    //  learnerPartyID: JSON.parse(data2).responseObject.learnerPartyID
                                    // partyId:

                                }
                                // 
                                LoginService.TokenUser(res => {
                                    localStorage.setItem("jwt-token", res.responseObject);

                                    GeneralEnquiryService.PostEnquiry(data, res => {
                                        if (res.status === "success") {
                                            success(
                                                fields.fullName +
                                                " , Thank you for the Enquiry, we would contact you.",
                                                successNotification
                                            );
                                            resetForm({});
                                            // setFieldValue(
                                            //     (fields.emailID = "")
                                            //   );
                                            this.setState({

                                                display: "none"
                                            })

                                            window.location.reload();
                                        }
                                    });
                                });

                            }
                            else {
                                warning(
                                    "Enter Valid OTP",
                                    warningNotification
                                );
                            }
                        }
                    }
                    }
                    render={({ errors, touched, handleSubmit, setFieldValue, value }) => (
                        <React.Fragment>
                            <Form onSubmit={handleSubmit} className="form_name" id="getintouch" style={{ display: this.state.display }}>
                                <div className="modal-dialog modal-md" role="document">
                                    <div className="modal-content">
                                        <button
                                            type="reset"
                                            className="close md-hidden lg-hidden sm-hidden"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                            onClick={this.handleClose}
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        <section className="registration">
                                            <div className="modal-body">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="modal-head">
                                                                <div className="section-title-black" style={{ textAlign: "center" }} >
                                                                    <h2 >Get In Touch</h2>
                                                                    <p>Leave us your message and we will get back to you</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>FULL NAME <span className="validation"> *</span></label>
                                                            <Field type="text"
                                                                name="fullName"
                                                                id="fullName"
                                                                autoComplete="fullName"
                                                                className={
                                                                    "form-control" +
                                                                    (errors.fullName && touched.fullName
                                                                        ? " is-invalid"
                                                                        : "")
                                                                }
                                                                placeholder="Full Name"
                                                            />
                                                            <ErrorMessage
                                                                name="fullName"
                                                                className="validation"
                                                                component="div"
                                                            />
                                                            <label>EMAIL <span className="validation"> *</span></label>
                                                            <Field type="email" name="emailId"
                                                                id="emailId"
                                                                autoComplete="emailId"
                                                                className={
                                                                    "form-control" +
                                                                    (errors.emailId && touched.emailId
                                                                        ? " is-invalid"
                                                                        : "")
                                                                }
                                                                placeholder="Email ID"
                                                            />
                                                            <ErrorMessage
                                                                name="emailId"
                                                                className="validation"
                                                                component="div"
                                                            />
                                                            <label>MOBILE<span className="validation"> *</span></label>
                                                            <Field type="text" name="mobileNumber"
                                                                id="mobileNumber"
                                                                autoComplete="mobileNumber"
                                                                maxlength="10"
                                                                className={
                                                                    "form-control" +
                                                                    (errors.mobileNumber && touched.mobileNumber
                                                                        ? " is-invalid"
                                                                        : "")
                                                                }
                                                                placeholder="Mobile Number"
                                                            />
                                                            {this.state.showGenerateOtp &&
                                                                !this.state.isOTPGenerated && (
                                                                    <div>

                                                                        <a
                                                                            className="otp-resend"
                                                                            // onClick={this.handleGenerateOtp}
                                                                            onClick={this.startTimer}
                                                                        >
                                                                            Generate OTP
                                          </a>

                                                                    </div>
                                                                )}

                                                            <ErrorMessage
                                                                name="mobileNumber"
                                                                className="validation"
                                                                component="div"
                                                            />
                                                            {this.state.showOTPBox && (
                                                                <div className="">
                                                                    <label>
                                                                        ENTER OTP{" "}
                                                                        <span className="validation"> *</span>
                                                                    </label>
                                                                    <Field
                                                                        type="text"
                                                                        name="OTP"
                                                                        id="OTP"
                                                                        maxlength="4"
                                                                        onBlur={this.handleValidateOtp}
                                                                        className={"form-control"}
                                                                        placeholder=''
                                                                        style={{ width: "100%" }}
                                                                    />
                                                                    {this.state.showTimer ?
                                                                        <div>
                                                                            {this.state.showResendOtp && (
                                                                                <React.Fragment>
                                                                                    <div className="otp-resend">
                                                                                        <a
                                                                                            onClick={e => {
                                                                                                // alert(value.OTP)
                                                                                                // setFieldValue(value.OTP = "")
                                                                                                this.startTimer()

                                                                                            }}
                                                                                        // onClick={this.handleGenerateOtp}
                                                                                        >
                                                                                            Resend OTP
                                          </a>

                                                                                    </div>
                                                                                </React.Fragment>
                                                                            )}
                                                                            <h5 className="timer">{this.state.time.m} : {this.state.time.s} Time Left</h5>
                                                                        </div> : ''}



                                                                    <ErrorMessage
                                                                        name="OTP"
                                                                        component="div"
                                                                        className="validation"
                                                                    />
                                                                </div>
                                                            )}
                                                            <label>MESSAGE <span className="validation"> *</span></label>
                                                            <Field as="textarea" rows="2" cols="50" name="message"
                                                                id="message"
                                                                autoComplete="message"
                                                                className={
                                                                    "form-control" +
                                                                    (errors.message && touched.message
                                                                        ? " is-invalid"
                                                                        : "")
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="message"
                                                                className="validation"
                                                                component="div"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 col-sm-12 col-xs-12">

                                                        <Field
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name="termsandconditions"


                                                            style={{ marginRight: "10px" }}
                                                        />
                                                        <span
                                                            class="form-check-label"
                                                            for="gridCheck"
                                                            style={{ marginRight: "15px" }}
                                                        >
                                                            <a href={TC} target="_blank">Terms and Conditions</a>
                                                            <span className="validation"> *</span>
                                                        </span>
                                                    </div>
                                                </div>
                                                {!this.state.isMaintainenceActivated ?
                                                    <div className="getintouchbtn">
                                                        <button type="submit" className="btn-5">Submit
                                                            </button> </div> : ''
                                                }
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </Form>

                            <div
                                className="modal fade"
                                id="enq"
                                tabIndex="-1"
                                role="dialog"
                                aria-labelledby="modalLabel"
                                aria-hidden="true"
                            >
                                <Form onSubmit={handleSubmit} className="form_name">
                                    <div className="modal-dialog modal-lg" role="document">
                                        <div className="modal-content">
                                            <button
                                                type="reset"
                                                className="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                                onClick={this.handleClose}
                                            >
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                            <section className="registration">
                                                <div className="modal-body">
                                                    <div className="container">
                                                        <div className="row">
                                                            <div className="col-md-8">
                                                                <div className="modal-head">
                                                                    <div className="section-title-black" style={{ textAlign: "center" }} >
                                                                        <h2 >Get In Touch</h2>
                                                                        <p>Leave us your message and we will get back to you</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-8">
                                                            <div className="form-group">
                                                                <label>FULL NAME <span className="validation"> *</span></label>
                                                                <Field type="text"
                                                                    name="fullName"
                                                                    id="fullName"
                                                                    autoComplete="fullName"
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.fullName && touched.fullName
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                    placeholder="Full Name"
                                                                />
                                                                <ErrorMessage
                                                                    name="fullName"
                                                                    className="validation"
                                                                    component="div"
                                                                />
                                                                <label>EMAIL <span className="validation"> *</span></label>
                                                                <Field type="email" name="emailId"
                                                                    id="emailId"
                                                                    autoComplete="emailId"
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.emailId && touched.emailId
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                    placeholder="Email ID"
                                                                />
                                                                <ErrorMessage
                                                                    name="emailId"
                                                                    className="validation"
                                                                    component="div"
                                                                />
                                                                <label>MOBILE  <span className="validation"> *</span></label>
                                                                <Field type="text" name="mobileNumber"
                                                                    id="mobileNumber"
                                                                    autoComplete="mobileNumber"

                                                                    className={
                                                                        "form-control" +
                                                                        (errors.mobileNumber && touched.mobileNumber
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                    placeholder="Mobile Number"
                                                                />

                                                                <ErrorMessage
                                                                    name="mobileNumber"
                                                                    className="validation"
                                                                    component="div"
                                                                />



                                                            </div>
                                                            <div className="form-group">
                                                                <label>MESSAGE <span className="validation"> *</span></label>
                                                                <Field as="textarea" rows="8" cols="50" name="message"
                                                                    id="message"
                                                                    autoComplete="message"
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.message && touched.message
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="message"
                                                                    className="validation"
                                                                    component="div"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12"></div>
                                                        <div className="col-md-12 col-sm-12 col-xs-12">

                                                            <Field

                                                                className="form-check-input"
                                                                type="checkbox"
                                                                name="termsandconditions"


                                                                style={{ marginRight: "10px" }}
                                                            />
                                                            <span
                                                                class="form-check-label"
                                                                for="gridCheck"
                                                                style={{ marginRight: "15px" }}
                                                            >
                                                                <a href={TC} target="_blank">Terms and Conditions</a>
                                                                <span className="validation"> *</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {!this.state.isMaintainenceActivated ?
                                                        <button type="submit" className="btn-4 mt-30">Submit</button> : ''
                                                    }
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </React.Fragment>
                    )}
                />

            </div>
        );
    }
}

export default generalenquiry;
