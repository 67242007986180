import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DashboardHeader from "../../components/dashboardheader/dashboardheader";
import Finxfooter from "../finxfooter/finxfooter";
import LearnerDashboard from "../../components/learner-dashboard/learner-dashboard";
import { getProductsError, getProducts, getProductsPending } from "../../reducer/userReducer";
import { getCalenderEvents, getCalenderEventsPending, getCalenderEventsError } from "../../reducer/calenderEventsReducer";
import { getAlertNotificationEvents, getAlertNotificationEventsPending, getAlertNotificationEventsError } from "../../reducer/alertsNotificationReducer";
import { getNotificationEvents, getNotificationEventsPending, getNotificationEventsError } from "../../reducer/notificationReducer";
import { getProductPreRequisite, getProductPreRequisitePending, getProductPreRequisiteError } from "../../reducer/productPreRequisiteReducer";
import { getproductDetailsByParty, getproductDetailsByPartyPending, getproductDetailsByPartyError } from "../../reducer/productDetailsByPartyIDReducer";
import { getproductDetailsAll, getproductDetailsAllPending, getproductDetailsAllError } from "../../reducer/productDetailsAllReducer";

//import fetchProductsAction from '../../services/fetchUser';
import { fetchCalenderEvents, fetchAlertNotificationEvents, fetchNotificationEvents, fetchProductDetailsByPartyId, fetchProductDetailsAllIsActive, getProgressOverview } from "../../services/fetchLearnerDashboard.service";

import { getPersonnelData, getPersonnelAddressData } from "../../reducer/userProfileReducer";
import { RegisterUserService } from "../../services/registration.service";
import { LoginService } from "../../services/login.service";
import { error, errorNotification } from "../../components/notification/notifications";
import { getUserRoleAcess, getUserRoleAcessError, getUserRoleAcessPending } from "../../reducer/roleAccessReducer";
import {
  getIsMaintenance, getTimeRemaining, getIsNotified, getTimeNotiRemaining, getIsClicked,
   getPending, getError } from "../../reducer/maintenanceReducer";
import { fetchDeploymentFlagAPI } from "../../services/userManagementService";
import { getLearnersProgressOverview, getLearnersProgressOverviewPending, getLearnersProgressOverviewError } from "../../reducer/learnerProgressOverviewReducer";
import WelcomePage from "../../components/learner-dashboard/welcomepage";

const mapStateToProps = state => ({
  products: getProducts(state),

  personnelData: getPersonnelData(state.userProfileReducers),
  addressData: getPersonnelAddressData(state.userProfileReducers),

  userRoleAccess: getUserRoleAcess(state.roleAccessReducer),
  pendingUserRoleAccess: getUserRoleAcessPending(state.roleAccessReducer),
  errorUserRoleAccess: getUserRoleAcessError(state.roleAccessReducer),

  calenderEvents: getCalenderEvents(state.calenderEventsReducer),
  pendingCalenderEvents: getCalenderEventsPending(state.calenderEventsReducer),
  errorCalanderEvents: getCalenderEventsError(state.calenderEventsReducer),

  alertNotificationEvents: getAlertNotificationEvents(
    state.alertNotificationEventsReducer
  ),
  pendingAlertNotificationEvents: getAlertNotificationEventsPending(
    state.alertNotificationEventsReducer
  ),
  errorAlertNotificationEvents: getAlertNotificationEventsError(
    state.alertNotificationEventsReducer
  ),

  notificationEvents: getNotificationEvents(state.notificationEventsReducer),
  pendingNotificationEvents: getNotificationEventsPending(
    state.notificationEventsReducer
  ),
  errorNotificationEvents: getNotificationEventsError(
    state.notificationEventsReducer
  ),

  productPreRequisite: getProductPreRequisite(state.productPreRequisiteReducer),
  pendingProductPreRequisite: getProductPreRequisitePending(
    state.productPreRequisiteReducer
  ),
  errorProductPreRequisite: getProductPreRequisiteError(
    state.productPreRequisiteReducer
  ),

  productDetailsByPartyId: getproductDetailsByParty(
    state.productDetailsByPartyIDReducer
  ),
  pendingProductDetailsByPartyId: getproductDetailsByPartyPending(
    state.productDetailsByPartyIDReducer
  ),
  errorProductDetailsByPartyId: getproductDetailsByPartyError(
    state.productDetailsByPartyIDReducer
  ),

  productDetailsAll: getproductDetailsAll(state.productDetailsAllReducer),
  pendingproductDetailsAll: getproductDetailsAllPending(state.productDetailsAllReducer),
  errorproductDetailsAll: getproductDetailsAllError(state.productDetailsAllReducer),

  learnerProgressOverview: getLearnersProgressOverview(state.learnersProgressOverviewReducer),
  pendinglearnerProgressOverview: getLearnersProgressOverviewPending(state.learnersProgressOverviewReducer),
  errorlearnerProgressOverview: getLearnersProgressOverviewError(state.learnersProgressOverviewReducer),
  isMaintenance: getIsMaintenance(state.maintenanceReducer),
  timeRemaining: getTimeRemaining(state.maintenanceReducer),
  isClicked: getIsClicked(state.maintenanceReducer),
  isNotified:getIsNotified(state.maintenanceReducer),
  timeNotiRemaining:getTimeNotiRemaining(state.maintenanceReducer),
  pending: getPending(state.maintenanceReducer),
  error: getError(state.maintenanceReducer),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      //fetchProducts: fetchProductsAction,
      fetchUserProfileData: RegisterUserService.getUserPersonnelDetails,
      fetchUserAddressData: RegisterUserService.getUserAddress,
      fetchCalenderEvents: fetchCalenderEvents,
      fetchAlertNotificationEvents: fetchAlertNotificationEvents,
      fetchNotificationEvents: fetchNotificationEvents,
      //fetchProductPreRequisiteByParentProductId: fetchProductPreRequisiteByParentProductId,
      fetchProductDetailsByPartyId: fetchProductDetailsByPartyId,
      fetchProductDetailsAllIsActive: fetchProductDetailsAllIsActive,
      fetchLearnerProfileProgressOverview: getProgressOverview,
      fetchDeploymentFlagAPI1:fetchDeploymentFlagAPI,
      //fetchUserRoleAccess: fetchUserRoleAccess
    },
    dispatch
  );

const connectFunction = connect(mapStateToProps, mapDispatchToProps);

const LearnerDashboardLandingAuthenticated = connectFunction(

  class extends Component {
    constructor(props) {
      super(props);
      console.log("in studnbt dash", props);
      this.state = {
        isVisible: false,
        isAuthorised: false,
      };
    }

    componentDidMount() {

      let partyID = localStorage.getItem("userid-Token");
      LoginService.TokenUser(res => {
        localStorage.setItem("jwt-token", res.responseObject);
        if (localStorage.getItem('reload') === 'true') {
          localStorage.setItem('reload', 'false');
          window.location.reload();
        }

      });

      const {
        fetchUserProfileData,
        fetchUserAddressData,
        fetchCalenderEvents,
        fetchAlertNotificationEvents,
        fetchNotificationEvents,
        //fetchProductPreRequisiteByParentProductId,
        fetchProductDetailsByPartyId,
        fetchProductDetailsAllIsActive,
        fetchLearnerProfileProgressOverview,
        fetchDeploymentFlagAPI1,
      } = this.props;


      fetchProductDetailsByPartyId("learnerPartyId=" + partyID);
      fetchCalenderEvents();
      fetchAlertNotificationEvents();
      //sneha change
     // fetchNotificationEvents("partyId=" + partyID + "&deliveryChannel=EMAIL"); //"partyId=" + partyID
     fetchNotificationEvents("partyId=" + partyID); //"partyId=" + partyID
     fetchProductDetailsAllIsActive(true);
      fetchUserProfileData("learnerPartyID=" + partyID);
      fetchUserAddressData("partyId=" + partyID, (res) => {
        console.log("learner dashboard landing ", res);
      });
      fetchLearnerProfileProgressOverview("learnerPartyId=" + partyID)
      fetchDeploymentFlagAPI1();
      //fetchProductPreRequisiteByParentProductId('parentProductID=' + partyID);
    }

    render() {

      const {
        personnelData,
        addressData,
        products,
        calenderEvents,
        pendingCalenderEvents,
        errorCalanderEvents,
        alertNotificationEvents,
        pendingAlertNotificationEvents,
        notificationEvents,
        pendingNotificationEvents,
        productPreRequisite,
        productDetailsByPartyId,
        productDetailsAll,
        pendingProductDetailsByPartyId,
        pendingproductDetailsAll,
        learnerProgressOverview,
        pendinglearnerProgressOverview,
        userRoleAccess,
        pendingUserRoleAccess,
        isMaintenance,
        timeRemaining,
        isClicked,
        isNotified,
        timeNotiRemaining,
      } = this.props;
      localStorage.setItem("PersonnelData", JSON.stringify(this.props.personnelData))
      localStorage.setItem('AdminAlertNotification', JSON.stringify(alertNotificationEvents))
      //localStorage.setItem('AdminNotification', JSON.stringify(notificationEvents))

      if (errorCalanderEvents === "Invalid Token") {
        error("Please Relogin.", errorNotification);
        this.props.history.push("/");
      }

      if (!pendingUserRoleAccess) {
        if (personnelData.status === "success" && addressData.status === "success"
          && !pendingCalenderEvents && !pendingproductDetailsAll && !pendingProductDetailsByPartyId
          && !pendinglearnerProgressOverview && !pendingAlertNotificationEvents
          && !pendingNotificationEvents) {

          // if (personnelData.responseObject["isTourCompleted"] === 0) {
          //   return (
          //     <React.Fragment>
          //       <WelcomePage {...this.props} />
          //     </React.Fragment>
          //   );
          // } else {
          return (
            <React.Fragment>
              <DashboardHeader
                {...this.props}
              />
              <LearnerDashboard
                isMaintenance={isMaintenance}
                timeRemaining={timeRemaining}
                isClicked={isClicked}
                isNotified={isNotified}
                timeNotiRemaining={timeNotiRemaining}
                history={this.props.history}
                products={products}
                calenderEvents={calenderEvents}
                productPreRequisite={productPreRequisite}
                productDetailsByPartyId={productDetailsByPartyId}
                personnelData={personnelData.responseObject}
                addressData={addressData.responseListObject}
                productDetailsAll={productDetailsAll}
                learnerProgressOverviewData={learnerProgressOverview}
                userRoleAccess={userRoleAccess}
              />
              <Finxfooter />
            </React.Fragment>
          );
          // }
        } else {
          return null;
        }
      } else {
        return null;
      }
    }
  }
);

export default LearnerDashboardLandingAuthenticated;
