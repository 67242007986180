import React from 'react';
import ErrorImg from '../../assets/images/error.png';

class ErrorBoundary extends React.Component {

    constructor(props) {
        super(props);
        //localStorage.clear();
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true });
        // You can also log the error to an error reporting service
        //logErrorToMyService(error, info);
        if (localStorage.getItem('reload1') === 'true') {
            localStorage.setItem('reload1', 'false');
            window.location.reload();
        }
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <React.Fragment>
                <div class="container">
                    <div id="notfound">
                        <div class="notfound">
                            <div class="notfound-404">
                                <img src={ErrorImg} class="img-responsive center-block" width="200px" height="auto" />
                            </div>
                            <h2>Something went wrong !!</h2>
                            <p>Please contact RCP Support ...</p>
                            <button type="button" class="btn-1 mt-30">
                                <a href="/">Go To Homepage</a></button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
