import React from 'react';
import './loader.css';

class finxproduct extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMaintainenceActivated: false,
            show:"none"
        }
    }
    componentDidMount(){
    }
    render() {
        return (
            <React.Fragment>
                <section className="enroll section-padding">
                    {this.state.isMaintainenceActivated ?
                        <div id="maintenance">
                            <div class="wrapper" id="avisPopUp">
                                <div class="construction">
                                    <p>System will be Under Maintenance on 27nd ( Tuesday ) September 2022 for upgradation starting from 03.00 PM to 04.00 PM.
                                       Inconvenience is regretted. Reach out to RCP Support Team for any clarifications.
                                    </p>
                                </div>
                            </div>
                        </div> : ''}

                </section>
            </React.Fragment>
        );
    }
}
export default finxproduct;