import React from "react";

class MyProductNoRecord extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            euinFlag: false
        }
    }

    componentDidMount() {
        var data1 = localStorage.getItem("PersonnelData")
        if(JSON.parse(data1).responseObject.euinNumber !== "" && JSON.parse(data1).responseObject.euinNumber !== null && JSON.parse(data1).responseObject.euinNumber != undefined){
            if(JSON.parse(data1).responseObject.euinValidUpto !== "" && JSON.parse(data1).responseObject.euinValidUpto !== null && JSON.parse(data1).responseObject.euinValidUpto != undefined){
            var today = new Date();
            var euinDt = JSON.parse(data1).responseObject.euinValidUpto.split(" ");
            let euinDate = euinDt[0].split("/");
            let euinDate2 =
             euinDate[2] +
              "-" +
              euinDate[1] +
              "-" +
              euinDate[0];
           
           let  mydate = new Date(euinDate2);

            var diff = mydate.getTime() - today.getTime();  
           
            var daydiff = diff / (1000 * 60 * 60 * 24);   
    
            if(daydiff > 365){
                this.setState({ euinFlag: true  });
                
            } else {
                this.setState({ euinFlag: false  });
            }}
        }
    }


    render() {
        return (
            <React.Fragment>
                {/* <div className="course">
                    {this.props.CalledFrom === 'MyLearning' ?
                        "No Course has been Assigned to you." : ""}
                    
                </div> */}
              
                {this.state.euinFlag ? (
                <div className="course">
                    {this.props.CalledFrom === 'MyLearning' ?
                        "Your EUIN is still active and not eligible for renewal. You can subscribe to other courses in the What’s next section." : ""}
                    
                </div>
                )
                :
                <div className="course">
                    {this.props.CalledFrom === 'MyLearning' ?
                        "" : ""}
                    
                </div>
                }
            </React.Fragment>
        )
    }

}

export default MyProductNoRecord;
