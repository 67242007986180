// Library Imports
import React, { Component } from "react";

export default class LoadingFallBack extends Component {
  render() {
    console.log('Loading FallBack Called');
    return (
      <section>
        <div id="loader">
          <div class="loading" style={{ zIndex: 9999 }}>
            <div class="dot"></div>
            <div class="dot1"></div>
            <div class="dot2"></div>
            <div class="dot3"></div>
            <div class="dot4"></div>
          </div>
        </div>
      </section>
    );
  }
}
