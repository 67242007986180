
import RestDataSource from "./restdatasource";
import { Global_var } from "../global/global_var";

export const LookUpService = {
  getLookingFor,
  getOccuption,
  getWhoareu,
  getAmc,
  getJob,
  getCielReport,
  getEmpanelmentReport,
  getMandateReport,
  notRegisteredUsers
};

function getCielReport(TypeId, fn, fnError) {
  //var url = "http://172.16.16.99:9006" + Global_var.URL_GETCIELREPORT;
  var url = Global_var.BASEURL + Global_var.URL_GETCIELREPORT;
  return new RestDataSource(url, fnError).GetOneByParam(TypeId, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function getJob(TypeId, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GETJOB;
  return new RestDataSource(url, fnError).GetOneByParam(TypeId, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function getLookingFor(TypeId, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GETLOOKUP_BY_REG_LOOKING_FOR;
  return new RestDataSource(url, fnError).GetOneByParam(TypeId, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function getOccuption(TypeId, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GETLOOKUP_BY_REG_LOOKING_FOR;
  return new RestDataSource(url, fnError).GetOneByParam(TypeId, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function getWhoareu(TypeId, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GETLOOKUP_BY_REG_LOOKING_FOR;
  return new RestDataSource(url, fnError).GetOneByParam(TypeId, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function getAmc(fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GETAMC_LIST;
  return new RestDataSource(url, fnError).GetData(res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function getEmpanelmentReport(TypeId, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GETEMPANELMENTREPORT;
  return new RestDataSource(url, fnError).GetOneByParam(TypeId, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function getMandateReport(TypeId, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_MANDATE_REPORT;
  return new RestDataSource(url, fnError).GetOneByParam(TypeId, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function notRegisteredUsers(TypeId, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_NOT_REGISTERED_REPORT;
  return new RestDataSource(url, fnError).GetOneByParam(TypeId, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}