import React from "react";
import AMCFunnelChart from "../../assets/images/CIEL-AMC-funnel.png";
import { Chart } from "react-google-charts";
import { bottom } from "@popperjs/core";

class funnel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pieData: [
                { value: 10, label: 'apples', color: 'red' },
                { value: 20, label: 'bananas', color: 'green' },
                { value: 30, label: 'oranges', color: 'blue' },
            ],


            pieOptions: {
                exportEnabled: true,
                animationEnabled: true,
                title: {
                    text: "Website Traffic Sources"
                },
                data: [{
                    type: "pie",
                    startAngle: 75,
                    toolTipContent: "<b>{label}</b>: {y}%",
                    showInLegend: "true",
                    legendText: "{label}",
                    indexLabelFontSize: 16,
                    indexLabel: "{label} - {y}%",
                    dataPoints: [
                        { y: 18, label: "Direct" },
                        { y: 49, label: "Organic Search" },
                        { y: 9, label: "Paid Search" },
                        { y: 5, label: "Referral" },
                        { y: 19, label: "Social" }
                    ]
                }]
            }
        }
    }



    componentDidMount() {


        //         getFunnelCount(
        //             "",
        //             res => {
        //                 alert('aaa')

        // //this.setState({data:res.responseListObject})
        // console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa')
        //                 console.log(res)
        //             }
        //         );


    }


    render() {
        let Registered = 0
        let feat = 0
        let subscribed = 0
        let certification = 0
        let arnApplied = 0
        let Empanelled = 0

        {

            this.props.data !== null && this.props.data.length > 0 && this.props.data.map(a => {

                if (a.stage == "REGISTERED") {
                    Registered = a.count
                }
                if (a.stage == "FEAT COMPLETED") {
                    feat = a.count
                }
                if (a.stage == "NISM VA SUBSCRIBED") {
                    subscribed = a.count
                }
                if (a.stage == "NISM CERTIFICATION COMPLETED") {
                    certification = a.count
                }
                if (a.stage == "KYD-ARN ACCEPTED") {
                    arnApplied = a.count
                }
                if (a.stage == "EMPANELLED") {
                    Empanelled = a.count
                }
            })
        }



        return (
            <div>
               
                {/* <h3 className="learner-title" >Stagewise Summary</h3> */}
                {/* <div id="funnelChart">

                    <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">



                        <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12 pl pr">
                            <img src={AMCFunnelChart} className="img-responsive ml-35" />
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12 pl pr">
                            <ul>
                                <li className="F-mt">
                                    <span className="F-blue"><i class="fa fa-stop" aria-hidden="true"></i></span>
                                    <strong>
                                       
                                        {Registered} </strong> <span className="lable  "> - Registered</span>
                                </li>
                                <li className="F-mt1">
                                    <span className="F-orange"><i class="fa fa-stop" aria-hidden="true"></i></span>
                                    <strong>
                                       

                                        {feat}</strong> <span className="lable  "> - FEAT Completed </span>
                                </li>
                                <li className="F-mt2">
                                    <span className="F-green"><i class="fa fa-stop" aria-hidden="true"></i></span>
                                    <strong>
                                       
                                        {subscribed} </strong>  <span className="lable  "> - NISM VA Subscribed </span>
                                </li>
                                <li className="F-mt3">
                                    <span className="F-purple"><i class="fa fa-stop" aria-hidden="true"></i></span>
                                    <strong>
                                      
                                        {certification}   </strong> <span className="lable  "> - NISM Certification Completed</span>
                                </li>
                                <li className="F-mt4">
                                    <span className="F-skyblue"><i class="fa fa-stop" aria-hidden="true"></i></span>
                                    <strong>
                                       


                                        {arnApplied}   </strong> <span className="lable  "> - ARN Approved </span>
                                </li>
                                <li className="F-mt5">
                                    <span className="F-red"><i class="fa fa-stop" aria-hidden="true"></i></span>
                                    <strong>
                                        {Empanelled}

                                        
                                    </strong> <span className="lable  "> - Empanelled </span>
                                </li>
                            </ul>
                        </div>
                       

                    </div>

                   
                    <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                         <Chart
                            width={'100%'}
                            height={'300px'}
                            margin={'0px'}
                            chartType="PieChart"
                            loader={<div>Loading Chart</div>}
                            data={[
                                ['Task', 'Hours per Day'],
                                ['Registered', Registered],
                                ['FEAT Completed', feat],
                                ['NISM VA Subscribed', subscribed],
                                ['NISM Certification Completed', certification],
                                ['ARN Approved', arnApplied],
                                ['Empanelled Approved', Empanelled],
                            ]}

                            options={{
                                // title: 'My Daily Activities',
                                legend: bottom,
                                is3D: true,
                                width: "100%",
                                height: 300,
                                // colors: ['#0f65b3']['#000'],
                                slices: {
                                    0: { color: '#0f65b3' },
                                    1: { color: '#de8123' },
                                    2: { color: '#519b1c' },
                                    3: { color: '#a02769' },
                                    4: { color: '#328ea7' },
                                    5: { color: '#d84640' },
                                },
                            }}
                            rootProps={{ 'data-testid': '1' }}
                        />


                       

                    </div>
                </div> */}

            </div>

        );
    }
}

export default funnel;
