// Library Imports
import React from 'react';
import * as moment from "moment";
import loadable from "@loadable/component";
import ReactHTMLParser from "react-html-parser";
// Service Imports
import { FetchNotificationService } from "../../services/fetchnotification";
// Other Imports
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
import { error, success, warning, warningNotification, errorNotification, successNotification } from '../notification/notifications';
// Component Imports
import LoadingFallBack from "../../components/loadingFallBack"
const Finxfooter = loadable(() => import('../../containers/finxfooter/finxfooter'), {
    fallback: <LoadingFallBack />
});
const Dashboardheader = loadable(() => import('../dashboardheader/dashboardheader'), {
    fallback: <LoadingFallBack />
});
// import $ from "jquery";
// import { LoginService } from "../../services/login.service";

class Notification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inboxCount:"",
            fetchalerts: '',
            documentList:[],
            currentTab:localStorage.getItem("roleId") == "6"?0:1,
           // notification: this.props.history.location.state !== undefined ? this.props.history.location.state.notification : JSON.parse(localStorage.getItem('AdminNotification')),
            alertid: this.props.history.location.state !== undefined ? this.props.location.state.alertid : 0,
            value: this.props.history.location.state !== undefined ? this.props.location.state.value : "",
           // inboxCount: this.props.history.location.inboxCount !== undefined ? this.props.location.state.inboxCount : "",
        }
        this.handleCancel = this.handleCancel.bind(this);
    }
    handleCancel = () => {
    //    this.props.history.goBack('-1');
this.props.history.push("/my-inbox",{currentTab:this.state.currentTab})
  

    };
    handleDocumentDownload=async (item)=>{
            let params=`documentId=${item[1]}`
            this.setState({loading:true})
            FetchNotificationService.documentDownload(params,(res)=>{
              if(res.status==="success"){
                this.setState({loading:false})
          const link = document.createElement('a');
          link.href = res.documentUrl;
          link.target="_blank"
          link.download =res.documentUrl;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
              }
              else{
                this.setState({loading:false})
                warning(res.reasonText,warningNotification)
              }
            })
    }
    componentDidMount() {
        if(this.props.location?.state?.documentList){
           let documentList=this.props.location?.state?.documentList
            this.setState({documentList})
        }
        if(this.props.location?.state?.currentTab){
            let currentTab=this.props.location?.state?.currentTab
             this.setState({currentTab})
         }
        if(this.props.history.location.state !== undefined){
            this.setState({
                notification: this.props.history.location.state.notification
              })
        } else {
            let partyID = localStorage.getItem("ciel-userid-Token");
            FetchNotificationService.fetchNotification("partyId=" + partyID, (res1) => {
            this.setState({
                 notification: res1.responseListObject
            })
         })
        }

        let senderPartyId = localStorage.getItem("ciel-userid-Token");
        FetchNotificationService.getCount("senderPartyId=" + senderPartyId, (res1) => {
            this.setState({
              inboxCount: res1.responseObject.unreadCount
            })
          })
        
    }

    render() {
        return (
            <React.Fragment>
                  {this.state.loading && (
                      <PopUpPortal
                        HidePopup={this.state.loading}
                        IsVisible={this.state.loading}
                      />
                    )}
                <Dashboardheader
                    //alertNotificationEvents={this.props.location.state.alerts} {...this.props}
                    //notificationEvents={this.props.location.state.notification}{...this.props}
                    {...this.props}
                />

                <section id="notification-page" class="pt-50 mb-50">
                    <div class="container">
                        <div class="panel-group" id="accordion">
                            {this.state.notification !== undefined ? this.state.notification ? this.state.notification.map((item, key) => (
                                <div class="panel panel-default">
                                <a class="accordion-toggle">
                                    {/* <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href={'#collapse' + item.notifyHistoryId}> */}
                                        {/* <div class="panel-heading unread"> */}
                                        <div className={"panel-heading " +(item.read_unread ? '': 'unread' )}>
                                            <div className="row">
                                            <div className="col-md-11 col-sm-10 col-xs-10 pr">
                                                <h4 class="panel-title">
                                                    {ReactHTMLParser(item.emailSubjectText)}
                                                </h4>
                                                <h4 class="panel-title-date">
                                                    {moment(item.lastAttemptDatetime?.split(" ")[0], "DD/MM/YYYY").format("DD-MM-YYYY")}
                                                </h4>
                                                </div>
                                                <div className="col-md-1 col-sm-2 col-xs-2 pl">
                                                <h4 class="panel-title-email text-right">Email</h4>
                                            </div>
                                            </div>
                                        </div>
                                    </a>
                                   
                                    {/* <div id={'collapse' + item.notifyHistoryId}
                                        class={"panel-collapse collapse" + (this.props.location.state.alertid ? (this.props.location.state.alertid === item.notifyHistoryId ? "in" : '') : "")} > */}
                                        <div id={'collapse' + item.notifyHistoryId}
                                         >
                                        <div class="panel-body">
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <div class="bg-box">
                                                    <div class="payment-detail">
                                                        <div class="table-responsive">
                                                            <table class="paydetail-table">
                                                                <tr>
                                                                    <td>From</td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <small class="text-warning pull-right">{moment(item.lastAttemptDatetime?.split(" ")[0], "DD/MM/YYYY").format("DD-MM-YYYY") + " " + item.lastAttemptDatetime?.split(" ")[1]}</small>
                                                                        <strong class="text-info"> {item.fromEmailId}</strong></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>To</td>
                                                                    <td>:</td>
                                                                    <td><b>{ReactHTMLParser(item.toEmailIdOrMobileNo)}</b></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>cc</td>
                                                                    <td>:</td>
                                                                    <td><b>{ReactHTMLParser(item.ccEmailId)}</b></td>
                                                                </tr>
                                                                {/* <tr>
                                                                    <td>bcc</td>
                                                                    <td>:</td>
                                                                    <td><b> {ReactHTMLParser(item.bccEmailId)}</b></td>
                                                                </tr> */}
                                                                <tr>
                                                                    <td>Subject</td>
                                                                    <td>:</td>
                                                                    <td><b class="text-info">{ReactHTMLParser(item.emailSubjectText)}</b></td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td><b>{ReactHTMLParser(item.emailSMSBodyText)}</b></td>
                                                                    <td><b>{ReactHTMLParser(item.addSignatureText)}</b></td>
                                                                </tr>
                                                                    {this.state.documentList?.length>0&&
                                                                   <tr>
                                                                    <td>Attachments</td>
                                                                    <td>:</td>
                                                                    {/* {this.state.documentList.map((item1)=>(
                                                                     <td key={item1[1]}>  
                                                                     <p>
                                                                     <a onClick={()=>{this.handleDocumentDownload(item1)}}>
                                                                        {item1[0]}
                                                                        {" "}
                                                                        <i
                                                                        className="fa fa-download"
                                                                        aria-hidden="true"
                                                                        ></i>
                                                                        </a>
                                                                    </p>   
                                                                        </td>
                                                                    )) 
                                                                   } */}
                                                                   {this.state.documentList.map((item1, index) => (
                                                                    <tr key={index}>
                                                                        <td>  
                                                                            <p>
                                                                                <a onClick={() => { this.handleDocumentDownload(item1) }}>
                                                                                    {item1[0]}
                                                                                    {" "}
                                                                                    <i className="fa fa-download" aria-hidden="true"></i>
                                                                                </a>
                                                                            </p>   
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                                </tr>
                                                                     }
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            )) : '' : ''

                            }
                        </div>
                        <div className="row">
                            <button className="btn-5 mr-20 ml-20 mt-10 pull-right" onClick={this.handleCancel}><span aria-hidden="true">Back</span></button>
                        </div>

                    </div>
                </section>

                <Finxfooter />
            </React.Fragment>
        )
    }

}

export default Notification;

