import React from 'react';
import AdminHeader from "../dashboardheader/adminheader";
import { LoginService } from "../../services/login.service";
import { error, success, warning, warningNotification, errorNotification, successNotification } from '../notification/notifications';
import ReactHTMLParser from "react-html-parser";
import Finxfooter from "../../containers/finxfooter/finxfooter";
import { FetchNotificationService } from "../../services/fetchnotification";
import $ from "jquery";

class AdminNotification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fetchalerts: '',
            //notification: this.props.history.location.state !== undefined ? this.props.history.location.state.notification : JSON.parse(localStorage.getItem('AdminNotification')),
            alertid: this.props.history.location.state !== undefined ? this.props.location.state.alertid : 0
        }
        this.handleCancel = this.handleCancel.bind(this);

    }

    componentDidMount() {

        if(this.props.history.location.state !== undefined){
            this.setState({
                notification: this.props.history.location.state.notification
              })
        } else {
            let partyID = localStorage.getItem("ciel-userid-Token");
            FetchNotificationService.fetchNotification("partyId=" + partyID, (res1) => {
            this.setState({
                 notification: res1.responseListObject
            })
         })
        }

    }

    handleCancel = () => {
        this.props.history.goBack('-1');
    };
    render() {
        return (
            <React.Fragment>
                <AdminHeader
                    {...this.props}
                />

                <section id="notification-page" class="pt-50 mb-50">
                    <div class="container">
                        <div class="panel-group" id="accordion">
                            {this.state.notification !== undefined ? this.state.notification ? this.state.notification.map((item, key) => (
                                <div class="panel panel-default">
                                    <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href={'#collapse' + item.notifyHistoryId}>
                                        <div class="panel-heading">
                                            {/* <span className="pull-right">Email</span> */}
                                            <div className="col-md-9 col-sm-9 col-xs-9">
                                                <h4 class="panel-title">
                                                    {ReactHTMLParser(item.emailSubjectText)}
                                                </h4>
                                            </div>
                                            <div className="col-md-3 col-sm-3 col-xs-3">
                                                <h4 class="panel-title text-right">Email</h4>
                                            </div>
                                        </div>
                                    </a>
                                    <div id={'collapse' + item.notifyHistoryId}
                                        class={"panel-collapse collapse" + (this.props.location.state !== undefined ? this.props.location.state.alertid ? (this.props.location.state.alertid === item.notifyHistoryId ? "in" : '') : '' : '')} >
                                        <div class="panel-body">
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <div class="bg-box">
                                                    <div class="payment-detail">
                                                        <div class="table-responsive">
                                                            <table class="paydetail-table">
                                                                <tr>
                                                                    <td>From</td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <small class="text-warning pull-right">{item.lastAttemptDatetime}</small>
                                                                        <strong class="text-info"> {item.fromEmailId}</strong></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>To</td>
                                                                    <td>:</td>
                                                                    <td><b>{ReactHTMLParser(item.toEmailIdOrMobileNo)}</b></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>cc</td>
                                                                    <td>:</td>
                                                                    <td><b>{ReactHTMLParser(item.ccEmailId)}</b></td>
                                                                </tr>
                                                                {/* <tr>
                                                                    <td>bcc</td>
                                                                    <td>:</td>
                                                                    <td><b> {ReactHTMLParser(item.bccEmailId)}</b></td>
                                                                </tr> */}
                                                                <tr>
                                                                    <td>Subject</td>
                                                                    <td>:</td>
                                                                    <td><b class="text-info">{ReactHTMLParser(item.emailSubjectText)}</b></td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td><b>{ReactHTMLParser(item.emailSMSBodyText)}</b></td>
                                                                    <td><b>{ReactHTMLParser(item.addSignatureText)}</b></td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            )) : '' : ''

                            }
                        </div>
                        <div className="row">
                            <button className="btn-5 mr-20 ml-20 mt-10 pull-right" onClick={this.handleCancel}><span aria-hidden="true">Back</span></button>
                        </div>

                    </div>
                </section>


                <Finxfooter />
            </React.Fragment>
        )
    }

}

export default AdminNotification;

