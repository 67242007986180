import { Global_var } from "../global/global_var";
import RestDataSource from "../services/restdatasource";
import { LoginService } from "./login.service";

export const RegionManagementService = {
  getCountry,
  getState,
  getCity,
  getAddressByPin,
  getCityById,
  getCenter,
  getCompanyList,
  getCompanyWithId,
  getUserDetails,
  getUserMandate,
  getMandateComponents,
  productlistWithId,
  saveMandateDetails,
  saveRcpLearnerAssessment,
  learnerMandateDetails,
  getUserMandateById,
  getLearnerSubscribedProduct,
  getEncryptedString,
  getOthersCompanyWithId,
  validateEmail,
  getCompanyListById,
  othersCompanyValidate,
  getUserEmailFlag,
  getLearnerCompany,
  getCompanyMandateList,
  getUserId,
  checkNismValidity,
  setNismRenewalflag,
  updateUserWfJourney,
  checkInMandate,
  checkMandateDetails,
};

function getCountry(fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GETCOUNTRY;
  return new RestDataSource(url, fnError).GetData(res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function getState(country, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_GETSTATE;
        return new RestDataSource(url, fnError).GetOneByParam(country, res => {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
          }
        }
      );
    }
  }, fnError);
}

function getCity(stateId, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_GETCITY;
        return new RestDataSource(url, fnError).GetOneByParam(stateId, res => {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
          }
        }
      );
    }
  }, fnError);
}

function getAddressByPin(pinno, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_REGION_MANAGEMENT_BY_PIN;
  //  var url = Global_var.BASEURL+ Global_var.URL_GETADDRESSBYPIN;
  // Global_var.BASEURL
  return new RestDataSource(url, fnError).GetOneByParam(pinno, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}


function getCityById(stateId, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_CITY_BY_ID;
  return new RestDataSource(url, fnError).GetOneByParam(stateId, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}


function getCenter(stateId, fn, fnError) {

  //var url = "http://192.168.12.100:9007/fc-learner-dashboard/api/v1/NISM_Location/get"
  var url = Global_var.BASEURL + Global_var.URL_CENTER;
  return new RestDataSource(url, fnError).GetOneByParam(stateId, res => {
    if (res != null) {

      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function getCompanyList(fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_COMPANY_LIST;
  
  return new RestDataSource(url, fnError).GetData((res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function getCompanyWithId(Id, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_COMPANY_BY_ID;

  return new RestDataSource(url, fnError).GetOneByParam(Id, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function getUserDetails(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_USER_DETAILS;

  return new RestDataSource(url, fnError).GetOneByParam(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function getUserMandate(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_USER_MANDATE_DETAILS;

  return new RestDataSource(url, fnError).GetOneByParam(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function getMandateComponents(id, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_MANDATE_COMPONENTS;

  return new RestDataSource(url, fnError).GetOneByParam(id, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function productlistWithId(data, fn) {
    var url = Global_var.BASEURL + Global_var.URL_GET_PRODUCT_BY_IDS;
    return new RestDataSource(url).Store(data, res => fn(res.data));
}

function saveMandateDetails(data, fn) {
  var url = Global_var.BASEURL + Global_var.URL_SAVE_MANDATE;
  return new RestDataSource(url).Store(data, res => fn(res.data));
}

function saveRcpLearnerAssessment(data, fn) {
  var url = Global_var.BASEURL + Global_var.URL_SAVE_RCP_LEARNER_ASSESSMENT;
  return new RestDataSource(url).Store(data, res => fn(res.data));
}

// function saveRcpLearnerAssessment(id, fn, fnError) {
//   var url = Global_var.BASEURL + Global_var.URL_SAVE_RCP_LEARNER_ASSESSMENT;

//   return new RestDataSource(url, fnError).GetOneByParam(id, (res) => {
//     if (res != null) {
//       if (res.headers["jtitoken"] != null) {
//         localStorage.setItem("jti-token", res.headers.jtitoken);
//       }
//       fn(res.data);
//     }
//   });
// }

function learnerMandateDetails(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_LEARNER_MANDATE_DETAILS;

  return new RestDataSource(url, fnError).GetOneByParam(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function getUserMandateById(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_MANDATE_BY_ID;

  return new RestDataSource(url, fnError).GetOneByParam(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function getLearnerSubscribedProduct(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_LEARNER_SUBSCRIBED_PRODUCT;

  return new RestDataSource(url, fnError).GetOneByParam(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function getEncryptedString(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_ENCRYPTED_STRING;

  return new RestDataSource(url, fnError).GetOneByParam(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function getOthersCompanyWithId(companyId, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_OTHER_COMPANY_WITH_ID;
        return new RestDataSource(url, fnError).GetOneByParam(companyId, res => {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
          }
        }
      );
    }
  }, fnError);
}

function validateEmail(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_VALIDATE_EMAIL;
  return new RestDataSource(url, fnError).GetOneByParam(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function getCompanyListById(companyId, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_COMPANY_WITH_ID;
        return new RestDataSource(url, fnError).GetOneByParam(companyId, res => {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
          }
        }
      );
    }
  }, fnError);
}

function othersCompanyValidate(data, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_VALIDATE_OTHER_COMPANY;

    return new RestDataSource(url).Store(data, res => fn(res.data));
  });
}

function getUserEmailFlag(partyId, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_USER_EMAIL_FLAG;
        return new RestDataSource(url, fnError).GetOneByParam(partyId, res => {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
          }
        }
      );
    }
  }, fnError);
}

function getLearnerCompany(learnerId, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_LEARNER_COMPANY;
        return new RestDataSource(url, fnError).GetOneByParam(learnerId, res => {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
          }
        }
      );
    }
  }, fnError);
}

function getCompanyMandateList(companyId, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_MANDATE_BY_COMPANY_ID;
        return new RestDataSource(url, fnError).GetOneByParam(companyId, res => {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
          }
        }
      );
    }
  }, fnError);
}

function getUserId(emailID, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_USER_ID;
        return new RestDataSource(url, fnError).GetOneByParam(emailID, res => {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
          }
        }
      );
    }
  }, fnError);
}

//Renewal Api:

function checkNismValidity(learnerID, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_NISM_VALIDITY_CHECK;

  return new RestDataSource(url, fnError).GetOneByParam(learnerID, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function setNismRenewalflag(data, fn) {
  var url = Global_var.BASEURL + Global_var.URL_SET_NISM_RENEW_FLAG;
  return new RestDataSource(url).Store(data, res => fn(res.data));
}

function updateUserWfJourney(data, fn) {
  var url = Global_var.BASEURL + Global_var.URL_UPDATE_USER_WF_JOURNEY;
  return new RestDataSource(url).Store(data, res => fn(res.data));
}

function checkInMandate(data, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_CHECK_IN_MANDATE;

    return new RestDataSource(url).Store(data, res => fn(res.data));
  });
}

function checkMandateDetails(data, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_CHECK_MANDATE_DETAILS;

    return new RestDataSource(url).Store(data, res => fn(res.data));
  });
}
