import RestDataSource from "./restdatasource";
import { Global_var } from "../global/global_var";
import { fetchAssessmentTermsAndCondition, fetchAssessmentInstruction } from '../action/preAssessmentAction';
import { LoginService } from './login.service';
import { success, successNotification, error, errorNotification, warning, warningNotification } from "../components/notification/notifications";
import { DesktopWindows } from "@material-ui/icons";

export function fetchTermsAndCondition(userRoleDetails) {
  var url = Global_var.BASEURL + Global_var.PREASSESSMENT_TERMS_CONDITION;
  // var url = "http://192.168.10.107:9000/fc-learner/api/v1/assessment/term_condition/_get"
  // return new RestDataSource(url, fnError).Store(userRoleDetails, (res) => fn(res.data));
  return dispatch => {

    var respData = [];

    return new RestDataSource(url, err =>
      dispatch(fetchAssessmentTermsAndCondition(err))
    ).Store(userRoleDetails, (res) => {

      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }
        //  respData = userRoleDetails
        dispatch(fetchAssessmentTermsAndCondition(res.data));
      }
    });
  };
}

export function fetchTestInstruction(payloadData) {
  var url = Global_var.BASEURL + Global_var.PREASSESSMENT_TEST_INSTRUCTION;
  // var url = "http://192.168.10.107:9000/fc-learner/api/v1/assessment/instruction/_get"
  // return new RestDataSource(url, fnError).Store(userRoleDetails, (res) => fn(res.data));
  return dispatch => {

    var respData = [];

    return new RestDataSource(url, err =>
      dispatch(fetchAssessmentInstruction(err))
    ).Store(payloadData, (res) => {

      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }

        const data = "Shravan"
        //  respData = userRoleDetails
        dispatch(fetchAssessmentInstruction(res.data));
      }
    });
  };
}

export function updateTermsAndCondition(data, fn, fnError) {

  LoginService.TokenUser(res => {
    if (res.status === 'success') {
      localStorage.setItem('jwt-token', res.responseObject);
      var url = Global_var.BASEURL + Global_var.PREASSESSMENT_TEST_TEMS_UPDATE;
      // var url = "http://192.168.10.107:9000/fc-learner/api/v1/assessment/term_condition/_update"
      return new RestDataSource(url, fnError).Store(data, (res) => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem('jti-token', res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}

export function fetchQuestionOption(payloadQuestionOption, fn, fnError) {

  LoginService.TokenUser(res => {
    if (res.status === 'success') {
      localStorage.setItem('jwt-token', res.responseObject);
      var url = Global_var.BASEURL + Global_var.PREASSESSMENT_TEST_QUESTION_OPTION;
      // var url = "http://192.168.10.107:9000/fc-learner/api/v1/assessment/test_questionaire/_get"
      return new RestDataSource(url, fnError).Store(payloadQuestionOption, (res) => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem('jti-token', res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}

export function updateScore(payloadupdateScore, fn, fnError) {

  LoginService.TokenUser(res => {
    if (res.status === 'success') {
      localStorage.setItem('jwt-token', res.responseObject);
      var url = Global_var.BASEURL + Global_var.PREASSESSMENT_TEST_SCORE_UPDATE;
      // var url = "http://192.168.10.107:9000/fc-learner/api/v1/assessment/test_score/_update"
      return new RestDataSource(url, fnError).Store(payloadupdateScore, (res) => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem('jti-token', res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}


export function updateStatus(data, fn, fnError) {

  LoginService.TokenUser(res => {
    if (res.status === 'success') {
      localStorage.setItem('jwt-token', res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_WORKFLOW_STATUS_UPDATE;
      //var url = "http://192.168.12.100:9006/fc-learner/api/v1/stage/wf_journy/_update"
      return new RestDataSource(url, fnError).Update(data, (res) => {
        if (res != null) {

          if (res.headers["jtitoken"] != null) {
            localStorage.setItem('jti-token', res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}
export function sendAmcList(data, fn, fnError) {

  LoginService.TokenUser(res => {
    if (res.status === 'success') {
      localStorage.setItem('jwt-token', res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_AMC_LIST_APPROVAL;
      //var url = "http://192.168.12.100:9006/fc-learner/api/v1/stage/wf_journy/_update"
      return new RestDataSource(url, fnError).Store(data, (res) => {
        if (res != null) {

          if (res.headers["jtitoken"] != null) {
            localStorage.setItem('jti-token', res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}
// export function reattemptSendMail(data, fn) {
//   LoginService.TokenUser(res => {
//     localStorage.setItem("jwt-token", res.responseObject);
//     //   var url = Global_var.BASEURL + Global_var.URL_SEND_MAIL_REATTEMPT;
//     let url = "http://192.168.12.100:9006/fc-learner/api/v1/mail/_send"
//     // let url = Global_var.BASEURL + Global_var.URL_UPDATEUSERPROFILE;
//     return new RestDataSource(url).Store(data, res => {
//       // console.log(res)
//       success(
//         "Your request has been submitted successfully.",
//         successNotification
//       );
//       // return res
//       // console.log(props)
//       // props.history.push("/learnerDashboard");
//       // window.location.reload()
//       fn(res.data)
//     });
//   });
// }

export function reattemptSendMail(data, props, fn, fnError) {
  console.log(props)
  LoginService.TokenUser(res => {
    if (res.status === 'success') {
      localStorage.setItem('jwt-token', res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_SEND_MAIL;
      //   let url = "http://192.168.12.100:9006/fc-learner/api/v1/mail/_send"
      //var url = "http://192.168.12.100:9006/fc-learner/api/v1/stage/wf_journy/_update"
      return new RestDataSource(url, fnError).Store(data, (res) => {
        if (res.data.reasonText === "success") {
          success(
            "Your request has been successfully submitted ",
            successNotification
          );
          props.push("/learnerDashboard")
          window.location.reload()
        }
        if (res != null) {

          if (res.headers["jtitoken"] != null) {
            localStorage.setItem('jti-token', res.headers.jtitoken);
          }
          // fn(res.data);
        }
      });
    }
  }, fnError);
}