import { Global_var } from '../global/global_var';
import RestDataSource from './restdatasource';
import { LoginService } from './login.service';

export const ProductService = {
  ProductByName,
  ProductById,
  addProduct,
  updateProduct,
  addModules,
  updateModules,
  deleteIsactiveInactive,
  fetchProductPreRequisiteByParentProduct,
  addLearningPath,
  fetchProductAllExceptMappedProduct,
  updateIsactiveInactiveModuleList,
  addProductImageVideo,
  filterlearners,
  updategdpiStatus,
  updateLearningPath,
  fetchLearningPathParentProductID,
  fetchLearningPathParentProductIDAssignUser,
  fetchProductDetailsByPartyId,
  bulkupdateuserstatus,
  fetchUnassignedUserDetails,
  getProgressOverview,
  fetchModulesData,
  fetchAllProduct,
  getStudentProgress
  //fetchProductTypeData
};

function fetchAllProduct(fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === 'success') {

      localStorage.setItem('jwt-token', res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_PRODUCT_ALL;
      //var mobilevalidat = JSON.stringify(ProductByName);
      return new RestDataSource(url, fnError).GetOneByParam(null, (res) => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem('jti-token', res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}


function ProductByName(ProductByName, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === 'success') {

      localStorage.setItem('jwt-token', res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_PRODUCT_BYNAME;
      //var mobilevalidat = JSON.stringify(ProductByName);
      return new RestDataSource(url, fnError).GetOneByParam(ProductByName, (res) => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem('jti-token', res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}

function ProductById(ProductById, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === 'success') {
      localStorage.setItem('jwt-token', res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_PRODUCT_BYID;
      //var mobilevalidat = JSON.stringify(ProductByName);
      return new RestDataSource(url, fnError).GetOneByParam(ProductById, (res) => {

        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem('jti-token', res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}

function fetchProductPreRequisiteByParentProduct(ProductById, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_PRODUCT_PRE_REQUISITE;
  //var mobilevalidat = JSON.stringify(ProductByName);
  return new RestDataSource(url, fnError).GetOneByParam(ProductById, (res) => {

    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem('jti-token', res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function fetchProductAllExceptMappedProduct(ProductById, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_PRODUCT_ALL_EXCEPT_MAPPED;
  //var mobilevalidat = JSON.stringify(ProductByName);
  return new RestDataSource(url, fnError).Store(ProductById, (res) => {

    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem('jti-token', res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function fetchProductDetailsByPartyId(id, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === 'success') {
      localStorage.setItem("jwt-token", res.responseObject);
      //var url = Global_var.BASEURL + Global_var.URL_PRODUCT_BY_PARTYID; // old URL
      var url = Global_var.BASEURL + Global_var.URL_PRODUCT_BY_PARTYID_NEW; //new url by dhanaji
      return new RestDataSource(url, err => fnError(err)).GetOneByParam(id, res => {
        if (res.data.status === "success") {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
              fn(res.data)
            } else {
              fnError(res.data.reasonText);
            }
          }
        }
      });
    }
  });
}
function addProduct(data, fn, fnError) {
  LoginService.TokenUser(res => {
    //console.log("SERVICE", res);
    if (res.status === "success") {
      //console.log("RES.STATUS", res.status);
      localStorage.setItem("jwt-token", res.responseObject);
      let url = Global_var.BASEURL + Global_var.URL_ADD_PRODUCT;
      return new RestDataSource(url, fnError).Store(data, res => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}

function addProductImageVideo(data, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      let url = Global_var.BASEURL + Global_var.URL_ADD_PRODUCT_THUMBNAIL_VIDEO;
      return new RestDataSource(url, fnError).Store(data, res => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}


function editProduct(data, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      //console.log("RES>STATUS", res.status);
      localStorage.setItem("jwt-token", res.responseObject);
      let url = Global_var.BASEURL + Global_var.URL_ADD_PRODUCT;
      return new RestDataSource(url, fnError).Update(data, res => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}

function updateProduct(user, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === 'success') {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_UPDATEPRODUCT;
      //var url = Global_var.URL_UPDATEUSERPROFILE;

      return new RestDataSource(url, fnError).Update(user, res => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  });
}

function filterlearners(statusparameter, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === 'success') {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_GDPI_LEARNER;
      //var url = Global_var.URL_UPDATEUSERPROFILE;

      return new RestDataSource(url, fnError).GetOneByParam(statusparameter, response => {
        if (res != null) {
          // if (res.headers["jtitoken"] != null) {
          //   localStorage.setItem("jti-token", res.headers.jtitoken);
          // }
          fn(response.data);
        }

      });
    }
  });
}

function updategdpiStatus(GDPIDATA, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === 'success') {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_GDPI_BULKUPLOAD;
      //var url = Global_var.URL_UPDATEUSERPROFILE;

      return new RestDataSource(url, fnError).Update(GDPIDATA, res => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  });
}

function bulkupdateuserstatus(GDPIDATA, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === 'success') {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_BULK_USER_STATUS_CHANGE;

      return new RestDataSource(url, fnError).Update(GDPIDATA, res => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  });
}
function addModules(data, fn, fnError) {
  LoginService.TokenUser(res => {
    //console.log("SERVICE", res);
    if (res.status === "success") {
      //console.log("RES.STATUS", res.status);
      localStorage.setItem("jwt-token", res.responseObject);
      let url = Global_var.BASEURL + Global_var.URL_ADD_MODULES;
      return new RestDataSource(url, fnError).Store(data, res => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}

function addLearningPath(data, fn, fnError) {

  LoginService.TokenUser(res => {
    if (res.status === "success") {
      //console.log("RES.STATUS", res.status);
      localStorage.setItem("jwt-token", res.responseObject);
      let url = Global_var.BASEURL + Global_var.URL_ADD_LEARNINGPATH;
      return new RestDataSource(url, fnError).Store(data, res => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}

function updateModules(user, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === 'success') {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_UPDATEMODULES;
      //var url = Global_var.URL_UPDATEUSERPROFILE;
      return new RestDataSource(url, fnError).Update(user, res => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  });
}

function deleteIsactiveInactive(productIDIsActive, fn, fnError) {

  LoginService.TokenUser(res => {
    if (res.status === 'success') {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_UPDATEISACTIVEINACTIVE;
      //var url = Global_var.URL_UPDATEUSERPROFILE;
      return new RestDataSource(url, fnError).DeleteOneByParam(productIDIsActive, res => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  });
}

function updateIsactiveInactiveModuleList(user, fn, fnError) {
  // 

  LoginService.TokenUser(res => {
    if (res.status === 'success') {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_UPDATEISACTIVEINACTIVEMODULE;
      //var url = Global_var.URL_UPDATEUSERPROFILE;
      return new RestDataSource(url, fnError).Update(user, res => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  });
}


function updateLearningPath(user, fn, fnError) {

  LoginService.TokenUser(res => {
    if (res.status === 'success') {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_UPDATELEARNINGPATH;
      //var url = Global_var.URL_UPDATEUSERPROFILE;
      return new RestDataSource(url, fnError).Update(user, res => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  });
}

function fetchLearningPathParentProductID(parentIdparameter, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === 'success') {
      localStorage.setItem('jwt-token', res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_PARENTPRODUCTID;
      return new RestDataSource(url, fnError).GetOneByParam(parentIdparameter, (res) => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem('jti-token', res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}

function fetchLearningPathParentProductIDAssignUser(parentIdparameter, fn, fnError) {

  LoginService.TokenUser(res => {
    if (res.status === 'success') {
      localStorage.setItem('jwt-token', res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_PARENTPRODUCTIDASSIGNUSER;
      return new RestDataSource(url, fnError).GetOneByParam(parentIdparameter, (res) => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem('jti-token', res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}

function fetchUnassignedUserDetails(ProductIDParameter, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === 'success') {
      localStorage.setItem('jwt-token', res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_USERLIST_BY_PRODUCT_ID;
      return new RestDataSource(url, fnError).GetOneByParam(ProductIDParameter, (res) => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem('jti-token', res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}


function getProgressOverview(learnerPartyId, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === 'success') {
      localStorage.setItem('jwt-token', res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_GET_PROGRESS_OVERVIEW;
      return new RestDataSource(url, fnError).GetOneByParam(learnerPartyId, (res) => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem('jti-token', res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}

function fetchModulesData(param, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === 'success') {
      localStorage.setItem('jwt-token', res.responseObject);

      var url = Global_var.BASEURL + Global_var.URL_GETMODULESBYID;
      return new RestDataSource(url, fnError).GetOneByParam(param, (res) => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem('jti-token', res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}
function getStudentProgress(ProductData, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_LEARNER_PROGRESS;
  return new RestDataSource(url, fnError).Store(ProductData, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem('jti-token', res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}





