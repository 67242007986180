import React from 'react'
import logo5 from '../../assets/images/partner-logo1.png';
import logo2 from '../../assets/images/partner-logo2.png';
import logo1 from '../../assets/images/partner-logo3.png';
import logo3 from '../../assets/images/partner-logo4.png';
import logo4 from '../../assets/images/partner-logo5.png';
import logo6 from '../../assets/images/partner-logo6.png';
import logo7 from '../../assets/images/partner-logo7.png';
import logo8 from '../../assets/images/partner-logo8.png';
import logo9 from '../../assets/images/partner-logo9.png';
import logo10 from '../../assets/images/partner-logo10.png';
import logo11 from '../../assets/images/partner-logo11.png';
import logo12 from '../../assets/images/partner-logo12.png';
import logo13 from '../../assets/images/partner-logo13.png';
import logo14 from '../../assets/images/partner-logo14.png';
import logo15 from '../../assets/images/partner-logo15.png';
import logo16 from '../../assets/images/partner-logo16.png';
import logo17 from '../../assets/images/partner-logo17.png';
import logo18 from '../../assets/images/partner-logo18.png';
import FinxRegistration from "../../components/mainpage/finxregistration/finxregistration";
import FAQPDF from "../../assets/pdf/RCP_FAQs.pdf";
import TC from "../../assets/pdf/T-C_RCP.pdf";
import POLICY from "../../assets/pdf/Policies_RCP.pdf";
import CP from "../../assets/pdf/Cookies-ExpertMFD.pdf";
import RP from "../../assets/pdf/Refund_Policy.pdf";
import DC from "../../assets/pdf/Disclaimer_RCP.pdf";


function 
corporatesimage(props) {
    return (
        <React.Fragment>
            <section className="partners section-padding" id="ourpartners">
                <div className="container">
                    <div className="row">
                        <div className="section-title-white">
                            <h2>COLLABORATIVE PARTNERS</h2>
                            {/* <p>An affliate network future leaders</p> */}
                        </div>
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="row">
                                <section className="customer-logos slider">
                                    {/* <div className="slide"><a href="https://mutualfund.adityabirlacapital.com/" target="_blank"><img src={logo1} /></a></div>
                                    <div className="slide"><a href="https://www.axismf.com/" target="_blank"><img src={logo2} /></a> </div>
                                    <div className="slide"><a href="https://www.icicipruamc.com/" target="_blank"><img src={logo3} /></a></div>

                                    <div className="slide"><a href="https://www.ltfs.com/companies/lnt-investment-management/lnt-mutual-fund.html" target="_blank"><img src={logo4} /></a></div>
                                    <div className="slide"><a href="https://mf.nipponindiaim.com/" target="_blank"><img src={logo5} /></a></div>

                                    <div className="slide"><a href="https://www1.nseindia.com/" target="_blank"><img src={logo6} /></a></div>
                                    <div className="slide"><a href="https://www.sbimf.com/" target="_blank"><img src={logo7} /></a></div>
                                    <div className="slide"><a href="https://www.sundarammutual.com/" target="_blank"><img src={logo8} /></a></div>
                                    <div className="slide"><a href="https://www.hdfcfund.com/" target="_blank"><img src={logo9} /></a></div> */}
                                   
                                    <div className="slide"><img src={logo1} /></div>
                                    <div className="slide"><img src={logo2} /> </div>
                                    <div className="slide"><img src={logo3} /></div>

                                    <div className="slide"><img src={logo4} /></div>
                                    <div className="slide"><img src={logo5} /></div>

                                    <div className="slide"><img src={logo6} /></div>
                                    <div className="slide"><img src={logo7} /></div>
                                    <div className="slide"><img src={logo8} /></div>
                                    <div className="slide"><img src={logo9} /></div>

                                    <div className="slide"><img src={logo10} /></div>
                                    <div className="slide"><img src={logo11} /></div>
                                    <div className="slide"><img src={logo12} /></div>
                                    <div className="slide"><img src={logo13} /></div>

                                    <div className="slide"><img src={logo14} /></div>
                                    <div className="slide"><img src={logo15} /></div>
                                    <div className="slide"><img src={logo16} /></div>
                                    <div className="slide"><img src={logo17} /></div>
                                    <div className="slide"><img src={logo18} /></div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="sub-footer" id="subfooter">

                <div class="container">
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                        <div class="col-md-4 col-lg-4 col-sm-6 col-xs-12">
                            <div className="section-title-black">
                                <h2>ABOUT US</h2>
                            </div>
                            <p>RCP is an endeavour of CIEL to make certification for individuals a seamless process.</p>
                            <div className="footer-btn">
                                <a href=""
                                    data-keyboard="true" data-toggle="modal"     data-keyboard="true" data-target="#feat-Reg" >

                                    <button type="button" class="blue-btn"> LOGIN/REGISTER</button>
                                </a>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-4 col-sm-6 col-xs-12">
                            <div className="section-title-black">
                                <h2>QUICK MENU</h2>
                            </div>
                            <div className="quickMenu">
                                <ul>
                                    
                                    <li> <a href={TC} target="_blank" > Terms and Conditions </a></li>
                                    <li> <a href={POLICY} target="_blank" > Privacy Policy </a></li>
                                    <li> <a href={RP} target="_blank" > Refund Policy </a></li>
                                    {/* <li> <a href={DC} target="_blank" > Disclaimer </a></li> */}
                                    <li> <a href={FAQPDF} target="_blank" > FAQs </a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-4 col-sm-6 col-xs-12">
                            <div className="section-title-black">
                                <h2>SOCIAL MEDIA</h2>
                            </div>
                            <div className="socialLinks">
                                <a href="https://www.facebook.com/ciellearning" target="_blank"  class="fa fa-facebook"></a>
                                <a href="https://www.instagram.com/ciellearning/" class="fa fa-instagram" target="_blank" ></a>
                                <a href="https://www.linkedin.com/company/ciellearning" class="fa fa-linkedin" target="_blank" ></a>
                                <a href="https://twitter.com/ciellearning" class="fa fa-twitter" target="_blank" ></a>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <FinxRegistration {...props} /> */}
        </React.Fragment>
    );
}

export default corporatesimage