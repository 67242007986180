import { Global_var } from "../global/global_var";
import RestDataSource from "./restdatasource";
import { LoginService } from "./login.service";
import { func } from "prop-types";

import { fetchattemptedListPending, fetchattemptedListSuccess, fetchattemptedListError } from "../action/reattemptAction";

export const ReattemptTestService = {
  updateReattemptTest,
  fetchDataByProduct,
  getStatus,
  getStatuscompleted,
  UpdateWorkflow,
  getDateLocationData,
  getAssessmentResult,
  UpdateWorkflowTransition,
  getKYDpdf,
  getARNpdf,
  acceptKyd,
  saveNomineeDetails,
  getAMCcount,
  getAuditData
};

export function getAMCcount(data, fn, fnError) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_GET_APPROVED_AMC_LIST;
    return new RestDataSource(url, fnError).GetOneByParam(data, res => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }

        fn(res.data);
      }
    });
  });
}
function updateReattemptTest(data, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_REATTEMPT_TEST;
    //let url = "http://192.168.12.100:9006/fc-learner/api/v1/assessment/test/_initiate"
    return new RestDataSource(url).Store(data, res => fn(res.data));
  });
}

export function fetchDataByProduct(param) {
  //let url = "http://192.168.12.100:9006/fc-learner/api/v1/assessment//list/_get"
  let url = Global_var.BASEURL + Global_var.URL_GET_ATTEMPTED_LEARNER;

  return dispatch => {
    // dispatch(fetchDistributorListPending());
    return new RestDataSource(url, err =>
      dispatch(fetchattemptedListError(err.message))
    ).GetOneByParam(param, res => {
      if (res !== null) {
        if (res.headers["jtitoken"] !== null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);

          //  let data=[]
          //  data.push(res.data.responseObject)
          console.log(res)
          dispatch(fetchattemptedListSuccess(res.data.responseListObject));
        }
      }
    });
  };
}


function getStatus(data, fn) {

  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_WORKFLOW_SEACH;
    // let url="http://192.168.12.113:8088/thor-workflowservices/api/v1/process/_search"
    //let url="http://192.168.12.113:8088/thor-workflowservices/api/v1/workflow/_search"
    return new RestDataSource(url).Store(data, res => fn(res));
  });
}

function getStatuscompleted(data, fn) {

  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_WORKFLOW_PROCESS;
    //let url="http://192.168.12.113:8088/thor-workflowservices/api/v1/process/_search"
    // let url="http://192.168.12.113:8088/thor-workflowservices/api/v1/workflow/_search"
    return new RestDataSource(url).Store(data, res => fn(res));
  });
}

function getKYDpdf(data, fn) {

  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_KYD_PDF_GENERATOR;
    return new RestDataSource(url).Store(data, res => fn(res));
  });
}
function getARNpdf(data, fn) {

  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_ARN_PDF_GENERATOR;
    return new RestDataSource(url).Store(data, res => fn(res));
  });
}




function UpdateWorkflow(data, fn) {

  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_WORKFLOW_UPDATE;
    //let url = "http://192.168.12.113:9026/thor-workflowservices/api/v1/process/_update"

    return new RestDataSource(url).Store(data, res => fn(res));
  });
}

function UpdateWorkflowTransition(data, fn) {

  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_WORKFLOW_TRANSITION;
    //let url = "http://192.168.12.113:9026/thor-workflowservices/api/v1/process/_update"

    return new RestDataSource(url).Store(data, res => fn(res));
  });
}




function getDateLocationData(data, fn, fnError) {
  //let url = "http://192.168.12.100:9026/thor-workflowservices/api/v1//process/_get"
  var url = Global_var.BASEURL + Global_var.URL_GET_DATE_LOCATION;
  return new RestDataSource(url).Store(data, res => fn(res));
  // return new RestDataSource(url).Update(user, res => fn(res.data));
}



export function getAssessmentResult(data, fn, fnError) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    //  var url = Global_var.BASEURL + Global_var.URL_DOCUMENT_DOWNLOAD;
    var url = Global_var.BASEURL + Global_var.URL_GET_ATTEMPTED_LEARNER;
    return new RestDataSource(url, fnError).GetOneByParam(data, res => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }

        fn(res.data);
      }
    });
  });
}

function acceptKyd(data, fn, fnError) {
  //let url = "http://192.168.12.100:9026/thor-workflowservices/api/v1//process/_get"
  var url = Global_var.BASEURL + Global_var.URL_ACCEPT_KYD;
  return new RestDataSource(url).Store(data, res => fn(res));
  // return new RestDataSource(url).Update(user, res => fn(res.data));
}
function saveNomineeDetails(data, fn, fnError) {
  //let url = "http://192.168.12.100:9026/thor-workflowservices/api/v1//process/_get"
  var url = Global_var.BASEURL + Global_var.URL_NOMINEE_CREATE;
  return new RestDataSource(url).Store(data, res => fn(res));
  // return new RestDataSource(url).Update(user, res => fn(res.data));
}

function getAuditData(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_AUDIT_DETAILS;

  return new RestDataSource(url, fnError).GetOneByParam(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}