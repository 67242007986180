import { Global_var } from "../global/global_var";
import RestDataSource from "./restdatasource";
import { LoginService } from "./login.service";
import { func } from "prop-types";
import {
  fetchUserProfileData,
  fetchUserAddressData
} from "../action/userProfile";

export const RegisterUserService = {
  RegisterUser,
  getUserPersonnelDetails,
  getUserAddress,
  updateUserProfile,
  updateUserPersonalData,
  uploadUserFiles,
  AHRegisterUser,
  AHRegisterUserBulk,
  getEmployeType,
  updateMyTour,
  getAdminUserAddress,
  bulkUpload,
  bulkNigoUpload,
  checkPAN,
  validateUserData,
  createdBy,
  paymentLink,
  AHActivateUser,
  sendMail,
  certificateDownload,
  downloadDocumet,
  uploadDocumet,
  downloadZip,
  downloadDocumet1,
  sendMailKYD,
  sendKYDMail,
  sendSms,
  validateLoginUserData,
  saveUser,
  sendNoMandateMail,
  sendSuccessMandateMail,
  checkUser,
  getPanDetails,
  sendMailToCielops,
  getEuinDetails,
  validateFalsePositiveEmail,
  generateOTPMail,
  validateOTPMail,
  emailAuthntication,
  userMail,
  userSMS,
  nismExamMail,
  nismExamSms,
  saveEuinDetais,
  getEuinRenewalDetails,
  bulkDownloadZip,
  bulkNismUpload,
  bulkWfUpdateToPaymentComplete,
  bulkUploadExam,
  getUserPersonnelDetailsForUpdate,
  unlockUserProfile,
  changeMandateType,
};

function RegisterUser(user, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      //   let URL='http://192.168.12.113:9005'
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_FC_REGISTER_USER;
      // Global_var.BASEURL
      //var mobilevalidat = JSON.stringify(ProductByName);
      return new RestDataSource(url, fnError).Store(user, res => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}

function AHRegisterUser(user, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_AH_REGISTER_USER;

      //var mobilevalidat = JSON.stringify(ProductByName);
      return new RestDataSource(url, fnError).Store(user, res => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}


function AHRegisterUserBulk(user, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_AH_REGISTER_USER_BULK;

      //var mobilevalidat = JSON.stringify(ProductByName);
      return new RestDataSource(url, fnError).Store(user, res => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}

function getUserPersonnelDetails(user, fn, fnError) {
  //user="learnerPartyID=199986"

  return dispatch => {
    //   var url="http://192.168.12.113:8061/api/v1/learner"
    var url = Global_var.BASEURL + Global_var.URL_USERDATA;
    return new RestDataSource(url, fnError).GetOneByParam(user, res => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }
        dispatch(fetchUserProfileData(res.data));
      }
    });
  };
}

function getUserAddress(user, fn, fnError) {
  // user="partyId=199986"


  return dispatch => {
    // var url = 'http://192.168.12.113:9015/api/v1/party/address'
    var url = Global_var.BASEURL + Global_var.URL_USERADDRESS;
    return new RestDataSource(url, fnError).GetOneByParam(user, res => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }

        dispatch(fetchUserAddressData(res.data));
        fn(res.data);
      }
    });
  };
}

function getAdminUserAddress(user, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_USERADDRESS;
  return new RestDataSource(url, fnError).GetOneByParam(user, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }

      fn(res.data);
    }
  });
}

function updateUserProfile(user, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    // let url = "http://192.168.12.113:9001/fc-admin/api/v1/admin/updateuser"
    let url = Global_var.BASEURL + Global_var.URL_UPDATEUSERPROFILE;
    return new RestDataSource(url).Update(user, res => fn(res.data));
  });
}

function uploadUserFiles(file, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_UPLOADFILES;
    return new RestDataSource(url).Store(file, res => fn(res));
  });
}
function getEmployeType(fn, fnError) {
  let emptype = "codeTypeName=employment";
  var url = Global_var.BASEURL + Global_var.URL_GETLOOKUP_BY_CD_TYPE_NAME;
  return new RestDataSource(url, fnError).GetOneByParam(emptype, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function updateMyTour(data, fn, fnError) {
  let url = Global_var.BASEURL + Global_var.URL_USERDATA;
  return new RestDataSource(url, fnError).Update(data, res => {
    fn(res.data);
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
    }
  });
}

function bulkUpload(file, fn) {

  //var url = Global_var.BASEURL + Global_var.URL_BULK_UPLOAD;
  var url = Global_var.BASEURL + Global_var.URL_BULK_UPLOAD_SCORE
  return new RestDataSource(url).Store(file, res => {
    if (res != null) {

      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res);
    }
  });
}
function bulkNigoUpload(file, fn) {
  var url = Global_var.BASEURL + Global_var.URL_BULK_NIGO_UPLOAD;
  
  return new RestDataSource(url).Store(file, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function checkPAN(user, fn, fnError) {

  var url = Global_var.BASEURL + Global_var.URL_Check_PAN;
  return new RestDataSource(url, fnError).GetOneByParam(user, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }

      fn(res.data);
    }
  });
}

function validateUserData(user, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_Validate_New_User;
  return new RestDataSource(url, fnError).GetOneByParam(user, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }

      fn(res.data);
    }
  });
}

function createdBy(user, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_CREATED_BY;
  return new RestDataSource(url, fnError).GetOneByParam(user, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }

      fn(res.data);
    }
  });
}

function AHActivateUser(user, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_AH_ACTIVATE_TEST_USER;

      //var mobilevalidat = JSON.stringify(ProductByName);
      return new RestDataSource(url, fnError).Store(user, res => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}

function paymentLink(data, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_SEND_EMAIL;
    // let url = "http://192.168.10.107:9000/fc-learner/api/v1/learner/send/email"
    // let url = Global_var.BASEURL + Global_var.URL_UPDATEUSERPROFILE;
    return new RestDataSource(url).Store(data, res => fn(res.data));
  });
}

function sendMail(data, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_SEND_MAIL;

    return new RestDataSource(url).Store(data, res => fn(res.data));
  });
}

function sendMailKYD(data, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_SEND_MAIL_KYD;

    return new RestDataSource(url).Store(data, res => fn(res.data));
  });
}

function certificateDownload(data, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_CERTIFICATE_DOWNLOAD;

    return new RestDataSource(url).Store(data, res => fn(res.data));
  });
}

function downloadDocumet(data, fn, fnError) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);

    var url = Global_var.BASEURL + Global_var.URL_DOCUMENT_DOWNLOAD;

    return new RestDataSource(url, fnError).GetOneByParam(data, res => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }

        fn(res.data);
      }
    });
  });
}

function downloadDocumet1(data, fn, fnError) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);

    var url = Global_var.BASEURL + Global_var.URL_ADD_PRODUCT_THUMBNAIL_VIDEO

    return new RestDataSource(url, fnError).GetOneByParam(data, res => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }

        fn(res.data);
      }
    });
  });
}
function uploadDocumet(data, fn, fnError) {
  //alert(Global_var.BASEURL + Global_var.URL_DOCUMENT_DOWNLOAD)
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_ADD_PRODUCT_THUMBNAIL_VIDEO
    // Global_var.URL_DOCUMENT_DOWNLOAD;

    return new RestDataSource(url).Store(data, res => fn(res.data));
  });
}

function downloadZip(data, fn, fnError) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_ZIP;



    // Global_var.URL_DOCUMENT_DOWNLOAD;

    return new RestDataSource(url).Store(data, res => fn(res.data));
  });
}

function sendKYDMail(data, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_KYD_MAIL;

    return new RestDataSource(url).Store(data, res => fn(res.data));
  });
}

function sendSms(data, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_SEND_SMS;
  //  var url = "http://172.16.16.99:9006" + Global_var.URL_SEND_SMS;
    return new RestDataSource(url).Store(data, res => fn(res.data));
  });
}

function validateLoginUserData(user, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_Validate_LOGIN_User;
  return new RestDataSource(url, fnError).GetOneByParam(user, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }

      fn(res.data);
    }
  });
}

function saveUser(data, fn) {
  console.log("data of save user", data)
  var url = Global_var.BASEURL + Global_var.URL_SAVE_USER;
  return new RestDataSource(url).Store(data, res => fn(res.data));
}

function sendNoMandateMail(data, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_SEND_NO_MANDATE_EMAIL;

    return new RestDataSource(url).Store(data, res => fn(res.data));
  });
}

function sendSuccessMandateMail(data, fn) {
  var url = Global_var.BASEURL + Global_var.URL_MANDATE_USER_EMAIL;
  return new RestDataSource(url).Store(data, res => fn(res.data));
}

function checkUser(user, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_Check_USER_REGISTRATION;
  return new RestDataSource(url, fnError).GetOneByParam(user, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }

      fn(res.data);
    }
  });
}
  
  function getPanDetails(panNo, fn, fnError) {
    //alert("sdfghjk")
    var url = Global_var.BASEURL + Global_var.URL_PAN_DETAILS;
    return new RestDataSource(url, fnError).GetOneByParam(panNo, res => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }
  
        fn(res.data);
      }
    });
}

function sendMailToCielops(data, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_CIEL_OPS_MAIL;

    return new RestDataSource(url).Store(data, res => fn(res.data));
  });
}

function getEuinDetails(panNo, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_EUIN_DETAILS;
  //var url = "http://172.16.16.131:7006/fc-learner/api/v1/getEuinDetails"
  return new RestDataSource(url, fnError).GetOneByParam(panNo, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }

      fn(res.data);
    }
  });
}

function validateFalsePositiveEmail(emailID, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_FALSE_POSITIVE_EMAIL;
  return new RestDataSource(url, fnError).GetOneByParam(emailID, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }

      fn(res.data);
    }
  });
}

function generateOTPMail(data, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_OTP_EMAIL;

    return new RestDataSource(url).Store(data, res => fn(res.data));
  });
}

function validateOTPMail(data, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_VALIDATE_OTP_EMAIL;

    return new RestDataSource(url).Store(data, res => fn(res.data));
  });
}

// function emailAuthntication(data, fn) {
//   LoginService.TokenUser(res => {
//     localStorage.setItem("jwt-token", res.responseObject);
//     var url = Global_var.BASEURL + Global_var.URL_AUTHENTICATE_EMAIL;

//     return new RestDataSource(url).Store(data, res => fn(res.data));
//   });
// }

function emailAuthntication(emailID, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_AUTHENTICATE_EMAIL;
  return new RestDataSource(url, fnError).GetOneByParam(emailID, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }

      fn(res.data);
    }
  });
}

function userMail(data, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_MANDATE_USER_EMAIL;

    return new RestDataSource(url).Store(data, res => fn(res.data));
  });
}

function userSMS(data, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_MANDATE_USER_SMS;
    return new RestDataSource(url).Store(data, res => fn(res.data));
  });
}

function nismExamMail(data, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_SEND_MAIL;

    return new RestDataSource(url).Store(data, res => fn(res.data));
  });
}

function nismExamSms(data, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_SEND_SMS;
    return new RestDataSource(url).Store(data, res => fn(res.data));
  });
}

function saveEuinDetais(data, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_SAVE_EUIN_DETAILS;

    return new RestDataSource(url).Store(data, res => fn(res.data));
  });
}

function getEuinRenewalDetails(panNo, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_RENEWAL_DETAILS;
  //var url = "http://172.16.16.131:7006/fc-learner/api/v1/getEuinDetails"
  return new RestDataSource(url, fnError).GetOneByParam(panNo, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }

      fn(res.data);
    }
  });
}

function bulkDownloadZip(data, fn, fnError) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_BULK_ZIP;
    return new RestDataSource(url).Store(data, res => fn(res.data));
  });
}

function bulkNismUpload(file, fn) {
  var url = Global_var.BASEURL + Global_var.URL_BULK_UPLOAD_NISM
  return new RestDataSource(url).Store(file, res => {
    if (res != null) {

      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res);
    }
  });
}

function bulkWfUpdateToPaymentComplete(file, fn) {
  var url = Global_var.BASEURL + Global_var.URL_BULK_UPLOAD_WF_Update_To_Payment_Complete
  return new RestDataSource(url).Store(file, res => {
    if (res != null) {

      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res);
    }
  });
}

function bulkUploadExam(file, fn) {
  var url = Global_var.BASEURL + Global_var.URL_BULK_UPLOAD_NISM_EXAM
  return new RestDataSource(url).Store(file, res => {
    if (res != null) {

      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res);
    }
  });
}

function getUserPersonnelDetailsForUpdate(user, fn, fnError) {
  // let url = "http://192.168.71.73:7006/rcp-fc-learner/api/v1/learner/getLearners"
  var url = Global_var.BASEURL + Global_var.URL_USERDATAS;
  return new RestDataSource(url, fnError).GetOneByParam(user, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function updateUserPersonalData(user, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
     //let url = "http://192.168.71.73:7001/rcp-fc-admin/api/v1/admin/updatepersonaldetails"
     let url = Global_var.BASEURL + Global_var.URL_UPDATEUSERPERSONALDATA;
    return new RestDataSource(url).Update(user, res => fn(res.data));
  });
}

function unlockUserProfile(user, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    //let url = "http://192.168.71.73:7001/rcp-fc-admin/api/v1/admin/unloackProfile"
    let url = Global_var.BASEURL + Global_var.URL_UNLOCKUSERPROFILE;
    return new RestDataSource(url).Update(user, res => fn(res.data));
  });
}
function changeMandateType(user, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_CHANGE_MANDATE_TYPE;
  return new RestDataSource(url, fnError).Update(user, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}


