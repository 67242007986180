import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
import saveAs from 'save-as'
import { ReattemptTestService } from "../../services/reattemptTest.service"
import JSZipUtils from 'jszip-utils'
import Finxlogo from "../../assets/images/dapIcon.png";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select, { createFilter } from 'react-select';
import { success, successNotification, error, errorNotification, warning, warningNotification } from "../notification/notifications";
import jsPDF from "jspdf";
import { ExcelService } from "../Excel/excel";
import Modal from "react-responsive-modal";
import "jspdf-autotable";
import * as moment from "moment";
import DatePicker from "react-datepicker";
import DashboardHeader from "../../components/dashboardheader/dashboardheader";
import { CSVLink, CSVDownload } from "react-csv";
import "react-datepicker/dist/react-datepicker.css";
import { LookUpService } from "../../services/lookupService";
import { RegisterUserService } from "../../services/registration.service";
import { Global_var } from "../../global/global_var";
import { RegionManagementService } from "../../services/regionManagement.service";
import { LoginService } from "../../services/login.service";
var JSZip = require("jszip");

class CielReport extends Component {
    constructor(props) {
        super(props);
        if (localStorage.getItem('login') !== null) {
            this.state = {
                loading: false,
                selected: {},
                selectAll: 0,
                data: [],
                productData: [],
                exportData: [],
                listOfYear1: [],
                listOfMonth: [],
                listOfDay: [],
                jobData: [],
                showRegDate: false,
                showLocation: false,
                showWhoIs: false,
                showFeatScore: false,
                showFeatQuartile: false,
                showNismScore: false,
                cityList: []
            }

        }
        else {
            window.location.href = '/';
        }
    }
    componentDidMount() {
        LoginService.TokenUser(res => {
            localStorage.setItem("jwt-token", res.responseObject);
            RegionManagementService.getCity("", res => {
                let city = []
                for (let i = 0; i < res.responseListObject.length; i++) {
                    let obj = {}
                    //obj['value'] = res.responseListObject[i].cityId
                    obj['value'] = res.responseListObject[i].cityName
                    obj['label'] = res.responseListObject[i].cityName
                    city.push(obj)
                }
                this.setState({
                    cityList: city,
                });
            }
            );
        })
        LookUpService.getCielReport(
            "firstName=&lastName=&stage=&panNumber=&emailId=&mobileNumber=&fromDate=&toDate=&location",
            res => {

                this.setState({
                    jobData: res.responseListObject,
                });

            }
        );

        var year = new Date().getFullYear();
        year = year
        var listOfYear = [];
        for (var i = 0; i < 100; i++) {
            var current = year - i;
            listOfYear.push(current);

        }
        var listOfMonth = [];

        var listOfMonth = [{ "key": 1, "value": "Jan" },
        { "key": 2, "value": "Feb" },
        { "key": 3, "value": "Mar" },
        { "key": 4, "value": "Apr" },
        { "key": 5, "value": "May" },
        { "key": 6, "value": "Jun" },
        { "key": 7, "value": "Jul" },
        { "key": 8, "value": "Aug" },
        { "key": 9, "value": "Sep" },
        { "key": 10, "value": "Oct" },
        { "key": 11, "value": "Nov" },
        { "key": 12, "value": "Dec" },
        ];
        var listOfDay = [];
        for (var i = 1; i <= 31; i++) {
            listOfDay.push(i);
        }
        this.setState({ listOfMonth: listOfMonth });
        this.setState({ listOfYear1: listOfYear });
        this.setState({ listOfDay: listOfDay });

        //The function is called

        let product = []
        var productdata = this.props.fetchUserAddressData;
        if (productdata) {
            for (let i = 0; i < productdata.length; i++) {
                let obj = {}
                obj['value'] = productdata[i].productID
                obj['label'] = productdata[i].productName
                product.push(obj)
            }
            this.setState({
                productData: product
            })
        }
    }

    WhyTheFillForm = () => {
        this.setState({ linkModalloading: true, showWhyTheFillForm: true });
    }

    onWhyTheFillFormCloseModal = () => {
        this.setState({ linkModalloading: false, showWhyTheFillForm: false });
    };
    filterCaseInsensitive = ({ id, value }, row) =>
        row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true;

    onChangeParameter(selected, setFieldValue) {
        setFieldValue('city', selected)
    }
    urlToPromise(url) {
        return new Promise(function (resolve, reject) {
            JSZipUtils.getBinaryContent(url, function (err, data) {
                if (err) {
                    reject(err);
                } else {
                    resolve(data);
                }
            });
        });
    }

    exportToCSV = () => {

        let modifiedData = [];
        //let data = this.state.jobData.length > 0 ? this.state.jobData : [];
        let data = []
        for (let i = 0; i < this.state.jobData.length; i++) {
            let data1 = {}
            data1['srNo'] = i + 1
            data1['stage'] = this.state.jobData[i].stage
            data1['firstName'] = this.state.jobData[i].firstName
            data1['lastName'] = this.state.jobData[i].lastName
            data1['panNumber'] = this.state.jobData[i].panNumber
            data1['emailID'] = this.state.jobData[i].emailID
            data1['mobileNo'] = this.state.jobData[i].mobileNo
            if (this.state.showRegDate === true) {
                data1['createdDate'] = this.state.jobData[i].createdDate
            }
            if (this.state.showLocation === true) {
                data1['location'] = this.state.jobData[i].location
            }
            if (this.state.showWhoIs === true) {
                data1['whoIs'] = this.state.jobData[i].whoIs
            }
            if (this.state.showFeatScore === true) {
                data1['featScore'] = this.state.jobData[i].featScore
            }
            if (this.state.showFeatQuartile === true) {
                data1['featQuatletes'] = this.state.jobData[i].featQuatletes
            }
            if (this.state.showNismScore === true) {
                data1['nismScore'] = this.state.jobData[i].nismScore
            }

            data.push(data1)
        }

        if (data.length > 0) {
            data.forEach((element, index) => {
                var data1 = {}
                data1['Sr. No.'] = index + 1
                data1['Stage'] = element.stage
                data1['First Name'] = element.firstName
                data1['Last Name'] = element.lastName
                data1['PAN No'] = element.panNumber
                data1['Email ID'] = element.emailID
                data1['Mobile No'] = element.mobileNo
                if (this.state.showRegDate === true) {                  
                    data1['Registration Date'] =  element.hasOwnProperty('createdDate') ? moment(element.createdDate.split(" ")[0], "DD/MM/YYYY").format("DD-MM-YYYY") : null
                }
                if (this.state.showLocation === true) {
                    data1['Location'] = element.hasOwnProperty('location') ? element.location : null
                }
                if (this.state.showWhoIs === true) {
                    data1['Who Are You'] =  element.hasOwnProperty('whoIs') ? element.whoIs : null
                }
                if (this.state.showFeatScore === true) {
                    data1['Feat Score'] = element.hasOwnProperty('featScore') ? element.featScore : null
                }
                if (this.state.showFeatQuartile === true) {
                    data1['Feat Quartile'] =  element.hasOwnProperty('featQuatletes') ? element.featQuatletes : null
                }
                if (this.state.showNismScore === true) {
                    data1['Nism Score'] = element.hasOwnProperty('nismScore') ? (element.nismScore) : 'NA'
                }

                modifiedData.push(data1)
            });

            // var modifiedData2 = []
            // modifiedData.map(e1 => {
            //     var modifiedData1 = []
            //     e1.map(t => {
            //         if (t !== null) {
            //             modifiedData1.push(t)
            //         }

            //     })
            //     modifiedData2.push(modifiedData1)
            // })

            let date = new Date();
            let time = new Date().toLocaleString().split(",")[1]

            let time1 = time.split(" ")[2]
            let time2 = time.split(":")[0] + "-" + time.split(":")[1] + "-" + time1

            time2 = time2.split(" ")[1]

            let date1 = moment(date).format("DD-MM-YYYY")
            let d2 = date1

            let title = "Distributor-Details-" + time2 + "-"

            ExcelService.ExcelData(modifiedData, title);
        }
        else {
            warning("No record found", warningNotification)
        }
    };


    exportPDF = () => {
        
        if (this.state.jobData.length > 0) {

            const unit = "pt";
            const size = "A4"; // Use A1, A2, A3 or A4
            const orientation = "landscape"; // portrait or landscape
            const marginLeft = 40;
            const doc = new jsPDF(orientation, unit, size);
            doc.setFontSize(15);
            let modifiedData = [];
            const title = "Report Distributor Details";
            // let data = this.state.jobData.length > 0 ? this.state.jobData : [];

            let data = [];
            let headers = ["Sr. No.", "Stage", "First Name",
                "Last Name", "PAN No", "Email ID", "Mobile No"];


            if (this.state.showRegDate === true) {
                headers.push("Registration Date")

            }
            if (this.state.showLocation === true) {
                headers.push("Location")

            }
            if (this.state.showWhoIs === true) {
                headers.push("Who Are You")

            }
            if (this.state.showFeatScore === true) {
                headers.push("FeatScore")

            }
            if (this.state.showFeatQuartile === true) {
                headers.push("Feat Quartile")

            }
            if (this.state.showNismScore === true) {
                headers.push("NISM Score")
            }
            headers = [headers]
            let temp = []
            for (let i = 0; i < this.state.jobData.length; i++) {
                let data1 = {}
                data1['srNo'] = i + 1
                data1['stage'] = this.state.jobData[i].stage
                data1['firstName'] = this.state.jobData[i].firstName
                data1['lastName'] = this.state.jobData[i].lastName
                data1['panNumber'] = this.state.jobData[i].panNumber
                data1['emailID'] = this.state.jobData[i].emailID
                data1['mobileNo'] = this.state.jobData[i].mobileNo
                if (this.state.showRegDate === true) {
                    data1['createdDate'] = this.state.jobData[i].createdDate
                }
                if (this.state.showLocation === true) {
                    data1['location'] = this.state.jobData[i].location
                }
                if (this.state.showWhoIs === true) {
                    data1['whoIs'] = this.state.jobData[i].whoIs
                }
                if (this.state.showFeatScore === true) {
                    data1['featScore'] = this.state.jobData[i].featScore
                }
                if (this.state.showFeatQuartile === true) {
                    data1['featQuatletes'] = this.state.jobData[i].featQuatletes
                }
                if (this.state.showNismScore === true) {
                    data1['nismScore'] = this.state.jobData[i].nismScore
                }


                data.push(data1)

            }
            // console.log("data:: ", data)
            // console.log("headers:::", headers)
            // const headers = [["Sr. No.", "First Name",
            //     "Last Name", "PAN No", "Email ID", "Mobile No",
            //     "Registration Date", "Location", "Who Is",
            //     "FeatScore", "Feat Quartile", "NISM Score"]];

            data.forEach((element, index) => {
                
                modifiedData.push([
                    index + 1,
                    element.stage,
                    element.firstName,
                    element.lastName,
                    element.panNumber,
                    element.emailID,
                    element.mobileNo,
                    element.hasOwnProperty('createdDate') ? moment(element.createdDate.split(" ")[0], "DD/MM/YYYY").format("DD-MM-YYYY") : null,
                    element.hasOwnProperty('location') ? element.location : null,
                    element.hasOwnProperty('whoIs') ? element.whoIs : null,
                    element.hasOwnProperty('featScore') ? element.featScore : null,
                    element.hasOwnProperty('featQuatletes') ? element.featQuatletes : null,
                    element.hasOwnProperty('nismScore') ? (element.nismScore) : 'NA'
                ])
            });
            console.log("modifiedData PDF :: ", modifiedData)
            var modifiedData2 = []
            modifiedData.map(e1 => {
                var modifiedData1 = []
                e1.map(t => {
                    if (t !== null) {
                        modifiedData1.push(t)
                    }

                })
                modifiedData2.push(modifiedData1)
            })


            console.log("modifiedData1", modifiedData2)
            let content = {
                startY: 50,
                head: headers,
                body: modifiedData2
            };

            doc.text(title, marginLeft, 40);
            doc.autoTable(content);

            let date = new Date();
            let time = new Date().toLocaleString().split(",")[1]

            let time1 = time.split(" ")[2]
            time = time.split(":")[0] + "-" + time.split(":")[1] + "-" + time1
            time = time.split(" ")

            let date1 = moment(date).format("DD-MM-YYYY")
            let title1 = "Distributor-Details-" + date1 + "-" + time[1] + ".pdf"

            doc.save(title1)
        }
        else {
            warning("No record found", warningNotification)
        }
    }

    downloadFile = (id) => {
        let admitCardDocId = id
        RegisterUserService.downloadDocumet("documentId=" + admitCardDocId + "&bucketTag=" + Global_var.CONST_BUCKET_NAME, res1 => {

            window.location.href = res1.responseObject.documentUrl
            //window.$('#downloadAdmitCard').modal('hide')
        })

    }

    isValidDate = (fromDate, toDate) => {
        
        if (fromDate !== "") {
            let fromDate1 = fromDate.split('-')
            if (fromDate1[1] == 2 && fromDate1[2] > 29) {
                warning("Please select valid Date", warningNotification)
                return false
            } else if (fromDate1[1] == 4 || fromDate1[1] == 6 || fromDate1[1] == 9 || fromDate1[1] == 11) {
                if (fromDate1[2] > 30) {
                    warning("Please select valid Date", warningNotification)
                    return false
                } else {
                    return true
                }
            }
            else {
                return true
            }
        }

        if (toDate !== "") {
            let toDate1 = fromDate.split('-')
            if (toDate1[1] == 2 && toDate1[2] > 29) {
                warning("Please select valid Date", warningNotification)
                return false

            } else if (toDate1[1] == 4 || toDate1[1] == 6 || toDate1[1] == 9 || toDate1[1] == 11) {
                if (toDate1[2] > 30) {
                    warning("Please select valid Date", warningNotification)
                    return false
                } else {
                    return true
                }
            } else {
                return true
            }
        }
    }

    calculateDate = (fromYear, fromMonth, fromDay, toYear, toMonth, toDay) => {

        fromYear = this.state.fromYear
        fromMonth = this.state.fromMonth
        fromDay = this.state.fromDay
        toYear = this.state.toYear
        toMonth = this.state.toMonth
        toDay = this.state.toDay
        
        let fromDate1 = ""
        if ((fromYear !== undefined && fromYear !== "" && fromYear !== "0") && (fromMonth !== undefined && fromMonth !== "" && fromMonth !== "0") && (fromDay !== undefined && fromDay !== "" && fromDay !== "0")) {
            fromDate1 = fromYear + "-" + fromMonth + "-" + fromDay
            let flag = this.isValidDate(fromDate1, "")
            this.setState({
                fromDate: fromDate1
            })
        } else {
            fromDate1 = ""
        }

        let toDate1 = ""
        if ((toYear !== undefined && toYear !== "" && toYear !== "0") && (toMonth !== undefined && toMonth !== "" && toMonth !== "0") && (toDay !== undefined && toDay !== "" && toDay !== "0")) {
            toDate1 = toYear + "-" + toMonth + "-" + toDay
            let flag = this.isValidDate("", toDate1)
            this.setState({
                toDate: toDate1
            })
        } else {
            toDate1 = ""
        }



        if (fromDate1 != "" && toDate1 != "") {
            if (fromDate1 > toDate1) {
                // warning("To Date should be greater than from date ",warningNotification)
                return false
            } else {

                return true

            }


        }
    }

    render() {
        let title = 'Score Range     0-22  23-45  46-68  69-90' + "\u000d" + 'Result Category   Q1     Q2       Q3       Q4';

        let product = []


        var roledata = this.props.userRoleAccess;
        console.log(this.props.productDetailsAll)
        var productdata = this.props.productDetailsAll;
        if (productdata) {
            for (let i = 0; i < productdata.length; i++) {
                let obj = {}
                obj['value'] = productdata[i].productID
                obj['label'] = productdata[i].productName
                product.push(obj)
            }

        }
        let tempcsvData2 = []
        // let data = ["Sr. No.", "First Name",
        //     "Last Name", "PAN No", "Email ID", "Mobile No",
        //     "Registration Date", "Location", "Who Is",
        //     "FeatScore", "Feat Quartile", "NISM Score"]

        let data = ["Sr. No.", "First Name",
            "Last Name", "PAN No", "Email ID", "Mobile No"];


        if (this.state.showRegDate === true) {
            data.push("Registration Date")

        }
        if (this.state.showLocation === true) {
            data.push("Location")

        }
        if (this.state.showWhoIs === true) {
            data.push("Who Are You")

        }
        if (this.state.showFeatScore === true) {
            data.push("FeatScore")

        }
        if (this.state.showFeatQuartile === true) {
            data.push("Feat Quartile")

        }
        if (this.state.showNismScore === true) {
            data.push("NISM Score")
        }

        let temp = []
        for (let i = 0; i < this.state.jobData.length; i++) {
            let data1 = {}
            data1['srNo'] = i + 1
            data1['firstName'] = this.state.jobData[i].firstName
            data1['lastName'] = this.state.jobData[i].lastName
            data1['panNumber'] = this.state.jobData[i].panNumber
            data1['emailID'] = this.state.jobData[i].emailID
            data1['mobileNo'] = this.state.jobData[i].mobileNo
            if (this.state.showRegDate === true) {
                data1['createdDate'] = this.state.jobData[i].createdDate
            }
            if (this.state.showLocation === true) {
                data1['location'] = this.state.jobData[i].location
            }
            if (this.state.showWhoIs === true) {
                data1['whoIs'] = this.state.jobData[i].whoIs
            }
            if (this.state.showFeatScore === true) {
                data1['featScore'] = this.state.jobData[i].featScore
            }
            if (this.state.showFeatQuartile === true) {
                data1['featQuatletes'] = this.state.jobData[i].featQuatletes
            }
            if (this.state.showNismScore === true) {
                data1['nismScore'] = this.state.jobData[i].nismScore
            }

            temp.push(data1)

        }

        temp.length !== null && temp.map((element, index) => {
            let csvData = []
            csvData.push(
                index + 1,
                element.firstName,
                element.lastName,
                element.panNumber,
                element.emailID,
                element.mobileNo,
                element.hasOwnProperty('createdDate') ? moment(element.createdDate.split(" ")[0], "DD/MM/YYYY").format("DD-MM-YYYY") : '',
                element.hasOwnProperty('location') ? element.location : '',
                element.hasOwnProperty('whoIs') ? element.whoIs : '',
                element.hasOwnProperty('featScore') ? element.featScore : '',
                element.hasOwnProperty('featQuatletes') ? element.featQuatletes : '',
                element.hasOwnProperty('nismScore') ? (element.nismScore) : 'NA'
                // element.createdDate ? moment(element.createdDate.split(" ")[0], "DD/MM/YYYY").format("DD-MM-YYYY") : '',
                // element.location,
                // element.whoIs,
                // element.featScore,
                // element.featQuatletes,
                // element.featQuatletes,
            )
            console.log(csvData)

            tempcsvData2.push(csvData)
        })
        let array = [data, ...tempcsvData2]

        let date = new Date();
        let time = new Date().toLocaleString().split(",")[1]

        let time1 = time.split(" ")[2]
        let time2 = time.split(":")[0] + "-" + time.split(":")[1] + "-" + time1

        time2 = time2.split(" ")[1]

        let date1 = moment(date).format("DD-MM-YYYY")

        let title1 = "DateRequest-List-" + date1 + "-" + time2 + ".csv"


        console.log(array)

        return (
            <React.Fragment>
                <DashboardHeader

                    {...this.props}

                />
                <PopUpPortal
                    HidePopup={this.state.loading}
                    IsVisible={this.state.loading}
                />

                <section class="dashboard-tab user-tab pt-50 mb-50">
                    <div class="padding-rl-50">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-xs-12">

                                    <Formik
                                        enableReinitialize={true}
                                        initialValues={{
                                            status: '',
                                            fname: '',
                                            lname: '',
                                            mobileNumber: '',
                                            fromDate: "",
                                            toDate: "",
                                            fromDate1: '',
                                            toDate1: '',
                                            emailId: '',
                                            panNo: '',
                                            cityId: '',
                                            city: '',
                                            parameter: '',
                                            listOfMonth: this.state.listOfMonth,
                                            listOfDay: this.state.listOfDay,
                                            listOfMonth1: this.state.listOfMonth,
                                            listOfDay1: this.state.listOfDay,
                                            day: '',
                                            month: '',
                                            year: '',
                                            today: '',
                                            tomonth: '',
                                            toyear: ''
                                        }}
                                        validationSchema={Yup.object().shape({
                                            //static validations
                                            fname: Yup.string()
                                                .matches(/^[a-zA-Z ]{1,180}$/, "you must specify text"),
                                            emailId: Yup.string()
                                                .email('Invalid E-Mail'),
                                            lname: Yup.string()
                                                .matches(/^[a-zA-Z ]{1,180}$/, "you must specify text"),
                                            panNo: Yup.string()
                                                .matches(
                                                    /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
                                                    "Please enter the valid PAN number"
                                                ),
                                            mobileNumber: Yup.string()
                                                .matches(/^[6-9]\d{9}$/, "Please enter the valid mobile number")
                                        }
                                        )}

                                        onSubmit={(fields, { resetForm }) => {
                                            
                                            let fromDate1 = ""
                                            if ((fields['year'] !== undefined && fields['year'] !== "") || (fields['month'] !== undefined && fields['month'] !== "") || (fields['day'] !== undefined && fields['day'] !== "")) {
                                                fromDate1 = fields['year'] + "-" + fields['month'] + "-" + fields['day']

                                            } else {
                                                //warning("Please select valid from date", warningNotification);

                                                fromDate1 = ""

                                            }

                                            let toDate1 = ""
                                            if ((fields['toyear'] !== undefined && fields['toyear'] !== "") || (fields['tomonth'] !== undefined && fields['tomonth'] !== "") || (fields['today'] !== undefined && fields['today'] !== "")) {
                                                toDate1 = fields['toyear'] + "-" + fields['tomonth'] + "-" + fields['today']

                                            } else {
                                                //warning("Please select valid to date", warningNotification);

                                                toDate1 = ""

                                            }
                                            if (fromDate1 !== "" && toDate1 !== "") {
                                                if (new Date(fromDate1) > new Date(toDate1)) {
                                                    warning("To Date should be greater than From Date", warningNotification)
                                                    fromDate1 = ""
                                                    toDate1 = ""
                                                }
                                            }
                                            if (fields.status == "SEARCH") {
                                                let param = ''
                                                let isblank = false;
                                                if ((fields.fname !== undefined || fields.fname !== '')) {
                                                    param = param + "firstName=" + fields.fname + '&'
                                                }
                                                if ((fields.lname !== undefined || fields.lname !== '')) {
                                                    param = param + "lastName=" + fields.lname + '&'
                                                }
                                                if (fromDate1 !== undefined && fromDate1 !== "") {
                                                    param = param + "fromDate=" + fromDate1 + '&'
                                                }
                                                else {
                                                    param = param + "fromDate=" + '&'
                                                }
                                                if (toDate1 !== undefined && toDate1 !== "") {
                                                    param = param + "toDate=" + toDate1 + '&'
                                                }
                                                else {
                                                    param = param + "toDate=" + '&'
                                                }
                                                if ((fields.emailId !== undefined || fields.emailId !== '')) {
                                                    param = param + "emailId=" + fields.emailId + '&'
                                                }
                                                if ((fields.panNo !== undefined || fields.panNo !== '')) {
                                                    param = param + "panNumber=" + fields.panNo + '&'
                                                }
                                                if ((fields.city !== '')) {
                                                    param = param + "location=" + fields.city.value + '&'
                                                } else {
                                                    param = param + "location=" + '&'
                                                }
                                                if ((fields.mobileNumber !== undefined || fields.mobileNumber !== '')) {
                                                    param = param + "mobileNumber=" + fields.mobileNumber + '&'
                                                }
                                                if ((fields.stage !== '' && fields.stage !== undefined)) {
                                                    // param = param + "stage=" + fields.stage + '&'
                                                    param = param + "stage=" + fields.stage
                                                    localStorage.setItem('stage', fields.stage)
                                                } else {
                                                    //param = param + "stage=" + '&'
                                                    param = param + "stage="
                                                    localStorage.setItem('stage', "")
                                                }
                                                if (fields.stage !== '' || fields.city !== '' || fields.fname !== '' || fields.lname !== '' || fields.panNo !== '' || fields.emailId !== '' || toDate1 !== "" || fromDate1 !== "" || fields.mobileNumber !== '') {
                                                    // LookUpService.getCielReport(param)
                                                    LookUpService.getCielReport(param, res => {
                                                        if (res.status === "success") {
                                                            this.setState({
                                                                jobData: res.responseListObject
                                                            })
                                                        }
                                                    });
                                                } else {
                                                    warning("Atleast one parameter is required to fetch the data", warningNotification);
                                                }

                                            }
                                            else {
                                                //this.props.fetchDistributor("firstName=&lastName=&fromDate=&toDate=&emailId=&panNumber=&cityId=0&roleId=" + localStorage.getItem("roleId") + "&applicationStatus=&stage=&additionalDetail=false&examLocation=&examFromDate=&examToDate=&stateId=&learnerId=0&amcId=0");
                                                LookUpService.getCielReport(
                                                    "firstName=&lastName=&stage=&panNumber=&emailId=&mobileNumber=&fromDate=&toDate=&location", res => {
                                                        if (res.status === "success") {
                                                            this.setState({
                                                                jobData: res.responseListObject
                                                            })
                                                        }
                                                    });

                                            }

                                        }}

                                        render={({
                                            errors, touched, handleSubmit, values, setFieldValue
                                        }) => (
                                            <React.Fragment>
                                                <Form onSubmit={handleSubmit} className="form-group">
                                                    <section class="updateGDPI">
                                                        <div class="modal-body">
                                                            <div class="row">
                                                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                                                    <div class="product-add">
                                                                        <div class="form-group">
                                                                            <div class="row">
                                                                                <div class="col-md-4 col-sm-4 col-xs-12">
                                                                                    <label>
                                                                                        First Name
                                        </label>
                                                                                    <Field
                                                                                        type="text"
                                                                                        name="fname"
                                                                                        id="fname"
                                                                                        autoComplete="fname"
                                                                                        className={
                                                                                            "form-control" +
                                                                                            (errors.fname &&
                                                                                                touched.text
                                                                                                ? " is-invalid"
                                                                                                : "")
                                                                                        }
                                                                                        placeholder="First Name"
                                                                                        className="form-control"
                                                                                    />
                                                                                    <ErrorMessage
                                                                                        name="fname"
                                                                                        component="div"
                                                                                        className="validation"
                                                                                    />
                                                                                </div>
                                                                                <div class="col-md-4 col-sm-4 col-xs-12">
                                                                                    <label>
                                                                                        Last name
                        </label>
                                                                                    <Field
                                                                                        type="lname"
                                                                                        name="lname"
                                                                                        id="lname"
                                                                                        autoComplete="lname"
                                                                                        className={
                                                                                            "form-control" +
                                                                                            (errors.lname &&
                                                                                                touched.lname
                                                                                                ? " is-invalid"
                                                                                                : "")
                                                                                        }
                                                                                        placeholder="Last Name"
                                                                                    />
                                                                                    <ErrorMessage
                                                                                        name="lname"
                                                                                        component="div"
                                                                                        className="validation"
                                                                                    />
                                                                                </div>
                                                                                <div class="col-md-4 col-sm-4 col-xs-12">
                                                                                    <label>
                                                                                        Email Id
                                        </label>
                                                                                    <Field
                                                                                        type="emailId"
                                                                                        name="emailId"
                                                                                        id="emailId"
                                                                                        autoComplete="emailId"
                                                                                        className={
                                                                                            "form-control" +
                                                                                            (errors.emailId &&
                                                                                                touched.emailId
                                                                                                ? " is-invalid"
                                                                                                : "")
                                                                                        }
                                                                                        placeholder="Email Id"
                                                                                    />
                                                                                    <ErrorMessage
                                                                                        name="emailId"
                                                                                        component="div"
                                                                                        className="validation"
                                                                                    />
                                                                                </div>
                                                                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12"></div>
                                                                                <div class="col-md-4 col-sm-4 col-xs-12">
                                                                                    <label>
                                                                                        Registered From Date
                        </label>



                                                                                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                                                                        <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 pl pr">
                                                                                            <Field
                                                                                                as="select"
                                                                                                name="day"
                                                                                                id="day"
                                                                                                // onChange={handleChange}
                                                                                                //  disabled={this.state.isDisabled}
                                                                                                //  disabled
                                                                                                onChange={
                                                                                                    e => {

                                                                                                        
                                                                                                        var listOfMonth = []
                                                                                                        setFieldValue(values.day = e.target.value)
                                                                                                        if (e.target.value == 31) {
                                                                                                            listOfMonth = [{ "key": 1, "value": "Jan" },

                                                                                                            { "key": 3, "value": "Mar" },

                                                                                                            { "key": 5, "value": "May" },

                                                                                                            { "key": 7, "value": "Jul" },
                                                                                                            { "key": 8, "value": "Aug" },

                                                                                                            { "key": 10, "value": "Oct" },

                                                                                                            { "key": 12, "value": "Dec" },
                                                                                                            ];


                                                                                                        } else if (e.target.value == 30) {
                                                                                                            listOfMonth = [

                                                                                                                { "key": 1, "value": "Jan" },

                                                                                                                { "key": 3, "value": "Mar" },
                                                                                                                { "key": 4, "value": "Apr" },
                                                                                                                { "key": 5, "value": "May" },
                                                                                                                { "key": 6, "value": "Jun" },
                                                                                                                { "key": 7, "value": "Jul" },
                                                                                                                { "key": 8, "value": "Aug" },
                                                                                                                { "key": 9, "value": "Sep" },
                                                                                                                { "key": 10, "value": "Oct" },
                                                                                                                { "key": 11, "value": "Nov" },
                                                                                                                { "key": 12, "value": "Dec" },

                                                                                                            ];


                                                                                                        }
                                                                                                        else if (e.target.value == 28 || e.target.value == 29) {
                                                                                                            listOfMonth = [{ "key": 1, "value": "Jan" },
                                                                                                            { "key": 2, "value": "Feb" },
                                                                                                            { "key": 3, "value": "Mar" },
                                                                                                            { "key": 4, "value": "Apr" },
                                                                                                            { "key": 5, "value": "May" },
                                                                                                            { "key": 6, "value": "Jun" },
                                                                                                            { "key": 7, "value": "Jul" },
                                                                                                            { "key": 8, "value": "Aug" },
                                                                                                            { "key": 9, "value": "Sep" },
                                                                                                            { "key": 10, "value": "Oct" },
                                                                                                            { "key": 11, "value": "Nov" },
                                                                                                            { "key": 12, "value": "Dec" },
                                                                                                            ];
                                                                                                        } else {
                                                                                                            listOfMonth = [{ "key": 1, "value": "Jan" },
                                                                                                            { "key": 2, "value": "Feb" },
                                                                                                            { "key": 3, "value": "Mar" },
                                                                                                            { "key": 4, "value": "Apr" },
                                                                                                            { "key": 5, "value": "May" },
                                                                                                            { "key": 6, "value": "Jun" },
                                                                                                            { "key": 7, "value": "Jul" },
                                                                                                            { "key": 8, "value": "Aug" },
                                                                                                            { "key": 9, "value": "Sep" },
                                                                                                            { "key": 10, "value": "Oct" },
                                                                                                            { "key": 11, "value": "Nov" },
                                                                                                            { "key": 12, "value": "Dec" },
                                                                                                            ];

                                                                                                        }
                                                                                                        setFieldValue(values.listOfMonth = listOfMonth)
                                                                                                        // this.setState({
                                                                                                        //   listOfMonth: listOfMonth
                                                                                                        // })
                                                                                                    }}

                                                                                                className={
                                                                                                    "form-control" +
                                                                                                    (errors.day && touched.day
                                                                                                        ? "is-invalid"
                                                                                                        : "")
                                                                                                }
                                                                                                className="form-control"
                                                                                            >
                                                                                                <option value="">DD</option>
                                                                                                {(
                                                                                                    values.listOfDay ||
                                                                                                    []
                                                                                                ).map(d => (
                                                                                                    <option
                                                                                                        value={d}
                                                                                                    >
                                                                                                        {d}
                                                                                                    </option>
                                                                                                ))}
                                                                                            </Field>
                                                                                        </div>
                                                                                        <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 pl pr">
                                                                                            <Field
                                                                                                as="select"
                                                                                                name="month"
                                                                                                id="month"
                                                                                                //   onChange={handleChange}
                                                                                                //     disabled={this.state.isDisabled}
                                                                                                //  disabled
                                                                                                onChange={
                                                                                                    e => {
                                                                                                        

                                                                                                        setFieldValue(values.month = e.target.value)
                                                                                                        if (e.target.value == 2) {
                                                                                                            var listOfDay = [];
                                                                                                            for (var i = 1; i <= 29; i++) {
                                                                                                                //    var current = year - i;
                                                                                                                listOfDay.push(i);
                                                                                                                // this.setState({ listOfDay: listOfDay });
                                                                                                            }
                                                                                                        } else if (e.target.value == 1 || e.target.value == 3 || e.target.value == 5 || e.target.value == 7 || e.target.value == 8 || e.target.value == 10 || e.target.value == 12) {
                                                                                                            var listOfDay = [];
                                                                                                            for (var i = 1; i <= 31; i++) {
                                                                                                                //    var current = year - i;
                                                                                                                listOfDay.push(i);
                                                                                                                //  this.setState({ listOfDay: listOfDay });
                                                                                                            }
                                                                                                        }
                                                                                                        else {
                                                                                                            var listOfDay = [];
                                                                                                            for (var i = 1; i <= 30; i++) {
                                                                                                                //    var current = year - i;
                                                                                                                listOfDay.push(i);
                                                                                                                //  this.setState({ listOfDay: listOfDay });
                                                                                                            }
                                                                                                        }

                                                                                                        setFieldValue(values.listOfDay = listOfDay)
                                                                                                    }
                                                                                                }
                                                                                                className={
                                                                                                    "form-control" +
                                                                                                    (errors.month && touched.month
                                                                                                        ? "is-invalid"
                                                                                                        : "")
                                                                                                }
                                                                                                className="form-control"
                                                                                            >
                                                                                                <option value="">MM</option>
                                                                                                {(
                                                                                                    values.listOfMonth ||
                                                                                                    []
                                                                                                ).map(m => (
                                                                                                    <option
                                                                                                        value={m.key}
                                                                                                    >
                                                                                                        {m.value}
                                                                                                    </option>
                                                                                                ))}
                                                                                            </Field>
                                                                                        </div>
                                                                                        <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5 pl pr">
                                                                                            <Field
                                                                                                as="select"
                                                                                                name="year"
                                                                                                id="year"
                                                                                                // onChange={handleChange}
                                                                                                //    disabled={this.state.isDisabled}
                                                                                                //  disabled
                                                                                                onChange={
                                                                                                    e => {


                                                                                                        setFieldValue(values.year = e.target.value)
                                                                                                    }}
                                                                                                className={
                                                                                                    "form-control" +
                                                                                                    (errors.year && touched.year
                                                                                                        ? "is-invalid"
                                                                                                        : "")
                                                                                                }
                                                                                                className="form-control"
                                                                                            >
                                                                                                <option value="">YYYY</option>
                                                                                                {(
                                                                                                    this.state.listOfYear1 ||
                                                                                                    []
                                                                                                ).map(year => (
                                                                                                    <option
                                                                                                        value={year}
                                                                                                    >
                                                                                                        {year}
                                                                                                    </option>
                                                                                                ))}
                                                                                            </Field>
                                                                                        </div>
                                                                                    </div>



                                                                                    <p className="validation">  {values.errmsg}</p>
                                                                                    {errors.fromDate && <div className="validation">{errors.fromDate}</div>}
                                                                                </div>
                                                                                <div class="col-md-4 col-sm-4 col-xs-12">
                                                                                    <label>
                                                                                        Registered To Date
                                      </label>


                                                                                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                                                                        <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 pl pr">
                                                                                            <Field
                                                                                                as="select"
                                                                                                name="today"
                                                                                                id="today"
                                                                                                // onChange={handleChange}
                                                                                                //  disabled={this.state.isDisabled}
                                                                                                //  disabled
                                                                                                onChange={
                                                                                                    e => {

                                                                                                        
                                                                                                        var listOfMonth = []
                                                                                                        setFieldValue(values.today = e.target.value)
                                                                                                        if (e.target.value == 31) {
                                                                                                            listOfMonth = [{ "key": 1, "value": "Jan" },

                                                                                                            { "key": 3, "value": "Mar" },

                                                                                                            { "key": 5, "value": "May" },

                                                                                                            { "key": 7, "value": "Jul" },
                                                                                                            { "key": 8, "value": "Aug" },

                                                                                                            { "key": 10, "value": "Oct" },

                                                                                                            { "key": 12, "value": "Dec" },
                                                                                                            ];


                                                                                                        } else if (e.target.value == 30) {
                                                                                                            listOfMonth = [

                                                                                                                { "key": 1, "value": "Jan" },

                                                                                                                { "key": 3, "value": "Mar" },
                                                                                                                { "key": 4, "value": "Apr" },
                                                                                                                { "key": 5, "value": "May" },
                                                                                                                { "key": 6, "value": "Jun" },
                                                                                                                { "key": 7, "value": "Jul" },
                                                                                                                { "key": 8, "value": "Aug" },
                                                                                                                { "key": 9, "value": "Sep" },
                                                                                                                { "key": 10, "value": "Oct" },
                                                                                                                { "key": 11, "value": "Nov" },
                                                                                                                { "key": 12, "value": "Dec" },

                                                                                                            ];


                                                                                                        }
                                                                                                        else if (e.target.value == 28 || e.target.value == 29) {
                                                                                                            listOfMonth = [{ "key": 1, "value": "Jan" },
                                                                                                            { "key": 2, "value": "Feb" },
                                                                                                            { "key": 3, "value": "Mar" },
                                                                                                            { "key": 4, "value": "Apr" },
                                                                                                            { "key": 5, "value": "May" },
                                                                                                            { "key": 6, "value": "Jun" },
                                                                                                            { "key": 7, "value": "Jul" },
                                                                                                            { "key": 8, "value": "Aug" },
                                                                                                            { "key": 9, "value": "Sep" },
                                                                                                            { "key": 10, "value": "Oct" },
                                                                                                            { "key": 11, "value": "Nov" },
                                                                                                            { "key": 12, "value": "Dec" },
                                                                                                            ];
                                                                                                        } else {
                                                                                                            listOfMonth = [{ "key": 1, "value": "Jan" },
                                                                                                            { "key": 2, "value": "Feb" },
                                                                                                            { "key": 3, "value": "Mar" },
                                                                                                            { "key": 4, "value": "Apr" },
                                                                                                            { "key": 5, "value": "May" },
                                                                                                            { "key": 6, "value": "Jun" },
                                                                                                            { "key": 7, "value": "Jul" },
                                                                                                            { "key": 8, "value": "Aug" },
                                                                                                            { "key": 9, "value": "Sep" },
                                                                                                            { "key": 10, "value": "Oct" },
                                                                                                            { "key": 11, "value": "Nov" },
                                                                                                            { "key": 12, "value": "Dec" },
                                                                                                            ];

                                                                                                        }
                                                                                                        setFieldValue(values.listOfMonth1 = listOfMonth)
                                                                                                        // this.setState({
                                                                                                        //   listOfMonth: listOfMonth
                                                                                                        // })
                                                                                                    }}

                                                                                                className={
                                                                                                    "form-control" +
                                                                                                    (errors.today && touched.today
                                                                                                        ? "is-invalid"
                                                                                                        : "")
                                                                                                }
                                                                                                className="form-control"
                                                                                            >
                                                                                                <option value="">DD</option>
                                                                                                {(
                                                                                                    values.listOfDay1 ||
                                                                                                    []
                                                                                                ).map(d => (
                                                                                                    <option
                                                                                                        value={d}
                                                                                                    >
                                                                                                        {d}
                                                                                                    </option>
                                                                                                ))}
                                                                                            </Field>
                                                                                        </div>
                                                                                        <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 pl pr">
                                                                                            <Field
                                                                                                as="select"
                                                                                                name="tomonth"
                                                                                                id="tomonth"
                                                                                                //   onChange={handleChange}
                                                                                                //     disabled={this.state.isDisabled}
                                                                                                //  disabled
                                                                                                onChange={
                                                                                                    e => {


                                                                                                        setFieldValue(values.tomonth = e.target.value)
                                                                                                        if (e.target.value == 2) {
                                                                                                            var listOfDay = [];
                                                                                                            for (var i = 1; i <= 29; i++) {
                                                                                                                //    var current = year - i;
                                                                                                                listOfDay.push(i);
                                                                                                                // this.setState({ listOfDay: listOfDay });
                                                                                                            }
                                                                                                        } else if (e.target.value == 1 || e.target.value == 3 || e.target.value == 5 || e.target.value == 7 || e.target.value == 8 || e.target.value == 10 || e.target.value == 12) {
                                                                                                            var listOfDay = [];
                                                                                                            for (var i = 1; i <= 31; i++) {
                                                                                                                //    var current = year - i;
                                                                                                                listOfDay.push(i);
                                                                                                                //  this.setState({ listOfDay: listOfDay });
                                                                                                            }
                                                                                                        }
                                                                                                        else {
                                                                                                            var listOfDay = [];
                                                                                                            for (var i = 1; i <= 30; i++) {
                                                                                                                //    var current = year - i;
                                                                                                                listOfDay.push(i);
                                                                                                                //  this.setState({ listOfDay: listOfDay });
                                                                                                            }
                                                                                                        }
                                                                                                        setFieldValue(values.listOfDay1 = listOfDay)
                                                                                                    }
                                                                                                }
                                                                                                className={
                                                                                                    "form-control" +
                                                                                                    (errors.tomonth && touched.tomonth
                                                                                                        ? "is-invalid"
                                                                                                        : "")
                                                                                                }
                                                                                                className="form-control"
                                                                                            >
                                                                                                <option value="">MM</option>
                                                                                                {(
                                                                                                    values.listOfMonth1 ||
                                                                                                    []
                                                                                                ).map(m => (
                                                                                                    <option
                                                                                                        value={m.key}
                                                                                                    >
                                                                                                        {m.value}
                                                                                                    </option>
                                                                                                ))}
                                                                                            </Field>
                                                                                        </div>
                                                                                        <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5 pl pr">
                                                                                            <Field
                                                                                                as="select"
                                                                                                name="toyear"
                                                                                                id="toyear"
                                                                                                // onChange={handleChange}
                                                                                                //    disabled={this.state.isDisabled}
                                                                                                //  disabled
                                                                                                onChange={
                                                                                                    e => {


                                                                                                        setFieldValue(values.toyear = e.target.value)
                                                                                                    }}
                                                                                                className={
                                                                                                    "form-control" +
                                                                                                    (errors.toyear && touched.toyear
                                                                                                        ? "is-invalid"
                                                                                                        : "")
                                                                                                }
                                                                                                className="form-control"
                                                                                            >
                                                                                                <option value="">YYYY</option>
                                                                                                {(
                                                                                                    this.state.listOfYear1 ||
                                                                                                    []
                                                                                                ).map(year => (
                                                                                                    <option
                                                                                                        value={year}
                                                                                                    >
                                                                                                        {year}
                                                                                                    </option>
                                                                                                ))}
                                                                                            </Field>
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* <DatePicker selected={values.toDate}
                                        autoComplete="off"
                                        placeholderText="dd-mm-yyyy"
                                        dateFormat="dd-MM-yyyy"
                                        value={values.toDate}
                                        onChangeRaw={event => {
                                         
                                         console.log(typeof event.target.value)
                                         let temp = event.target.value
                                         
                                         if (temp !== undefined) {
                                           if (temp.includes("/")) {
                                             
                                             setFieldValue(values.toDate = "")
                                             setFieldValue(values.errmsg="Date should be in dd-mm-yyyy format.")

                                           }
                                           else {
                                             //setFieldValue(values.toDate = date)
                                             setFieldValue(values.errmsg1="")

                                           }
                                         }
                                       }
                                       }
                                        maxLength="50"
                                                minDate={values.fromDate}
                                                onChange={date => {
                                               
                                                  setFieldValue(values.toDate = date)
                                                  

                                                  setFieldValue(values.toDate1 = moment(date).format("YYYY-MM-DD"))

                                                  // setFieldValue(values.dateOfBirth = date)
                                                }}
                                                className={
                                                  "form-control" +
                                                  (errors.toDate &&
                                                    touched.toDate
                                                    ? " is-invalid"
                                                    : "")
                                                }

                                              /> */}
                                                                                    <p className="validation">  {values.errmsg1}</p>

                                                                                    {errors.toDate && <div className="validation">{errors.toDate}</div>}
                                                                                    {/* <Field
                                          type="date"
                                          name="toDate"
                                          id="toDate"
                                          autoComplete="toDate"
                                          className={
                                            "form-control" +
                                            (errors.toDate &&
                                              touched.toDate
                                              ? " is-invalid"
                                              : "")
                                          }
                                          onChange={(e) => {

                                            var Todate = e.target.value

                                            var FromDate = values.fromDate
                                            if (Todate !== '' && FromDate !== '') {
                                              if ((Date.parse(Todate) < Date.parse(FromDate))) {
                                                //  alert();
                                                warning("Registered To date should be greater than Registered From date", warningNotification);
                                                setFieldValue(
                                                  (values.fromDate = "")
                                                )
                                                setFieldValue(
                                                  (values.toDate = "")
                                                );
                                              }
                                              else {
                                                setFieldValue(
                                                  (values.toDate = Todate)
                                                )
                                              }
                                            }
                                            else {
                                              setFieldValue(
                                                (values.toDate = Todate)
                                              )
                                            }
                                          }
                                          }
                                          placeholder="To Date"
                                        />
                                        <ErrorMessage
                                          name="toDate"
                                          component="div"
                                          className="validation"
                                        /> */}
                                                                                </div>

                                                                                <div class="col-md-4 col-sm-4 col-xs-12">
                                                                                    <label>
                                                                                        Pan No
                        </label>
                                                                                    <Field
                                                                                        type="text"
                                                                                        name="panNo"
                                                                                        id="panNo"
                                                                                        autoComplete="panNo"
                                                                                        className={
                                                                                            "form-control" +
                                                                                            (errors.panNo &&
                                                                                                touched.panNo
                                                                                                ? " is-invalid"
                                                                                                : "")
                                                                                        }
                                                                                        placeholder="PAN No"
                                                                                        style={{textTransform:"uppercase"}}
                                                                                    />
                                                                                    <ErrorMessage
                                                                                        name="panNo"
                                                                                        component="div"
                                                                                        className="validation"
                                                                                    />
                                                                                </div>
                                                                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12"></div>
                                                                                <div class="col-md-4 col-sm-4 col-xs-12">
                                                                                    <label>
                                                                                        Location
                        </label>


                                                                                    <Select
                                                                                        //   onBlur={() => setFieldTouched("parameter", true)}

                                                                                        onChange={(value) => this.onChangeParameter(value, setFieldValue)}
                                                                                        name="city"
                                                                                        options={this.state.cityList}
                                                                                        filterOption={createFilter({
                                                                                            matchFrom: 'start'
                                                                                        })}
                                                                                        value={values.city}

                                                                                    />

                                                                                </div>

                                                                                <div class="col-md-4 col-sm-4 col-xs-12">
                                                                                    <label>
                                                                                        Completed Stages
                        </label>




                                                                                    <Field
                                                                                        as="select"
                                                                                        name="stage"
                                                                                        id="stage"
                                                                                        autoComplete="Quartlet"
                                                                                        // onChange={e=>{
                                                                                        //   setFieldValue(values.stage=e.target.value)
                                                                                        //   localStorage.setItem('stage',e.target.value)
                                                                                        // }}
                                                                                        className={
                                                                                            "form-control" +
                                                                                            (errors.Quartlet &&
                                                                                                touched.Quartlet
                                                                                                ? " is-invalid"
                                                                                                : "")
                                                                                        }
                                                                                        placeholder="Quartlet"
                                                                                    >
                                                                                        <option value="">--Select--</option>

                                                                                        {/* <option value="REGISTERED">REGISTERED</option>
                                                                                        <option value="PROFILE COMPLETED">PROFILE COMPLETED</option>
                                                                                        <option value="FEAT COMPLETED">FEAT COMPLETED</option>
                                                                                        <option value="NISM VA SUBSCRIBED">NISM VA SUBSCRIBED</option>


                                                                                        <option value="NISM VA PAYMENT COMPLETED">NISM VA PAYMENT COMPLETED</option>
                                                                                        <option value="NISM PROFILE CREATED">NISM PROFILES CREATED</option>
                                                                                        <option value="NISM EXAM DATE CONFIRMED">NISM EXAM DATE CONFIRMED</option>
                                                                                        <option value="ADMIT CARD AVAILABLE">ADMIT CARD AVAILABLE</option>
                                                                                        <option value="NISM VA CERTIFICATION">NISM VA CERTIFICATION</option>
                                                                                        <option value="KYD-ARN APPLIED">KYD-ARN APPLIED</option>

                                                                                        <option value="KYD-ARN ACCEPTED">KYD-ARN ACCEPTED</option>
                                                                                        <option value="UNDER EMPANELMENT">UNDER EMPANELMENT</option>
                                                                                        <option value="EMPANELLED">EMPANELLED</option> */}

                                                                                        <option value="REGISTERED">REGISTERED</option>
                                                                                        <option value="PROFILE COMPLETED">PROFILE COMPLETED</option>
                                                                                        <option value="NISM VA SUBSCRIBED">NISM VA SUBSCRIBED</option>
                                                                                        <option value="NISM VA PAYMENT COMPLETED">NISM VA PAYMENT COMPLETED</option>
                                                                                        <option value="NISM PROFILE CREATED">NISM PROFILE CREATED</option>
                                                                                        <option value="NISM EXAM DATE CONFIRMED">NISM EXAM DATE CONFIRMED</option>
                                                                                        <option value="NISM EXAM ENROLMENT COMPLETED">NISM EXAM ENROLMENT COMPLETED</option>
                                                                                        <option value="NISM CERTIFICATION COMPLETED">NISM CERTIFICATION COMPLETED</option>
                                                                                        <option value="EUIN NUMBER APPLIED">EUIN NUMBER APPLIED</option>
                                                                                        <option value="EUIN NUMBER GENERATED">EUIN NUMBER GENERATED</option>
                                                                                        <option value="EUIN NUMBER REJECTED">EUIN NUMBER REJECTED</option>
                                                                                        <option value="EUIN NUMBER FAILED">EUIN NUMBER FAILED</option>
                                                                                        <option value="EUIN RENEWAL APPLIED">EUIN RENEWAL APPLIED</option>
                                                                                        <option value="EUIN RENEWED">EUIN RENEWED</option>
                                                                                        <option value="EUIN RENEWAL REJECTED">EUIN RENEWAL REJECTED</option>
                                                                                        <option value="EUIN RENEWAL FAILED">EUIN RENEWAL FAILED</option>






                                                                                    </Field>




                                                                                </div>
                                                                                {/* <div class="col-md-4 col-sm-4 col-xs-12">









                                                                                    </div> */}
                                                                                <div class="col-md-4 col-sm-4 col-xs-12">
                                                                                    <label>
                                                                                        Mobile Number
                                        </label>
                                                                                    <Field
                                                                                        type="text"
                                                                                        name="mobileNumber"
                                                                                        id="mobileNumber"
                                                                                        autoComplete="mobileNumber"
                                                                                        className={
                                                                                            "form-control" +
                                                                                            (errors.mobileNumber &&
                                                                                                touched.text
                                                                                                ? " is-invalid"
                                                                                                : "")
                                                                                        }
                                                                                        placeholder="Mobile Number"
                                                                                        className="form-control"
                                                                                    />
                                                                                    <ErrorMessage
                                                                                        name="mobileNumber"
                                                                                        component="div"
                                                                                        className="validation"
                                                                                    />
                                                                                </div>

                                                                                {/* :""} */}
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr mb-20">
                                                                            <button
                                                                                type="submit"
                                                                                class="btn-4 pull-right"
                                                                                onClick={async () => {


                                                                                    // alert("values.city"+values.city)
                                                                                    // alert("values.city"+values.fname)
                                                                                    // alert("values.city"+values.city)
                                                                                    // alert("values.city"+values.city)


                                                                                    setFieldValue(
                                                                                        (values.status = 'SEARCH')
                                                                                    );
                                                                                }}
                                                                            >Search</button>
                                                                            <button
                                                                                type="submit"
                                                                                id="close"
                                                                                class="btn-4 pull-right"
                                                                                onClick={async () => {
                                                                                    setFieldValue(
                                                                                        (values.status = 'RESET')
                                                                                    );
                                                                                    setFieldValue(
                                                                                        (values.mobileNumber = '')
                                                                                    );
                                                                                    setFieldValue(
                                                                                        (values.fname = '')
                                                                                    );
                                                                                    setFieldValue(
                                                                                        (values.lname = '')
                                                                                    );
                                                                                    setFieldValue(
                                                                                        (values.fromDate = '')
                                                                                    ); setFieldValue(
                                                                                        (values.toDate = '')
                                                                                    );
                                                                                    setFieldValue(
                                                                                        (values.emailId = '')
                                                                                    ); setFieldValue(
                                                                                        (values.panNo = '')
                                                                                    );

                                                                                    setFieldValue(
                                                                                        (values.city = '')
                                                                                    );
                                                                                    setFieldValue(
                                                                                        (values.stage = '')
                                                                                    );
                                                                                    setFieldValue(
                                                                                        (values.amcList = '')
                                                                                    );



                                                                                    setFieldValue(
                                                                                        (values.day = "")
                                                                                    );
                                                                                    setFieldValue(
                                                                                        (values.month = "")
                                                                                    );
                                                                                    setFieldValue(
                                                                                        (values.year = "")
                                                                                    );


                                                                                    setFieldValue(
                                                                                        (values.today = "")
                                                                                    );
                                                                                    setFieldValue(
                                                                                        (values.tomonth = "")
                                                                                    );
                                                                                    setFieldValue(
                                                                                        (values.toyear = "")
                                                                                    );


                                                                                    localStorage.setItem('stage', "")
                                                                                    //localStorage.setItem('amcid',"")
                                                                                }}
                                                                            >
                                                                                Reset
                  </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </Form>
                                            </React.Fragment>
                                        )}
                                    />

                                    <div class="table-header">


                                        <h3>
                                            <div class="row">
                                                <div class="col-md-6 col-sm-6 col-xs-12">
                                                    <span class="left-icon">
                                                        <i class="fa fa-list" aria-hidden="true"></i>
                                                    </span>{" "}
                       Distributor Details
                        </div>
                                                <div class="col-md-6 col-sm-6 col-xs-12">

                                                    {/* <span class="right-icon">
                                                        <a title="Export to CSV" >

                                                            <CSVLink data={array} filename={title1}>                                      <i

                                                                class="fa fa-file-code-o"
                                                                aria-hidden="true"

                                                            >  </i></CSVLink>

                                                        </a>
                                                    </span> */}
                                                    <span class="right-icon">

                                                        <a title="Export to Excel" >
                                                            <i
                                                                onClick={() => this.exportToCSV()}
                                                                class="fa fa-file-excel-o"
                                                                aria-hidden="true"

                                                            ></i>
                                                        </a>
                                                    </span>
                                                    <span class="right-icon">
                                                        <a title="Export to PDF">
                                                            <i
                                                                onClick={() => this.exportPDF()}
                                                                class="fa fa-file-pdf-o"
                                                                aria-hidden="true"
                                                            ></i>
                                                        </a>
                                                    </span>




                                                    <span class="right-icon" id="action-dropdown">
                                                        <li className="nav-item dropdown notification" id="user-option">

                                                            <a
                                                                //onClick={this.signout}
                                                                className="nav-link text-light"
                                                                className="notification"
                                                                id="navbarDropdown"
                                                                role="button"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                                title="Show/Hide Columns"
                                                            >
                                                                <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
                                                            </a>
                                                            <ul className="dropdown-menu" id="notification-profile" style={{ width: 'auto !important' }}>
                                                                <li className="notification-box">
                                                                    <div class="row">
                                                                        <div class="panel-body">
                                                                            <div className="notification-content">
                                                                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                                                                    <div >


                                                                                        <input
                                                                                            className="form-check-input"
                                                                                            type="checkbox"
                                                                                            name="regDate12"
                                                                                            //checked={true}
                                                                                            //  value="7"
                                                                                            //   onClick={this.selectRole}
                                                                                            checked={this.state.showRegDate}
                                                                                            // disabled={false}
                                                                                            onClick={
                                                                                                e => {

                                                                                                    if (this.state.showRegDate == false) {
                                                                                                        this.setState({
                                                                                                            showRegDate: true
                                                                                                        })
                                                                                                    }
                                                                                                    else {
                                                                                                        this.setState({
                                                                                                            showRegDate: false
                                                                                                        })
                                                                                                    }
                                                                                                }
                                                                                            }

                                                                                            style={{ marginRight: "10px" }}
                                                                                        />
                                                                                        <label
                                                                                            class="form-check-label"
                                                                                            htmlFor="gridCheck"
                                                                                            style={{ marginRight: "15px" }}
                                                                                        >
                                                                                            Registartion Date
                                            </label>
                                                                                        <br />
                                                                                        <input
                                                                                            className="form-check-input"
                                                                                            type="checkbox"
                                                                                            name="location"
                                                                                            // checked={true}
                                                                                            // value="7"
                                                                                            //   onClick={this.selectRole}
                                                                                            checked={this.state.showLocation}
                                                                                            onClick={
                                                                                                e => {
                                                                                                    if (this.state.showLocation == false) {
                                                                                                        this.setState({
                                                                                                            showLocation: true
                                                                                                        })
                                                                                                    }
                                                                                                    else {
                                                                                                        this.setState({
                                                                                                            showLocation: false
                                                                                                        })
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                            style={{ marginRight: "10px" }}
                                                                                        />
                                                                                        <label
                                                                                            class="form-check-label"
                                                                                            htmlFor="gridCheck"
                                                                                            style={{ marginRight: "15px" }}
                                                                                        >
                                                                                            Location
                                            </label> <br />
                                                                                        <input
                                                                                            className="form-check-input"
                                                                                            type="checkbox"
                                                                                            name="whois"
                                                                                            checked={this.state.showWhoIs}
                                                                                            onClick={
                                                                                                e => {
                                                                                                    if (this.state.showWhoIs == false) {
                                                                                                        this.setState({
                                                                                                            showWhoIs: true
                                                                                                        })
                                                                                                    }
                                                                                                    else {
                                                                                                        this.setState({
                                                                                                            showWhoIs: false
                                                                                                        })
                                                                                                    }
                                                                                                }
                                                                                            }


                                                                                            style={{ marginRight: "10px" }}
                                                                                        />
                                                                                        <label
                                                                                            class="form-check-label"
                                                                                            htmlFor="gridCheck"
                                                                                            style={{ marginRight: "15px" }}
                                                                                        >
                                                                                            Who Are You?
                                            </label> <br />

                                                                                        <input
                                                                                            className="form-check-input"
                                                                                            type="checkbox"
                                                                                            name="featScore"
                                                                                            checked={this.state.showFeatScore}

                                                                                            onClick={
                                                                                                e => {
                                                                                                    if (this.state.showFeatScore == false) {
                                                                                                        this.setState({
                                                                                                            showFeatScore: true
                                                                                                        })
                                                                                                    }
                                                                                                    else {
                                                                                                        this.setState({
                                                                                                            showFeatScore: false
                                                                                                        })
                                                                                                    }
                                                                                                }
                                                                                            }


                                                                                            style={{ marginRight: "10px" }}
                                                                                        />
                                                                                        <label
                                                                                            class="form-check-label"
                                                                                            htmlFor="gridCheck"
                                                                                            style={{ marginRight: "15px" }}
                                                                                        >
                                                                                            Feat Score
                                            </label> <br />
                                                                                        <input
                                                                                            className="form-check-input"
                                                                                            type="checkbox"
                                                                                            name="featQuartile"
                                                                                            checked={this.state.showFeatQuartile}
                                                                                            onClick={
                                                                                                e => {
                                                                                                    if (this.state.showFeatQuartile == false) {
                                                                                                        this.setState({
                                                                                                            showFeatQuartile: true
                                                                                                        })
                                                                                                    }
                                                                                                    else {
                                                                                                        this.setState({
                                                                                                            showFeatQuartile: false
                                                                                                        })
                                                                                                    }
                                                                                                }
                                                                                            }



                                                                                            style={{ marginRight: "10px" }}
                                                                                        />
                                                                                        <label
                                                                                            class="form-check-label"
                                                                                            htmlFor="gridCheck"
                                                                                            style={{ marginRight: "15px" }}
                                                                                        >
                                                                                            Feat Quartile
                                            </label>  <br />
                                                                                        <input
                                                                                            className="form-check-input"
                                                                                            type="checkbox"
                                                                                            name="nismScore"
                                                                                            checked={this.state.showNismScore}
                                                                                            onClick={
                                                                                                e => {
                                                                                                    if (this.state.showNismScore == false) {
                                                                                                        this.setState({
                                                                                                            showNismScore: true
                                                                                                        })
                                                                                                    }
                                                                                                    else {
                                                                                                        this.setState({
                                                                                                            showNismScore: false
                                                                                                        })
                                                                                                    }
                                                                                                }
                                                                                            }


                                                                                            style={{ marginRight: "10px" }}
                                                                                        />
                                                                                        <label
                                                                                            class="form-check-label"
                                                                                            htmlFor="gridCheck"
                                                                                            style={{ marginRight: "15px" }}
                                                                                        >
                                                                                            NISM Score
                                            </label>
                                                                                    </div>
                                                                                </div>


                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </span>
                                                </div>
                                            </div>
                                        </h3>
                                    </div>

                                    <div class="bg-box mb-30">
                                        <div class="table-responsive">

                                            <ReactTable
                                                minRows={2}
                                                data={this.state.jobData === null ? [] : this.state.jobData}


                                                className="-striped -highlight"
                                                filterable={false}
                                                onPageChange={this.stateonpagechange}
                                                defaultFilterMethod={this.filterCaseInsensitive}
                                                ref={(refReactTable) => { this.refReactTable = refReactTable; }}

                                                columns={[
                                                    {
                                                        columns: [

                                                            {
                                                                Header: "Sr No",
                                                                id: "row",
                                                                width: 50,
                                                                Cell: (row) => {
                                                                    return <div>{row.index + 1}</div>;
                                                                }
                                                            },
                                                            {
                                                                Header: "Stage",
                                                                id: "stage",
                                                                accessor: d => d.stage,
                                                            },
                                                            {
                                                                Header: "First Name",
                                                                id: "firstName",
                                                                accessor: d => d.firstName,

                                                            },
                                                            {
                                                                Header: "Last Name",
                                                                id: "lastName",
                                                                accessor: d => d.lastName,

                                                            },


                                                            {
                                                                Header: "PAN Number",
                                                                id: "pan",
                                                                accessor: d => d.panNumber
                                                            },
                                                            {
                                                                Header: "Email Id",
                                                                id: "email",
                                                                accessor: d => d.emailID,

                                                            },
                                                            {
                                                                Header: "Mobile No.",
                                                                id: "MobNo",
                                                                accessor: d => d.mobileNo,

                                                            },


                                                            {
                                                                Header: "Registration Date",
                                                                id: "RegDate",
                                                                accessor: d => d.createdDate ? moment(d.createdDate.split(" ")[0], "DD/MM/YYYY").format("DD-MM-YYYY") : '',
                                                                show: this.state.showRegDate

                                                            },
                                                            {
                                                                Header: "Location",
                                                                id: "location",
                                                                accessor: d => d.location,
                                                                show: this.state.showLocation

                                                            },
                                                            {
                                                                Header: "Who Are You?",
                                                                id: "whoIs",
                                                                accessor: d => d.whoIs,
                                                                show: this.state.showWhoIs

                                                            },

                                                            {
                                                                Header: "Feat Score",
                                                                id: "FeatScore",
                                                                accessor: d =>
                                                                    d.featScore,
                                                                show: this.state.showFeatScore

                                                            },
                                                            {
                                                                Header: "Feat Quartile",
                                                                id: "FeatQuartile",
                                                                accessor: d => d.featQuatletes,
                                                                show: this.state.showFeatQuartile

                                                            },

                                                            {
                                                                Header: "NISM Score",
                                                                id: "nismScore",
                                                                accessor: d =>
                                                                    d.nismScore?(d.nismScore):'NA',
                                                                show: this.state.showNismScore

                                                            },

                                                        ]
                                                    }
                                                ]}
                                                defaultPageSize={10}
                                                style={{
                                                    width: "100%",
                                                    maxHeight: "500px"
                                                }}
                                            />


                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>


            </React.Fragment>
        );

    }
}

export default CielReport;
