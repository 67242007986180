import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import learnerIcon from '../../../assets/images/learners.png';
import Finxfooter from '../../../containers/finxfooter/finxfooter';
import ThankU from '../../../assets/images/Thank_u.png';

class RegisterSuccess extends Component {


    handleBackClick = () => {
        window.location.replace('/');
    }
    render() {
        return (


            // <React.Fragment>
            //     <CssBaseline />
            //     <div className="container mb-50p">
            //         <div className="col-md-7 col-sm-7 col-md-offset-3 col-sm-offset-3 mt-30">


            //             <div className="modal-content">
            //                 <section className="registration resetpass-modal">
            //                     <div className="modal-body">
            //                         <div className="row">
            //                             <div className="col-md-4 col-sm-6 col-xs-12">
            //                                 <div className="modal-head">
            //                                 </div>
            //                                 <img src={learnerIcon} className="img-responsive" />
            //                             </div>
            //                             <div className="col-md-7 col-md-offset-1 col-sm-6 col-xs-12" >
            //                                 <div className="modal-head text-center">
            //                                     <h3>Thank You! </h3>
            //                                 </div>
            //                                 <p>Congratulations! You have completed ExpertMFD registration 
            //                                     successfully. Credentials to access will be shared shortly 
            //                                     over your registered email id.
            //                                 </p>

            //                                 <div className="row">
            //                                     <div className="col-md-10 col-sm-10 col-xs-12 mt-50">
            //                                         <button type="submit" onClick={this.handleBackClick} className="btn-4 pull-right" name="Submit">Ok</button>
            //                                     </div>
            //                                 </div>
            //                             </div>
            //                         </div>
            //                     </div>
            //                 </section>
            //             </div>
            //         </div>
            //     </div>

            //     <Finxfooter />
            // </React.Fragment>

            <React.Fragment>
                <CssBaseline />
                <div className="container mb-50p">
                    <div className="">
                    <div className="col-md-12 col-sm-12 col-xs-12 text-center" >
                                        {/* <div className="col-md-7 col-md-offset-1 col-sm-6 col-xs-12" > */}
                                            {/* <div className="modal-head text-center">
                                                <h3>Thank You! </h3>
                                            </div>
                                            <p>Congratulations! You have successfully regsitered on CIEL's Regulatory Platform.  You will receive your login credentials on your registered email id shortly.  Alternately you can login with your registered mobile number as well. Thank you.
                                            </p> */}
                                <img src={ThankU} className="img-responsive text-center margin-auto" width="300px"/>
                                                <div className="col-md-12 col-sm-12 col-xs-12">
                                                    <button type="submit" onClick={this.handleBackClick} className="btn-4 margin-auto" name="Submit">Ok</button>
                                                </div>

                        {/* <div className="modal-content">
                            <section className="registration resetpass-modal">
                                <div className="modal-body">
                                    <div className="row"> */}
                                          {/* <div className="col-md-4 col-sm-6 col-xs-12">
                                            <div className="modal-head">
                                            </div>
                                            <img src={learnerIcon} className="img-responsive" />
                                        </div> */}
                         {/* <div className="col-md-12 col-sm-12 col-xs-12" > */}
                                        {/* <div className="col-md-7 col-md-offset-1 col-sm-6 col-xs-12" > */}
                                            {/* <div className="modal-head text-center">
                                                <h3>Thank You! </h3>
                                            </div>
                                            <p>Congratulations! You have successfully regsitered on CIEL's Regulatory Platform.  You will receive your login credentials on your registered email id shortly.  Alternately you can login with your registered mobile number as well. Thank you.
                                            </p> */}
                                {/* <img src={ThankU} className="img-responsive" />
                                            <div className="row">
                                                <div className="col-md-10 col-sm-10 col-xs-12 mt-50">
                                                    <button type="submit" onClick={this.handleBackClick} className="btn-4 pull-right" name="Submit">Ok</button>
                                                </div> */}
                                            {/* </div>
                                        </div>
                                    </div>
                                </div>
                            </section> */}
                        </div>
                    </div>
                </div>

                <Finxfooter />
            </React.Fragment>


        );

    }
}

export default RegisterSuccess;