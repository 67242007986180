import { Global_var } from "../global/global_var";
import RestDataSource from "./restdatasource";
export const FeedbackService = {
    PostFeedback,
    getConcernTypeList,
    getMandateBankNameList,
    feedBackReportList
}

function PostFeedback(feedback, fn, fnError) {
    var url = Global_var.BASEURL + Global_var.URL_POSTFEEDBACK;
    return new RestDataSource(url, fnError).Store(feedback, res => {
        if (res != null) {
            if (res.headers["jtitoken"] != null) {
                localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
        }
    });
}
function getConcernTypeList(fn, fnError) {
    var url = Global_var.BASEURL + Global_var.URL_CONCERN_TYPE_LIST;
    return new RestDataSource(url, fnError).GetData(res => {
        if (res != null) {
            if (res.headers["jtitoken"] != null) {
                localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
        }
    });
}
function getMandateBankNameList(fn, fnError) {
    var url = Global_var.BASEURL + Global_var.URL_MANDATE_BANK_NAME_LIST;
    return new RestDataSource(url, fnError).GetData(res => {
        if (res != null) {
            if (res.headers["jtitoken"] != null) {
                localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
        }
    });
}
function feedBackReportList(data, fn, fnError) {
    var url = Global_var.BASEURL + Global_var.URL_FEEDBACK_REPORT_LIST;
    return new RestDataSource(url, fnError).GetOneByParam(data,(res) => {
        if (res != null) {
            if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
        }
        }
    );
  }

  
