import { applyMiddleware, createStore, combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";

import { createLogger } from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";
import productsReducer from "../reducer/userReducer";
import roleReducer from "../reducer/roleReducer";
import roleAccessReducer from "../reducer/roleAccessReducer";
import OtherRoleAccessEMailReducer from '../reducer/OtherRoleAccessEMailReducer';
import userProfileReducers from "../reducer/userProfileReducer";
import learnerReducer from "../reducer/learnerReducer";
import calenderEventsReducer from "../reducer/calenderEventsReducer";
import alertNotificationEventsReducer from "../reducer/alertsNotificationReducer";
import productPreRequisiteReducer from "../reducer/productPreRequisiteReducer";
import productDetailsByPartyIDReducer from "../reducer/productDetailsByPartyIDReducer";
import adminUserReducer from "../reducer/adminUserReducer";
import productDetailsAllReducer from "../reducer/productDetailsAllReducer";
import gdpiStatusReducer from "../reducer/gdpiStatusReducer";
import learnersProgressOverviewReducer from "../reducer/learnerProgressOverviewReducer";
import learnerEnquiryReducer from "../reducer/learnerEnquiryReducer";
import notificationEventsReducer from "../reducer/notificationReducer";

import productPartnerReducer from "../reducer/productPartnerReducer";
import productStatusReducer from "../reducer/productStatusReducer";
import productHasModuleReducer from "../reducer/productHasModuleReducer";
import productProductTypeReducer from "../reducer/productProductTypeReducer";
import learnersPaymentHistoryReducer from "../reducer/learnerPaymentHistoryReducer";
import learnersEnrollmentReducer from "../reducer/learnerEnrollmentReducer";

import modulesListReducer from "../reducer/modulesListReducer";
import adminUserProfileReducer from "../reducer/adminProfileUserReducer";

import learningPathReducer from "../reducer/learningPathReducer";
import learningPathProductDataReducer from "../reducer/learningPathProductDataReducer";
import userActivityReducer from "../reducer/userActivityReducer";
import assessmentReportReducer from "../reducer/assessmentReportReducer";
import cfxAssessmentReportReducer from "../reducer/cfxAssessmentReportReducer";
import unassignedUsersReportReducer from "../reducer/unassignedUsersReducers";
import distributorListReducer from "../reducer/disributorListProfile";
import PreAssessmentReducer from "../reducer/preAssessmentReducer";
import reattemptedReducer from "../reducer/reattemptTestReducer";
import funnelStatusCountReducer from "../reducer/funnelStatusCountReducer"
import maintenanceReducer from "../reducer/maintenanceReducer";
// import { initialState } from '../reducer/userReducer';

const loggerMiddleware = createLogger();
export default createStore(
  combineReducers({
    productsReducer,
    roleReducer,
    roleAccessReducer,
    userProfileReducers,
    learnerReducer,
    calenderEventsReducer,
    alertNotificationEventsReducer,
    notificationEventsReducer,
    productPreRequisiteReducer,
    productDetailsByPartyIDReducer,
    adminUserReducer,
    productDetailsAllReducer,
    learnersProgressOverviewReducer,
    gdpiStatusReducer,
    learnerEnquiryReducer,
    productPartnerReducer,
    productStatusReducer,
    productHasModuleReducer,
    productProductTypeReducer,
    learnersPaymentHistoryReducer,
    modulesListReducer,
    learnersEnrollmentReducer,
    learningPathReducer,
    learningPathProductDataReducer,
    userActivityReducer,
    assessmentReportReducer,
    adminUserProfileReducer,
    cfxAssessmentReportReducer,
    unassignedUsersReportReducer,
    OtherRoleAccessEMailReducer,
    distributorListReducer,
    PreAssessmentReducer,
    reattemptedReducer,
    funnelStatusCountReducer,
    maintenanceReducer
  }),

  composeWithDevTools(applyMiddleware(thunkMiddleware, loggerMiddleware))
);
