import { Global_var } from "../global/global_var";
import RestDataSource from "./restdatasource";
import { LoginService } from "./login.service";

export const MobileValidationService = {
  MobileValidation,
  MobileOTP,
  MobileOTPValidation
};

function MobileValidation(mobilevalidate, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_MOBILE_URL;
      //var mobilevalidat = JSON.stringify(ProductByName);
      return new RestDataSource(url, fnError).GetOneByParam(
        mobilevalidate,
        res => {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
          }
        }
      );
    }
  }, fnError);

  // LoginService.TokenUser(res => {
  //     localStorage.setItem('jwt-token', res.responseObject);
  //     
  //     var url = Global_var.BASEURL + Global_var.URL_MOBILE_URL;
  //     return new RestDataSource(url, fnError).GetOneByParam(mobilevalidate, (res) => fn(res));
  // }, fnError);
}

function MobileOTP(mobilenumber, countryCode, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_SENDOTP;

      let mobileotp = "mobileNo=" + mobilenumber + "&countryCode=" + countryCode;

      return new RestDataSource(url, fnError).GetOneByParam(mobileotp, res => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}

function MobileOTPValidation(mobilenumber, countryCode, otp, fn, fnError) {

  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_OTP_VALIDATE;
      let mobileotp = "mobileNo=" + mobilenumber + "&countryCode=" + countryCode + "&otp=" + otp;

      return new RestDataSource(url, fnError).GetOneByParam(mobileotp, (res) => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem('jti-token', res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}
